import * as yup from 'yup';
import {checkImmat, checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment/moment';

export const validationSchemaIT = yup.object({
  immatriculation: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu, voir',
          function(value) {
            return checkImmat(value || '')
          }),
  referenceSinistre: yup.string()
      .required('Veuillez saisir une valeur')
      .min(6, 'La référence sinistre doit faire au moins 6 caractères')
      .test('test-referenceSinistre-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
      'identiques d\'affilée',
      function(value) {
        return checkRefSinFormat(value || '')
      })
      .test('test-referenceSinistre-char', 'Le champ contient des caractères interdits',
          function(value) {
            return checkRefSinSpecialChar(value || '')
          }),
  date: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  heure: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heure', 'L\'heure doit être antérieure à l\'heure actuelle pour une date égale à celle du jour',
          function(value, ctx) {
            const date = ctx.parent.date;
            if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
              return value <= moment().format('HH:mm')
            }
            return true
          }),
});
