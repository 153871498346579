import React, {useEffect, useState} from 'react';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {getValeursCodes} from '../../../api/norme';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {ModalJustificatif} from './ModalJustificatif';
import {MenuItem, Modal, Slide, Typography} from '@mui/material';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {IAttachment, IAttachments} from './initialStateFormFDV';
import {customTheme} from '../../../common/GlobalTheme';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface AlerteProps {
  onChangeFamilleAlerte: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeTypeAlerte: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeCommentaire: (event: React.ChangeEvent<HTMLInputElement>) => void,
  familleAlerteValue: string,
  onErrorFamille?: boolean,
  typeAlerteValue: string,
  onErrorType?: boolean,
  commentaireAlerteValue: string,
  openModalJustificatifsValue: {alerteId: number, open: boolean},
  onClickOpenModalJustificatifs: () => void,
  onClickCloseModalJustificatifs: () => void,
  alerteId: number,
  attachments: IAttachment[],
  onSaveAttachments: (attachments: IAttachment[], idAlerte: number) => void
}

/** Design */
const styles = (theme: Theme) => ({
  alerteContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  error: {
    fontSize: '12px',
  },
  red: {
    color: 'red',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

const getSublistOfListByValue = (list: ItemGetValeursCodesModel[], valuesList: string[]) => {
  return list.filter((item) => valuesList.includes(item.valeur))
};

export const Alerte = ({onChangeFamilleAlerte, onChangeTypeAlerte, familleAlerteValue, onErrorFamille,
  typeAlerteValue, onErrorType, commentaireAlerteValue, onChangeCommentaire,
  openModalJustificatifsValue, onClickOpenModalJustificatifs, onClickCloseModalJustificatifs,
  alerteId, attachments, onSaveAttachments}: AlerteProps) => {
  const classes = useEmotionStyles(styles);

  const [familleAlerteList, setFamilleAlerteList] = useState([]);
  const [typeAlerteList, setTypeAlerteList] = useState<ItemGetValeursCodesModel[]>([]);
  const [typeAlerteListToDisplay, setTypeAlerteListToDisplay] = useState<ItemGetValeursCodesModel[]>([]);

  useEffect(() => {
    getValeursCodes('AR2', '98700101').then((response) => setFamilleAlerteList(response));
    getValeursCodes('AR2', '98660101').then((response) => setTypeAlerteList(response))
  }, []);

  useEffect(() => {
    if (familleAlerteValue === 'A') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(typeAlerteList, ['11', '20', '21', '23']))
    } else if (familleAlerteValue === 'B') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(
          typeAlerteList, ['01', '02', '03', '08', '09', '10', '12', '16', '19', '22', '28']))
    } else if (familleAlerteValue === 'C') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(typeAlerteList, ['25', '29']))
    } else if (familleAlerteValue === 'D') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(
          typeAlerteList, ['04', '05', '13', '14', '24', '26', '27', '30']))
    } else if (familleAlerteValue === 'E') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(typeAlerteList, ['06', '07', '15', '17', '18']))
    } else if (familleAlerteValue === 'Z') {
      setTypeAlerteListToDisplay(getSublistOfListByValue(typeAlerteList, ['98', '99']))
    }
  }, [familleAlerteValue]);

  const familleAlerteListSort = [...familleAlerteList].sort(
      (a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
        if (a.libelleedition > b.libelleedition) {
          return 1
        } else {
          return -1
        }
      });
  const itemsListFamilleAlerte = familleAlerteListSort.map((fam: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={fam.valeur} value={fam.valeur}>{fam.libelleedition}</MenuItem>
    )
  });

  const typeAlerteListToDisplaySort = [...typeAlerteListToDisplay].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListTypeAlerte = typeAlerteListToDisplaySort.map((type: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={type.valeur} value={type.valeur}>{type.libelleedition}</MenuItem>
    )
  });

  const onUpload = (justificatifs: IAttachments) => {
    onSaveAttachments(justificatifs.attachments, alerteId)
  };

  const slideContentJustificatif = (
    <div>
      <ModalJustificatif
        alerteId={alerteId}
        attachmentsList={attachments}
        onClose={onClickCloseModalJustificatifs}
        onSave={onUpload}
      />
    </div>
  );

  return (
    <div className={classes.alerteContainer}>
      <Typography className={classes.libelle}>Famille d&apos;alerte <span className={classes.red}>*</span></Typography>
      <TextFieldSelect
        id={'familleAlerte'}
        name={'familleAlerte'}
        marginDense={true}
        value={familleAlerteValue}
        onChange={onChangeFamilleAlerte}
        itemsList={itemsListFamilleAlerte}
        onError={onErrorFamille}
      />
      {onErrorFamille ?
        <Typography className={`${classes.red} ${classes.error}`}>
          Veuillez sélectionner une valeur
        </Typography> : ''
      }

      <Typography className={classes.libelle} style={{marginTop: '10px'}}>
        Type d&apos;alerte <span className={classes.red}>*</span>
      </Typography>
      <TextFieldSelect
        id={'typeAlerte'}
        name={'typeAlerte'}
        marginDense={true}
        disabled={!familleAlerteValue}
        value={typeAlerteValue}
        onChange={onChangeTypeAlerte}
        itemsList={itemsListTypeAlerte}
        onError={onErrorType}
      />
      {familleAlerteValue && onErrorType ?
        <Typography className={`${classes.red} ${classes.error}`}>
          Veuillez sélectionner une valeur
        </Typography> : ''
      }

      <Typography className={classes.libelle} style={{marginTop: '20px'}}>Justificatifs</Typography>
      <div style={{marginTop: '5px'}}>
        <ButtonLink id={'boutonJustificatifs' + alerteId} libelle={attachments.length !== 0 ?
          'Gérer les justificatifs (' + attachments.length + ')' :
          'Ajouter des justificatifs'}
        isLibelleUpperCase={true} isLibelleBold={true} onClick={onClickOpenModalJustificatifs}/>
      </div>

      <Typography className={classes.libelle} style={{marginTop: '20px'}}>Informations complémentaires</Typography>
      <TextFieldMultiline id={'infosComplementaires'} marginDense={true} value={commentaireAlerteValue}
        maxLength={800} rows={4} onChange={onChangeCommentaire} helperText={commentaireAlerteValue.length + '/800'}
        backgroundColorHelperText={customTheme.palette.secondary.light}/>

      <Modal
        open={openModalJustificatifsValue.alerteId === alerteId ? openModalJustificatifsValue.open : false}
        aria-labelledby="modal-justificatif"
        aria-describedby="modal-formulaire-fdv-justificatif"
      >
        <Slide in={openModalJustificatifsValue.alerteId === alerteId ? openModalJustificatifsValue.open : false}
          direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentJustificatif}
        </Slide>
      </Modal>
    </div>
  )
};
