import React from 'react';
import {Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface BlocTitleProps {
    title: string,
}

const styles = (theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '4px',
  },
});

const BlocTitle = (props: BlocTitleProps) => {
  const classes = useEmotionStyles(styles);
  const {title} = props;
  return (
    <Typography className={classes.title}>
      {title}
    </Typography>
  );
};

export default BlocTitle;
