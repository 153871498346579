import {ICodeValeur} from './Models';

export const detailOptiqueList: ICodeValeur[] =
  [
    {
      id: 0,
      valeur: '281002',
      libelleedition: 'Feu Avant',
    },
    {
      id: 1,
      valeur: '271004',
      libelleedition: 'Feu Arrière',
    },
  ];

export const typeInterventionList: ICodeValeur[] =
  [
    {
      id: 0,
      valeur: '1',
      libelleedition: 'Réparation',
    },
    {
      id: 1,
      valeur: '2',
      libelleedition: 'Remplacement',
    },
    {
      id: 2,
      valeur: 'L',
      libelleedition: 'Lustrage',
    },
  ];
