import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContentDEDTProps {
  code?: string,
  valeur: string,
  libelle: string,
  type: string,
}

interface ContentDCProps {
  libelle: string,
  type: string,
  contenu: ContentDEDTProps[],
}

interface ContentSELISTProps {
  type: string,
  contenu: ContentSEProps[],
  openAccordion: boolean,
}

interface ContentSEProps {
  libelle: string,
  type: string,
  contenu: Array<ContentDCProps | ContentDEDTProps>,
  openAccordion: boolean,
}

interface ContentGRLISTProps {
  type: string,
  contenu: ContentGRProps[],
  openAccordion: boolean,
}

interface ContentGRProps {
  libelle: string,
  type: string,
  contenu: Array<ContentGRLISTProps | ContentGRProps | ContentSELISTProps | ContentSEProps>,
  openAccordion: boolean,
}

const styles = (theme: Theme) => ({
  divDC: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  libelleDC: {
    marginLeft: '32px',
    fontSize: '12px',
  },
  content: {
    fontSize: '12px',
  },
  hr: {
    border: 'solid 1px ' + theme.palette.secondary.main,
    margin: '1px 0',
  },
  accordion: {
    'fontSize': '10px',
    'boxShadow': 'none',
    '&.Mui-expanded': {
      margin: '5px 0',
    },
  },
  accordionSummary: {
    backgroundColor: theme.palette.secondary.main,
  },
  accordionSummaryRoot: {
    'minHeight': 'inherit',
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  accordionSummaryContent: {
    'margin': '0',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  accordionSummaryExpandIcon: {
    padding: '0 12px',
  },
  libelleSE: {
    color: theme.palette.link.main,
    fontWeight: 'bold',
    fontSize: '12px',
  },
  accordionDetails: {
    display: 'inherit',
    padding: '8px 16px 0',
  },
  divDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  libelleGR: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
});

/** Retourne true si l'objet passé en paramètre est une instance de ContentDEDTProps */
function instanceOfContentDEDTProps(object: any): object is ContentDEDTProps {
  return 'valeur' in object
}

/* Affichage des DE et DT */
export const getContentDEDT = (content: ContentDCProps | ContentDEDTProps) => {
  let result = '';

  if (content && instanceOfContentDEDTProps(content)) {
    const code = content.code;
    const valeur = content.valeur;

    if (code && valeur) {
      result = code + ' - ' + valeur
    } else if (valeur) {
      result = valeur
    }
  }

  return result
};

/* Affichage des DC */
export const ContentDC = (props: ContentDCProps) => {
  const {contenu} = props;
  const classes = useEmotionStyles(styles);
  let i = 0;
  return (
    <div>
      {contenu?.map((value) => {
        i++;
        return (
          <div key={value.type + '-' + i}>
            <div className={classes.divDC}>
              <span style={{width: 'max-content'}}>
                <Tooltip disableInteractive title={value.type} placement="right">
                  <Typography className={classes.libelleDC}>{value.libelle}</Typography>
                </Tooltip>
              </span>
              <Typography className={classes.content}>{getContentDEDT(value)}</Typography>
            </div>
            <hr className={classes.hr}/>
          </div>
        )
      })}
    </div>
  )
};

/* Affichage des SE */
export const ContentSE = (props: ContentSEProps) => {
  const {type, libelle, contenu, openAccordion} = props;
  const classes = useEmotionStyles(styles);
  const [open, setOpen] = useState<boolean>(openAccordion);
  let i = 0;

  useEffect(() => {
    setOpen(openAccordion)
  }, [openAccordion]);

  return (
    <Accordion classes={{root: classes.accordion}} expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        className={classes.accordionSummary}
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
          expandIconWrapper: classes.accordionSummaryExpandIcon,
        }}
        expandIcon={<ExpandMoreIcon/>}
      >
        <Tooltip disableInteractive title={type} placement="right">
          <Typography className={classes.libelleSE}>{libelle}</Typography>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionDetails}}>
        {contenu?.map((value) => {
          i++;
          return (
            <div key={value.type + '-' + i}>
              <div className={classes.divDetails}>
                <span style={{width: 'max-content'}}>
                  <Tooltip disableInteractive title={value.type} placement="right">
                    <Typography className={classes.content}>{value.libelle}</Typography>
                  </Tooltip>
                </span>
                <span>
                  <Typography className={classes.content}>{getContentDEDT(value)}</Typography>
                </span>
              </div>
              <hr className={classes.hr}/>
              {value?.type?.startsWith('DC') &&
                <ContentDC libelle={value.libelle} type={value.type} contenu={(value as ContentDCProps).contenu}/>
              }
            </div>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
};

/* Affichage des SE LIST */
export const ContentSELIST = (props: ContentSELISTProps) => {
  const {contenu, openAccordion} = props;
  let i = 0;
  return (
    <>
      {contenu?.map((value) => {
        i++;
        return (
          <ContentSE
            key={value.type + '-' + i}
            type={value.type}
            libelle={value.libelle}
            contenu={value.contenu}
            openAccordion={openAccordion}
          />
        );
      })}
    </>
  )
};

/* Affichage des GR */
export const ContentGR = (props: ContentGRProps) => {
  const {type, libelle, contenu, openAccordion} = props;
  const classes = useEmotionStyles(styles);
  const [open, setOpen] = useState<boolean>(openAccordion);
  let i = 0;

  useEffect(() => {
    setOpen(openAccordion)
  }, [openAccordion]);

  return (
    <Accordion className={classes.accordion} expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        className={classes.accordionSummary}
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
          expandIconWrapper: classes.accordionSummaryExpandIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Tooltip disableInteractive title={type} placement="right">
          <Typography className={classes.libelleGR}>{libelle}</Typography>
        </Tooltip>
      </AccordionSummary>
      {contenu?.map((value) => {
        i++;
        if (value.type.startsWith('SE') && value.type.substring(9) !== 'LIST') {
          return (
            <AccordionDetails key={value.type + '-' + i} classes={{root: classes.accordionDetails}}>
              <ContentSE
                type={value.type}
                libelle={(value as ContentSEProps).libelle}
                contenu={(value as ContentSEProps).contenu}
                openAccordion={openAccordion}
              />
            </AccordionDetails>
          )
        } else if (value.type.startsWith('SE') && value.type.substring(9) === 'LIST') {
          return (
            <AccordionDetails key={value.type + '-' + i} classes={{root: classes.accordionDetails}}>
              <ContentSELIST
                type={value.type}
                contenu={(value as ContentSELISTProps).contenu}
                openAccordion={openAccordion}
              />
            </AccordionDetails>
          )
        } else if (value.type.startsWith('GR') && value.type.substring(8) !== 'LIST') {
          return (
            <AccordionDetails key={value.type + '-' + i} classes={{root: classes.accordionDetails}}>
              <ContentGR
                type={value.type}
                libelle={(value as ContentGRProps).libelle}
                contenu={(value as ContentGRProps).contenu}
                openAccordion={openAccordion}
              />
            </AccordionDetails>
          )
        } else if (value.type.startsWith('GR') && value.type.substring(8) === 'LIST') {
          return (
            <ContentGRLIST
              key={value.type + '-' + i}
              type={value.type}
              contenu={(value as ContentGRLISTProps).contenu}
              openAccordion={openAccordion}
            />
          )
        } else {
          return (
            <Typography key={value.type + '-' + i} className={classes.content}>Donnée inconnue</Typography>
          )
        }
      })}
    </Accordion>
  )
};

/* Affichage des GR LIST */
export const ContentGRLIST = (props: ContentGRLISTProps) => {
  const {contenu, openAccordion} = props;
  let i = 0;
  return (
    <>
      {contenu?.map((value) => {
        i++;
        return (
          <ContentGR
            key={value.type + '-' + i}
            type={value.type}
            libelle={value.libelle}
            contenu={value.contenu}
            openAccordion={openAccordion}
          />
        );
      })}
    </>
  )
};
