import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {StyledTypography} from '../../common/StyledTypography';
import moment from 'moment';
import {BlocAdresse} from '../../common/adresse/BlocAdresse';
import {telToScreen} from '../../../common/Utils';
import {ActeurModel} from '../../../model/detailsDossier/ActeurModel';
import {customTheme} from '../../../common/GlobalTheme';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  card: {
    marginBottom: '15px',
  },
});

export const InfosDossierFDV = () => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const referenceSinistre = mission?.infoMissionDTO?.referenceSinistre;
  const immatriculation = mission?.infoMissionDTO?.immatriculation;
  const numeroPolice = mission?.societaireDTO?.numero;
  const numeroMission = mission?.infoMissionDTO?.numeroMission;
  const dateMissionnement = moment(mission?.infoMissionDTO?.dateMissionnement);
  const natureSinistre = mission?.infoMissionDTO?.natureSinistre;
  const adresseSinistre = mission?.gestionDTO?.informationSinistreDTO?.adresseSinistre;
  const natureObstacle = mission?.infoMissionDTO?.natureObstacle;

  const acteursFiltres = mission?.acteursDTO?.acteurs?.filter((acteur: ActeurModel) =>
    ['04', '05', '06'].includes(acteur.codeActeur!));

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant={'h3'}>Identification</Typography>
          <StyledTypography title={'N° Sinistre'} text={referenceSinistre}/>
          <StyledTypography title={'N° d\'immatriculation'} text={immatriculation}/>
          <StyledTypography title={'N° de police / sociétaire'} text={numeroPolice}/>
          <StyledTypography title={'N° de la mission'} text={numeroMission}/>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant={'h3'}>Acteurs</Typography>
          {acteursFiltres?.map((acteur: ActeurModel, index: number) => {
            const keyDiv = `${index}`
            return (
              <div key={keyDiv} style={{marginBottom: acteursFiltres.length !== index + 1 ? '15px' : '0px'}}>
                <Typography style={customTheme.styledTypography.libelle}>{acteur.role}</Typography>
                <Typography style={customTheme.styledTypography.donneeGrise16}>{acteur.libelle}</Typography>
                <Typography style={customTheme.styledTypography.donneeGrise16}>{acteur.telephonePersonnel ?
                  telToScreen(acteur.telephonePersonnel) : telToScreen(acteur.telephoneMobile)}
                </Typography>
              </div>
            )
          })}
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant={'h3'}>Événement</Typography>
          {dateMissionnement.isValid() ?
            <StyledTypography title={'Date'} text={dateMissionnement.format('DD/MM/YYYY')}/> :
            <StyledTypography title={'Date'} text={''}/>
          }
          <StyledTypography title={'Nature du sinistre'} text={natureSinistre}/>
          <BlocAdresse title={'Lieu'} adresse={adresseSinistre} />
          <StyledTypography title={'Nature de l\'obstacle'} text={natureObstacle}/>
        </CardContent>
      </Card>
    </React.Fragment>
  )
};
