import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PieceJointe from '../pieceJointe/PieceJointe';
import {customTheme as theme} from '../../../common/GlobalTheme';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {Siv} from '../siv/Siv';
import Mission from '../mission/Mission';
import {VueEdi} from '../../detailsDossier/components/historiqueComponents/vueEDI/VueEdi';
import VuePdf from '../pdf/VuePdf';
import Edi from '../edi/Edi';

const LiensTransparents = () => {
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path={'/extranet/liens-transparents/pj/:urlParams'} element={<PieceJointe/>}/>
            <Route path={'/extranet/liens-transparents/mission/:token'} element={<Mission/>}/>
            <Route
              path={'/extranet/liens-transparents/mission/vue-edi/:tokenEdi'}
              element={<VueEdi isAccesTransparent={true}/>}
            />
            <Route path={'/extranet/liens-transparents/siv/:urlParams'} element={<Siv/>}/>
            <Route path={'/extranet/liens-transparents/edi/:tokenEdi'} element={<Edi/>}/>
            <Route path={'/extranet/liens-transparents/pdf/:urlParams'} element={<VuePdf/>}/>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
};

export default LiensTransparents;
