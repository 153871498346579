import React from 'react';
import {InformationsTitulaire} from './InformationsTitulaire';
import {Entete} from './Entete';

interface ReponseITProps {
  formulaire: string,
  data: any,
  prefix: string
}

export const ReponseIT = (props: ReponseITProps) => {
  const {formulaire, data, prefix} = props

  return (<div>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <Entete data={data} prefix={prefix} formulaire={formulaire}/>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}/>
    </div>
    <InformationsTitulaire data={data} prefix={prefix}/>
  </div>)
};
