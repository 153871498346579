import produce from 'immer'
import {Dispatch} from 'react'

import {SearchModel} from '../model/common/SearchModel'
import {SearchActions} from '../action/search.action'

type SearchActionType =
    | 'SET_SEARCH'

export interface SearchAction {
    type: SearchActionType
    search?: SearchModel
    error? : string
}

export interface SearchDataState {
    search?: SearchModel
    error?: string
    dispatch: Dispatch<SearchAction>
    actions: SearchActions
}


export const searchReducer = (state: SearchDataState, action: SearchAction) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return produce(state, (draftState) => {
        draftState.search = action.search
        return draftState
      })
    default:
      return {...state}
  }
}

export interface SearchReducerState {
  searchReducer: SearchDataState
}
