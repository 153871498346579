import React, {useState} from 'react';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';
import {RefSinistre} from '../../../common/RefSinistre';
import {PoliceAss} from '../../../common/PoliceAss';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {Nom} from '../../../common/personne/Nom';
import {Prenom} from '../../../common/personne/Prenom';
import {SocieteCommerciale} from '../../../common/personne/SocieteCommerciale';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {Typography} from '@mui/material';
import {ISIVFormCTAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {postSIVForm} from '../../../../api/siv';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {Loader} from '../../../common/Loader';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperTextWithIcon} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaCT} from './validationSchemaCT';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '0px 0px 8px 8px',
    marginBottom: '16px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
  marginTop: {
    marginTop: '24px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  typography: {
    fontSize: '14px',
  },
  bottom: {
    textAlign: 'right' as const,
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FormCT = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormCTAccesTransparent;
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation]),
    policeAssurance: getInitialValueSIV([data?.policeAssurance, mission?.societaireDTO?.numero]),
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim()]),
    typePersonne: getInitialValueSIV(['pp', data?.typePersonne]),
    nom: getInitialValueSIV([data?.nom]),
    nomUsage: getInitialValueSIV([data?.nomUsage]),
    prenom: getInitialValueSIV([data?.prenom]),
    societeCommerciale: data?.societeCommerciale || false,
    raisonSociale: getInitialValueSIV([data?.raisonSociale]),
    siren: getInitialValueSIV([data?.siren?.slice(0, 9)]),
    siret: getInitialValueSIV([data?.siret?.slice(0, 14)]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'CT', vehicule: {numeroImmatriculation: formik.values.immatriculation},
      referenceSinistre: formik.values.referenceSinistre, numeroPoliceAssurance: formik.values.policeAssurance,
      titulaireActuel: {isPersonnePhysique: formik.values.typePersonne === 'pp',
        isSocieteCommerciale: formik.values.societeCommerciale,
        identificationPersonne: formik.values.typePersonne === 'pm' ? formik.values.raisonSociale : '',
        siren: formik.values.typePersonne === 'pm' ? formik.values.siren : '',
        siret: formik.values.typePersonne === 'pm' ? formik.values.siret : '',
        nom: formik.values.typePersonne === 'pp' ? formik.values.nom : '',
        nomUsage: formik.values.typePersonne === 'pp' ? formik.values.nomUsage : '',
        prenom: formik.values.typePersonne === 'pp' ? formik.values.prenom : ''},
    };
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        }).catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formik.setFieldTouched(field, false, false)
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaCT,
    onSubmit: onSubmit,
  });

  const disabled = !!(formik.touched.immatriculation && formik.errors.immatriculation) ||
    !!(formik.touched.policeAssurance && formik.errors.policeAssurance) ||
    !!(formik.touched.referenceSinistre && formik.errors.referenceSinistre) ||
    !!(formik.touched.nom && formik.errors.nom) ||
    !!(formik.touched.nomUsage && formik.errors.nomUsage) ||
    !!(formik.touched.prenom && formik.errors.prenom) ||
    !!(formik.touched.raisonSociale && formik.errors.raisonSociale) ||
    !!(formik.touched.siren && formik.errors.siren) ||
    !!(formik.touched.siret && formik.errors.siret);

  return (
    <div>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <Immatriculation
            className={classes.textfield}
            name={'immatriculation'}
            formulaire={'CT'}
            value={formik.values.immatriculation}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('immatriculation')}
            onError={(formik.touched.immatriculation && !!formik.errors.immatriculation) || false}
            helperText={helperTextWithIcon(formik, 'immatriculation', '', classes.helpIcon)}
          />
          <PoliceAss
            className={classes.textfield}
            name={'policeAssurance'}
            formulaire={'CT'}
            value={formik.values.policeAssurance}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('policeAssurance')}
            onError={(formik.touched.policeAssurance && !!formik.errors.policeAssurance) || false}
            helperText={formik.touched.policeAssurance && formik.errors.policeAssurance ?
              formik.errors.policeAssurance : ''
            }
          />
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'CT'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={false}
            value={formik.values.referenceSinistre}
            onError={(formik.touched.referenceSinistre && !!formik.errors.referenceSinistre) || false}
            helperText={formik.touched.referenceSinistre && formik.errors.referenceSinistre ?
              formik.errors.referenceSinistre : ''
            }
          />
        </div>

        <PersonnePhysiqueMorale
          className={classes.marginTop}
          name={'typePersonne'}
          formulaire={'CT'}
          onChangeValue={formik.handleChange}
          value={formik.values.typePersonne}
        />

        {formik.values.typePersonne === 'pp' &&
          <div className={`${classes.flex} ${classes.marginTop}`}>
            <Nom
              className={classes.textfield}
              name={'nom'}
              value={formik.values.nom}
              fullWidth={true}
              formulaire={'CT'}
              nomUsage={false}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('nom')}
              onError={(formik.touched.nom && !!formik.errors.nom) || false}
              helperText={formik.touched.nom && formik.errors.nom ? formik.errors.nom : ''}
              maxLength={80}
            />
            <Nom
              className={classes.textfield}
              name={'nomUsage'}
              value={formik.values.nomUsage}
              fullWidth={true}
              formulaire={'CT'}
              nomUsage={true}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('nomUsage')}
              onError={(formik.touched.nomUsage && !!formik.errors.nomUsage) || false}
              helperText={formik.touched.nomUsage && formik.errors.nomUsage ? formik.errors.nomUsage : ''}
              maxLength={80}
            />
            <Prenom
              className={classes.textfield}
              name={'prenom'}
              value={formik.values.prenom}
              fullWidth={true}
              formulaire={'CT'}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('prenom')}
              onError={(formik.touched.prenom && !!formik.errors.prenom) || false}
              helperText={formik.touched.prenom && formik.errors.prenom ? formik.errors.prenom : ''}
              maxLength={80}
            />
          </div>
        }

        {formik.values.typePersonne === 'pm' &&
          <React.Fragment>
            <SocieteCommerciale
              className={classes.marginTop}
              name={'societeCommerciale'}
              value={formik.values.societeCommerciale}
              onChangeValue={formik.handleChange}
            />
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <RaisonSociale
                className={classes.textfield}
                name={'raisonSociale'}
                value={formik.values.raisonSociale}
                formulaire={'CT'}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('raisonSociale')}
                onError={(formik.touched.raisonSociale && !!formik.errors.raisonSociale) || false}
                helperText={formik.touched.raisonSociale && formik.errors.raisonSociale ?
                  formik.errors.raisonSociale : ''
                }
              />
              <Siren
                className={classes.textfield}
                id={'siren'}
                name={'siren'}
                value={formik.values.siren}
                formulaire={'CT'}
                isMandatory={formik.values.societeCommerciale}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siren')}
                onError={(formik.touched.siren && !!formik.errors.siren) || false}
                helperText={helperTextWithIcon(formik, 'siren', '', classes.helpIcon)}
              />
              <Siret
                className={classes.textfield}
                id={'siret'}
                name={'siret'}
                value={formik.values.siret}
                formulaire={'CT'}
                isMandatory={formik.values.societeCommerciale}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siret')}
                onError={(formik.touched.siret && !!formik.errors.siret) || false}
                helperText={helperTextWithIcon(formik, 'siret', '', classes.helpIcon)}
              />
            </div>
          </React.Fragment>
        }
      </div>

      <Typography className={classes.typography}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      {responseReturn === -1 &&
        <Typography className={`${classes.red} ${classes.error}`}>
          Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
        </Typography>
      }

      <div className={classes.bottom}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={'boutonEnvoyerCT'} disabled={disabled} onClick={formik.submitForm}/>
        }
      </div>
    </div>
  )
};
