import React, {useEffect, useState} from 'react';
import {CardContainer} from '../CardContainer';
import {
  getLibelleValeur,
  getLibelleValeurCoordonneesTitulaire,
  getValueElement,
} from '../Utils';
import {ItemGetValeursCodesModel} from '../../../../../model/common/ItemGetValeursCodesModel';
import {getValeursCodes} from '../../../../../api/norme';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';

interface TitulaireProps {
    data: any,
    prefix: string
    formulaire: string
}

export const TitulaireNouveau = (props: TitulaireProps) => {
  const {data, prefix, formulaire} = props
  const [typesVoieList, setTypesVoieList] = useState<ItemGetValeursCodesModel[]>([]);
  const [indicesVoieList, setIndicesVoieList] = useState<ItemGetValeursCodesModel[]>([]);

  useEffect(() => {
    getValeursCodes(DOMAIN.SPEC_SIV, 'typeVoieAdresse').then((response) => setTypesVoieList(response));
    getValeursCodes(DOMAIN.SPEC_SIV, 'complementNumeroAdresse').then((response) => setIndicesVoieList(response));
  }, []);

  const libellesValeursTitulaire = (data: any) => {
    const sexe = () => {
      if (data.getElementsByTagName(prefix + 'sexe').length !== 0) {
        return data.getElementsByTagName(prefix + 'sexe')[0].value === 'M' ? 'M' : 'F'
      }
      return ''
    }
    const libelleValeurIdentiteTitulaire = () => {
      if (formulaire == 'DC') {
        if (data.getElementsByTagName(prefix + 'raison_sociale').length !== 0) {
          return ([
            [
              getLibelleValeur('Société commerciale :', getValueElement(data, prefix, 'societe_commerciale')),
            ],
            [
              getLibelleValeur('Raison sociale :', getValueElement(data, prefix, 'raison_sociale')),
              getLibelleValeur('SIREN :', getValueElement(data, prefix, 'siren')),
              getLibelleValeur('SIRET :', getValueElement(data, prefix, 'siret')),
            ],
          ])
        } else {
          return ([
            [
              getLibelleValeur('Nom :', getValueElement(data, prefix, 'nom_usage')),
              getLibelleValeur('Prénom :', getValueElement(data, prefix, 'prenom')),
              getLibelleValeur('Sexe :', sexe()),
            ],
          ])
        }
      } else {
        return ([
          [
            getLibelleValeur('Raison sociale :', getValueElement(data, prefix, 'raison_sociale')),
            getLibelleValeur('N° SIREN :', getValueElement(data, prefix, 'titulaire_nouveau', 'siren')),
            getLibelleValeur('N° SIRET :', getValueElement(data, prefix, 'titulaire_nouveau', 'siret')),
          ],
        ])
      }
    }
    return (
      [
        {
          titre: 'Identité du titulaire',
          libellesValeurs: libelleValeurIdentiteTitulaire(),
        },
        getLibelleValeurCoordonneesTitulaire(data, prefix, false, indicesVoieList, typesVoieList),
      ]
    )
  };

  return (
    <CardContainer
      titreContainer={formulaire == 'DC' ? 'Titulaire (nouveau)' : 'Titulaire (nouveau) : Assureur'}
      content={libellesValeursTitulaire(data)}/>
  )
};
