import {Card, CardContent, Typography} from '@mui/material'
import {StyledTypography} from '../../../common/StyledTypography'
import React from 'react'
import {useMissionContext} from '../../../../store/MissionContext'
import {BlocAdresse} from '../../../common/adresse/BlocAdresse';

export const ContentInfosDossierSimple = () => {
  const {mission} = useMissionContext()
  const informationsMission = mission?.infoMissionDTO
  const informationsSocietaire = mission?.societaireDTO
  return (
    <>
      <StyledTypography id={'numSinistre'} title={'N° de sinistre'} text={informationsMission?.referenceSinistre} />
      <StyledTypography id={'immat'} title={'Immatriculation'} text={informationsMission?.immatriculation} />
      <StyledTypography id={'nomAssure'} title={'Nom de l\'assuré'} text={informationsSocietaire?.nom} />
      <StyledTypography title={'N° de police / sociétaire'} text={informationsSocietaire?.numero} />
      <BlocAdresse title={'Adresse de l\'assuré'} adresse={informationsSocietaire?.adresse} />
    </>
  )
}

export const InfosDossierSimple = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'}>Informations du dossier</Typography>
        <ContentInfosDossierSimple/>
      </CardContent>
    </Card>
  )
}
