import {IDocument} from '@cyntler/react-doc-viewer';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import React from 'react';

interface NoRendererProps {
    document?: IDocument,
    fileName: string
}

const NoRenderer = (props: NoRendererProps) => {
  const {document: doc, fileName} = props
  const onClickDownLoad = (doc: IDocument | undefined) => {
    if (doc) {
      const link = document.createElement('a');
      link.download = fileName || ''
      link.href = doc.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      flexDirection: 'column'}}>
      <p>Impossible de visualiser la pièce jointe {fileName}</p>
      {!!doc && <ButtonBlue id={'telechargerPj'}
        libelle={'Télécharger'}
        onClick={() => onClickDownLoad(doc)}
      />
      }
    </div>)
}

export default NoRenderer;
