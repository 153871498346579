import {MenuItem, Typography} from '@mui/material';
import {TextFieldSelect} from '../formsComponents/TextFieldSelect';
import React, {useEffect, useState} from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {getValeursCodes} from '../../../api/norme';
import {DOMAIN} from '../../../constantes/constantesNormeAuto';

interface IndiceProps {
  className?: string,
  name: string,
  formulaire: string,
  value: string
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

export const Indice = (props: IndiceProps) => {
  const {className, name, formulaire, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  const [indicesVoieList, setIndicesVoieList] = useState([]);

  useEffect(() => {
    getValeursCodes(DOMAIN.SPEC_SIV, 'complementNumeroAdresse').then((response) => setIndicesVoieList(response));
  }, []);

  const indicesVoieListSort = [...indicesVoieList].sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListIndice = indicesVoieListSort.map((indice: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={indice.valeur} value={indice.valeur}>{indice.libelleedition}</MenuItem>
    )
  });

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Indice
      </Typography>

      <TextFieldSelect
        id={'indice' + formulaire}
        name={name}
        fullWidth={true}
        marginDense={true}
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onError={onError}
        helperText={helperText}
        itemsList={itemsListIndice}
        withEmptyItem={true}
        emptyItemLabel={'Aucun'}
      />
    </div>
  )
};
