import ConfigEnvironnement from '../ConfigEnvironnement';
import AxiosService from '../services/AxiosService';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne les configurations environement et keycloak requiqses pour l'authentification
 *
 * @return {Promise} configurations
 */
export async function getConfigurations() {
  const conf = await axiosInstance(`${baseUrl}/config`)
      .then( (response)=> {
        return response.data
      })
      .catch((e)=> console.error(e));

  const configEnvironnement = new ConfigEnvironnement(conf.ssoBaseUrl, conf.realm, conf.clientId,
      conf.sslRequired, conf.redirectUrl, conf.publicClient, conf.service);
  return {
    kcConfig: {
      url: configEnvironnement.ssoBaseUrl + '/auth',
      realm: configEnvironnement.realm,
      clientId: configEnvironnement.clientId,
    },
    configEnvironnement: configEnvironnement,
  }
}
