import './App.css';
import {AppBarHeader} from './components/app-bar/AppBarHeader';
import {Erreur404} from './Erreur404';
import {Loader} from './components/common/Loader';
import {DetailsDossier} from './components/detailsDossier/DetailsDossier';
import {VueEdi} from './components/detailsDossier/components/historiqueComponents/vueEDI/VueEdi';
import {Recherche} from './components/rechercheDossier/RechercheDossier';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import React, {useEffect, useState, useMemo} from 'react';
import {customTheme as theme} from './common/GlobalTheme';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {MissionContextProvider} from './store/MissionContext';
import {SearchContextProvider} from './store/SearchContext';
import {ResultContextProvider} from './store/ResultContext';
import Keycloak from 'keycloak-js';
import ConfigEnvironnement from './ConfigEnvironnement';
import {SIVForm} from './components/forms/siv/SIVForm';
import {FDVForm} from './components/forms/fdv/FDVForm';
import {PortailComponent} from './components/portail/PortailComponent';
import PriseRdv from './components/priseRDV/PriseRDV';
import RechercherRdv from './components/rechercherRDV/RechercherRDV';
import DetailRDV from './components/rechercherRDV/detailRDV/DetailRDV';
import {UserContext, UserContextType, userInitialState} from './store/UserContext';
import useUser from './hook/useUser';
import ReactGA from 'react-ga4'
import {GenericError} from './components/common/errorsComponents/GenericError';
import {Forms} from './components/forms/Others/Forms';
import {ACTION} from './constantes/Action';

interface AppProps {
  keycloak: Keycloak,
  configEnvironnement: ConfigEnvironnement
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const {keycloak, configEnvironnement} = props;
  const ROLE_ASSISTANCE = 'SinappsAuto_Assistance';
  const isAssistance = !!keycloak.tokenParsed?.realm_access?.roles.includes(ROLE_ASSISTANCE);
  const nomCompteAssistance = keycloak.tokenParsed?.name || '';
  const [dataUser, setDataUser] = useState<UserContextType>({...userInitialState,
    token: keycloak.idToken ?? '', isAssistance: isAssistance});

  const storageUser = sessionStorage.getItem('user')
  const userFromStorage: UserContextType = storageUser && JSON.parse(storageUser);

  const [userCompte, setUserCompte] = useState<string>(userFromStorage?.userCompte ||
      (isAssistance ? dataUser.userCompte : keycloak.tokenParsed?.preferred_username));

  const {data, error, loading} = useUser({
    userCompte: userCompte,
    isAssistance: isAssistance,
    isTransparent: false,
    codeService: configEnvironnement.service,
    keycloak: keycloak,
    userFromStorage: userFromStorage,
  });

  useEffect(() => {
    const ID_MESURE = window.extranet.globalConfig.gaIdMesure
    ReactGA.initialize(ID_MESURE)
  }, [])

  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('choixService', user.service);
      setDataUser(user);
    }
  }, [data]);

  useEffect(() => {
    if (isAssistance) {
      sessionStorage.setItem('nomCompteAssistance', nomCompteAssistance);
    }
  }, [isAssistance])

  useEffect(() => {
    const isTraked = localStorage.getItem('isTraked')
    if (!isAssistance && !isTraked && !!dataUser && !!dataUser.type && !!dataUser.type.trim()) {
      ReactGA.event('send_client_type',
          {'type_client': dataUser.type})
      localStorage.setItem('isTraked', JSON.stringify(true))
    }
  }, [dataUser])

  // Évènement déclenché au clic sur la flèche retour du navigateur
  useEffect(() => {
    window.onpopstate = () => {
      if (window.location?.pathname === '/extranet/dossiers') {
        sessionStorage.removeItem('searchRdv');
        sessionStorage.removeItem('fromPath');
        sessionStorage.removeItem('mission');
        sessionStorage.removeItem('listRdv');
        sessionStorage.removeItem('tab');
        sessionStorage.removeItem('tabSiv');
        sessionStorage.removeItem('tokenMission');
        sessionStorage.removeItem('detailRdv');
      }
    };
  });

  const onChoiceClient = (userCompte: string) => {
    sessionStorage.setItem('loginUser', userCompte);
    setUserCompte(userCompte)
  }

  const valueUserContextProvider = useMemo(() => ({user: dataUser, setDataUser}), [dataUser]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <BrowserRouter>
        <UserContext.Provider value={valueUserContextProvider}>
          <ThemeProvider theme={theme}>
            {loading && <Loader/>}

            {error && <GenericError action={ACTION.DECONNEXION} keycloak={keycloak}/>}

            {!loading && !error &&
              <Routes>
                <Route
                  path={'/extranet/portail-sinapps-auto'}
                  element={
                    <PortailComponent configEnvironnement={configEnvironnement} onChangeClient={onChoiceClient} />
                  }
                />
                <Route
                  path='/extranet/'
                  element={<Navigate state={{from: '/extranet/'}} to="/extranet/portail-sinapps-auto"/>}
                />
                <Route
                  path={'/extranet/dossiers/*'}
                  element={
                    <SearchContextProvider>
                      <ResultContextProvider>
                        <MissionContextProvider>
                          <div className="App">
                            <AppBarHeader
                              configEnvironnement={configEnvironnement}
                              setUser={setDataUser}
                              isTransparent={false}
                            />
                            <Routes>
                              <Route path={'/'} element={<Recherche/>}/>
                              <Route path={'/mission/:token'} element={<DetailsDossier/>}/>
                              <Route path={'/formulaires-siv/:tokenSiv'} element={<SIVForm/>}/>
                              <Route path={'/formulaires-siv'} element={<SIVForm/>}/>
                              <Route path={'/prise-rdv/rechercher-rdv'} element={<RechercherRdv/>}/>
                              <Route path={'/prise-rdv/prendre-rdv'} element={<PriseRdv/>}/>
                              <Route path={'/prise-rdv/prendre-rdv/:token'} element={<PriseRdv/>}/>
                              <Route path={'/prise-rdv/modification-rdv'} element={<PriseRdv/>}/>
                              <Route path={'/detail-rdv/:params'} element={<DetailRDV/>}/>
                              <Route path={'/fdv/:tokenFdv'} element={<FDVForm/>}/>
                              <Route path={'/formulaires'} element={<Forms/>}/>
                              <Route path={'*'} element={<GenericError action={ACTION.RETOUR_PORTAIL}/>}/>
                            </Routes>
                          </div>
                        </MissionContextProvider>
                      </ResultContextProvider>
                    </SearchContextProvider>
                  }
                />
                <Route
                  path={'/extranet/mission/vue-edi/:tokenEdi'}
                  element={<VueEdi/>}
                />
                <Route
                  path={'*'}
                  element={<Erreur404/>}
                />
              </Routes>
            }
          </ThemeProvider>
        </UserContext.Provider>
      </BrowserRouter>
    </StyledEngineProvider>
  );
};

export default App;
