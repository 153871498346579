import {Card, CardContent, IconButton, Tooltip, Typography} from '@mui/material'
import React, {useContext, useState} from 'react';
import {ReactComponent as LoupeIcon} from '../../../../images/icones/icn-loupe.svg'
import {ReactComponent as RADIcon} from '../../../../images/icones/icn-rad.svg'
import {ReactComponent as CancelRADIcon} from '../../../../images/icones/icn-cancel-rad.svg'
import {postRAD} from '../../../../api/message';
import {getDocumentPdf} from '../../../../api/dossier';
import {useParams} from 'react-router'
import {ParamsMissionType} from '../../DetailsDossier'
import {TokenEdiModel} from '../../../../model/common/TokenEdiModel';
import {Link} from 'react-router-dom';
import {dateToDateEtHeure} from '../../../../common/utils/DateUtils';
import moment from 'moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {useMessagesList} from '../pjComponents/Attachments';
import {Message} from '../pjComponents/MessageBloc';
import {useMissionContext} from '../../../../store/MissionContext';
import {customTheme} from '../../../../common/GlobalTheme';
import {Loader} from '../../../common/Loader';
import {AttachmentProps, downloadAllAttachments, downloadOneAttachment} from '../DownloadAttachments';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import ModalConfirmation from '../../../common/ModalConfirmation';
import useHasRole from '../../../../hook/useHasRole';
import {permittedRolesRAD, permittedRolesSupport} from '../../../../constantes/roles/Roles';
import {UserContext} from '../../../../store/UserContext';
import {TokenModel} from '../../../../model/common/TokenModel';
import {isArrayEmpty} from '../../../../common/Utils';
import SlideNotification from '../../../common/notifications/SlideNotification';

const base64 = require('base-64');

export interface BlocHistoriqueProps {
  id: string,
  click: (tabPanelValue: number, idPJ: string) => void,
  libelle?: string,
  emetteur?: string,
  destinataire? : string,
  destinataireAbo? : string,
  statut: string,
  etat: string,
  envoyeRecu: string,
  dateReception: string | undefined,
  dateEmission: string | undefined,
  idDocument: string | undefined,
  ssType: string | undefined,
  type: string,
  typeCode: string,
  codeService: string,
  isnIdentifier: string,
  archive: string | undefined,
  docVisualisable: boolean | undefined,
  nbPieceJointe?: number,
  idPieceJointe: string,
  referenceSinistre: string | undefined,
  isDossierSimple: boolean | undefined,
  userCodeAbonne?: string,
  isAssistance: boolean,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    boxShadow: 'none',
    marginBottom: '15px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '8px 15px',
    borderRadius: '8px 8px 0px 0px',
  },
  cardContentRoot: {
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    width: '95%',
  },
  edi: {
    flexBasis: '25px',
    flexGrow: 1,
  },
  contentEdi: {
    backgroundColor: theme.palette.white.main,
    border: '1px solid ' + theme.palette.text.primary,
    borderRadius: '30px',
    width: 'max-content',
  },
  linkEdi: {
    textDecoration: 'none',
  },
  libelle: {
    flexBasis: '10px',
    flexGrow: 3,
  },
  pjContainer: {
    flexBasis: '15px',
    flexGrow: 1,
  },
  loader: {
    display: 'block',
    marginTop: '0px',
    marginLeft: '15px',
  },
  pointer: {
    cursor: 'pointer',
  },
  emetteurEtDestinataire: {
    flexBasis: '0px',
    flexGrow: 7,
    display: 'flex',
  },
  emetteur: {
    flexBasis: '0px',
    flexGrow: 1,
    marginRight: '20px',
  },
  destinataire: {
    flexBasis: '0px',
    flexGrow: 1,
  },
  titreActeur: {
    color: theme.palette.secondary.dark2,
    fontSize: '12px',
  },
  acteur: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  typeDoc: {
    fontSize: '12px',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '0.71',
    padding: '5px 8px',
  },
  iconColor: {
    fill: theme.palette.link.main,
  },
  size14: {
    fontSize: '14px',
  },
  right: {
    display: 'flex',
    minWidth: '15%',
    margin: 'auto 0px',
  },
  loaderPDF: {
    margin: '10px 10px 0px 10px',
  },
  rotate: {
    transform: 'rotate(45deg)',
    margin: '0px',
  },
});

const ARCHIVE = 'O';
const EN_COURS_DESARCHIVAGE = 'A';
const EN_ATTENTE_GESTION = 'EN ATTENTE DE GESTION';
const INACTIF_SD = 'INACTIF PAR SD';
const RECU = 'recu';
const ACTIF = 'Actif';
const EMIS = 'Emis';
const DISPONIBLE = 'Disponible';
const NON_RECUPERABLE = 'Non récupérable';
const MESSAGE_CONFIRMATION_RAD = 'Êtes-vous sûr de vouloir remettre à disposition ce message ?';
const MESSAGE_ANNULATION_RAD = 'Êtes-vous sûr de vouloir annuler la remise à disposition ?';
const AUTO_HIDE_DURATION = 10000;

export const DetailedBlocHistorique = ({
  id,
  click,
  libelle,
  emetteur,
  destinataire,
  destinataireAbo,
  statut,
  etat,
  envoyeRecu,
  dateReception,
  dateEmission,
  idDocument,
  ssType,
  type,
  typeCode,
  codeService,
  isnIdentifier,
  archive,
  docVisualisable,
  nbPieceJointe,
  idPieceJointe,
  referenceSinistre,
  isDossierSimple,
  userCodeAbonne,
  isAssistance,
}: BlocHistoriqueProps) => {
  const classes = useEmotionStyles(styles);
  const params = useParams<keyof ParamsMissionType>() as ParamsMissionType;
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const hasRoleRAD = useHasRole(permittedRolesRAD);
  const hasRoleSupport = useHasRole(permittedRolesSupport);
  const search: TokenModel = JSON.parse(base64.decode(params.token));
  const indicateurArchivage = mission?.indicateurArchivage;
  const [pdfIsPending, setPdfIsPending] = useState<boolean>(false);
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [isLoadingPJ, setIsLoadingPJ] = useState(false);
  const [onErrorDownloadAll, setOnErrorDownloadAll] = useState(false);
  const [onErrorDownloadOne, setOnErrorDownloadOne] = useState(false);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [notification, setNotification] = useState<{theme: 'Success' | 'Error' | 'Warning', message: string}>({
    theme: 'Success',
    message: '',
  });

  const canOpenPdf = () => {
    return !('DI0000' === typeCode || 'RP0000' === typeCode || 'CSIT00' === typeCode || 'CSSA00' === typeCode ||
      'CSCT00' === typeCode || 'ODDA00' === typeCode || 'ODDC00' === typeCode || 'ODIR00' === typeCode ||
      'AQIR00' === typeCode || 'ISIT00' === typeCode || 'ISSA00' === typeCode || 'ISCT00' === typeCode ||
      'AQDA00' === typeCode || 'AQDC00' === typeCode || '900501' === typeCode || ('410301' === typeCode &&
        '31' === ssType) || archive === ARCHIVE || archive === EN_COURS_DESARCHIVAGE) && docVisualisable;
  };

  const isRAD = hasRoleRAD && etat === ACTIF && ((envoyeRecu === RECU && destinataireAbo === userCodeAbonne) ||
      hasRoleSupport) && ((statut === EMIS && !!dateEmission) || (statut === NON_RECUPERABLE && !dateEmission));

  const isCancellableRAD = hasRoleRAD && etat === ACTIF && statut === DISPONIBLE && ((envoyeRecu === RECU &&
      destinataireAbo === userCodeAbonne ) || hasRoleSupport);

  const handlePdf = () => {
    if (idDocument) {
      const urlParams = !user.isTransparent ? search : {
        ...search,
        userCompte: user.userCompte,
        codeAbonne: user.codeAbo,
        codeGTA: user.codeGTA,
      };
      setPdfIsPending(true);
      getDocumentPdf(idDocument, urlParams, typeCode, ssType ?? '', isnIdentifier, user.isTransparent)
          .then((response) => {
            // Create a Blob from the PDF Stream
            const file = new Blob(
                [response],
                {type: 'application/pdf'});
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // Open the URL on new Window
            if (window.navigator.msSaveOrOpenBlob) {
              // IE11
              window.navigator.msSaveOrOpenBlob(file, 'document.pdf')
            } else {
              window.open(fileURL)
            }
            setPdfIsPending(false)
          }).catch(() => {
            setNotification({
              theme: 'Error',
              message: 'Document inaccessible',
            });
            setPdfIsPending(false);
          })
    }
  };

  const ediButton = () => {
    const toLink = !user.isTransparent ? '/extranet/mission/vue-edi/' : '/extranet/liens-transparents/mission/vue-edi/';
    const token: TokenEdiModel = {
      idDocument: idDocument,
      search: search,
      type: typeCode,
      ssType: ssType,
      isnIdentifier: isnIdentifier,
    };
    const tokenEdi = base64.encode(JSON.stringify(token));
    const typeMessage = type?.trim() === 'OM Argos' ? 'OMa' : type?.trim();
    return (
      <div className={classes.edi}>
        <div className={classes.contentEdi}>
          {archive === ARCHIVE || archive === EN_COURS_DESARCHIVAGE ?
            <Typography className={classes.typeDoc}>{typeMessage}</Typography> :
            <Tooltip title='Consulter la vue EDI du message'>
              <Link className={classes.linkEdi} to={toLink + tokenEdi} target={'_blank'}>
                <Typography className={classes.typeDoc}>{typeMessage}</Typography>
              </Link>
            </Tooltip>
          }
        </div>
      </div>
    )
  };
  const acteurList = mission!.acteursDTO!.acteurs!;
  const messages = useMessagesList(acteurList);
  let messageWithAttachments: Message = {
    id: '',
    typeDoc: '',
    libelleDoc: '',
    author: '',
    dateReceive: '',
    nbPj: 0,
  };
  messages.forEach((message) => {
    if (message.id.trim() === idPieceJointe?.trim()) {
      messageWithAttachments = message
    }
  });

  const allPJs = () => {
    const tab: AttachmentProps[] = [];
    messageWithAttachments?.attachments?.forEach((att) => {
      tab.push({
        id: att.id,
        name: att.name,
        date: att.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    messageWithAttachments?.pictures?.forEach((att) => {
      tab.push({
        id: att.id,
        name: att.name,
        date: att.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  const downloadAttachments = () => {
    if (allPJs().length === 1) {
      const attachment: AttachmentProps = {
        id: allPJs()[0].id,
        name: allPJs()[0].name,
        date: messageWithAttachments.dateReceive?.substring(0, 10).replaceAll('/', ''),
      };
      downloadOneAttachment({attachment, author: messageWithAttachments.author || '',
        typeDoc: messageWithAttachments.typeDoc || '',
        referenceSinistre: referenceSinistre ?? '', immatriculation: '', numClient: user.numClient,
        setOnErrorDownload: setOnErrorDownloadOne, setIsLoading: setIsLoadingPJ});
    } else {
      downloadAllAttachments({attachments: allPJs(), author: messageWithAttachments.author || '',
        typeDoc: messageWithAttachments.typeDoc || '', referenceSinistre: referenceSinistre ?? '',
        numClient: user.numClient, setIsLoading: setIsLoadingZip, setIsEndDownload: setIsEndDownload,
        setIsFilesInZip: setIsFilesInZip, setOnErrorDownload: setOnErrorDownloadAll, setNbFilesInZip});
    }
  };

  const onClickRAD = () => {
    setShowModalConfirmation(true)
  };

  const onConfirmRAD = () => {
    const formData = {
      idDocument: isnIdentifier.slice(0, 10),
      typeDocument: typeCode,
      action: isRAD ? 'O' : 'N',
      codeService: codeService,
      isAssistance: isAssistance,
    };

    postRAD(formData)
        .then(() => {
          setNotification({
            theme: 'Success',
            message: formData.action === 'O' ?
              'Remise à disposition effectuée' :
              'Annulation de la remise à disposition effectuée',
          });
        }).catch(() => {
          setNotification({
            theme: 'Error',
            message: formData.action === 'O' ?
              'La remise à disposition est impossible' :
              'L\'annulation de la remise à disposition est impossible',
          });
        }).finally(() => {
          setShowModalConfirmation(false);
          setTimeout(() => {
            window.location.reload()
          }, 500);
        })
  };

  return (
    <Card id={id} className={classes.card}>
      <CardContent classes={{root: classes.cardContentRoot}} className={classes.cardContent}>
        <div className={classes.main}>
          <div className={classes.left}>
            {ediButton()}
            <div className={classes.libelle}>
              <Typography id={`libelleMessage-${id}`} style={{color: customTheme.palette.secondary.dark2}}>
                {libelle?.trim()}
              </Typography>
              <div id={`statutMessage-${id}`} className={classes.size14}>{statut}</div>
            </div>
            {nbPieceJointe !== 0 &&
            (!isArrayEmpty(messageWithAttachments?.attachments) || !isArrayEmpty(messageWithAttachments?.pictures)) &&
            etat?.toUpperCase() !== EN_ATTENTE_GESTION && etat?.toUpperCase() !== INACTIF_SD ?
              <div className={classes.pjContainer}>
                {(isLoadingPJ || isLoadingZip) && <Loader className={classes.loader} size={20}/>}
                {!isLoadingPJ && !isLoadingZip &&
                  <Tooltip title={isDossierSimple ? 'Télécharger PJ' : 'Consulter PJ'}>
                    <IconButton id={`consultPj-${id}`} className={classes.pointer}
                      disabled={indicateurArchivage === 'O' || indicateurArchivage === 'A'}
                      onClick={() => isDossierSimple ? downloadAttachments() : click(3, idPieceJointe)}>
                      <AttachFileIcon
                        className={`${classes.rotate} ${indicateurArchivage === 'N' ? classes.iconColor : ''}`}
                      />
                    </IconButton>
                  </Tooltip>
                }
              </div> :
              <div className={classes.pjContainer}>&nbsp;</div>
            }
            <div className={classes.emetteurEtDestinataire}>
              <div className={classes.emetteur}>
                <Typography className={classes.titreActeur}>Emetteur</Typography>
                <Typography id={`emetteurMessage-${id}`} className={classes.acteur}>
                  {emetteur ? emetteur.trim() : <div>&nbsp;</div>}
                </Typography>
                <div id={`dateReceptionMessage-${id}`} className={classes.size14}>
                  {dateReception ?
                  dateToDateEtHeure(moment(dateReception?.replace(' - ', ' ' ), 'DD/MM/YYYY HH:mm:ss').toDate()) :
                    <span>&nbsp;</span>
                  }
                </div>
              </div>
              <div className={classes.destinataire}>
                <Typography className={classes.titreActeur}>Destinataire</Typography>
                <Typography id={`destinataireMessage-${id}`} className={classes.acteur}>
                  {destinataire ? destinataire.trim() : <div>&nbsp;</div>}
                </Typography>
                <div id={`dateEmissionMessage-${id}`} className={classes.size14}>
                  {dateEmission &&
                    dateToDateEtHeure(moment(dateEmission.replace(' - ', ' ' ), 'DD/MM/YYYY HH:mm:ss').toDate())
                  }
                  {!dateEmission && <span>{etat}</span>}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.right}>
            {pdfIsPending &&
              <Loader className={classes.loaderPDF} size={20}/>
            }
            {!pdfIsPending && canOpenPdf() &&
              <Tooltip title='Consulter le PDF du message'>
                <IconButton id={`btnOpenPDF-${id}`} className={classes.iconColor} onClick={handlePdf}>
                  <LoupeIcon/>
                </IconButton>
              </Tooltip>
            }
            {isRAD &&
                <Tooltip title='Remettre à disposition'>
                  <IconButton
                    id={`BouttonRAD-${id}`}
                    className={classes.iconColor}
                    onClick={onClickRAD}
                  >
                    <RADIcon/>
                  </IconButton>
                </Tooltip>
            }
            {isCancellableRAD &&
                <Tooltip title='Annuler la remise à disposition'>
                  <IconButton
                    id={`BouttonAnnulerRAD-${id}`}
                    className={classes.iconColor}
                    onClick={onClickRAD}
                  >
                    <CancelRADIcon/>
                  </IconButton>
                </Tooltip>
            }
          </div>
        </div>

        {onErrorDownloadAll || onErrorDownloadOne &&
          <Typography style={{color: 'red', fontSize: '14px'}}>
            Erreur lors du téléchargement
          </Typography>
        }

        {!isFilesInZip &&
          <Typography style={{color: 'red', fontSize: '14px', marginTop: '5px'}}>
            Aucune pièce jointe valide à télécharger
          </Typography>
        }

        {isFilesInZip && isEndDownload && nbFilesInZip !== allPJs().length &&
          <Typography style={{color: 'red', fontSize: '14px', marginTop: '5px'}}>
            {nbFilesInZip}
            {nbFilesInZip === 1 ? ' pièce jointe téléchargée' : ' pièces jointes téléchargées'} sur {allPJs().length}
          </Typography>
        }

        {notification.message.length > 0 &&
          <SlideNotification
            theme={notification.theme}
            autoHideDuration={AUTO_HIDE_DURATION}
          >
            {notification.message}
          </SlideNotification>
        }

        <ModalConfirmation
          open={showModalConfirmation}
          message={isRAD ? MESSAGE_CONFIRMATION_RAD : MESSAGE_ANNULATION_RAD}
          onClose={()=> setShowModalConfirmation(false)}
          onConfirm={onConfirmRAD}
        />
      </CardContent>
    </Card>
  )
};
