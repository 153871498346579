import React, {useEffect, useMemo, useState} from 'react';
import {customTheme as theme} from '../../../common/GlobalTheme';
import {AppBarHeader} from '../../app-bar/AppBarHeader';
import {SIVForm} from '../../forms/siv/SIVForm';
import {UserContext, UserContextType, userInitialState} from '../../../store/UserContext';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import useUser from '../../../hook/useUser';
import {Loader} from '../../common/Loader';
import {GenericError} from '../../common/errorsComponents/GenericError';
import {useParams} from 'react-router';
import ConfigEnvironnement from '../../../ConfigEnvironnement';
import {IParamsUrl} from '../model/models';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import {getMessageErrorByStatusCode} from '../utils/utils';

const base64 = require('base-64');

export const Siv = () => {
  const params = useParams<keyof IParamsUrl>();
  const token = JSON.parse(base64.decode(params.urlParams));
  Cookies.set('token', token.accessToken);

  const [dataUser, setDataUser] = useState<UserContextType>(
      {...userInitialState,
        isTransparent: true,
        isAssistance: false,
      });
  const [messageError, setMessageError] = useState<string>('');

  const decodedToken = jwtDecode<any>(token.accessToken);
  const userCompte = decodedToken['preferred_username'];

  const {data, error, loading} = useUser({
    userCompte: userCompte,
    isAssistance: false,
    isTransparent: true,
    codeService: token.codeService,
  });

  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      setDataUser(user);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const statusCode= error.response ? error.response.status : 0;
      const messageErrorByStatusCode = getMessageErrorByStatusCode(statusCode, 'user');
      setMessageError(messageErrorByStatusCode)
    }
  }, [error])

  const conf = new ConfigEnvironnement('', '', '', '', '', '', '');
  const valueUserContextProvider = useMemo(() => (
    {user: data, setDataUser: (user: any) => console.log(user)}), [data, dataUser]);

  return (
    <>
      {loading &&
        <Loader/>
      }
      {!!messageError &&
        <GenericError errorMessage={messageError}/>
      }
      {!loading && dataUser.codeAbo &&
        <StyledEngineProvider injectFirst={true}>
          <UserContext.Provider value={valueUserContextProvider}>
            <ThemeProvider theme={theme}>
              <AppBarHeader configEnvironnement={conf} setUser={(user) => console.log(user)} isTransparent={true}/>
              <SIVForm/>
            </ThemeProvider>
          </UserContext.Provider>
        </StyledEngineProvider>
      }
    </>
  )
};
