import AxiosService from '../services/AxiosService';
import {TokenModel} from '../model/common/TokenModel';
import {DataProps} from '../components/forms/siv/initialStateFormSIV';
import Cookies from 'js-cookie';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne les données permettant de pré-remplir les formulaires DA et DC
 *
 * @param {TokenModel} token - Objet contenant les infos sur le client (numClient, codeGTA, codeAbonne, codeService) et
 * sur la mission (referenceSinistre, numeroMission)
 * @param {String} codeAction - Code de l'action (A = DA, C = DC)
 * @param {Boolean} isFromDA - Booléen indiquant si on vient de la DA
 * @return {Promise} response data
 */
export async function getPreremplissageDADC(token: TokenModel, codeAction: string, isFromDA: boolean) {
  const response = await axiosInstance(`${baseUrl}/preRemplissageSIV`,
      {
        params: {
          numClient: token.numClient,
          userCompte: token.userCompte,
          codeGTA: token.codeGTA,
          codeAbonne: token.codeAbonne,
          codeService: token.codeService,
          referenceSinistre: token.referenceSinistre,
          numeroMission: token.numeroMission,
          codeAction: codeAction,
          isFromDA: isFromDA,
        },
      });
  return response.data
}

/**
 * Envoie un message SIV
 *
 * @param {Object} data - Objet contenant les données du message SIV à envoyer
 * @param {boolean} isAccesTransparent - Booléen qui indique si on est en lien transparent
 * @return {Promise}
 */
export function postSIVForm(data: DataProps, isAccesTransparent: boolean) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Cookies.get('token')}`,
      'X-darva-lien-transparent': isAccesTransparent ? 'O' : 'N',
    },
  };
  return axiosInstance.post(`${baseUrl}/sendSIV`, data, axiosConfig)
}
