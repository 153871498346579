import React from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {TextField, Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {getIdFromName} from '../../utils/Utils';
import moment from 'moment';

interface InputFieldProps extends FieldConfig {
  label?: string
  mandatory?: boolean
  maxLength?: number
  disabled?: boolean
  className?: string
  multiline?: boolean
  minRows?: number
  maxRows?: number
}
const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
})

const InputTextField = ({label, ...props} : InputFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta] = useField<string>(props as FieldHookConfig<string>);
  const {type, maxLength, mandatory, disabled, className, multiline, minRows, maxRows} = props

  return (
    <div className={className ?? classes.container}>
      {label && <Typography className={classes.libelle}>
        {label} {mandatory && <span className={classes.red}>*</span>}
      </Typography>
      }
      <TextField
        id={getIdFromName(field.name)}
        fullWidth
        margin='dense'
        size='small'
        type={type ?? 'search'}
        disabled={Boolean(disabled)}
        multiline={Boolean(multiline)}
        minRows={minRows ?? 1}
        maxRows={maxRows ?? 1}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...field}
        InputProps={{
          inputProps: {
            maxLength: maxLength,
            ...(type === 'date') && {min: '1900-01-01', max: moment().format('YYYY-MM-DD')},
          },
          classes: {
            root: classes.textfield,
          },
        }}
      />
    </div>
  );
};

export default InputTextField;
