import React, {useEffect} from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {stylesCommon} from '../StylesCommon';
import {Entete} from './Entete';
import {Vehicule} from './Vehicule';
import {TitulaireNouveau} from './TitulaireNouveau';
import {Declaration} from './Declaration';
import {TitulaireActuel} from './TitulaireActuel';

interface RequeteDADCProps {
  refSinistre: string,
  numMission: string,
  data: any,
  formulaire: string
}

export const RequeteDADC = (props: RequeteDADCProps) => {
  const classes = useEmotionStyles(stylesCommon);
  const {refSinistre, numMission, data, formulaire} = props

  useEffect(() => {
    if (formulaire == 'DA') document.title = 'Requête DA'
    if (formulaire == 'DC') document.title = 'Requête DC';
  }, []);

  return (<React.Fragment>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <Entete data={data} prefix={'ns1:'} formulaire={formulaire}
        refSinistre={refSinistre} numMission={numMission}
      />
      <TitulaireActuel data={data} prefix={'ns1:'} formulaire={formulaire}/>
    </div>

    <div className={classes.flex} style={{columnGap: '30px', marginTop: '30px'}}>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}>
        <TitulaireNouveau data={data} prefix={'ns1:'} formulaire={formulaire}/>
      </div>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}>
        <div style={{columnGap: '30px', display: 'flex'}}>
          <Vehicule data={data} prefix={'ns1:'} formulaire={formulaire}/>
        </div>
        <div style={{columnGap: '30px', marginTop: '30px', display: 'flex', height: '100%'}}>
          <Declaration data={data} prefix={'ns1:'} formulaire={formulaire}/>
        </div>
      </div>
    </div>
  </React.Fragment>)
};
