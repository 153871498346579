import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {ButtonBlue} from '../common/buttons/ButtonBlue';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {UserContext} from '../../store/UserContext';
import {RendezVousModel} from '../../model/rechercherRendezVous/SearchRdvModel';
import useHasRole from '../../hook/useHasRole';
import {permittedRolesRDV} from '../../constantes/roles/Roles';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {useMissionContext} from '../../store/MissionContext';
import {checkRefSin} from '../priseRDV/PriseRDV';
import {RefSinistreRdv} from '../common/RefSinistreRdv';

interface BlocSearchProps {
  onSearch: (referenceSinistre: string) => void,
  listRdv?: RendezVousModel[],
}

interface SearchProps {
  referenceSinistre: string,
}

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '10px',
  },
  textfield: {
    width: '340px',
  },
  libelle: {
    color: theme.palette.secondary.dark,
  },
});

const BlocSearch = (props: BlocSearchProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {mission} = useMissionContext();
  const navigate = useNavigate();
  const hasRoleModificationRDV = useHasRole(permittedRolesRDV);
  const {onSearch} = props;
  const searchRdv = sessionStorage.getItem('searchRdv') && JSON.parse(sessionStorage.getItem('searchRdv') ?? '');
  const initialState: SearchProps = {referenceSinistre: mission?.infoMissionDTO?.referenceSinistre ??
        searchRdv?.referenceSinistre.trim() ?? ''};
  const [state, setState] = useState<SearchProps>(initialState);
  const [onErrorRefSinistre, setOnErrorRefSinistre] = useState<boolean>(false);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(true);

  const handleChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [input]: event.target.value});
    const searchRdv = {referenceSinistre: event.target.value};
    sessionStorage.setItem('searchRdv', JSON.stringify(searchRdv));
    setDisabledSearch(checkRefSin(event.target.value, true).onError)
  };

  const onBlurRefSin = (event: React.FocusEvent<HTMLInputElement>) => {
    setOnErrorRefSinistre(checkRefSin(event.target.value, true).onError);
    setDisabledSearch(checkRefSin(event.target.value, true).onError)
  };

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Informations de l&apos;assureur</Typography>
      <div className={classes.flex}>
        <div className={classes.textfield}>
          <RefSinistreRdv
            value={state.referenceSinistre}
            onChangeValue={handleChange('referenceSinistre')}
            onBlur={onBlurRefSin}
            onFocus={() => setOnErrorRefSinistre(false)}
            isMandatory={true}
            onError={onErrorRefSinistre}
            typeError={checkRefSin(state.referenceSinistre, true).typeError}
            formulaire={'rdv-search'}/>
        </div>
        <div style={{height: '40px', margin: '32px 24px 4px'}}>
          <ButtonBlue
            id={'rechercher'}
            libelle={'Rechercher'}
            onClick={() => onSearch(state.referenceSinistre)}
            disabled={disabledSearch}
          />
        </div>
        {hasRoleModificationRDV && (user.type === 'Assureur' || user.type === 'Courtier') &&
        <div style={{height: '40px', margin: '32px 0px 4px'}}>
          <ButtonBlue
            id={'prendreRdv'}
            libelle={'Prendre un nouveau rendez-vous'}
            disabled={(props.listRdv !== undefined) ?
                  (props.listRdv?.length !== 0 && (props.listRdv?.some((rdv) =>
                    ((rdv.status === 'Accepte' || rdv.status === 'EnAttente' || rdv.status === 'NonRealise') &&
                          moment(rdv.dateDebut) >= moment())))) : true}
            onClick={() => {
              sessionStorage.setItem('fromPath', location.pathname);
              navigate(`/extranet/dossiers/prise-rdv/prendre-rdv`);
            }}
          />
        </div>
        }
      </div>
    </div>
  );
};

export default BlocSearch;
