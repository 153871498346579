import React from 'react';
import {Typography} from '@mui/material';
import {TextFieldSearch} from './formsComponents/TextFieldSearch';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface RefSinistreRdvProps {
  className?: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  isMandatory: boolean,
  onError: boolean,
  value?: string,
  typeError: string,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const RefSinistreRdv = ({className, formulaire, onChangeValue, onBlur, onFocus, onError, isMandatory,
  value, typeError, helperText}: RefSinistreRdvProps) => {
  const classes = useEmotionStyles(styles);

  const libelleError = () => {
    if (typeError === '') {
      return 'Veuillez saisir une valeur'
    }
    if (typeError === 'errorLength') {
      return 'La référence sinistre doit faire au moins 6 caractères'
    }
    if (typeError === 'errorFormat') {
      return 'La référence sinistre ne doit pas contenir plus de 9 caractères identiques d\'affilée'
    }
    if (typeError === 'errorChar') {
      return 'Le champ contient des caractères interdits'
    }
    return ''
  }

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Référence sinistre {isMandatory ? <span className={classes.red}>*</span> : ''}
      </Typography>

      <TextFieldSearch
        id={'referenceSinistre' + formulaire}
        fullWidth={true}
        maxLength={17}
        value={value}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {onError ?
        <Typography className={`${classes.red} ${classes.error}`}>
          {libelleError()}
        </Typography> : ''
      }
    </div>
  )
};
