import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {customTheme} from '../../../../common/GlobalTheme';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {findElementByCodeInArray} from '../../../../common/Utils';
import {ButtonBlueDelete} from '../../../common/formsComponents/ButtonBlueDelete';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getValeursCodes} from '../../../../api/norme';

interface MotifProps {
  nbMotifs: number,
  id: number,
  codeMotif: string,
  onErrorCodeMotif: boolean,
  detail: string,
  expanded: boolean,
  onChangePanel: (panel: string) => void,
  handleChangeMotif: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleChangeDetail: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onDelete: (event: React.MouseEvent<HTMLElement>) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '16px',
  },
  summary: {
    maxHeight: '50px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '8px 8px 0px 0px',
  },
  divContainerSummary: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  accordionDetailsRoot: {
    padding: '10px 16px',
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
});

export const Motif = (props: MotifProps) => {
  const {nbMotifs, id, detail, codeMotif, onErrorCodeMotif, expanded, onChangePanel, handleChangeMotif,
    handleChangeDetail, onDelete} = props;
  const classes = useEmotionStyles(styles);
  const [motifs, setMotifs] = useState<ItemGetValeursCodesModel[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await getValeursCodes('AR2', '90690101').then((result) => setMotifs(result));
      setLoading(false)
    };
    fetchData().then();
  }, []);

  motifs.sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListMotifs = motifs.map((motif) => {
    return (
      <MenuItem key={motif.valeur} value={motif.valeur}>{motif.libelleedition}</MenuItem>
    )
  });

  const libelleMotif = findElementByCodeInArray(motifs, codeMotif);

  if (isLoading) {
    return <></>;
  }
  return (
    <div className={classes.container}>
      <div style={{width: '90%'}}>
        <Accordion style={{borderRadius: '8px 8px 0px 0px'}} expanded={expanded}
          onChange={() => onChangePanel(`panel-${id}`)}>
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls='panel1a-content'
            id={`panel1a-header-${id}`}
            className={classes.summary}
            classes={{root: classes.summaryRoot}}
          >
            <div className={classes.divContainerSummary}>
              <div>
                <Typography style={{fontWeight: 'bold'}}>
                  {id} - {libelleMotif?.libelleedition || <i>Ajoutez un motif de sollicitation</i>}
                </Typography>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
            <div style={{width: '100%'}}>
              <Typography id={'motifLabel'} style={customTheme.styledTypography.libelle}>
                Motif <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                id={`motif-${id}`}
                name={'motif'}
                marginDense={true}
                fullWidth={true}
                value={codeMotif}
                itemsList={itemsListMotifs}
                onChange={handleChangeMotif}
                onError={onErrorCodeMotif}
              />
              {onErrorCodeMotif &&
                <Typography id={'motifRequired'} className={classes.error}>Veuillez sélectionner une valeur</Typography>
              }
              <div style={{marginTop: '10px'}}>
                <Typography style={customTheme.styledTypography.libelle}>Détail de la sollicitation</Typography>
                <TextFieldMultiline
                  id={`detail-${id}`}
                  marginDense={true}
                  value={detail}
                  onChange={handleChangeDetail}
                  rows={3}
                  maxLength={200}
                  helperText={detail.length + '/200'}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{display: 'flex', width: '10%'}}>
        <div style={{margin: 'auto'}}>
          <ButtonBlueDelete id={'boutonSupprimer' + id} onClick={onDelete} disabled={nbMotifs === 1}/>
        </div>
      </div>
    </div>
  )
};
