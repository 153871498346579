import {Dispatch} from 'react'

import {SearchAction, SearchDataState} from '../reducers/SearchReducer'
import {SearchModel} from '../model/common/SearchModel'

export const getSearchActions = (state: SearchDataState, dispatch: Dispatch<SearchAction>) => ({
  setSearch: (search : SearchModel ) => {
    dispatch( {type: 'SET_SEARCH', search: search} )
  },
})

/**
 * This hack allow us to get the list of the methods names without maintaining a enum
 */
// get an "instance" of the function
// eslint-disable-next-line
// @ts-ignore
const getSearchActionsDummy = (false as true) && getSearchActions(undefined, undefined)
export type SearchActions = typeof getSearchActionsDummy; // create a type from that instance


