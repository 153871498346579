import React from 'react'
import {Card, CardContent, Typography} from '@mui/material';
import {StyledTypography} from '../../../common/StyledTypography';
import {useParams} from 'react-router';
import {ParamsMissionType} from '../../DetailsDossier';
import {useMissionContext} from '../../../../store/MissionContext';

const base64 = require('base-64')

export interface ContentInfosDossierProps {
  showMission?: boolean,
}

export const ContentInfosDossierArchive = ({showMission}: ContentInfosDossierProps) => {
  const {mission} = useMissionContext()
  const informationsMission = mission?.infoMissionDTO
  const params = useParams<keyof ParamsMissionType>() as ParamsMissionType;
  const search = JSON.parse(base64.decode(params.token))
  const immatriculation = search?.immatriculation
  return (
    <>
      <StyledTypography title={'N° de sinistre'} text={informationsMission?.referenceSinistre} />
      {showMission &&
        <StyledTypography title={'N° de la mission'} text={informationsMission?.numeroMission} />}
      <StyledTypography title={'Immatriculation'} text={immatriculation} />
    </>
  )
}

export const InfosDossierArchive = ({showMission}: ContentInfosDossierProps) => {
  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'}>Informations du dossier</Typography>
        <ContentInfosDossierArchive showMission={showMission}/>
      </CardContent>
    </Card>
  )
}
