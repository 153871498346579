import React, {DragEvent, useRef, useState} from 'react';
import {Typography} from '@mui/material';
import {ReactComponent as CustomUploadIcon} from '../../../../images/icones/icn-upload.svg';
import {IAttachment, IAttachments} from '../initialStateFormFDV';
import Attachments from './Attachments';
import {customTheme} from '../../../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Taille maximale par fichier (10 Mo) */
const MAX_FILE_SIZE = 10 * 1024;

/** Taille maximale de l'ensemble des fichiers (30 Mo) */
const MAX_SIZE_ALL_FILES = 30 * 1024;

/** Nombre maximum de fichiers (5) */
const MAX_NUMBER_FILES = 5;

/** Type autorisé des fichers */
const TYPES_FILES_OK = ['.bmp', '.doc', '.docx', '.eml', '.gif', '.html', '.jpeg', '.pdf', '.png',
  '.pps', '.ppsx', '.ppt', '.pptx', '.tif', '.tiff', '.txt', '.xls', '.xlsx', '.xml', '.jpg'];

const LISTE_FILES_OK = '.bmp, .doc, .docx, .eml, .gif, .html, .jpeg, .pdf, .png, .pps, .ppsx, .ppt, .pptx, ' +
    '.tif, .tiff, .txt, .xls, .xlsx, .xml, .jpg';

export interface UploadJustificatifProps {
  alerteId: number,
  attachments: IAttachments
  onChangeAttachments: (attachments: IAttachments) => void,
  onSave: (attachments: IAttachments) => void
  onDeleteAttachments: (attachments: IAttachments) => void,
  setDisabled: (isDisabled: boolean) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  text: {
    marginTop: '12px',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
  dropArea: {
    'height': '185px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexFlow': 'column noWrap',
    'padding': '8px',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: 'none',
      borderRadius: '2%',
    },
  },
});

const UploadJustificatif = (props: UploadJustificatifProps) => {
  const classes = useEmotionStyles(styles);
  const {alerteId, attachments, setDisabled, onChangeAttachments, onSave, onDeleteAttachments} = props;

  const fileInput = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<IAttachments>(attachments);
  const [dragging, setDragging] = useState(false);

  const style = dragging ?
    {'backgroundColor': customTheme.palette.secondary.light, 'boxShadow': 'none', 'borderRadius': '2%'} : {};

  const handleDragEnterAndLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(!dragging)
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation()
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const fileList = event.dataTransfer.files;

    if (fileList.length) {
      const files = Array.from(fileList);
      const attachmentsTemporary = buildAttachments(files);
      handleAttachmentsTemporary(attachmentsTemporary)
    }
  };

  const handleChangeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.length) {
      const files = Array.from(event?.target?.files);
      const attachmentsTemporary = buildAttachments(files);
      handleAttachmentsTemporary(attachmentsTemporary);
    }
    event.target.value = ''
  };

  const buildAttachments = (files: File[]) => {
    const attachmentsTemporary: IAttachments = {alerteId: alerteId, attachments: [], isValid: true};
    // s'il y a des fichiers existants
    const existingFiles: File[] = selectedFiles?.attachments ?
        Array.from(selectedFiles.attachments, (attach) => attach.file!) : [];

    const validateExistingFiles: File[] = selectedFiles.attachments
        .filter((attachment) => !attachment.onError)
        .map((attach) => attach.file!);

    // vérifier le nombre total des fichiers
    if (checkNumberFiles([...files, ...existingFiles])) {
      attachmentsTemporary.error = {
        type: 'overflow',
        message: 'Nombre maximal de fichiers atteint',
      };
      attachmentsTemporary.isValid = false
    } else if (checkSizeAllFiles([...files, ...validateExistingFiles])) {
      // vérifier la taille totale des fichiers
      attachmentsTemporary.error = {
        type: 'sizeAllFiles',
        message: 'Taille maximale atteinte pour l\'ensemble des fichiers',
      };
      attachmentsTemporary.isValid = false
    } else {
      attachmentsTemporary.attachments = buildFilesData(files);
    }
    return attachmentsTemporary
  };

  const handleAttachmentsTemporary = async (attachmentsTemporary: IAttachments) => {
    if (attachmentsTemporary.isValid) {
      setDisabled(true);
      if (selectedFiles.attachments && selectedFiles.attachments.length > 0) {
        attachmentsTemporary.attachments = [...selectedFiles.attachments, ...attachmentsTemporary.attachments]
      }
      let nbAttachmentsOnError = 0;
      let nbAttachmentsNotCompleted = 0;
      attachmentsTemporary.attachments.forEach((attachment) => {
        if (attachment.onError) nbAttachmentsOnError++;
        if (!attachment.completed) nbAttachmentsNotCompleted++;
      });
      if (nbAttachmentsOnError > 0 && nbAttachmentsNotCompleted === 0) setDisabled(false);
      setSelectedFiles({...attachmentsTemporary})
    } else {
      setSelectedFiles({
        ...selectedFiles,
        isValid: attachmentsTemporary.isValid,
        error: attachmentsTemporary.error,
      })
    }
    // Permet de se positionner sur le 1er élément de la liste une fois les fichiers choisis
    // fonction async pour attendre que la liste selectedFiles soit mise à jour et que les éléments panel-X soient créés
    const panel = document.getElementById('panel-' + attachmentsTemporary.attachments
        .find((attachment) => !attachment.completed)?.id);
    panel?.scrollIntoView({behavior: 'smooth', block: 'center'})
  };

  const buildFilesData = (files: File[]) => {
    const attachmentsList: IAttachment[] = [];
    files.forEach((file, index) => {
      const attachment: IAttachment = {
        id: selectedFiles?.attachments ? selectedFiles.attachments?.length + index : index,
        reference: file.name,
        nature: '',
        descriptif: '',
        completed: Math.round(file.size / 1024) > MAX_FILE_SIZE,
        file: file,
        onError: Math.round(file.size / 1024) > MAX_FILE_SIZE || !checkTypeFile(file.name),
        onTypeError: !checkTypeFile(file.name),
      };
      attachmentsList.push(attachment)
    });
    return attachmentsList
  };

  const checkTypeFile = (name : string) => {
    const type = '.' + name.split('.').pop();
    return TYPES_FILES_OK.includes(type ? type.toLowerCase() : '')
  };

  const checkNumberFiles = (files: File[]) => {
    return files.length > MAX_NUMBER_FILES
  };

  const checkSizeAllFiles = (files: File[]) => {
    const sizeAllFiles = files.map((file) => file.size).reduce((prev, curr) => prev + curr, 0);
    return Math.round(sizeAllFiles / 1024) > MAX_SIZE_ALL_FILES
  };

  const handleChangeAttachments = (attachmentsList: IAttachment[]) => {
    const attachmentsTemporary = {...selectedFiles, attachments: [...attachmentsList]};
    setSelectedFiles({...attachmentsTemporary});
    onChangeAttachments({...attachmentsTemporary})
  };

  const onSaveAttachments = (attachmentsList: IAttachment[]) => {
    const attachmentsTemporary = {...selectedFiles, attachments: [...attachmentsList]};
    setSelectedFiles({...attachmentsTemporary});
    onSave({...attachmentsTemporary})
  };

  const checkAllFilesCompleted = (attachmentsList: IAttachment[]) => {
    return attachmentsList.every((attachment) => {
      return attachment.completed
    })
  };

  const onDeleteAttachment = (attachmentsList: IAttachment[]) => {
    checkAllFilesCompleted(attachmentsList) ? setDisabled(false) : setDisabled(true);
    const attachmentsCopy = {alerteId: selectedFiles.alerteId, isValid: true, attachments: attachmentsList};
    setSelectedFiles({...attachmentsCopy});
    onDeleteAttachments(attachmentsCopy)
  };

  return (
    <div>
      <Typography className={classes.text} style={customTheme.styledTypography.donneeGrise16}>
        Faites glisser ou cliquez pour importer vos fichiers
      </Typography>
      <Typography style={customTheme.styledTypography.donneeGrise16}>
        Vous pouvez déposer jusqu&apos;à 5 fichiers avec une taille maximale par fichier de 10 Mo et
        une taille maximale pour l&apos;ensemble des fichiers de 30 Mo.
      </Typography>
      <div className={classes.container}>
        <div
          style={style}
          className={classes.dropArea}
          onDragEnter={handleDragEnterAndLeave}
          onDragLeave={handleDragEnterAndLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => fileInput?.current?.click()}
        >
          <CustomUploadIcon/>

          <input
            type="file"
            hidden
            multiple
            ref={fileInput}
            onChange={handleChangeInputFile}
            accept={LISTE_FILES_OK}
          />
        </div>
        {selectedFiles?.error &&
          <div>
            <Typography style={{color: 'red', fontSize: '12px'}}>{selectedFiles?.error?.message}</Typography>
          </div>
        }
      </div>

      {selectedFiles?.attachments && selectedFiles?.attachments.length > 0 &&
        <Attachments
          attachments={selectedFiles.attachments}
          onChangeAttachments={handleChangeAttachments}
          onSaveAttachments={onSaveAttachments}
          onDeleteAttachment={onDeleteAttachment}
        />
      }
    </div>
  );
};

export default UploadJustificatif;
