import React, {useEffect} from 'react'
import {getLibelleValeur, getValueElement} from '../Utils';
import {Entete} from './Entete';

interface RequeteCTProps {
  refSinistre: string,
  data: any,
}

export const RequeteCT = (props: RequeteCTProps) => {
  const {refSinistre, data} = props

  const libelleValeurIdentiteTitulaire = () => {
    if (data.getElementsByTagName('ns1:' + 'raison_sociale').length !== 0) {
      return ([
        [
          getLibelleValeur('Société commerciale :', getValueElement(data, 'ns1:', 'societe_commerciale')),
        ],
        [
          getLibelleValeur('Raison sociale :', getValueElement(data, 'ns1:', 'raison_sociale')),
          getLibelleValeur('SIREN :', getValueElement(data, 'ns1:', 'siren')),
          getLibelleValeur('SIRET :', getValueElement(data, 'ns1:', 'siret')),
        ],
      ])
    } else {
      return ([
        [
          getLibelleValeur('Nom :', getValueElement(data, 'ns1:', 'nom_naissance')),
          getLibelleValeur(`Nom d'usage :`, getValueElement(data, 'ns1:', 'nom_usage')),
          getLibelleValeur('Prénom :', getValueElement(data, 'ns1:', 'prenom')),
        ],
      ])
    }
  }

  useEffect(() => {
    document.title = 'Requête CT';
  }, []);

  return (
    <div style={{columnGap: '30px', width: '60%', margin: 'auto'}}>
      <Entete data={data} prefix={'ns1:'} formulaire={'CT'}
        withoutTitreContainer={true}
        refSinistre={refSinistre}
        specificiteCT={
          {
            titre: 'Identité du titulaire',
            libellesValeurs: libelleValeurIdentiteTitulaire(),
          }
        }
      />
    </div>
  )
};
