import {Switch} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledSwitch = styled(Switch)(() => ({
  '&.MuiSwitch-root': {
    width: '40px',
    height: '20px',
    padding: '0px',
    marginLeft: '20px',
  },
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    'color': 'white',
    '&.Mui-checked': {
      'transform': 'translateX(20px)',
      'color': 'white',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#0095DB',
      },
    },
    '&.Mui-disabled': {
      color: '#7C7C7C',
    },
  },
  '& .MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: '20px',
    opacity: 1,
    backgroundColor: '#9F9F9F',
  },
  '& .Mui-checked': {},
}));

export default StyledSwitch;
