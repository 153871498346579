import {useEffect, useState} from 'react';
import {getUserByCodeAbonne, getUserbyIdClient} from '../api/user';
import {UserContextType, userInitialState} from '../store/UserContext';
import {AxiosError} from 'axios';
import {getPartenaireSSOByCode} from '../constantes/paretenaireSSO/PartenaireSSO';
import Keycloak from 'keycloak-js';

interface UserProps {
  userCompte: string,
  codeService: string
  isAssistance: boolean
  isTransparent: boolean
  keycloak?: Keycloak
  noClient?: string,
  rolesSSO?: string[],
  userFromStorage?: UserContextType
}

const getServicesList = (abonnements: any) => {
  return abonnements.map((abo: any) => {
    switch (abo._ref_service) {
      case 'S085':
        return {codeService: 'S085', nomService: 'Développement'};
      case 'S084':
        return {codeService: 'S084', nomService: 'Certification'};
      case 'S083':
        return {codeService: 'S083', nomService: 'Test'};
      case 'S082':
        return {codeService: 'S082', nomService: 'Production'};
    }
  }).filter((x: any) => x !== null && x !== undefined);
}

const useUser = (props: UserProps) => {
  const {userCompte, isAssistance, isTransparent, codeService, keycloak, noClient, rolesSSO, userFromStorage} = props;
  const codePartenaireSSO = keycloak?.tokenParsed?.nocli ?? noClient;
  const initialStateUser = userFromStorage ?? {
    ...userInitialState,
    isAssistance: isAssistance,
    isTransparent: isTransparent,
  };

  const [data, setData] = useState<UserContextType>(initialStateUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    if (!userFromStorage && !!userCompte && !codePartenaireSSO) {
      getUserbyIdClient()
          .then((response) => {
            const listServices = getServicesList(response.compte.partenaire.abonnements);

            const userDetails: UserContextType = {
              userCompte: response.compte._ref_compte,
              nom: response.compte.partenaire.nom,
              type: response.compte.partenaire.type,
              roles: response.compte.roles.map((role: any) => role.code_role),
              codeAbo: response.compte.partenaire.abonnements
                  .find((e: any) => e._ref_service === codeService)?.code_abonne,
              service: codeService,
              listeServices: listServices,
              codeGTA: response.compte.partenaire.code_societe_gta,
              numClient: response.compte.partenaire.numero_client,
              isAssistance: isAssistance,
              isTransparent: isTransparent,
              isSSO: false,
              raisonSociale: response.compte.partenaire.raison_sociale,
              raisonSocialeInfogeo: response.infogeoEtablissement.siege.raisonSociale,
              siret: response.compte.partenaire.siret || '',
              siren: response.infogeoEtablissement.siren || '',
              telephone: response.compte.partenaire.telephone.trim() || '',
              token: keycloak?.idToken ?? '',
              adresse: {
                complementAdresse: response.infogeoEtablissement.adresse.complementAdresse,
                numeroVoie: response.infogeoEtablissement.adresse.numeroVoie,
                indiceRepetition: response.infogeoEtablissement.adresse.indiceRepetition,
                typeVoie: response.infogeoEtablissement.adresse.typeVoie,
                libelleVoie: response.infogeoEtablissement.adresse.libelleVoie,
                codePostal: response.infogeoEtablissement.adresse.codePostal,
                codeCedex: response.infogeoEtablissement.adresse.codeCedex,
                distributionSpeciale: response.infogeoEtablissement.adresse.distributionSpeciale,
                libelleCommune: response.infogeoEtablissement.adresse.libelleCommune,
                libelleCedex: response.infogeoEtablissement.adresse.libelleCedex,
              },
              filiales: response.filiales,
              agrements: response.agrements,
              agrementsBG: response.agrementsBG,
            };
            setData(userDetails);
          })
          .catch((error: AxiosError) => {
            setError(error);
          })
          .finally(() => setLoading(false))
    } else if (!userFromStorage && codePartenaireSSO) {
      const partenaireSSO = getPartenaireSSOByCode(codePartenaireSSO);
      const codeAbonnePartenaireSSO = !!partenaireSSO && partenaireSSO.codeAbonne

      if (codeAbonnePartenaireSSO) {
        getUserByCodeAbonne(codeAbonnePartenaireSSO, codeService)
            .then((response) => {
              const listServices = getServicesList(response.compte.partenaire.abonnements)

              const userDetails: UserContextType = {
                userCompte: keycloak?.tokenParsed?.preferred_username || userCompte,
                nom: response.compte.partenaire.nom,
                type: response.compte.partenaire.type,
                roles: keycloak?.tokenParsed?.realm_access?.roles ?? rolesSSO ?? [],
                codeAbo: codeAbonnePartenaireSSO,
                service: codeService,
                listeServices: listServices,
                codeGTA: response.compte.partenaire.code_societe_gta,
                numClient: response.compte.partenaire.numero_client,
                isAssistance: isAssistance,
                isTransparent: isTransparent,
                isSSO: true,
                raisonSociale: response.compte.partenaire.raison_sociale,
                raisonSocialeInfogeo: '',
                siret: response.compte.partenaire.siret || '',
                siren: '',
                telephone: response.compte.partenaire.telephone.trim() || '',
                token: keycloak?.idToken ?? '',
                adresse: {
                  complementAdresse: '',
                  numeroVoie: response.compte.partenaire.adresse.adresse1,
                  indiceRepetition: '',
                  typeVoie: response.compte.partenaire.adresse.adresse2,
                  libelleVoie: response.compte.partenaire.adresse.adresse3,
                  codePostal: response.compte.partenaire.adresse.code_postal,
                  codeCedex: '',
                  distributionSpeciale: '',
                  libelleCommune: response.compte.partenaire.adresse.commune,
                  libelleCedex: '',
                },
                filiales: response.filiales,
                agrements: response.agrements,
                agrementsBG: response.agrementsBG,
              }
              setData(userDetails);
            })
            .catch((error: AxiosError) => {
              setError(error);
            })
            .finally(() => setLoading(false))
      }
    } else {
      setLoading(false)
    }
  }, [userCompte, codePartenaireSSO]);

  return {data, error, loading}
};

export default useUser
