import {TokenEdiModel} from '../../../../model/common/TokenEdiModel';
import {UserContextType} from '../../../../store/UserContext';

export const mapperEDI = (search: any, user: UserContextType, data: any): TokenEdiModel => {
  return {
    idDocument: search.idDocument,
    search: {
      referenceSinistre: search.referenceSinistre,
      numeroMission: search.numeroMission,
      codeGTA: user.codeGTA,
      numClient: search.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: search.codeService,
      isDossierComplet: search.isDossierComplet,
      immatriculation: search.immatriculation,
    },
    type: data.docType,
    ssType: data.docSousType,
    isnIdentifier: data.identifier,
  } as TokenEdiModel;
};
