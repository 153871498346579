import React, {useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {StyledTypography} from '../../../common/StyledTypography';
import {useMissionContext} from '../../../../store/MissionContext';
import {ReactComponent as IconVoitureVEI} from '../../../../images/icones/icn-voiture-vei.svg';
import {ReactComponent as IconEuro} from '../../../../images/icones/icn-euro.svg';
import {ReactComponent as IconWheel} from '../../../../images/icones/icn-wheel.svg';
import {ReactComponent as IconFullWheel} from '../../../../images/icones/icn-full-wheel.svg';
import {findElementInArray, Dommages} from './Dommages';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    columnGap: '25px',
    [theme.breakpoints.down('lg')]: {
      columnGap: '15px',
    },
  },
  card: {
    width: '50%',
    ['@media print']: {
      boxShadow: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  etatVehicule: {
    padding: '5px',
    borderRadius: '4px',
    height: 'min-content',
    display: 'flex',
    alignItems: 'center',
  },
  etatVEIRIV: {
    backgroundColor: '#FCF0EF',
    color: '#EC4F3E',
  },
  etatNonRoulant: {
    backgroundColor: '#FCF0EF',
    color: '#EC4F3E',
    marginLeft: '15px',
  },
  etatVE: {
    backgroundColor: '#FEF9F2',
    color: '#F4B35F',
    marginLeft: '15px',
  },
  iconEtat: {
    marginRight: '5px',
  },
  subtitle: {
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.secondary.dark2,
    margin: '10px 0px',
  },
  autresChocs: {
    display: 'flex',
    columnGap: '5px',
  },
  autreChoc: {
    padding: '3px 8px',
    backgroundColor: '#DB653D1A',
    color: '#DB653D',
    fontSize: '12px',
    borderRadius: '50px',
  },
  autreVitre: {
    padding: '3px 8px',
    backgroundColor: '#2273981A',
    color: '#227398',
    fontSize: '12px',
    borderRadius: '50px',
  },
  containerTooltip: {
    minHeight: '25px',
    height: 'fit-content',
    marginTop: '-15px',
    marginBottom: '5px',
  },
  tooltip: {
    height: 'fit-content',
    width: 'fit-content',
    padding: '2px 8px',
    borderRadius: '15px',
  },
  tooltipVisible: {
    display: 'block',
  },
  tooltipHidden: {
    display: 'none',
  },
  tooltipChoc: {
    backgroundColor: '#DB653D1A',
    color: '#DB653D',
  },
  tooltipDommage: {
    backgroundColor: '#F4B35F1A',
    color: '#F4A13D',
  },
  tooltipElementVitre: {
    backgroundColor: '#2273981A',
    color: '#227398',
  },
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: '12px',
  },
});

export const VehiculeDommages = () => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const chocs = mission?.gestionDTO?.dommages?.chocs;
  const dommages = mission?.gestionDTO?.dommages?.dommages;
  const elementsVitres = mission?.gestionDTO?.dommages?.elementsVitres;
  const marque = mission?.gestionDTO?.vehicule?.marque;
  const modele = mission?.gestionDTO?.vehicule?.modele;
  const couleur = mission?.gestionDTO?.vehicule?.couleur;
  const genre = mission?.gestionDTO?.vehicule?.genre;
  const energie = mission?.gestionDTO?.vehicule?.energie;
  const kilometrage = mission?.gestionDTO?.vehicule?.kilometrage;
  const codeGenre = mission?.gestionDTO?.dommages?.codeGenre;
  const etiquettesEtatVehicule = mission?.gestionDTO?.vehicule?.etiquettesEtatVehicule;
  const [numeroChoc, setNumeroChoc] = useState<number>();
  const [intensiteChoc, setIntensiteChoc] = useState<string>();
  const [angleChoc, setAngleChoc] = useState<string>();
  const [numChocAssocie, setNumChocAssocie] = useState<number>();
  const [natureDegat, setNatureDegat] = useState<string>();
  const [libellePiece, setLibellePiece] = useState<string>();
  const [isElementVitre, setIsElementVitre] = useState<boolean>();

  const isUtilitaire = codeGenre === 'CAM' || codeGenre === 'CTTE' || codeGenre === 'TCP' || codeGenre === 'VASP';

  const isMoto = codeGenre === 'CL' || codeGenre === 'CYC1' || codeGenre === 'CYCL' || codeGenre === 'MTL' ||
    codeGenre === 'MTMI' || codeGenre === 'MTSP' || codeGenre === 'MTT1' || codeGenre === 'MTT2' ||
    codeGenre === 'MTTE' || codeGenre === 'VELO';

  const allInChocs = findElementInArray(chocs, 'INTERIEUR') && findElementInArray(chocs, 'DESSOUS') &&
    findElementInArray(chocs, 'AVANT/LATERAL DROIT') && findElementInArray(chocs, 'AVANT/LATERAL GAUCHE') &&
    findElementInArray(chocs, 'CENTRAL/LATERAL DROIT') && findElementInArray(chocs, 'CENTRAL/LATERAL GAUCHE') &&
    findElementInArray(chocs, 'ARRIERE/LATERAL DROIT') && findElementInArray(chocs, 'ARRIERE/LATERAL GAUCHE') &&
    findElementInArray(chocs, 'AVANT') && findElementInArray(chocs, 'ARRIERE') &&
    findElementInArray(chocs, 'PAVILLONS/DESSUS');

  const allInDommages = findElementInArray(dommages, 'INTERIEUR') && findElementInArray(dommages, 'DESSOUS') &&
    findElementInArray(dommages, 'AVANT/LATERAL DROIT') && findElementInArray(dommages, 'AVANT/LATERAL GAUCHE') &&
    findElementInArray(dommages, 'CENTRAL/LATERAL DROIT') && findElementInArray(dommages, 'CENTRAL/LATERAL GAUCHE') &&
    findElementInArray(dommages, 'ARRIERE/LATERAL DROIT') && findElementInArray(dommages, 'ARRIERE/LATERAL GAUCHE') &&
    findElementInArray(dommages, 'AVANT') && findElementInArray(dommages, 'ARRIERE') &&
    findElementInArray(dommages, 'PAVILLONS/DESSUS');

  const ninePointsInChocs = findElementInArray(chocs, 'AVANT/LATERAL DROIT') &&
    findElementInArray(chocs, 'AVANT/LATERAL GAUCHE') && findElementInArray(chocs, 'CENTRAL/LATERAL DROIT') &&
    findElementInArray(chocs, 'CENTRAL/LATERAL GAUCHE') && findElementInArray(chocs, 'ARRIERE/LATERAL DROIT') &&
    findElementInArray(chocs, 'ARRIERE/LATERAL GAUCHE') && findElementInArray(chocs, 'AVANT') &&
    findElementInArray(chocs, 'ARRIERE') && findElementInArray(chocs, 'PAVILLONS/DESSUS');

  const ninePointsInDommages = findElementInArray(dommages, 'AVANT/LATERAL DROIT') &&
    findElementInArray(dommages, 'AVANT/LATERAL GAUCHE') && findElementInArray(dommages, 'CENTRAL/LATERAL DROIT') &&
    findElementInArray(dommages, 'CENTRAL/LATERAL GAUCHE') && findElementInArray(dommages, 'ARRIERE/LATERAL DROIT') &&
    findElementInArray(dommages, 'ARRIERE/LATERAL GAUCHE') && findElementInArray(dommages, 'AVANT') &&
    findElementInArray(dommages, 'ARRIERE') && findElementInArray(dommages, 'PAVILLONS/DESSUS');

  const autresChocs: string[] = [];
  const chocsVisibles: string[] = [];
  chocs?.map((choc, _) => choc.libelleLowercase).filter((el) => {
    if (el === 'Intérieur' || el === 'Dessous' || (el === 'Indéterminé' && chocs?.length === 1)) {
      autresChocs.push(el)
    } else if (isMoto && !ninePointsInChocs && el === 'Pavillons/dessus') {
      autresChocs.push(el)
    } else {
      chocsVisibles.push(el)
    }
  });

  const autresDommages: string[] = [];
  const dommagesVisibles: string[] = [];
  dommages?.map((dommage, _) => dommage.libelleLowercase).filter((el) => {
    if (el === 'Intérieur' || el === 'Dessous' || (el === 'Indéterminé' && dommages?.length === 1)) {
      autresDommages.push(el)
    } else if (isMoto && !ninePointsInDommages && el === 'Pavillons/dessus') {
      autresDommages.push(el)
    } else {
      dommagesVisibles.push(el)
    }
  });

  const autresChocsDommages = () => {
    if (autresChocs && autresDommages) {
      return Array.from(new Set([...autresChocs, ...autresDommages]))
    } else if (autresChocs) {
      return autresChocs
    } else {
      return autresDommages ?? []
    }
  }

  const autresElementsVitres: string[] | undefined = elementsVitres?.filter((element) => {
    if (findElementInArray(chocs, 'ENSEMBLE') || findElementInArray(dommages, 'ENSEMBLE') ||
      allInChocs || allInDommages || ninePointsInChocs || ninePointsInDommages) {
      return element
    } else if (isMoto) {
      return element.code === '9'
    } else {
      return element.code === '2' || element.code === '6' || element.code === '9'
    }
  }).map((element) => {
    return element.libelle
  });

  const findElementVitre = (id: string) => {
    if (id === 'PAVILLONS/DESSUS') {
      return elementsVitres?.find((element) => element.code === '4')
    } else {
      return elementsVitres?.find((element) =>
        element.libelle.toLocaleLowerCase()
            .replaceAll((/[èéêë]/g), 'e')
            .replaceAll(' ', '-') === id)
    }
  };

  const onmouseover = (event: any) => {
    if (findElementInArray(chocs, event.target.id)) {
      setLibellePiece(findElementInArray(chocs, event.target.id).abreviation);
      setNumeroChoc(findElementInArray(chocs, event.target.id).numero);
      setIntensiteChoc(findElementInArray(chocs, event.target.id).intensite);
      setAngleChoc(findElementInArray(chocs, event.target.id).angle);
      setNumChocAssocie(undefined);
      setNatureDegat(undefined);
      setIsElementVitre(false)
    } else if (findElementInArray(dommages, event.target.id)) {
      setNumeroChoc(undefined);
      setIntensiteChoc(undefined);
      setAngleChoc(undefined);
      setNatureDegat(undefined);
      setIsElementVitre(false);
      setLibellePiece(findElementInArray(dommages, event.target.id).abreviation);
      chocs ?
        setNumChocAssocie(findElementInArray(dommages, event.target.id).numChocAssocie) : setNumChocAssocie(undefined)
    } else if (findElementVitre(event.target.id)) {
      setIsElementVitre(true);
      setNumeroChoc(undefined);
      setIntensiteChoc(undefined);
      setAngleChoc(undefined);
      setNumChocAssocie(undefined);
      const currentElement = findElementVitre(event.target.id);
      setLibellePiece(currentElement?.libelle);
      if (currentElement?.natureDegat) {
        setNatureDegat(currentElement.natureDegat)
      }
    } else {
      setNumeroChoc(undefined);
      setIntensiteChoc(undefined);
      setAngleChoc(undefined);
      setNumChocAssocie(undefined);
      setLibellePiece(undefined);
      setNatureDegat(undefined);
      setIsElementVitre(false)
    }
  };

  const classNameTooltip = () => {
    let className = `${classes.tooltip}`;
    if (numeroChoc) {
      className = className.concat(` ${classes.tooltipChoc}`)
    } else if (numChocAssocie || (libellePiece && !natureDegat && !isElementVitre)) {
      className= className.concat(` ${classes.tooltipDommage}`)
    } else if (natureDegat || isElementVitre) {
      className= className.concat(` ${classes.tooltipElementVitre}`)
    }
    if (libellePiece || numeroChoc || numChocAssocie || natureDegat) {
      className = className.concat(` ${classes.tooltipVisible}`)
    } else {
      className = className.concat(` ${classes.tooltipHidden}`)
    }
    return className
  };

  const renderAngleChoc = () => {
    if (angleChoc?.endsWith('o')) {
      return (<span style={{display: 'flex'}}>
        <Typography className={classes.text}>&nbsp;·&nbsp;</Typography>
        <Typography className={`${classes.bold} ${classes.text}`}>Angle :&nbsp;</Typography>
        <Typography className={classes.text}>
          {angleChoc.substring(0, angleChoc.length - 1) + '°'}
        </Typography>
      </span>)
    } else if (angleChoc?.endsWith(')')) {
      return (<span style={{display: 'flex'}}>
        <Typography className={classes.text}>&nbsp;·&nbsp;</Typography>
        <Typography className={`${classes.bold} ${classes.text}`}>Angle :&nbsp;</Typography>
        <Typography className={classes.text}>{angleChoc.replaceAll('o', '°')}</Typography>
      </span>)
    } else {
      return (<></>)
    }
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <Typography variant={'h3'}>Véhicule</Typography>
            {etiquettesEtatVehicule && etiquettesEtatVehicule?.length > 0 ?
              <div style={{display: 'flex'}}>
                {etiquettesEtatVehicule?.includes('VEI') || etiquettesEtatVehicule?.includes('RIV') ?
                  <div className={`${classes.etatVehicule} ${classes.etatVEIRIV}`}>
                    {etiquettesEtatVehicule?.includes('VEI') ?
                      <IconVoitureVEI className={classes.iconEtat}/> :
                      <IconEuro className={classes.iconEtat}/>
                    }
                    <div>{etiquettesEtatVehicule?.includes('VEI') ? 'VEI' : 'RIV'}</div>
                  </div> : ''
                }
                {etiquettesEtatVehicule?.includes('VE') ?
                  <div className={`${classes.etatVehicule} ${classes.etatVE}`}>
                    <IconWheel className={classes.iconEtat}/>
                    <div>VE</div>
                  </div> : ''
                }
                {etiquettesEtatVehicule?.includes('NonRoulant') &&
                !etiquettesEtatVehicule?.includes('VEI')?
                    <div className={`${classes.etatVehicule} ${classes.etatNonRoulant}`}>
                      <IconFullWheel className={classes.iconEtat}/>
                      <div>Non Roulant</div>
                    </div> : ''
                }
              </div> : ''
            }
          </div>
          <StyledTypography id={'marqueVeh'} title={'Marque'} text={marque}/>
          <div style={{marginTop: '25px'}}><StyledTypography id={'modeleVeh'} title={'Modèle'} text={modele}/></div>
          <div style={{marginTop: '25px'}}><StyledTypography id={'couleurVeh'} title={'Couleur'} text={couleur}/></div>
          <div style={{marginTop: '25px'}}><StyledTypography id={'genreVeh'} title={'Genre'} text={genre}/></div>
          <div style={{marginTop: '25px'}}><StyledTypography id={'energieVeh'} title={'Énergie'} text={energie}/></div>
          <div style={{marginTop: '25px'}}><StyledTypography id={'kmVeh'}
            title={'Kilométrage'} text={kilometrage}/></div>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent style={{height: '92%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <div>
            <Typography variant={'h3'}>Dommages</Typography>
            {(!findElementInArray(chocs, 'ENSEMBLE') && !findElementInArray(dommages, 'ENSEMBLE') &&
              !allInChocs && !allInDommages && !ninePointsInChocs && !ninePointsInDommages) &&
            <div className={classes.containerTooltip}>
              {(!chocs && !dommages) || (chocs?.length === 0 && dommages?.length === 0) ?
                  <span style={{display: 'block', margin: 'auto', width: 'max-content'}}>
                    Aucun dommage déclaré</span> : ''
              }
              <div className={classNameTooltip()}
              >
                {numeroChoc ?
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <span style={{display: 'flex'}}>
                      <Typography className={`${classes.bold} ${classes.text}`}>
                        Impact{(chocs?.length != undefined && chocs?.length > 1) ? ' n°' + numeroChoc : ''} :&nbsp;
                      </Typography>
                      <Typography className={classes.text}>{libellePiece}</Typography>
                    </span>
                    {intensiteChoc && intensiteChoc !== 'SANS OBJET' &&
                    <span style={{display: 'flex'}}>
                      <Typography className={classes.text}>&nbsp;·&nbsp;</Typography>
                      <Typography className={`${classes.bold} ${classes.text}`}>Intensité :&nbsp;</Typography>
                      <Typography className={classes.text}>
                        {intensiteChoc === 'LEGERE' ? 'légère' : intensiteChoc.toLowerCase()}
                      </Typography>
                    </span>
                    }
                    {renderAngleChoc()}
                  </div> : ''
                }
                {(natureDegat || (libellePiece && isElementVitre)) &&
                  <span style={{display: 'flex'}}>
                    <Typography className={`${classes.bold} ${classes.text}`}>{libellePiece}</Typography>
                    {natureDegat ? <Typography className={classes.text}>&nbsp;:&nbsp;{natureDegat}</Typography> : ''}
                  </span>
                }
                {numChocAssocie ?
                  <span style={{display: 'flex'}}>
                    <Typography className={`${classes.bold} ${classes.text}`}>Dommage :&nbsp;</Typography>
                    <Typography className={classes.text}>
                      {libellePiece} imputable à l&apos;impact{
                        (chocs?.length != undefined && chocs?.length > 1) ? ' n°' + numChocAssocie : ''
                      }
                    </Typography>
                  </span> : ''
                }
                {!chocs && !numChocAssocie && !natureDegat && !isElementVitre && libellePiece ?
                  <span style={{display: 'flex'}}>
                    <Typography className={`${classes.bold} ${classes.text}`}>Dommage :&nbsp;</Typography>
                    <Typography className={classes.text}>{libellePiece}</Typography>
                  </span> : ''
                }
              </div>
            </div>}
            <div style={{textAlign: 'center'}}>
              <Dommages onmouseover={onmouseover} allInChocs={allInChocs} allInDommages={allInDommages}
                ninePointsInChocs={ninePointsInChocs} ninePointsInDommages={ninePointsInDommages}
                isMoto={isMoto} isUtilitaire={isUtilitaire}/>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap',
            gap: autresElementsVitres ? '5px' : ''}}>
            {(!findElementInArray(chocs, 'ENSEMBLE') && !findElementInArray(dommages, 'ENSEMBLE') &&
              !allInChocs && !allInDommages) &&
                autresChocsDommages().length > 0 &&
                    <div>
                      {((chocsVisibles?.length === 0 && dommagesVisibles?.length === 0) ?
                          <Typography className={classes.subtitle}>
                            {autresChocsDommages().length > 1 ? 'Points de choc' : 'Point de choc'}
                          </Typography> :
                          <Typography className={classes.subtitle}>
                            {autresChocsDommages().length > 1 ? 'Autres chocs' : 'Autre choc'}
                          </Typography>
                      )}
                      <div className={classes.autresChocs}>
                        {autresChocsDommages().map((choc, index) => {
                          const keyTypography = `${index}`
                          return (
                            <Typography key={keyTypography} className={classes.autreChoc}>{choc}</Typography>
                          )
                        })}
                      </div>
                    </div>
            }
            {autresElementsVitres && autresElementsVitres.length > 0 &&
              <div>
                <Typography className={classes.subtitle}>
                  {autresElementsVitres.length > 1 ? 'Éléments vitrés' : 'Élément vitré'}
                </Typography>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: '5px'}}>
                  {autresElementsVitres.map((elementVitre, index) => {
                    const keyTypography = `${index}`
                    return (<Typography key={keyTypography} className={classes.autreVitre}>{elementVitre}</Typography>)
                  })}
                </div>
              </div>
            }
          </div>
        </CardContent>
      </Card>
    </div>
  )
};
