import React from 'react';
import ConfigEnvironnement from '../../ConfigEnvironnement';
import {AppBar, Button, Toolbar, Tooltip} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {useResultContext} from '../../store/ResultContext';
import {useSearchContext} from '../../store/SearchContext';
import {useMissionContext} from '../../store/MissionContext';
import {ReactComponent as LogoSinappsAutoExtranetBandeOrange} from
  '../../images/logo/logo-sinapps-auto-extranet-bande-orange.svg';
import {ReactComponent as LogoSinappsAutoRdvBandeOrange} from
  '../../images/logo/logo-sinapps-auto-rdv-bande-orange.svg';
import {UserContext, UserContextType} from '../../store/UserContext';
import useHasRole from '../../hook/useHasRole';
import {
  permittedRolesMenuFDV,
  permittedRolesMenuSIV,
  permittedRolesMenuRDV,
  permittedRolesMenuFormOM, permittedRolesFormPECBG,
} from '../../constantes/roles/Roles';
import {DetailsModel} from '../../model/detailsDossier/DetailsModel';
import {ToolbarSVP} from './ToolbarSVP';
import {NotificationsComponent} from './NotificationsComponent';
import {MenuComponent} from './MenuComponent';
import {MenuAccount} from './MenuAccount';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {isAssureurOrExpertOrCarrossier} from '../../common/utils/UserTypeUtils';

interface AppBarHeaderProps {
  configEnvironnement: ConfigEnvironnement;
  setUser: (user: UserContextType) => void;
  isTransparent: boolean;
}

const styles = (theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    ['@media print']: {
      display: 'none',
    },
  },
  mainBar: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    padding: '0px',
    border: 'none',
  },
  toolBar: {
    height: '70px',
  },
  title: {
    fontSize: '24px',
    letterSpacing: '0px',
    color: theme.palette.white.main,
  },
});

const FDV = '590101';

export const AppBarHeader: React.FC<AppBarHeaderProps> = (props) => {
  const classes = useEmotionStyles(styles);

  const hasRoleMenuSIV = useHasRole(permittedRolesMenuSIV);
  const hasRoleMenuFDV = useHasRole(permittedRolesMenuFDV);
  const hasRoleMenuRDV = useHasRole(permittedRolesMenuRDV);
  const hasRoleMenuForms = useHasRole(permittedRolesMenuFormOM);
  const hasRolePECBG = useHasRole(permittedRolesFormPECBG);
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = React.useContext(UserContext);
  const actionsResult = useResultContext().actions;
  const actionsSearch = useSearchContext().actions;
  const actionsMission = useMissionContext().actions;
  const isAssistance = user.isAssistance;
  const isInsideDossier = location.pathname.includes('/extranet/dossiers/mission/');
  const isOnPriseRdv = location.pathname.includes('/extranet/dossiers/prise-rdv');

  const missionContext = useMissionContext().mission;
  const getItemMission = sessionStorage.getItem('mission');
  let mission: DetailsModel = {};
  if (missionContext === undefined) {
    if (getItemMission) {
      mission = JSON.parse(getItemMission);
    }
  } else {
    mission = missionContext;
  }
  const isDossierSimple = mission?.dossierSimple;
  const indicateurArchivage = mission?.indicateurArchivage;
  const isOM = mission?.gestionDTO?.priseEnCharge?.isOM;
  const isAS = mission?.gestionDTO?.priseEnCharge?.isAS;
  const docsSaisissable = mission?.documentSaisissable ?? [];
  const isDossierNonBG = mission?.isDossierNonBG;

  const initialSearch = {
    numClient: user.numClient,
    userCompte: user.userCompte,
    codeAbo: user.codeAbo,
    codeService: user.service,
    nbMaxElement: '12',
  };

  const onClickPortail = () => {
    actionsResult.setResult({});
    actionsSearch.setSearch(initialSearch);
    actionsMission.setMission({});
    sessionStorage.removeItem('searchRdv');
    sessionStorage.removeItem('searchDossier');
    sessionStorage.removeItem('fromPath');
    sessionStorage.removeItem('mission');
    sessionStorage.removeItem('listRdv');
    sessionStorage.removeItem('tab');
    sessionStorage.removeItem('tabSiv');
    sessionStorage.removeItem('tokenMission');
    sessionStorage.removeItem('detailRdv');
    navigate('/extranet/portail-sinapps-auto');
  };

  const isSIVItem = hasRoleMenuSIV && !location.pathname.includes('/extranet/dossiers/formulaires-siv') &&
    user.type === 'Assureur';

  const isFDVItem = hasRoleMenuFDV && isInsideDossier && ((isOM || isAS) || !isDossierNonBG) &&
    isAssureurOrExpertOrCarrossier(user.type) && docsSaisissable.includes(FDV) &&
    !location.pathname.includes('/extranet/dossiers/fdv');

  const isRDVItem = hasRoleMenuRDV && isAssureurOrExpertOrCarrossier(user.type) &&
    !location.pathname.includes('/extranet/dossiers/prise-rdv');

  const getLogo = () => {
    if (isOnPriseRdv) {
      return <LogoSinappsAutoRdvBandeOrange className={classes.toolBar}/>
    } else {
      return <LogoSinappsAutoExtranetBandeOrange className={classes.toolBar}/>
    }
  };

  const isFormsItem = hasRoleMenuForms && (!location.pathname.includes('/extranet/dossiers/formulaires') ||
      location.pathname.includes('/extranet/dossiers/formulaires-siv')) &&
    (user.type === 'Assureur' || user.type === 'Courtier'|| user.type === 'Carrossier') ||
    (user.agrementsBG?.length > 0 && hasRolePECBG);

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.mainBar}>
          <Button className={classes.link} disableRipple onClick={onClickPortail} disabled={props.isTransparent}>
            <Tooltip title={'Retour au portail'}>
              {getLogo()}
            </Tooltip>
          </Button>

          <Toolbar className={classes.toolBar}>
            {/* Notifications */}
            {(isInsideDossier || props.isTransparent) && !isDossierSimple &&
                indicateurArchivage === 'N' && <NotificationsComponent/>
            }

            {/* Menu */}
            {!props.isTransparent && (isSIVItem || isFDVItem || isRDVItem || isFormsItem) &&
              <MenuComponent
                isSIVItem={isSIVItem}
                isFDVItem={isFDVItem}
                isRDVItem={isRDVItem}
                isFormsItem={isFormsItem}
              />
            }

            {/* Menu utilisateur */}
            <MenuAccount
              configEnvironnement={props.configEnvironnement}
              setUser={props.setUser}
              onClickPortail={onClickPortail}
              isInsideDossier={isInsideDossier}
              disabled={props.isTransparent}
            />
          </Toolbar>
        </Toolbar>
      </AppBar>

      {/* Menu mode SVP */}
      {isAssistance && <ToolbarSVP redirect={() => navigate('/extranet/portail-sinapps-auto')}/>}
    </div>
  )
};
