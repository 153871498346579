/*
* Retourne un booleen pour savoir si l'utilisateur est un Assureur
*/
export const isAssureur = (userType: string) => {
  return userType === 'Assureur'
};

/*
* Retourne un booleen pour savoir si l'utilisateur est un Expert
*/
export const isExpert = (userType: string) => {
  return userType === 'Expert'
};

/*
* Retourne un booleen pour savoir si l'utilisateur est un Carrossier
*/
export const isCarrossier = (userType: string) => {
  return userType === 'Carrossier'
};

/*
* Retourne un booleen pour savoir si l'utilisateur est un Assureur, un Expert ou un Carrosier
*/
export const isAssureurOrExpertOrCarrossier = (userType: string) => {
  return userType === 'Assureur' || userType === 'Expert' || userType === 'Carrossier'
};

/*
* Retourne un booleen pour savoir si l'utilisateur est un Assureur ou un Courtier
*/
export const isAssureurOrCourtier = (userType: string) => {
  return userType === 'Assureur' || userType === 'Courtier'
};
