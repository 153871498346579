import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface IdentificationPersonneProps {
  className?: string,
  name: string,
  formulaire: string,
  typePersonne: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onError: boolean,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  value: string,
  helperText: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const IdentificationPersonne = ({className, name, formulaire, typePersonne, onChangeValue, onError, helperText,
  onBlur, onFocus, value}: IdentificationPersonneProps) => {
  const classes = useEmotionStyles(styles);

  const libelle = () => {
    if (typePersonne === 'pp') {
      return 'physique '
    }
    if (typePersonne === 'pm') {
      return 'morale '
    }
    return ''
  }

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Identification de la personne {libelle()}
        <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSearch
        id={'identification' + typePersonne + formulaire}
        name={name}
        fullWidth={true}
        maxLength={typePersonne === 'pp' ? 131 : 80}
        onError={onError}
        helperText={helperText}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChangeValue}
        value={value}
      />
    </div>
  )
};
