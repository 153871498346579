import React from 'react';
import {MenuItem, Typography} from '@mui/material';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {checkRefSin, ErrorFields} from '../PriseRDV';
import {InformationsRdv} from '../../../model/priseRDV/RDVModel';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {IdigoParameterValueModel} from '../../../model/priseRDV/IdigoParameterValueModel';
import {RefSinistreRdv} from '../../common/RefSinistreRdv';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    columnGap: '40px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
});

interface InfoRdvProps {
  informationsRdv: InformationsRdv,
  activite: string,
  sousActivitesList: IdigoParameterValueModel[],
  errorFields: ErrorFields,
  onChangeValue: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  setErrorFields: (errorFields: ErrorFields) => void,
}

export const itemsTypeRDV: ItemGetValeursCodesModel[] = [
  {valeur: '01', libelle: 'Rendez-vous EAD', lowercase: 'Rendez-vous EAD',
    etat: 'actif', libelleedition: 'Rendez-vous EAD', fonction: '', uppercase: 'Rendez-vous EAD'},
  {valeur: '02', libelle: 'Rendez-vous d\'expertise', lowercase: 'Rendez-vous d\'expertise',
    etat: 'actif', libelleedition: 'Rendez-vous d\'expertise', fonction: '', uppercase: 'Rendez-vous d\'expertise'},
];

const InfoRdv = (props: InfoRdvProps) => {
  const classes = useEmotionStyles(styles);
  const {informationsRdv, activite, sousActivitesList, onChangeValue, errorFields, setErrorFields} = props;

  const itemsListSousActivites = sousActivitesList.map((item: IdigoParameterValueModel) => {
    return (
      <MenuItem key={item.code} value={item.code}>{item.libelle}</MenuItem>
    )
  });

  const itemsListTypeRDV = itemsTypeRDV.map((item: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>
    )
  });

  const onBlurRefSin = (event: React.FocusEvent<HTMLInputElement>) => {
    if (checkRefSin(event.target.value, true).onError) {
      setErrorFields([...errorFields,
        {filedName: 'referenceSinistre', errorMessage: checkRefSin(event.target.value, true).typeError},
      ])
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setErrorFields([...errorFields.filter((error)=> error.filedName != event.target.name)])
  };

  const isErrorField = (fieldName: string) => {
    return errorFields && errorFields.filter((error) => error.filedName === fieldName).length > 0 || false
  };

  const getMessageErrorFromList = (fieldName: string) => {
    const fieldOnError = errorFields?.filter((error) => error.filedName === fieldName);
    return (fieldOnError?.length) ? fieldOnError[0].errorMessage : ''
  };

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Informations du rendez-vous</Typography>
      <div className={classes.flex}>
        <div style={{flexGrow: 1}}>
          <RefSinistreRdv
            value={informationsRdv.referenceSinistre}
            onChangeValue={onChangeValue('referenceSinistre')}
            onBlur={onBlurRefSin}
            onFocus={handleFocus}
            isMandatory={true}
            onError={isErrorField('referenceSinistre')}
            typeError={checkRefSin(informationsRdv.referenceSinistre, true).typeError}
            formulaire={'RDV'}
          />
        </div>

        <div style={{flexGrow: 1}}>
          <Typography className={classes.libelle}>Type de rendez-vous <span style={{color: 'red'}}>*</span></Typography>
          <TextFieldSelect
            id={'typeRdv'}
            name={'typeRdv'}
            fullWidth={true}
            marginDense={true}
            value={informationsRdv.typeRdv.code}
            itemsList={itemsListTypeRDV}
            onError={isErrorField('typeRdv')}
            onChange={onChangeValue('typeRdv')}
          />
          {errorFields && isErrorField('typeRdv') &&
            <Typography id="component-error-text" className={classes.error} style={{maxWidth: '240px'}}>
              {getMessageErrorFromList('typeRdv')}
            </Typography>
          }
        </div>

        <div style={{flexGrow: 1}}>
          <Typography className={classes.libelle}>
            Prestation du réparateur <span style={{color: 'red'}}>*</span>
          </Typography>
          <TextFieldSelect
            id={'prestationReparateur'}
            name={'prestationReparateur'}
            fullWidth={true}
            marginDense={true}
            value={activite}
            itemsList={itemsListSousActivites}
            onError={isErrorField('sousActivite')}
            onChange={onChangeValue('sousActivite')}
          />
          {errorFields && isErrorField('sousActivite') &&
            <Typography className={classes.error}>{getMessageErrorFromList('sousActivite')}</Typography>
          }
        </div>
      </div>
    </div>
  );
};

export default InfoRdv;
