import React from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../../common/useEmotionStyles';

interface ErreurSIVProps {
  data: any,
  prefix: string,
}

/** Design */
const styles = () => ({
  sousTitre: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    flexGrow: 1,
    flexBasis: '0px',
  },
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0px 10px 20px #6469a61a',
  },
  cardContent: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
  },
  divLibelleVal: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  libelleElement: {
    color: '#7c7c7c',
    fontSize: '14px',
    minWidth: 'max-content',
  },
  valeurElement: {
    color: '#241E46',
    fontWeight: 'bold',
    fontSize: '14px',
  },
});

export const ErreurSIV = (props: ErreurSIVProps) => {
  const classes = useEmotionStyles(styles);
  const {data, prefix} = props;

  return (
    <div style={{margin: 'auto', width: '60%'}}>
      <Typography className={classes.sousTitre}>Erreur</Typography>
      <div className={classes.cardContainer}>
        <div className={classes.cardContent} style={{padding: '12px'}}>
          <div className={`${classes.flex} ${classes.divLibelleVal}`}>
            <Typography className={classes.libelleElement}>Code de l&apos;erreur :&nbsp;</Typography>
            <Typography className={classes.valeurElement}>
              {data.getElementsByTagName(prefix + 'code_erreur').length !== 0 ?
                data.getElementsByTagName(prefix + 'code_erreur')[0].value : ''}
            </Typography>
          </div>
          <div className={`${classes.flex} ${classes.divLibelleVal}`}>
            <Typography className={classes.libelleElement}>Description de l&apos;erreur :&nbsp;</Typography>
            <Typography className={classes.valeurElement}>
              {data.getElementsByTagName(prefix + 'message_erreur').length !== 0 ?
                data.getElementsByTagName(prefix + 'message_erreur')[0].value : ''}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
};
