import React, {useState} from 'react';
import {Button, Typography} from '@mui/material';
import {StyledTypography} from '../../common/StyledTypography';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ReactComponent as ReparateurCertifiedIcon} from '../../../images/icones/icn-reparateur-certified.svg';
import {customTheme} from '../../../common/GlobalTheme';
import {BlocAdresse} from '../../common/adresse/BlocAdresse';
import {ReactComponent as MarkerMapIcon} from '../../../images/icones/icones-map/icn-marker-map.svg';
import {dateToDateFullText, roundTime} from '../../../common/utils/DateUtils';
import moment from 'moment';
import {UserContext} from '../../../store/UserContext';
import {DisponibilitesProps} from '../../../model/priseRDV/DisponibiliteModel';
import {DebutFinProps, HoraireProps} from '../../../model/priseRDV/HoraireModel';
import {PartenaireProps} from '../../../model/priseRDV/PartenaireModel';
import {ButtonSeeLess} from '../../common/buttons/ButtonSeeLess';
import {ButtonSeeMore} from '../../common/buttons/ButtonSeeMore';
import {CreneauxDisponibiliteProps} from '../../../model/priseRDV/CreneauDisponibiliteModel';
import {CustomMarker} from './CustomMarker';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface CardReparateurProps {
  disponibilite: DisponibilitesProps,
  onClickCard: (numeroReparateur: string, infosPartenaire?: PartenaireProps) => void,
  onChangePartenaire: (input: string, data: {infosPartenaire: PartenaireProps,
    nextDispo: CreneauxDisponibiliteProps}) => void,
  onChangeActiveStep: (activeStep: number) => void,
  isFilterRepAgree: boolean,
}

type StylesProps = { isCertified?: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    position: 'relative' as const,
  },
  buttonCard: {
    padding: '0px',
    width: '100%',
    textAlign: 'inherit' as const,
  },
  cardReparateurs: {
    'width': '100%',
    'borderLeft': '5px solid transparent',
    'backgroundColor': theme.palette.white.main,
    'padding': '10px 10px 65px 15px',
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  reparateurSelected: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  reparateurCertifiedIcon: {
    fill: props.isCertified ? theme.palette.green.main : theme.palette.secondary.dark2,
    marginRight: '2px',
    width: '20px',
  },
  textCertified: {
    color: props.isCertified ? theme.palette.green.main : theme.palette.secondary.dark2,
    fontSize: '14px',
    fontWeight: 'bold',
    width: 'max-content',
  },
  adresseDistance: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  distanceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20px',
    padding: '5px',
  },
  distanceText: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    marginLeft: '10px',
  },
  flex: {
    display: 'flex',
  },
  button: {
    position: 'absolute' as const,
  },
  buttonLeft: {
    left: '20px',
    bottom: '15px',
  },
  buttonRight: {
    right: '10px',
    bottom: '14px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  textButton: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

export const CardReparateur = (props: CardReparateurProps) => {
  const {disponibilite, onClickCard, onChangePartenaire, onChangeActiveStep, isFilterRepAgree} = props;
  const infosPartenaire = disponibilite.partenaire;
  const {user} = React.useContext(UserContext);
  const isCertified = isFilterRepAgree ?? disponibilite.partenaire?.agreePar?.includes(user.siret);
  const classes = useEmotionStyles(styles, {isCertified});
  const [expanded, setExpanded] = useState(false);
  const distanceEnKm = (disponibilite.partenaire.distance / 1000).toFixed(2);
  const prochaineDispo = disponibilite.disponibilites.length > 0 ?
    moment(disponibilite.disponibilites[0].dateDebut) : '';
  const prochaineDispoFullText = !!prochaineDispo && prochaineDispo.isValid() &&
      dateToDateFullText(roundTime(prochaineDispo.toISOString())) || '';

  const services = infosPartenaire.activites.length && infosPartenaire.activites[0]?.servicesProposes;

  const reparateurSelected = disponibilite.isSelectedOnMap &&
    document.getElementById('reparateur-' + disponibilite.numero);
  if (reparateurSelected) reparateurSelected.scrollIntoView({behavior: 'smooth', block: 'center'});

  const onClickPrendreRdv = () => {
    onChangePartenaire('partenaire', {infosPartenaire: {...infosPartenaire, distance: Number(distanceEnKm)},
      nextDispo: disponibilite.disponibilites[0]});
    onChangeActiveStep(2)
  };

  const onClickInfos = () => {
    setExpanded(!expanded)
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.buttonCard}
        onClick={() => onClickCard(disponibilite.numero.toString(), infosPartenaire)}
      >
        <div id={'reparateur-' + disponibilite.numero.toString()}
          className={`${classes.cardReparateurs} ${disponibilite.isSelectedOnMap ? classes.reparateurSelected : ''}`}
        >
          <div className={classes.title}>
            <div className={classes.flexCenter}>
              <CustomMarker numero={disponibilite.numero.toString()} isSelected={disponibilite.isSelectedOnMap}/>
              <Typography variant="h3" style={{margin: '0px 0px 8px 10px'}}>
                {disponibilite.partenaire.raisonSociale.nom}
              </Typography>
            </div>
            <div className={classes.flexCenter} style={{marginBottom: '8px'}}>
              <ReparateurCertifiedIcon className={classes.reparateurCertifiedIcon}/>
              <Typography className={classes.textCertified}>
                {!isCertified ? 'non ' : ''}agréé
              </Typography>
            </div>
          </div>
          <div className={classes.adresseDistance}>
            <BlocAdresse adresse={disponibilite.partenaire.adresse}/>
            <div className={classes.distanceContainer}>
              <MarkerMapIcon style={{fill: customTheme.palette.primary.main, width: '10px', height: 'auto'}}/>
              <Typography className={classes.distanceText}>{distanceEnKm} km</Typography>
            </div>
          </div>
          <StyledTypography
            title={'Prochaine disponibilité'}
            text={prochaineDispoFullText}
          />
          {expanded &&
          <div>
            <div style={{marginTop: '12px'}}>
              <Typography style={customTheme.styledTypography.libelle}>Horaires d&apos;ouverture</Typography>
              <Typography style={customTheme.styledTypography.donneeGrise16}>
                {infosPartenaire?.horaires ?
                      Object.keys(infosPartenaire.horaires).map((jour, indexJ) => {
                        const keyDiv = `${indexJ}`
                        return (
                          <div key={keyDiv} className={classes.flex}>
                            <Typography>{jour.substring(0, 1).toUpperCase() + jour.substring(1)} :&nbsp;</Typography>
                            {infosPartenaire.horaires[jour as keyof HoraireProps].length > 0 ?
                              infosPartenaire.horaires[jour as keyof HoraireProps].map((horaires: DebutFinProps,
                                  indexH: number) => {
                                const keyDiv = `${indexH}`
                                return (
                                  <div key={keyDiv}>
                                    {(horaires.debut !== '' && horaires.fin !== '') &&
                                      <Typography>
                                        {infosPartenaire.horaires[jour as keyof HoraireProps].length > 1 &&
                                          indexH !== 0 && <span>&nbsp;-&nbsp;</span>
                                        }
                                        {horaires.debut.replace(':', 'h')} à {horaires.fin.replace(':', 'h')}
                                      </Typography>
                                    }
                                  </div>
                                )
                              }) :
                              <Typography>Fermé</Typography>
                            }
                          </div>
                        )
                      }) : <div><i>Aucun horaire renseigné</i></div>
                }
              </Typography>
            </div>
            <div style={{marginTop: '12px'}}>
              <Typography style={customTheme.styledTypography.libelle}>Services</Typography>
              <Typography style={customTheme.styledTypography.donneeGrise16}>
                {services && services.length > 0 ?
                  services.map((service, index) => {
                    const keySpan = `${index}`
                    return (
                      <span key={keySpan}>
                        {service}
                        {(services.length > 1 && services.length !== index+1) && <span>,&nbsp;</span>}
                      </span>
                    )
                  }) :
                  <div><i>Aucun service renseigné</i></div>
                }
              </Typography>
            </div>
          </div>
          }
        </div>
      </Button>

      <div className={`${classes.button} ${classes.buttonLeft}`}>
        <ButtonBlue id={'boutonPrendreRDV'} libelle={'Prendre RDV'} onClick={onClickPrendreRdv}/>
      </div>
      <div className={`${classes.button} ${classes.buttonRight}`}>
        <div className={classes.label}>
          {expanded &&
            <ButtonSeeLess
              id={'bouton-voir-moins-infos'}
              className={classes.textButton}
              iconPosition={'left'}
              libelleButton={'VOIR MOINS'}
              onClick={onClickInfos}
            />
          }
          {!expanded &&
            <ButtonSeeMore
              id={'bouton-voir-plus-infos'}
              className={classes.textButton}
              iconPosition={'left'}
              libelleButton={'VOIR PLUS'}
              onClick={onClickInfos}
            />
          }
        </div>
      </div>
    </div>
  )
};
