import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';

import IndicateurCard, {IndicateurModel} from './IndicateurCard';

export interface IndicateursProps {
    indicateurModelList?: IndicateurModel[]
    nombreMessageTotal?: number
}

const styles = () => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '1.8em',
    gridRowGap: '0.6em',
    ['@media (max-width:1600px)']: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    ['@media (max-width:900px)']: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    ['@media (max-width:600px)']: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

const IndicateursSuivi = ({indicateurModelList}: IndicateursProps) => {
  const classes = useEmotionStyles(styles)
  const [nbMessage, setNbMessage] = useState<number>(0);

  useEffect(() => {
    setNbMessage(getNombreMessageTotal())
  }, [indicateurModelList]);

  const getNombreMessageTotal = () => {
    let nbMessageTotal = 0;
    indicateurModelList?.map((indicateurModel) => {
      nbMessageTotal += indicateurModel.nombreOccurences
    });
    return nbMessageTotal
  };

  return (
    <div>
      <Typography variant={'h3'}>Messages en attente {nbMessage > 0 ? `(${nbMessage})` : '(0)'}</Typography>
      <div className={classes.grid}>
        {indicateurModelList?.map((indicateurModel, index) => {
          const keyDiv = `${index}`
          return (
            <div key={keyDiv} style={{marginTop: '20px'}}>
              <IndicateurCard
                docType={indicateurModel.docType}
                docTypeLibelle={indicateurModel.docTypeLibelle}
                nombreOccurences={indicateurModel.nombreOccurences}
              />
            </div>
          )
        })
        }
      </div>
    </div>
  );
};

export default IndicateursSuivi;
