import React, {useContext, useState} from 'react'
import {Card, CardContent, Button, Grid, IconButton, Modal, Slide, Tooltip, Typography} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import {StyledTypography} from '../../../common/StyledTypography'
import {useMissionContext} from '../../../../store/MissionContext'
import SD05Form from '../../../forms/sd05/SD05Form';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {UserContext} from '../../../../store/UserContext';
import {ButtonSeeLess} from '../../../common/buttons/ButtonSeeLess';
import {ButtonSeeMore} from '../../../common/buttons/ButtonSeeMore';
import {permittedRolesDetailsDossierModifications} from '../../../../constantes/roles/Roles';
import useHasRole from '../../../../hook/useHasRole';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {droitSaisieMessage} from '../../../../common/Utils';

/** Design */
const styles = (theme: Theme) => ({
  hideForPrint: {
    ['@media print']: {
      display: 'none !important',
    },
  },
  card: {
    ['@media print']: {
      boxShadow: 'none',
      pageBreakInside: 'avoid' as const,
    },
  },
  left: {
    width: '50%',
  },
  right: {
    width: '50%',
  },
  main: {
    display: 'flex',
    columnGap: '30px',
  },
  button: {
    'height': 'fit-content',
    'marginLeft': '15px',
    'marginTop': '-10px',
    'backgroundColor': theme.palette.link.main,
    'color': 'white',
    'borderRadius': '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  printBloc: {
    display: 'none',
    ['@media print']: {
      display: 'block !important',
    },
  },
  boutonSD05: {
    width: '40px',
    height: '40px',
    marginTop: '-5px',
    marginLeft: '5px',
  },
});

export const PriseEnCharge =() => {
  const classes = useEmotionStyles(styles)
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const hasRoleDossierModifications = useHasRole(permittedRolesDetailsDossierModifications);
  const priseEnCharge = mission?.gestionDTO?.priseEnCharge;
  const [expanded, setExpanded] = useState(false);
  const [openSD05Form, setOpenSD05Form] = useState(false);
  const tauxResponsabilite = priseEnCharge?.tauxResponsabilite;
  const natureContrat = priseEnCharge?.natureContrat;
  const plafondGarantie = priseEnCharge?.plafondGarantie;
  const pretVehicule = priseEnCharge?.pretVehicule;
  const mntPlafond = priseEnCharge?.mntPlafond;
  const reglementDirect = priseEnCharge?.reglementDirect;
  const garantieMiseEnJeu = priseEnCharge?.garantieMiseEnJeu;
  const franchise = priseEnCharge?.franchise;
  const isOM = priseEnCharge?.isOM;
  const isAS = priseEnCharge?.isAS;
  const isVisible = garantieMiseEnJeu ?? tauxResponsabilite ?? pretVehicule ?? reglementDirect ?? natureContrat ??
    franchise ?? plafondGarantie ?? mntPlafond;

  const numeroMission = mission?.infoMissionDTO?.numeroMission;
  const ActeursAutorises = ['ASSUREUR', 'COURTIER', 'RM'];

  const handleClickCloseModal = (reload: boolean) => {
    if (reload) {
      setOpenSD05Form(false);
      window.location.reload()
    } else {
      const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
        'Souhaitez-vous continuer ?');
      if (result) {
        setOpenSD05Form(false)
      }
    }
  };

  const slideSD05 = (
    <div>
      <SD05Form onClose={() => handleClickCloseModal(false)} onCloseAndRefresh={() => handleClickCloseModal(true)}/>
    </div>
  );

  const tauxResponsabiliteText = () => {
    if (tauxResponsabilite) {
      return tauxResponsabilite === '999' ? 'Indéterminé' :
        parseFloat(tauxResponsabilite)+'%'
    }
    return ''
  }

  return (
    <>
      {isVisible ?
          <Card className={classes.card}>
            <CardContent>
              <div style={{display: 'flex'}}>
                <Typography variant={'h3'}>Prise en charge</Typography>
                {numeroMission && hasRoleDossierModifications && ActeursAutorises.includes(user.type.toUpperCase()) &&
                    (isOM || isAS) && droitSaisieMessage('41030105', mission?.documentSaisissable!) &&
                    <Tooltip disableInteractive title='Modifier la prise en charge' placement="right">
                      <IconButton id={'boutonSD05'} className={`${classes.boutonSD05} ${classes.hideForPrint}`}
                        onClick={() => setOpenSD05Form(true)}>
                        <CreateIcon style={{color: '#0095db', marginRight: '0px'}}/>
                      </IconButton>
                    </Tooltip>
                }
              </div>
              <div className={classes.main}>
                <div className={classes.left}>
                  <StyledTypography id={'garantieMiseJeu'} title={'Garantie mise en jeu'}
                    text={garantieMiseEnJeu ?? ''} />
                  <StyledTypography id={'natureContrat'} title={'Nature du contrat'} text={natureContrat ?? ''} />
                  <div className={classes.printBloc}>
                    <StyledTypography id={'pretVehicule'} title={'Prêt du véhicule'}
                      text={pretVehicule ? parseFloat(pretVehicule)+' jour(s)' : ''} />
                    <StyledTypography id={'plafondGarantie'} title={'Plafond garantie'} text={plafondGarantie ?? ''} />
                  </div>
                  {expanded && (<div className={classes.hideForPrint}>
                    <StyledTypography id={'pretVehicule'} title={'Prêt du véhicule'}
                      text={pretVehicule ? parseFloat(pretVehicule)+' jour(s)' : ''} />
                    <StyledTypography id={'plafondGarantie'} title={'Plafond garantie'} text={plafondGarantie ?? ''} />
                  </div>)}
                </div>

                <div className={classes.right}>
                  <StyledTypography id={'tauxResponsabilite'}
                    title={'Taux de responsabilité'}
                    text={tauxResponsabiliteText()}
                  />
                  <StyledTypography id={'reglementValue'} title={'Règlement direct'} text={reglementDirect ?? ''} />
                  <div className={classes.printBloc}>
                    <StyledTypography id={'franchiseValue'}
                      title={'Franchise'}
                      text={franchise ? parseFloat(franchise)+' jour(s)' : ''}
                    />
                    <StyledTypography id={'montantPlafondValue'} title={'Montant du plafond'}
                      text={mntPlafond ? parseFloat(mntPlafond)+' €' : ''}
                    />
                  </div>
                  {expanded && (<div className={classes.hideForPrint}>
                    <StyledTypography id={'franchiseValue'}
                      title={'Franchise'}
                      text={franchise ? parseFloat(franchise)+' jour(s)' : ''}
                    />
                    <StyledTypography id={'montantPlafondValue'} title={'Montant du plafond'}
                      text={mntPlafond ? parseFloat(mntPlafond)+' €' : ''}
                    />
                  </div>)}
                </div>
              </div>
              <Grid container style={{justifyContent: 'flex-end'}} className={classes.hideForPrint}>
                {expanded ?
                  <ButtonSeeLess
                    id={'bouton-voir-moins-prise-en-charge'}
                    iconPosition={'top'}
                    libelleButton={'Voir moins d\'infos'}
                    onClick={() => setExpanded(!expanded)}
                  /> :
                  <ButtonSeeMore
                    id={'bouton-voir-plus-prise-en-charge'}
                    iconPosition={'top'}
                    libelleButton={'Voir plus d\'infos'}
                    onClick={() => setExpanded(!expanded)}
                  />
                }
              </Grid>
            </CardContent>
          </Card> :
        ActeursAutorises.includes(user.type.toUpperCase()) && (isOM || isAS) &&
        <Card>
          <CardContent style={{display: 'flex', alignItems: 'center'}}>
            <Typography variant={'h3'}>Prise en charge</Typography>
            <Button className={classes.button} variant="outlined" onClick={() => setOpenSD05Form(true)}>
              <AddCircleIcon/>Établir une prise en charge
            </Button>
          </CardContent>
        </Card>
      }
      <Modal
        open={openSD05Form}
        aria-labelledby="modal-form-SD05"
        aria-describedby="modal-formulaire-envoie-messageSD05"
      >
        <Slide
          in={openSD05Form}
          direction={'left'}
          timeout={800}
          mountOnEnter unmountOnExit>
          {slideSD05}
        </Slide>
      </Modal>
    </>
  )
};
