import React from 'react';
import {RetourDossier} from '../../common/buttons/RetourDossier';
import {Grid, Typography} from '@mui/material';
import {InfosDossierFDV} from './InfosDossierFDV';
import {ContentFormFDV, ParamsFdvType} from './ContentFormFDV';
import {useNavigate, useParams} from 'react-router';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesFDV} from '../../../constantes/roles/Roles';
import AccessDenied from '../../common/errorsComponents/AccessDenied';

import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '35px',
    opacity: '1',
  },
  center: {
    textAlign: 'left' as const,
    height: '100%',
  },
  card15: {
    marginTop: '15px',
  },
  header: {
    display: 'inline-block',
    height: 'auto',
  },
  maincard: {
    margin: '15px 0px',
    height: 'calc(100% - 72px)',
  },
  back: {
    textAlign: 'left' as const,
    height: '40px',
  },
});

export const FDVForm = () => {
  const classes = useEmotionStyles(styles);
  const params = useParams<keyof ParamsFdvType>() as ParamsFdvType;
  const token = params.tokenFdv;
  const navigate = useNavigate();
  const hasRoleFDV = useHasRole(permittedRolesFDV);

  return (
    <>
      {hasRoleFDV &&
        <div className={classes.root}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item style={{width: '22%', paddingRight: '20px'}}>
              <div>
                <div className={classes.back}>
                  <RetourDossier isLink={true} token={token}/>
                </div>
                <div className={classes.card15}>
                  <InfosDossierFDV/>
                </div>
              </div>
            </Grid>
            <Grid item style={{width: '70%'}}>
              <div className={classes.center}>
                <div className={classes.header}>
                  <Typography variant={'h1'}>Fiche de vigilance</Typography>
                </div>
                <div className={classes.maincard}>
                  <ContentFormFDV/>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
      {!hasRoleFDV &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder à la fiche de vigilance'}
          isReturnButton={true}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }
    </>
  )
};
