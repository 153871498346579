import {
  IDossier,
  IGestionnaire,
  IIdentification,
  IIntermediaire,
  IMissionnement,
  ICoordonneesLese,
  ICoordonneesReparateur,
  ICirconstances,
  IInfosVehicule,
  IZone,
  IGarantieResponsabilite,
  IElementCalcul,
  Attachments,
} from './ModelOM';
import {FormikValues} from 'formik';
import moment from 'moment';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';

const dossierInitialState = (values: FormikValues) : IDossier => {
  return {
    numeroPolice: '',
    referenceSinistre: '',
    numeroMission: '',
    assurance: values['assurance'] || '',
    scenario: '',
    isCourtier: values['isCourtier'] || false,
  }
};
const gestionnaireInitialState: IGestionnaire = {
  nomGestionnaire: '',
  codeGestionnaire: '',
};

const intermidiaireInitialState= (values: FormikValues) : IIntermediaire => {
  const intermediaire: IIntermediaire = values['intermediaire']
  return {
    nomIntermediaire: intermediaire['nomIntermediaire'],
    telephonePerso: intermediaire['telephonePerso'],
    telephonePro: intermediaire['telephonePro'],
    telephonePortable: intermediaire['telephonePortable'],
    adresse: {
      adresse1: intermediaire['adresse']['adresse1'],
      adresse2: intermediaire['adresse']['adresse2'],
      adresse3: intermediaire['adresse']['adresse3'],
      localite: {
        codePostal: intermediaire['adresse']['localite']['codePostal'],
        commune: intermediaire['adresse']['localite']['commune'],
      },
    },
  }
};

export const missionnementInitialState: IMissionnement = {
  codeAbonne: '',
  codeExpert: '',
  natureMission: '1',
  dateDepot: '',
  dateMissionnement: moment().format('YYYY-MM-DD'),
  lieuExpertise: '2',
  nom: '',
  adresse: '',
  adresseComplementaire1: '',
  adresseComplementaire2: '',
  localite: {
    codePostal: '',
    commune: '',
  },
  pays: 'FR',
};

const circonstancesInitialState: ICirconstances = {
  nature: 'D',
  typeCirconstances: '1',
  date: '',
  heure: '',
  lieu: '',
  departement: '',
  pays: 'FR',
  position: 'Aucun',
  projection: false,
  vehiculeAdverse: 'VP',
  autresVehicules: '0',
  autresObjets: false,
};

export const infosVehiculeInitialState: IInfosVehicule = {
  genre: 'VP',
  marque: {} as ItemGetValeursCodesModel,
  modele: '',
  immatriculation: '',
  serie: '',
};

const coordonneesLeseInitialState: ICoordonneesLese = {
  nom: '',
  telPerso: '',
  telPro: '',
  telPortable: '',
  adresse: '',
  adresseComplementaire1: '',
  adresseComplementaire2: '',
  localite: {
    codePostal: '',
    commune: '',
  },
};

const coordonneesReparateurInitialState: ICoordonneesReparateur = {
  nom: '',
  telPerso: '',
  telPro: '',
  telPortable: '',
  adresse: '',
  adresseComplementaire1: '',
  adresseComplementaire2: '',
  localite: {
    codePostal: '',
    commune: '',
  },
  codificationAssureur: '',
  natureAccord: '',
};

const garantieResponsabiliteInitialState: IGarantieResponsabilite = {
  garantieMiseEnJeu: '1',
  nature: '1',
  pret: '',
  reglement: '1',
  responsabilite: '',
  indetermine: false,
  modaliteDommages: '',
  autorisationModalite: '',
};

export const elementCalculInitialState: IElementCalcul = {
  id: 0,
  isValid: false,
  elementPriseEnCharge: '',
  descriptif: '',
  montantFranchiseForfaitaire: '',
  tauxFranchiseProportionnelle: '',
  montantPlancherFranchiseProportionnelle: '',
  montantPlafondFranchiseProportionnelle: '',
  tauxPriseEnCharge: '',
  montantPlafondPriseEnCharge: '',
  tvaRecuperable: false,
  elementComprisReparation: true,
  abattementADeduire: true,
};
const elementsCalculInitialState: IElementCalcul[] = [];

const piecesJointesInitialState: Attachments = {
  attachments: [],
  isValid: true,
}

export const identificationInitialState = (values: FormikValues) : IIdentification => {
  return {
    dossier: dossierInitialState(values),
    gestionnaire: gestionnaireInitialState,
    intermediaire: intermidiaireInitialState(values),
    isIntermediaire: values['isIntermediaire'],
    missionnement: missionnementInitialState,
    circonstances: circonstancesInitialState,
    infosVehicule: infosVehiculeInitialState,
    zonesVehicule: [],
    coordonneesLese: coordonneesLeseInitialState,
    coordonneesReparateur: coordonneesReparateurInitialState,
    garantieResponsabilite: garantieResponsabiliteInitialState,
    elementsCalcul: elementsCalculInitialState,
    piecesJointes: piecesJointesInitialState,
    commentaire: '',
  }
};

export const zonesVehicules: IZone[] = [
  {
    id: 0,
    zone: 'AVANT/LATERAL GAUCHE',
    code: '1',
    label: 'AV/LG',
  },
  {
    id: 1,
    zone: 'AVANT',
    code: '8',
    label: 'AV',
  },
  {
    id: 2,
    zone: 'AVANT/LATERAL DROIT',
    code: '2',
    label: 'AV/LD',
  },
  {
    id: 3,
    zone: 'CENTRAL/LATERAL GAUCHE',
    code: '3',
    label: 'CENT/LG',
  },
  {
    id: 4,
    zone: 'CENTRAL/LATERAL DROIT',
    code: '4',
    label: 'CENT/LD',
  },
  {
    id: 5,
    zone: 'PAVILLONS/DESSUS',
    code: '9',
    label: 'PAV/DES',
  },
  {
    id: 6,
    zone: 'ARRIERE/LATERAL GAUCHE',
    code: '5',
    label: 'AR/LG',
  },
  {
    id: 7,
    zone: 'ARRIERE',
    code: '7',
    label: 'AR',
  },
  {
    id: 8,
    zone: 'ARRIERE/LATERAL DROIT',
    code: '6',
    label: 'AR/LD',
  },
];

export const type2Roues: string[] = ['CL', 'CYC', 'MTL', 'MTMI', 'MRSP', 'MTT1', 'MTT2', 'MTTE', 'VELO'];

export const typeUtilitaire: string[] = ['CAM', 'CTTE', 'TCP', 'VASP'];

export const immatObligatoire = ['CAM', 'MTTE', 'CTTE', 'MAGA', 'TCP', 'TRA', 'TRR', 'CARA', 'VP', 'VDVP'];

export const lieuExpertiseList = ['9', 'A', 'B', 'C', 'D'];

export const typesVehicules = ['voiture', 'utilitaire', 'moto'];
