import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Typography} from '@mui/material';
import {ITechnicalInformation} from '../../model/ModelPECBG';

export interface TechnicalInformationProps {
  informationsTechniques: ITechnicalInformation,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  marginBottom16: {
    marginBottom: '16px',
  },
});

const TechnicalInformation = (props: TechnicalInformationProps) => {
  const {informationsTechniques} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Informations techniques
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.marginBottom16}>
            <span className={classes.libelle}>Service auto : </span>
            <span>{informationsTechniques.serviceAuto}</span>
          </div>
          <div className={classes.marginBottom16}>
            <span className={classes.libelle}>Délais de réponse DARVA : </span>
            <span>{informationsTechniques.delaiReponseDarva}</span>
          </div>
          <div>
            <span className={classes.libelle}>Délais de réponse “Assureur” : </span>
            <span>{informationsTechniques.delaiReponseAssureur}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalInformation;
