import React, {useContext, useState, useEffect} from 'react'
import {MenuItem, Typography, IconButton, Tooltip} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import {useMissionContext} from '../../../store/MissionContext'
import {postSD20Form} from '../../../api/message'
import {getValeursCodes} from '../../../api/norme'
import {UserContext} from '../../../store/UserContext'
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {Loader} from '../../common/Loader';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {FormModel} from '../../../model/forms/FormModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  form: {
    height: '100vh',
    width: '40%',
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  iconButton: {
    padding: '0px',
  },
  cancelIcon: {
    marginRight: '0px',
    color: theme.palette.white.main,
  },
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    margin: '0px 20px 0px 0px',
  },
});

const SD20Form = (props: FormModel) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const initialState = {
    referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
    numMission: mission?.infoMissionDTO?.numeroMission,
    dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
    codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
    nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
    coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
    codeGTA: mission?.codeGTA,
    codeService: user.service,
    numeroAssure: mission?.societaireDTO?.numero,
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: props.destinataire?.codeAbonne,
    codeMessage: '',
    commentaire: '',
    scenario: mission?.scenario,
    intermediaire: mission?.intermediaireDTO,
    numClient: user.numClient,
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
  };

  const [formData, setFormData] = useState(initialState);
  const [codesValeursMotifRelance, setCodesValeursMotifRelance] = useState([]);
  const [errorFields, setErrorFields] = useState({hasErrorCodeMessage: false});
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getValeursCodes('AR2', '91030101').then((res) => setCodesValeursMotifRelance(res))
  }, []);

  const codesValeursMotifRelanceSort = [...codesValeursMotifRelance].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListMotifsRelance = codesValeursMotifRelanceSort.map((item: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>
    )
  });

  const handleClick = () => {
    setErrorFields({hasErrorCodeMessage: !formData.codeMessage});
    if (formData.codeMessage) {
      setIsLoading(true);
      postSD20Form(formData)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, commentaire: event.target.value})
  };

  const handleChangeMotif = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields({hasErrorCodeMessage: false});
    setFormData({...formData, codeMessage: event.target.value})
  };

  return (
    <div className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Relance de l&apos;expert</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' className={classes.iconButton}
              onClick={responseReturn <= 0 ? props.onClose : props.onCloseAndRefresh}>
              <CancelIcon className={classes.cancelIcon}/>
            </IconButton>
          </Tooltip>
        </div>
        {responseReturn <= 0 &&
          <div className={classes.body}>
            <Typography className={classes.libelle}>
              Motif de la relance <span className={classes.red}>*</span>
            </Typography>
            <TextFieldSelect
              id={'motifRelance'}
              name={'motifRelance'}
              marginDense={true}
              fullWidth={true}
              value={formData.codeMessage}
              itemsList={itemsListMotifsRelance}
              onChange={(event) => handleChangeMotif(event)}
              onError={errorFields.hasErrorCodeMessage}
            />
            {errorFields.hasErrorCodeMessage ?
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez sélectionner le motif de la relance
              </Typography> : ''
            }
            <div style={{marginTop: '20px'}}>
              <Typography className={classes.libelle}>Votre message</Typography>
              <TextFieldMultiline id={'message'} marginDense={true} rows={14} onChange={handleChangeComment}/>
            </div>
            {responseReturn === -1 &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
        {(responseReturn === 201) &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Votre relance a bien été envoyée
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onCloseAndRefresh}/>
          </div>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onClose}/>
          </div>
        }
      </div>
      {responseReturn <= 0 &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ?
              <Loader className={classes.loader} size={30}/> :
              <ButtonBlueSend id={'boutonEnvoyer'} disabled={errorFields.hasErrorCodeMessage} onClick={handleClick}/>
            }
          </div>
        </div>
      }
    </div>
  )
};

export default SD20Form;
