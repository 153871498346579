import AxiosService from '../services/AxiosService';
import Cookies from 'js-cookie';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne la liste des valeurs de codes pour une DT (Donnée Technique)
 *
 * @param {String} domain - Nom du domaine dans lequel on recherche la donnée
 * @param {String} type - Type de la donnée
 * @return {Promise} response data
 *
 * @example
 *     getValeursCodes('AR2', '91030101')
 */
export async function getValeursCodes(domain: string, type: string) {
  const response = await axiosInstance(`${baseUrl}/valeursCode`,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        params: {
          domain: domain,
          type: type,
        },
      });
  return response.data
}
