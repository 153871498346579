import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Typography} from '@mui/material';
import {IRequestInformation} from '../../model/ModelPECBG';

export interface RequestInformationProps {
  demandeInformation: IRequestInformation,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

const RequestInformation = (props: RequestInformationProps) => {
  const {demandeInformation} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Informations sur la demande
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.grid}>
            <div>
              <span className={classes.libelle}>Assureur : </span>
              <span>{demandeInformation.assureur.toUpperCase()}</span>
            </div>
            <div>
              <span className={classes.libelle}>Date de survenance : </span>
              <span>{demandeInformation.dateSurvenance}</span>
            </div>
            <div>
              <span className={classes.libelle}>Numéro de contrat : </span>
              <span>{demandeInformation.numeroContrat}</span>
            </div>
            <div>
              <span className={classes.libelle}>Heure de survenance : </span>
              <span>{demandeInformation.heureSurvenance}</span>
            </div>
            <div>
              <span className={classes.libelle}>Numéro d&apos;immatriculation : </span>
              <span>{demandeInformation.numeroImmatriculation}</span>
            </div>
            <div>
              <span className={classes.libelle}>Kilométrage : </span>
              <span>{demandeInformation.kilometrage}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestInformation;
