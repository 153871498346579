import * as Yup from 'yup';
import {isStringEmpty} from '../../../../common/Utils';
import {checkImmat} from '../../../../common/utils/InputValuesUtils';
import {immatObligatoire, lieuExpertiseList} from '../model/ConstantsOM';
import {infosLieuExpertise} from '../steps/missionnementExpert/MissionnementExpert';
import {CODES_REGLEMENT_DIRECT} from '../steps/garantie/elementsCalcul/ElementsCalculs';
import moment from 'moment';

const identicalCharactersPattern = /(.)\1{9,}/;
const notAlphaNumPattern = /[^A-Za-z0-9]/;

export const IdentificationSchemaValidation = Yup.object().shape({
  dossier: Yup.object().shape({
    numeroPolice: Yup.string()
        .required('Veuillez saisir une valeur'),

    referenceSinistre: Yup.string()
        .test('test-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
            'identiques d\'affilée',
        (value) => !identicalCharactersPattern.test(value ?? ''))
        .test('test-special-characters', 'Le champ contient des caractères interdits',
            (value) => !notAlphaNumPattern.test(value ?? ''))
        .min(6, 'La référence sinistre doit faire au moins 6 caractères')
        .required('Veuillez saisir une valeur'),

    numeroMission: Yup.string()
        .test('test-numero-mission-char', 'Le champ contient des caractères interdits',
            (value) => !notAlphaNumPattern.test(value ?? ''))
        .required('Veuillez saisir une valeur'),

    assurance: Yup.string().required('Veuillez saisir une valeur'),

    scenario: Yup.string()
        .when('isCourtier', (isCourtier, schema) => {
          return isCourtier[0] ?
              schema.required('Veuillez saisir une valeur') :
              Yup.string().notRequired();
        }),
    isCourtier: Yup.boolean(),
  }),

  gestionnaire: Yup.object().shape({
    codeGestionnaire: Yup.string()
        .required('Veuillez saisir une valeur'),
  }),

  intermediaire: Yup.object().when('isIntermediaire', {
    is: (value: boolean) => value,
    then: () => Yup.object().shape({
      nomIntermediaire: Yup.string()
          .required('Veuillez saisir une valeur'),

      telephonePerso: Yup.string()
          .min(10, 'Veuillez saisir un numéro de téléphone valide'),

      telephonePro: Yup.string()
          .min(10, 'Veuillez saisir un numéro de téléphone valide'),

      telephonePortable: Yup.string()
          .min(10, 'Veuillez saisir un numéro de téléphone valide'),

      adresse: Yup.object().shape({
        localite: Yup.object().shape({
          codePostal: Yup.string(),
          commune: Yup.string(),
        }).test('test-localite-required', 'Veuillez saisir une valeur',
            (value) => Boolean(value?.codePostal && value?.commune)),
      }),

    }),
  }),

  isIntermediaire: Yup.boolean(),
});

export const MissionnementExpertSchemaValidation = Yup.object().shape({
  missionnement: Yup.object().shape({
    codeAbonne: Yup.string()
        .required('Veuillez saisir une valeur'),

    codeExpert: Yup.string(),

    natureMission: Yup.string()
        .required('Veuillez saisir une valeur'),

    dateDepot: Yup.string(),

    dateMissionnement: Yup.string()
        .required('Veuillez saisir une valeur'),

    lieuExpertise: Yup.string()
        .required('Veuillez saisir une valeur'),

    nom: Yup.string()
        .when('lieuExpertise', (lieuExpertise, schema) =>
        lieuExpertiseList.includes(lieuExpertise[0]) ?
          schema.required('Veuillez saisir une valeur') :
          schema.notRequired(),
        ),

    adresse: Yup.string(),

    localite: Yup.object().shape({
      codePostal: Yup.string(),
      commune: Yup.string(),
    }).test('test-localite-required', 'Veuillez saisir une valeur',
        (value, ctx) => {
          if (infosLieuExpertise.includes(ctx.parent.lieuExpertise)) {
            return Boolean(value?.codePostal && value?.commune);
          }
          return true
        }),

    pays: Yup.string(),
  }),
});

export const CirconstancesSchemaValidation = Yup.object().shape({
  circonstances: Yup.object().shape({
    nature: Yup.string().required('Veuillez saisir une valeur'),

    date: Yup.date()
        .max(new Date(), 'La date du sinistre ne peut être postérieure à la date du jour')
        .required('Veuillez saisir une valeur'),

    heure: Yup.string().when('date', {
      is: (value: string) => moment().isSame(moment(value), 'day'),
      then: () => Yup.string()
          .test('test-heure', 'L\'heure du sinistre ne peut être postérieure à l\'heure du jour', ((val) => {
            return val ? moment().isSameOrAfter(moment(val, 'HH:mm'), 'minute') : !val
          })),
    }),

    lieu: Yup.string().required('Veuillez saisir une valeur'),

    pays: Yup.string().required('Veuillez saisir une valeur'),

    departement: Yup.string().when('pays', {
      is: (value: string) => value && value === 'FR',
      then: () => Yup.string().required('Veuillez saisir une valeur'),
    }),

    typeCirconstances: Yup.string().required('Veuillez saisir une valeur'),

    vehiculeAdverse: Yup.string().when('typeCirconstances', {
      is: (value: string) => value && (value === '1' || value === '3'),
      then: () => Yup.string().required('Veuillez saisir une valeur'),
    }),
  }),
});

export const InfosVehiculeSchemaValidation = Yup.object().shape({
  infosVehicule: Yup.object().shape({
    genre: Yup.string()
        .required('Veuillez saisir une valeur'),

    marque: Yup.object()
        .required('Veuillez saisir une valeur')
        .test('is-not-empty', 'Veuillez saisir une valeur', (value) => {
          return Object.keys(value).length > 0;
        }),

    modele: Yup.string()
        .required('Veuillez saisir une valeur'),

    immatriculation: Yup.string()
        .when('genre', (genre, schema) =>
        immatObligatoire.includes(genre[0]) ?
          schema.required('Veuillez saisir une valeur')
              .test('test-immatriculation-format', 'Veuillez saisir une valeur au format attendu',
                  (value) => checkImmat(value || '')) :
          schema.when((values) => {
            if (values[0]) {
              return Yup.string()
                  .test('test-immatriculation-format', 'Veuillez saisir une valeur au format attendu',
                      (value) => checkImmat(value ?? ''))
            } else {
              return Yup.string().notRequired()
            }
          }),
        ),

    serie: Yup.string()
        .when('genre', (genre, schema) =>
        genre[0] === 'VELO' ?
          schema.required('Veuillez saisir une valeur') :
          schema.notRequired(),
        ),
  }),
});

export const CoordonneesSchemaValidation = Yup.object().shape({
  coordonneesLese: Yup.object().shape({
    nom: Yup.string().required('Veuillez saisir une valeur'),

    telPerso: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    telPro: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    telPortable: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    localite: Yup.object().shape({
      codePostal: Yup.string(),
      commune: Yup.string(),
    }).test('test-localite-required', 'Veuillez saisir une valeur',
        (value) => {
          return Boolean(value?.codePostal && value?.commune);
        }),
  }),

  coordonneesReparateur: Yup.object().shape({
    nom: Yup.string().test('test-nom', 'Veuillez saisir une valeur',
        function(value, ctx) {
        // Nom obligatoire si une des autres données est non null
          if (ctx.parent && (!isStringEmpty(ctx.parent.telPro) || !isStringEmpty(ctx.parent.adresse) ||
          !isStringEmpty(ctx.parent.adresseComplementaire1) || !isStringEmpty(ctx.parent.adresseComplementaire2) ||
          !isStringEmpty(ctx.parent.localite.codePostal) || !isStringEmpty(ctx.parent.localite.commune) ||
          !isStringEmpty(ctx.parent.codificationAssureur))) {
            return !isStringEmpty(value)
          }
          // Nom obligatoire si lieuExpertise = Réparateur (2)
          if (ctx?.from) {
            if (ctx.from[1] && ctx.from[1].value.missionnement.lieuExpertise === '2') {
              return !isStringEmpty(value)
            }
          }
          return true
        }),

    telPerso: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    telPro: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    telPortable: Yup.string()
        .min(10, 'Veuillez saisir un numéro de téléphone valide'),

    localite: Yup.object().shape({
      codePostal: Yup.string(),
      commune: Yup.string(),
    }).test('test-localite-required', 'Veuillez saisir une valeur',
        (value, ctx) => {
        // Localité obligatoire si une des autres données est non null
          if (ctx.parent && (!isStringEmpty(ctx.parent.telPro) || !isStringEmpty(ctx.parent.adresse) ||
            !isStringEmpty(ctx.parent.adresseComplementaire1) || !isStringEmpty(ctx.parent.adresseComplementaire2) ||
            !isStringEmpty(ctx.parent.nom) || !isStringEmpty(ctx.parent.codificationAssureur))) {
            return Boolean(value?.codePostal && value?.commune);
          }
          // Localité obligatoire si lieuExpertise = Réparateur (2)
          if (ctx?.from) {
            if (ctx?.from[2] && ctx.from[2].value.missionnement.lieuExpertise === '2') {
              return Boolean(value?.codePostal && value?.commune);
            }
          }
          return true
        }),

    natureAccord: Yup.string().test('test-natureAccord', 'Veuillez saisir une valeur',
        function(value, ctx) {
          if (ctx?.from) {
            if (ctx?.from[1] && ctx.from[1].value.missionnement.lieuExpertise === '2') {
              return !isStringEmpty(value)
            }
          }
          return true
        }),
  }),
});

export const GarantieSchemaValidation = Yup.object().shape({
  garantieResponsabilite: Yup.object().shape({
    garantieMiseEnJeu: Yup.string().required('Veuillez saisir une valeur'),

    nature: Yup.string().required('Veuillez saisir une valeur'),

    pret: Yup.string().required('Veuillez saisir une valeur'),

    reglement: Yup.string().required('Veuillez saisir une valeur'),

    responsabilite: Yup.string()
        .when('indetermine', (indetermine, schema) =>
          !indetermine[0] ?
            schema.required('Veuillez saisir une valeur') :
            schema.notRequired(),
        ).test('test-responsabilite',
            'Veuillez saisir une valeur au format attendu', function(value) {
              if (!isStringEmpty(value)) {
                return Number(value) <= 100
              }
              return true
            }),

    indetermine: Yup.boolean(),

    modaliteDommages: Yup.string().test('test-modaliteDommages', 'Veuillez saisir une valeur',
        function(value, ctx) {
          if (ctx.parent && isStringEmpty(value)) {
            return isStringEmpty(ctx.parent.autorisationModalite)
          }
          return true
        }),

    autorisationModalite: Yup.string().test('test-autorisationModalite', 'Veuillez saisir une valeur',
        function(value, ctx) {
          if (ctx.parent && isStringEmpty(value)) {
            return isStringEmpty(ctx.parent.modaliteDommages)
          }
          return true
        }),
  }),

  elementsCalcul: Yup.array().when('garantieResponsabilite.reglement', {
    is: (value: string) => CODES_REGLEMENT_DIRECT.includes(value),
    then: () => elementsCaculSchema(1),
    otherwise: () => elementsCaculSchema(0),
  }),
});

const elementsCaculSchema = (min: number) => {
  return Yup.array().of(
      Yup.object().shape({
        elementPriseEnCharge: Yup.string().required('Veuillez saisir une valeur'),
        tauxPriseEnCharge: Yup.string().test('test-tauxPriseEnCharge',
            'Veuillez saisir une valeur au format attendu', function(value) {
              if (!isStringEmpty(value)) {
                return Number(value) <= 100
              }
              return true
            }),
      }),
  ).min(min, 'Veuillez saisir au moins un élément de calcul')
};
