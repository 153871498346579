import React from 'react'
import {InfosVehicule} from './InfosVehicule';
import {CaracteristiquesTech} from './CaracteristiquesTech';
import {Entete} from './Entete';

interface ReponseCTProps {
  formulaire: string
  data: any,
  prefix: string
}

export const ReponseCT = (props: ReponseCTProps) => {
  const {formulaire, data, prefix} = props

  return (<div>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <Entete data={data} prefix={prefix} formulaire={formulaire}/>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}/>
    </div>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <InfosVehicule data={data} prefix={prefix}/>
      <CaracteristiquesTech data={data} prefix={prefix}/>
    </div>
  </div>)
};
