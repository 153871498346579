import React from 'react'
import {Entete} from './Entete';

interface ReponseIRProps {
  formulaire: string
  data: any,
  prefix: string
}

export const ReponseIR = (props: ReponseIRProps) => {
  const {formulaire, data, prefix} = props

  return (<div style={{columnGap: '30px', width: '60%', margin: 'auto'}}>
    <Entete data={data} prefix={prefix} formulaire={formulaire}
      withoutTitreContainer={true}/>
  </div>)
};
