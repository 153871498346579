import React from 'react';
import {TextField, Typography} from '@mui/material';
import moment from 'moment';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface DateProps {
  id: string,
  className?: string,
  classNameTextField?: string,
  name: string,
  isMandatory: boolean,
  title: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  value?: string,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  input: {
    'backgroundColor': theme.palette.white.main,
    '&:before': {
      border: 'none',
    },
  },
});

export const InputDate = ({id, className, classNameTextField, name, isMandatory, title, formulaire, onChangeValue,
  onBlur, onFocus, onError, value, helperText}: DateProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        {title} {isMandatory && <span className={classes.red}>*</span>}
      </Typography>

      <TextField
        className={classNameTextField}
        id={id + formulaire}
        name={name}
        type='date'
        margin="dense"
        size="small"
        variant="outlined"
        fullWidth={true}
        value={value}
        error={onError}
        helperText={helperText}
        InputProps={{
          classes: {
            root: classes.input,
          },
        }}
        inputProps={{
          min: '1900-01-01',
          max: moment().format('YYYY-MM-DD'),
        }}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
};
