import * as Yup from 'yup';
import {checkImmat} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {elementVitreOptique} from '../../../../constantes/constantesNormeAuto';
import {kilometrageCases, refReparateurMandatoryCases} from '../model/ConstantsPECBG';

export const InfosPecPgSchemaValidation = Yup.object().shape({
  infosVehicule: Yup.object().shape({
    immatriculation: Yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-immatriculation-format', 'Veuillez saisir une valeur au format attendu',
            (value) => checkImmat(value || '')),

    assureurGTA: Yup.string()
        .required('Veuillez sélectionner une valeur'),

    kilometrage: Yup.string()
        .test('test-kilometrage', 'Veuillez saisir une valeur', (value, context) => {
          if (kilometrageCases.includes(context.parent.assureurGTA)) {
            return Boolean(value?.length)
          }
          return true
        }),

    refReparateur: Yup.string()
        .test('test-refReparateur', 'Veuillez saisir une valeur', (value, context) => {
          if (refReparateurMandatoryCases.includes(context.parent.assureurGTA)) {
            return Boolean(value?.length)
          }
          return true
        }),

    dateSurvenance: Yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-date-survenance', 'La date de survenance ne peut être postérieure à la date du jour',
            (dateSurvenance) => {
              return moment(dateSurvenance, 'YYYY-MM-DD').isBefore(moment.now());
            }),

    heureSurvenance: Yup.string().when('dateSurvenance', (dateSurvenance) => {
      return Yup.string()
          .required('Veuillez saisir une valeur')
          .test('test-heure-survenance',
              'L\'heure de survenance ne peut être postérieure à l\'heure du jour',
              (heureSurvenance) => {
                const date = dateSurvenance[0] + ' ' + heureSurvenance;
                const now = moment.now();
                return moment(dateSurvenance[0], 'YYYY-MM-DD').isAfter(now) ?
                  true :
                  moment(date, 'YYYY-MM-DD HH:mm').isBefore(now);
              });
    }),
  }),

  sinistre: Yup.object().shape({
    circonstance: Yup.string()
        .required('Veuillez sélectionner une valeur'),
  }),

  dommages: Yup.array().of(
      Yup.object().shape({
        elementVitre: Yup.string()
            .required('Veuillez sélectionner une valeur'),
        typeIntervention: Yup.string()
            .required('Veuillez sélectionner une valeur'),
        descriptionOptique: Yup.string().test('test-descriptionOptique', 'Veuillez sélectionner une valeur',
            function(value, context) {
              if (elementVitreOptique === context.parent.elementVitre) {
                return value !== undefined && value !== ''
              }
              return true;
            }),
      }))
      .min(1, 'Veuillez ajouter au moins un dommage'),
});
