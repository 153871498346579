import React from 'react';
import InfosVehiculeBloc from './InfosVehiculeBloc';
import VehiculeBloc from './VehiculeBloc';

const InfosVehicule = () => {
  return (
    <div style={{marginBottom: '16px'}}>
      <InfosVehiculeBloc/>
      <VehiculeBloc/>
    </div>
  );
};

export default InfosVehicule;

