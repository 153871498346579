import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface SlideFormProps {
  title?: string
  childrenBody: React.ReactNode
  childrenFooter?: React.ReactNode
  onClose: () => void
}

type StylesProps = { isFooter: boolean }
const styles = (theme: Theme, {isFooter}: StylesProps) => ({
  container: {
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    height: '100vh',
    width: '40%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: isFooter ? '90vh' : 'auto',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 24px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  body: {
    padding: isFooter ? '24px' : '24px 24px 0px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
});

const SlideForm = (props: SlideFormProps) => {
  const {title, childrenBody, childrenFooter, onClose} = props
  const classes = useEmotionStyles(styles, {isFooter: !!childrenFooter});

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>
            {title}
          </Typography>
          <Tooltip
            disableInteractive
            title='Quitter'
            PopperProps={{disablePortal: true}}
          >
            <IconButton
              component='span'
              style={{padding: '0px'}}
              onClick={onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.body}>
          {childrenBody}
        </div>
      </div>
      {childrenFooter &&
        <div className={classes.footer}>
          {childrenFooter}
        </div>
      }
    </div>
  );
};

export default SlideForm;
