import React from 'react';
import {FormControlLabel, Radio} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface RadioBtnProps {
  id: string,
  style?: object,
  color?: string,
  value: string|boolean,
  label: string,
}

type StylesProps = {color?: string}
const styles = (theme: Theme, {color}: StylesProps) => ({
  radioBtn: {
    paddingRight: '0px',
  },
  radioBtnRoot: {
    color: color ?? '#241E46',
  },
});

export const RadioBtn = ({id, style, color, value, label}: RadioBtnProps) => {
  const classes = useEmotionStyles(styles, {color});

  return (
    <FormControlLabel
      style={style}
      value={value}
      label={label}
      control={<Radio id={id} color='primary' className={classes.radioBtn} classes={{root: classes.radioBtnRoot}}/>}
    />
  )
};
