import React from 'react';
import {CardContainer} from '../CardContainer';
import {getLibelleValeur, getValueDateElement, getValueElement} from '../Utils';

interface VehiculeProps {
  data: any,
  prefix: string
  formulaire: string
}

export const Vehicule = (props: VehiculeProps) => {
  const {data, prefix, formulaire} = props

  const libellesValeursVehicule = (data: any) => {
    return (
      [
        {
          libellesValeurs: [
            [
              getLibelleValeur('N° immatriculation :', getValueElement(data, prefix, 'numero_immatriculation')),
              getLibelleValeur('N° VIN :', getValueElement(data, prefix, 'vin')),
            ],
            [
              getLibelleValeur(`Présence du certificat d'immatriculation :`,
                  getValueElement(data, prefix, 'presence_CI')),
            ],
            [
              getLibelleValeur(`N° de formule du certificat d'immatriculation :`,
                  getValueElement(data, prefix, 'numero_formule')),
            ],
            [
              getLibelleValeur(`Date du certificat d'immatriculation :`, getValueDateElement(data, prefix, 'date_CI')),
            ],
            (formulaire == 'DA')?[
              getLibelleValeur(`Véhicule économiquement irréparable :`,
                  getValueElement(data, prefix, 'economiquement_irreparable')),
            ] : [],
          ],
        },
      ]
    )
  };

  return (
    <CardContainer titreContainer={'Véhicule'}
      content={libellesValeursVehicule(data)}/>
  )
};
