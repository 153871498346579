import AxiosService from '../services/AxiosService';
import Cookies from 'js-cookie';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Envoie un message SD99
 *
 * @param {Object} data - Objet contenant les données du message SD99 à envoyer
 * @return {Promise}
 */
export function postSD99Form(data: any) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
  };
  return axiosInstance.post(`${baseUrl}/sendSD99`, data, axiosConfig)
}

/**
 * Envoie un message SD20
 *
 * @param {Object} data - Objet contenant les données du message SD20 à envoyer
 * @return {Promise}
 */
export function postSD20Form(data: any) {
  return axiosInstance.post(`${baseUrl}/sendSD20`, data,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      },
  )
}

/**
 * Envoie un message SD05
 *
 * @param {Object} data - Objet contenant les données du message SD05 à envoyer
 * @return {Promise}
 */
export function postSD05Form(data: any) {
  return axiosInstance.post(`${baseUrl}/sendSD05`, data,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      },
  )
}

/**
 * Envoie un message IFR
 *
 * @param {Object} data - Objet contenant les données du message IFR à envoyer
 * @return {Promise}
 */
export function postIFRForm(data: any) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
  };
  return axiosInstance.post(`${baseUrl}/sendIFR`, data, axiosConfig)
}

/**
 * Envoie un message SRP
 *
 * @param {Object} data - Objet contenant les données du message SRP à envoyer
 * @return {Promise}
 */
export function postSRPForm(data: any) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
  };
  return axiosInstance.post(`${baseUrl}/sendSRP`, data, axiosConfig)
}

/**
 * Envoie un message FDV
 *
 * @param {Object} data - Objet contenant les données du message FDV à envoyer
 * @return {Promise}
 */
export function postFDVForm(data: any) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
  };
  return axiosInstance.post(`${baseUrl}/sendFDV`, data, axiosConfig)
}

/**
 * Remise à disposition
 *
 * @param {Object} data - Objet contenant les données nécessaires à la RAD d'un message
 * @return {Promise}
 */
export function postRAD(data: any) {
  return axiosInstance.post(`${baseUrl}/remiseAdisposition`, data,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      },
  )
}

/**
 * Envoie message OM
 *
 * @param {FormData} data - Objet contenant les données de l'OM à envoyer
 * @return {Promise}
 */
export function postOMForm(data: FormData) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${Cookies.get('token')}`,
    },
  };
  return axiosInstance.post(`${baseUrl}/sendOM`, data, axiosConfig)
}

