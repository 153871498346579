import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
  },
  marginTop12: {
    marginTop: '12px',
  },
  field: {
    width: '200px',
  },
});

const GestionnaireBloc = () => {
  const classes = useEmotionStyles(styles);
  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Gestionnaire</Typography>
      <div className={`${classes.flex} ${classes.marginTop12}`}>
        <InputTextField
          name='gestionnaire.nomGestionnaire'
          label='Nom du gestionnaire'
          maxLength={32}
        />
        <InputTextField
          name='gestionnaire.codeGestionnaire'
          label='Code Gestionnaire'
          mandatory={true}
          className={classes.field}
          maxLength={10}
        />
      </div>
    </div>
  );
};

export default GestionnaireBloc;
