import markerMapIcon from '../../../images/icones/icones-map/icn-marker-map.svg';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

type StylesProps = { isNumberSup9?: boolean, isSelected?: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  containerMarker: {
    position: 'relative' as const,
    width: 'fit-content',
  },
  numeroReparateur: {
    position: 'absolute' as const,
    color: theme?.palette.white.main,
    fontFamily: theme?.fonts.main,
    fontWeight: 'bold',
  },
  numeroReparateurOnMap: {
    top: '8px',
    left: props.isNumberSup9 ? '9px' : '13px',
  },
  image: {
    height: 'auto',
    filter: props.isSelected ?
      'invert(11%) sepia(38%) saturate(1697%) hue-rotate(219deg) brightness(95%) contrast(96%)' :
      'invert(42%) sepia(22%) saturate(4004%) hue-rotate(345deg) brightness(98%) contrast(88%)',
  },
});

/**
 * Retourne un marqueur, pour la map de la prise de rdv, bleu ou orange en fonction du paramètre isSelected
 * @param numero
 * @param isSelected
 * @return HTMLElement
 */
export function customMarker(numero: string, isSelected: boolean) {
  const isNumberSup9 = Number(numero) > 9;
  const classes = useEmotionStyles(styles, {isNumberSup9, isSelected});

  return `
    <div class='${classes.containerMarker}' id='marker-${numero}'>
      <img class='${classes.image}' src='${markerMapIcon}' alt='icon'/>
      <div class='${classes.numeroReparateur} ${classes.numeroReparateurOnMap}'>${numero}</div>
    </div>`
}
