import React, {useContext, useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {ReactComponent as DownloadIcon} from '../../../../images/icones/btn-download-small.svg'
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import groupBy from 'lodash/groupBy'
import {useMissionContext} from '../../../../store/MissionContext';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import PhotoViewer from '../../../gestionPhoto/PhotoViewer';
import {Loader} from '../../../common/Loader';
import Picture from './Picture';
import {ReactComponent as ArrowBack} from '../../../../images/icones/icn-arrow-back.svg';
import {ReactComponent as ArrowForward} from '../../../../images/icones/icn-arrow-forward.svg';
import {AttachmentProps, downloadAllAttachments, downloadOneAttachment} from '../DownloadAttachments';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../store/UserContext';

export interface GalleryProps {
  id : string,
  onClose: () => void,
  onReturn: (from: string) => void,
  album: PhotoModel[],
  photo: PhotoModel,
  from: string,
}

/** Design */
const styles = (theme: Theme) => ({
  paper: {
    height: '650px',
    width: '70%',
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '8px',
    padding: '24px',
    ['@media (max-width:1400px)']: {
      height: '80%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  buttonReturn: {
    'color': theme.palette.link.main,
    'padding': '0px',
    'margin': '5px 5px 0px 0px',
  },
  buttonClose: {
    'marginLeft': 'auto',
    'padding': '0px',
  },
  main: {
    display: 'flex',
    flexDirection: 'row' as const,
    height: '80%',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
    boxShadow: 'none',
    padding: '10px',
  },
  count: {
    textAlign: 'left' as const,
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    display: 'inline',
  },
  date: {
    marginLeft: '5px',
    textAlign: 'left' as const,
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '1',
    display: 'inline',
  },
  cards: {
    'maxHeight': '400px',
    'minHeight': '85px',
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  cell: {
    padding: '0px',
    border: 'none',
  },
  buttonPhoto: {
    padding: '3px',
    border: 'none',
  },
  btnDl: {
    width: '100%',
    marginTop: '20px',
  },
  iconDL: {
    fill: theme.palette.white.main,
    marginRight: '10px',
    minWidth: '20px',
  },
  blocImage: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  arrowLeft: {
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  arrowIcon: {
    width: '25px',
    height: '25px',
  },
  zoneImg: {
    display: 'flex',
    overflow: 'hidden',
    margin: '0px auto',
  },
  arrowRight: {
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '30%',
    paddingRight: '5%',
  },
  text: {
    marginLeft: '15px',
    textAlign: 'left' as const,
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '1',
  },
  iconDLPic: {
    fill: theme.palette.link.main,
  },
  loader: {
    marginTop: '0px 5px 0px 0px',
  },
  error: {
    textAlign: 'right' as const,
  },
});

export const Gallery = ({id, onClose, onReturn, album, photo, from}: GalleryProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const referenceSinistre = mission?.infoMissionDTO?.referenceSinistre;
  let position = -1;
  const [reset, setReset] = useState(0);
  const [isLoadingAlbum, setIsLoadingAlbum] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [onErrorDownloadAll, setOnErrorDownloadAll] = useState(false);
  const [onErrorDownloadOne, setOnErrorDownloadOne] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);
  const [imgUrlTab, setImgUrlTab] = useState<{imgId: string, url: string}[]>([]);
  const [isPhotoOnError, setIsPhotoOnError] = useState(false);

  const getAlbumActeur = () : PhotoModel[] => {
    let result = album;
    const groupe = groupBy(album, 'author');
    Object.keys(groupe).forEach((acteur) => {
      if (photo.author === acteur) {
        result = groupe[acteur]
      }
    });
    return result
  };

  const getIndex = () => {
    for (let i = 0; i < albumTmp.length; i++) {
      if (albumTmp[i].id === photo.id) {
        return i
      }
    }
  };

  const [albumTmp] = useState(getAlbumActeur());
  const [photoTmp, setPhotoTmp] = useState({id: id, index: getIndex(), contenu: photo});
  const title = () => {
    if (from === 'acteur') {
      return 'Photos de ' + albumTmp[0].author;
    }
    if (from === 'message' || from === 'pj') {
      return 'Photos du message ' + albumTmp[0].typeDoc + ' par ' + albumTmp[0].author;
    }
    return '';
  };

  const getBy3photo = (tab: PhotoModel[] | undefined) => {
    const result = [];
    if (tab != undefined) {
      const remainder = tab.length % 3;
      const nblignes = remainder === 0 ? tab.length / 3 : ~~(tab.length / 3) + 1;
      let index = 0;
      for (let i = 0; i < nblignes; i++) {
        result.push(tab.slice(index, index + 3));
        index = index + 3
      }
    }
    return result
  };

  const addIndex = () => {
    setIsPhotoOnError(false);
    if (albumTmp.length !== 0) {
      if (photoTmp.index === albumTmp.length - 1) {
        setPhotoTmp({id: albumTmp[0].id, index: 0, contenu: albumTmp[0]})
      } else {
        setPhotoTmp({id: albumTmp[photoTmp.index! + 1].id, index: photoTmp.index! + 1,
          contenu: albumTmp[photoTmp.index! + 1]})
      }
    }
    setReset(reset+1)
  };

  const subIndex = () => {
    setIsPhotoOnError(false);
    if (albumTmp.length !== 0) {
      if ( photoTmp.index === 0) {
        setPhotoTmp({id: albumTmp[albumTmp.length - 1].id, index: albumTmp.length - 1,
          contenu: albumTmp[albumTmp.length - 1]})
      } else {
        setPhotoTmp({id: albumTmp[photoTmp.index! - 1].id, index: photoTmp.index! - 1,
          contenu: albumTmp[photoTmp.index! - 1]})
      }
    }
    setReset(reset+1)
  };

  const saveImgSrc = (imgId:string, url: string) => {
    const image = {
      imgId,
      url,
    };
    setImgUrlTab((imgUrlTab) => [...imgUrlTab, image]);
  };

  const getImgUrl = (imgId: string) => {
    const imgUrl = imgUrlTab.filter((img) => img.imgId === imgId);
    if (imgUrl && imgUrl.length > 0) {
      return imgUrl[0].url;
    }
    return '';
  };

  const attachments = () => {
    const tab: AttachmentProps[] = [];
    albumTmp.forEach((photo) => {
      tab.push({
        id: photo.id,
        name: photo.name,
        date: photo.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  const attachment = {
    id: photoTmp.id,
    name: photoTmp.contenu.name,
    date: photoTmp.contenu.date?.substring(0, 10).replaceAll('/', ''),
  };

  return (
    <div id='popup-gallery' className={classes.paper}>
      <div className={classes.header}>
        {from !== 'pj' ?
          <IconButton id='close-popup-gallery' className={classes.buttonReturn} onClick={() => onReturn(from)}>
            <ArrowBackIcon style={{marginRight: '0px'}}/>
          </IconButton> : ''
        }
        <Typography id='title-gallery' variant={'h3'}>{title()}</Typography>
        <IconButton className={classes.buttonClose} onClick={onClose}>
          <CancelIcon style={{marginRight: '0px'}}/>
        </IconButton>
      </div>
      <div className={classes.main}>
        <div style={{marginTop: '35px'}}>
          <Card className={classes.card}>
            <CardContent style={{height: '100%', padding: '0px', minWidth: '220px'}}>
              <div>
                <Typography className={classes.count}>{albumTmp.length}</Typography>
                <Typography className={classes.date}>{albumTmp.length === 1 ? 'photo' : 'photos'}</Typography>
              </div>
              <div className={classes.cards}>
                <Table>
                  <TableBody>
                    {getBy3photo(albumTmp).map((imageRow) => {
                      return (
                        <TableRow key={'r'+position}>
                          {imageRow.map((image) => {
                            position++;
                            const pos = position;
                            return (
                              <TableCell className={classes.cell} key={pos}>
                                <Button type="button" className={classes.buttonPhoto}
                                  onClick={() => {
                                    setIsPhotoOnError(false);
                                    setPhotoTmp({id: image.id, index: pos, contenu: image});
                                    setReset(reset+1)
                                  }}
                                >
                                  <Picture imgId={image.id} saveImgSrc={saveImgSrc}/>
                                </Button>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
              {isLoadingAlbum ? <Loader size={20}/> :
                <ButtonBlue
                  id={'boutonTelecharger'}
                  className={classes.btnDl}
                  onClick={() => downloadAllAttachments({attachments: attachments(),
                    author: albumTmp[0].author ?? '',
                    typeDoc: from === 'message' || from === 'pj' ? albumTmp[0].typeDoc.trim() : '',
                    referenceSinistre: referenceSinistre ?? '',
                    numClient: user.numClient, setIsLoading: setIsLoadingAlbum,
                    setIsEndDownload, setIsFilesInZip, setOnErrorDownload: setOnErrorDownloadAll, setNbFilesInZip})}
                  icon={<DownloadIcon className={classes.iconDL}/>}
                  libelle={'Télécharger l\'album'}
                />
              }
              {onErrorDownloadAll &&
                <Typography style={{color: 'red', fontSize: '14px', marginTop: '10px'}}>
                  Erreur lors du téléchargement
                </Typography>
              }
              {!isFilesInZip &&
                <Typography style={{color: 'red', fontSize: '14px', textAlign: 'center', marginTop: '5px'}}>
                  Aucune photo valide à télécharger
                </Typography>
              }
              {isFilesInZip && isEndDownload && nbFilesInZip !== albumTmp.length &&
                <Typography style={{color: 'red', fontSize: '14px', textAlign: 'center', marginTop: '5px'}}>
                  {nbFilesInZip}
                  {nbFilesInZip === 1 ? ' image téléchargée' : ' images téléchargées'} sur {albumTmp.length}
                </Typography>
              }
            </CardContent>
          </Card>
        </div>
        <div style={{width: '100%', marginLeft: '10px'}}>
          <div className={classes.blocImage}>
            {albumTmp.length !== 1 ?
              <IconButton id={'btn-img-prec'} onClick={subIndex} className={classes.arrowLeft}>
                <ArrowBack className={classes.arrowIcon}/>
              </IconButton> : ''
            }
            {photo.id !== '' &&
              <div className={classes.zoneImg}>
                <PhotoViewer imgUrl={getImgUrl(photoTmp.id)} miseAzero={reset} setIsPhotoOnError={setIsPhotoOnError}
                  isPhotoOnError={isPhotoOnError}/>
              </div>
            }
            {albumTmp.length !== 1 ?
              <IconButton id={'btn-img-suiv'} onClick={addIndex} className={classes.arrowRight}>
                <ArrowForward className={classes.arrowIcon}/>
              </IconButton> : ''
            }
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
          {photo.id !== '' && <Typography className={classes.text}>{photoTmp.contenu.name}</Typography>}
          <Typography className={classes.date}>déposée le {photoTmp.contenu.date?.replace('-', 'à')}</Typography>
        </div>
        <div>
          {!isLoadingImage && !isPhotoOnError && getImgUrl(photoTmp.id) &&
            <IconButton onClick={() => downloadOneAttachment({attachment, author: photoTmp.contenu.author ?? '',
              typeDoc: photoTmp.contenu.typeDoc || '', referenceSinistre: referenceSinistre ?? '', immatriculation: '',
              numClient: user.numClient, setOnErrorDownload: setOnErrorDownloadOne, setIsLoading: setIsLoadingImage})}>
              <DownloadIcon className={classes.iconDLPic}/>
            </IconButton>
          }
          {isLoadingImage && <Loader className={classes.loader} size={20}/>}
        </div>
      </div>
      {onErrorDownloadOne &&
        <div className={classes.error}>
          <Typography style={{color: 'red', fontSize: '14px'}}>
            Erreur lors du téléchargement
          </Typography>
        </div>
      }
    </div>
  )
};
