import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Box, FormControl, IconButton, MenuItem, Typography, FormControlLabel, Tooltip} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import InputMask from 'react-input-mask-3.0'
import {useMissionContext} from '../../../store/MissionContext';
import {UserContext} from '../../../store/UserContext';
import {postSD05Form} from '../../../api/message';
import {getValeursCodes} from '../../../api/norme';
import {ActeurModel} from '../../../model/detailsDossier/ActeurModel'
import {ElementCalculModel} from '../../../model/detailsDossier/ElementCalculModel';
import ElementsCalcul from './ElementsCalcul';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import BlueSwitch from '../../common/BlueSwitch'
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {TextFieldCurrency} from '../../common/formsComponents/TextFieldCurrency';
import {Loader} from '../../common/Loader';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {FormModel} from '../../../model/forms/FormModel';
import {findElementByLibelleInArray} from '../../../common/Utils';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

type StylesProps = {
  showElementCalcul: boolean,
  isLoading: boolean,
}

/** Design */
const styles = (theme: Theme, {showElementCalcul, isLoading}: StylesProps) => ({
  form: {
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    height: '100vh',
    width: '40%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
  },
  content: {
    height: showElementCalcul ? '100vh' : '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    display: 'flex',
    height: '70px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  textFieldFullWidth: {
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
  textField: {
    width: '200px',
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
  elementsCalcul: {
    padding: '10px 20px 0px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
  divOK: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '40px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: isLoading ? 'flex-end': 'space-between',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    margin: '0px 20px 0px 0px',
  },
});

const SD05Form = (props: FormModel) => {
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');
  const messagesHistorique = mission?.historiqueDTO?.list ?? [];
  const [codesValeursGarantieMiseEnJeu, setCodesValeursGarantieMiseEnJeu] = useState([]);
  const [codesValeursNatureContrat, setCodesValeursNatureContrat] = useState([]);
  const [codesValeursReglementDirect, setCodesValeursReglementDirect] = useState([]);
  const [codesValeursPlafondGarantie, setCodesValeursPlafondGarantie] = useState([]);
  const [codesValeursRecours, setCodesValeursRecours] = useState([]);
  const [codesValeursConvention, setCodesValeursConvention] = useState([]);
  const [codesValeursBareme, setCodesValeursBareme] = useState([]);
  const [checkTaux, setCheckTaux] = useState(mission?.gestionDTO?.priseEnCharge?.tauxResponsabilite === '999');
  const [checkEnlevement, setCheckEnlevement] = useState(mission?.gestionDTO?.priseEnCharge?.enlevementAutorise);
  const [showElementCalcul, setShowElementCalcul] = useState(false);
  const [errorFields, setErrorFields] = useState({tauxResponsabiliteError: false, pretVehiculeError: false,
    codeAbonneDestinataireError: false});
  const [responseReturn, setResponseReturn] = useState(0);
  const [valueCurrency, setValueCurrency] = useState(mission?.gestionDTO?.priseEnCharge?.mntPlafond);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setLoadingData] = useState(true);
  const classes = useEmotionStyles(styles, {showElementCalcul, isLoading});

  // Récupération des destinataires des derniers messages OM et AS actifs
  const getDestinataires = () => {
    let destinataireOM: ActeurModel = {};
    let destinataireAS: ActeurModel = {};
    let list: ActeurModel[] = [];

    [...messagesHistorique].reverse().forEach((message) => {
      if (message.typeCode === '050401' && message.etat === 'Actif') {
        destinataireOM = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.destinataireAbo)[0] ?? {};
      }
      if (message.typeCode === '100401' && message.etat === 'Actif') {
        destinataireAS = mission?.acteursDTO?.acteurs?.filter((acteur) =>
          acteur.codeAbonne === message.destinataireAbo)[0] ?? {};
      }
    });

    if (destinataireOM.codeAbonne && destinataireAS.codeAbonne) {
      list = [destinataireOM, destinataireAS]
    } else if (destinataireOM.codeAbonne) {
      list = [destinataireOM]
    } else if (destinataireAS.codeAbonne) {
      list = [destinataireAS]
    }
    return list;
  };

  const destinatairesList = getDestinataires();

  const itemsListDestinataires = destinatairesList.map((destinataire: ActeurModel, index: number) => {
    const keyMenuItem = `${index}`
    return (
      <MenuItem
        key={keyMenuItem}
        id={`destinataire-${index}`}
        value={destinataire.codeAbonne}>
        {destinataire.libelle}
      </MenuItem>
    )
  });

  const defaultDestinataire = destinatairesList.length > 0 ? destinatairesList[0].codeAbonne : '';

  const initialState = {
    referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
    numMission: mission?.infoMissionDTO?.numeroMission,
    dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
    codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
    nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
    coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
    codeGTA: mission?.codeGTA,
    codeService: user.service,
    numeroAssure: mission?.societaireDTO?.numero,
    scenario: mission?.scenario,
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: defaultDestinataire,
    garantieMiseEnJeu: '',
    natureContrat: '',
    reglementDirect: '',
    tauxResponsabilite: mission?.gestionDTO?.priseEnCharge?.tauxResponsabilite !== '999' ?
        mission?.gestionDTO?.priseEnCharge?.tauxResponsabilite : '',
    pretVehicule: mission?.gestionDTO?.priseEnCharge?.pretVehicule,
    franchise: mission?.gestionDTO?.priseEnCharge?.franchise,
    plafondGarantie: '',
    montantPlafond: mission?.gestionDTO?.priseEnCharge?.mntPlafond,
    enlevementStockage: mission?.gestionDTO?.priseEnCharge?.enlevementAutorise ? 'O' : 'N',
    commentaire: '',
    elementsCalcul: mission?.gestionDTO?.priseEnCharge?.elementsCalcul,
    intermediaire: mission?.intermediaireDTO,
    numClient: user.numClient,
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
    recours: '',
    convention: '',
    bareme: '',
  };
  const [formData, setFormData] = useState(initialState);

  const fetchData = async () => {
    const garantieMiseEnJeu = getValeursCodes('AR2', '90430101');
    const natureContrat = getValeursCodes('AR2', '90130301');
    const reglementDirect = getValeursCodes('AR2', '90110301');
    const plafondGarantie = getValeursCodes('AR2', '90480101');
    const recours = getValeursCodes('AR2', '93090101');
    const convention = getValeursCodes('AR2', '90140202');
    const bareme = getValeursCodes('AR2', '90360203');

    await axios.all(
        [garantieMiseEnJeu, natureContrat, reglementDirect, plafondGarantie, recours, convention, bareme]).then(
        axios.spread((...responses) => {
          setCodesValeursGarantieMiseEnJeu(responses[0]);
          setCodesValeursNatureContrat(responses[1]);
          setCodesValeursReglementDirect(responses[2]);
          setCodesValeursPlafondGarantie(responses[3]);
          setCodesValeursRecours(responses[4]);
          setCodesValeursConvention(responses[5]);
          setCodesValeursBareme(responses[6]);
        }),
    )
  };

  useEffect(() => {
    fetchData().then(() => setLoadingData(false))
  }, []);

  useEffect(() => {
    const garantieMiseEnJeuDefaultValue = findElementByLibelleInArray(
        codesValeursGarantieMiseEnJeu,
        mission?.gestionDTO?.priseEnCharge?.garantieMiseEnJeu ?? '')?.valeur;

    const natureContratDefaultValue = findElementByLibelleInArray(
        codesValeursNatureContrat,
        mission?.gestionDTO?.priseEnCharge?.natureContrat ?? '')?.valeur;

    const reglementDirectDefaultValue = findElementByLibelleInArray(
        codesValeursReglementDirect,
        mission?.gestionDTO?.priseEnCharge?.reglementDirect ?? '')?.valeur;

    const plafondGarantieDefaultValue = findElementByLibelleInArray(
        codesValeursPlafondGarantie,
        mission?.gestionDTO?.priseEnCharge?.plafondGarantie ?? '')?.valeur;

    const recours = findElementByLibelleInArray(
        codesValeursRecours,
        mission?.gestionDTO?.recoursEtConventionDTO?.recours ?? '')?.valeur;

    const convention = findElementByLibelleInArray(
        codesValeursConvention,
        mission?.gestionDTO?.recoursEtConventionDTO?.convention ?? '')?.valeur;

    const bareme = findElementByLibelleInArray(
        codesValeursBareme,
        mission?.gestionDTO?.recoursEtConventionDTO?.bareme ?? '')?.valeur;

    setFormData({
      ...formData,
      garantieMiseEnJeu: garantieMiseEnJeuDefaultValue ?? '1',
      natureContrat: natureContratDefaultValue ?? '0',
      reglementDirect: reglementDirectDefaultValue ?? '1',
      plafondGarantie: plafondGarantieDefaultValue ?? '',
      recours: recours,
      convention: convention,
      bareme: bareme,
    });
  }, [codesValeursGarantieMiseEnJeu, codesValeursNatureContrat, codesValeursReglementDirect,
    codesValeursPlafondGarantie, codesValeursRecours, codesValeursConvention, codesValeursBareme]);

  const codesValeursGarantieMiseEnJeuSort = [...codesValeursGarantieMiseEnJeu].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListGarantieMiseEnJeu = codesValeursGarantieMiseEnJeuSort.map(
      (item: ItemGetValeursCodesModel, index: number) => {
        return (
          <MenuItem
            key={item.valeur}
            id={`garantieMiseEnJeu-${index}`}
            value={item.valeur}>
            {item.libelleedition}
          </MenuItem>
        )
      });

  const codesValeursNatureContratSort = [...codesValeursNatureContrat].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListNatureContrat = codesValeursNatureContratSort.map((item: ItemGetValeursCodesModel, index: number) => {
    return (
      <MenuItem
        key={item.valeur}
        id={`natureContrat-${index}`}
        value={item.valeur}>
        {item.libelleedition}
      </MenuItem>
    )
  });

  const codesValeursReglementDirectSort = [...codesValeursReglementDirect].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListReglementDirect = codesValeursReglementDirectSort.map((item: ItemGetValeursCodesModel, index) => {
    return (
      <MenuItem
        key={item.valeur}
        id={`reglementDirect-${index}`}
        value={item.valeur}>
        {item.libelleedition}
      </MenuItem>
    )
  });

  const codesValeursPlafondGarantieSort = [...codesValeursPlafondGarantie].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListPlafondGarantie = codesValeursPlafondGarantieSort.map((item: ItemGetValeursCodesModel, index) => {
    return (
      <MenuItem
        key={item.valeur}
        id={`plafondGarantie-${index}`}
        value={item.valeur}>
        {item.libelleedition}
      </MenuItem>
    )
  });

  useEffect(() => {
    setFormData({...formData, montantPlafond: valueCurrency})
  }, [valueCurrency]);

  useEffect(() => {
    checkTaux ? setFormData({
      ...formData,
      tauxResponsabilite: '',
    }) : setFormData({
      ...formData,
      tauxResponsabilite: mission?.gestionDTO?.priseEnCharge?.tauxResponsabilite === '999' ?
          '100' : mission?.gestionDTO?.priseEnCharge?.tauxResponsabilite,
    });
    setErrorFields({...errorFields, tauxResponsabiliteError: false})
  }, [checkTaux]);

  useEffect(() => {
    checkEnlevement ? setFormData({
      ...formData,
      enlevementStockage: 'O',
    }) : setFormData({
      ...formData,
      enlevementStockage: 'N',
    })
  }, [checkEnlevement]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<{ name?: string; value: unknown } | HTMLInputElement>) => {
      if (input === 'codeAbonneDestinataire') {
        setErrorFields({...errorFields, codeAbonneDestinataireError: false})
      }
      if (input === 'tauxResponsabilite') {
        if (event.target.value as number > 100) {
          event.target.value = '100'
        }
        setErrorFields({...errorFields, tauxResponsabiliteError: false})
      }
      if (input === 'pretVehicule') {
        setErrorFields({...errorFields, pretVehiculeError: false});
        formData.franchise = ''
      }
      if (input === 'franchise') {
        if (event.target.value as number > parseInt(formData.pretVehicule!) || event.target.value as number > 9) {
          event.target.value = '9'
        }
      }
      setFormData({...formData, [input]: event.target.value})
    };

  const handleSubmit = () => {
    const showPopupCondition = (formData.reglementDirect === '1'|| formData.reglementDirect === '3' ||
        formData.reglementDirect === '4') &&
        (formData?.elementsCalcul?.length === undefined || formData?.elementsCalcul?.length === 0);

    setErrorFields({tauxResponsabiliteError: !formData.tauxResponsabilite && !checkTaux,
      pretVehiculeError: !formData.pretVehicule, codeAbonneDestinataireError: !formData.codeAbonneDestinataire,
    });
    if ((formData.tauxResponsabilite || !formData.tauxResponsabilite && checkTaux) &&
        formData.pretVehicule && formData.codeAbonneDestinataire) {
      if (showPopupCondition) {
        const result = window.confirm('Saisissez au moins un élément de calcul');
        if (result) {
          setShowElementCalcul(!showElementCalcul)
        }
      } else {
        setIsLoading(true);
        const listElementsPayLoad = formData.elementsCalcul?.map((element) => {
          return {
            priseEnCharge: element.priseEnCharge,
            descriptif: element.descriptif,
            montantFranchiseForaitaire: element.montantFranchiseForaitaire,
            tauxFranchiseProportionnelle: element.tauxFranchiseProportionnelle,
            montantPlancherFranchiseProportionnelle: element.montantPlancherFranchiseProportionnelle,
            montantPlafondFranchiseProportionnelle: element.montantPlafondFranchiseProportionnelle,
            tauxPriseEnCharge: element.tauxPriseEnCharge,
            plafondPriseEnCharge: element.plafondPriseEnCharge,
            tvaRecuperableParAssure: element.tvaRecuperableParAssure,
            elementComprisReparation: element.elementComprisReparation,
            abattementADeduire: element.abattementADeduire}
        });
        const payload = {...formData, elementsCalcul: listElementsPayLoad};
        if (checkTaux) payload.tauxResponsabilite = '999';
        postSD05Form(payload)
            .then((response) => {
              setResponseReturn(response.status);
            })
            .catch((error) => {
              if (error.response) {
                setResponseReturn(error.response.status);
              } else {
                setResponseReturn(-1);
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
      }
    }
  };

  const handleSaveElementCalcul = (elementsCalcul: ElementCalculModel[]) =>{
    elementsCalcul.forEach((element) => {
      delete element.errorFields;
    });
    setFormData({...formData, elementsCalcul: elementsCalcul});
    setShowElementCalcul(!showElementCalcul)
  };

  const libelleBoutonElementsCalcul = !formData.elementsCalcul || formData.elementsCalcul?.length === 0 ?
    'Ajouter des éléments de calcul' : 'Gérer les éléments de calcul (' + formData.elementsCalcul?.length + ')';

  if (isLoadingData) {
    return <></>;
  }
  return (
    <div className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Modification de prise en charge</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}} onClick={props.onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        {responseReturn <= 0 && !showElementCalcul &&
          <div className={classes.body}>
            <Typography className={classes.libelle}>
              Destinataire <span style={{color: 'red'}}>*</span>
            </Typography>
            <TextFieldSelect
              className={classes.textFieldFullWidth}
              id={'destinataire'}
              name={'destinataire'}
              marginDense={true}
              value={formData.codeAbonneDestinataire!}
              itemsList={itemsListDestinataires}
              disabled={destinatairesList.length === 1}
              onChange={handleChange('codeAbonneDestinataire')}
              onError={errorFields.codeAbonneDestinataireError}
            />

            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>
                Garantie mise en jeu <span style={{color: 'red'}}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textFieldFullWidth}
                id={'garantie'}
                name={'garantie'}
                marginDense={true}
                value={formData.garantieMiseEnJeu}
                itemsList={itemsListGarantieMiseEnJeu}
                onChange={handleChange('garantieMiseEnJeu')}
              />
            </div>

            <div style={{display: 'flex', columnGap: '50px', marginTop: '10px'}}>
              <div style={{width: '50%'}}>
                <Typography className={classes.libelle}>
                  Nature du contrat <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextFieldSelect
                  className={classes.textFieldFullWidth}
                  id={'nature'}
                  name={'nature'}
                  marginDense={true}
                  value={formData.natureContrat}
                  itemsList={itemsListNatureContrat}
                  onChange={handleChange('natureContrat')}
                />
              </div>
              <div style={{width: '50%'}}>
                <Typography className={classes.libelle}>
                  Règlement direct <span style={{color: 'red'}}>*</span>
                </Typography>
                <TextFieldSelect
                  className={classes.textFieldFullWidth}
                  id={'reglement'}
                  name={'reglement'}
                  marginDense={true}
                  value={formData.reglementDirect}
                  itemsList={itemsListReglementDirect}
                  onChange={handleChange('reglementDirect')}
                />
              </div>
            </div>

            <div style={{display: 'flex', columnGap: '50px', marginTop: '10px'}}>
              <div style={{width: '50%'}}>
                <Typography className={classes.libelle}>Plafond de garantie</Typography>
                <TextFieldSelect
                  className={classes.textFieldFullWidth}
                  id={'plafond'}
                  name={'plafond'}
                  marginDense={true}
                  value={formData.plafondGarantie}
                  withEmptyItem={true}
                  emptyItemLabel={'Aucun'}
                  itemsList={itemsListPlafondGarantie}
                  onChange={handleChange('plafondGarantie')}
                />
              </div>
              <div style={{width: '50%'}}>
                <Typography className={`${classes.libelle} ${classes.flex}`}>
                  Montant du plafond
                  <Tooltip
                    disableInteractive
                    style={{marginLeft: '10px', marginTop: '-3px'}}
                    placement="top"
                    title={'Montant compris entre 0 et 9999999,99'}
                    PopperProps={{disablePortal: true}}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <TextFieldCurrency id={'montantPlafond'} value={valueCurrency} minValue='0000000.00'
                  maxValue='9999999.99' onChange={(event: React.ChangeEvent<{}>, value: string) =>
                    setValueCurrency(value)}
                />
              </div>
            </div>

            <div style={{display: 'flex', columnGap: '50px', marginTop: '10px'}}>
              <div>
                <Typography className={classes.libelle}>
                  Taux de responsabilité <span style={{color: 'red'}}>*</span>
                </Typography>
                <InputMask
                  mask='999'
                  maskPlaceholder={null}
                  value={formData.tauxResponsabilite}
                  disabled={checkTaux}
                  onChange={handleChange('tauxResponsabilite')}
                >
                  <TextFieldSearch
                    id={'taux'}
                    className={classes.textField}
                    disabled={checkTaux}
                    endAdornment={'%'}
                    onError={errorFields.tauxResponsabiliteError}
                  />
                </InputMask>
                {errorFields.tauxResponsabiliteError &&
                  <Typography className={classes.error}>Veuillez saisir une valeur</Typography>
                }
              </div>
              <div>
                <FormControlLabel style={{margin: '40px 0px 0px'}} label="Indéterminé" labelPlacement="start"
                  value={checkTaux} control={<BlueSwitch id={'tauxIndetermineSwitcher'} name="taux-checked"
                    checked={checkTaux}
                    onChange={() => setCheckTaux(!checkTaux)}/>}
                />
              </div>
            </div>

            <div style={{display: 'flex', columnGap: '50px', marginTop: '10px'}}>
              <div>
                <Typography className={classes.libelle}>
                  Prêt de véhicule <span style={{color: 'red'}}>*</span>
                </Typography>
                <InputMask
                  mask="999"
                  maskPlaceholder={null}
                  value={formData.pretVehicule ?? ''}
                  onChange={handleChange('pretVehicule')}
                >
                  <TextFieldSearch
                    id={'pret'}
                    className={classes.textField}
                    endAdornment={'jour(s)'}
                    onError={errorFields.pretVehiculeError}
                  />
                </InputMask>
                {errorFields.pretVehiculeError &&
                  <Typography className={classes.error}>Veuillez saisir une valeur</Typography>
                }
              </div>
              <div>
                <FormControl>
                  <Typography className={classes.libelle}>Franchise</Typography>
                  <InputMask
                    mask="99"
                    maskPlaceholder={null}
                    value={formData.franchise ?? ''}
                    onChange={handleChange('franchise')}
                    disabled={formData.pretVehicule === '' || parseInt(formData.pretVehicule!) < 1}
                  >
                    <TextFieldSearch
                      id={'franchise'}
                      className={classes.textField}
                      endAdornment={'jour(s)'}
                      disabled={formData.pretVehicule === '' || parseInt(formData.pretVehicule!) < 1}
                    />
                  </InputMask>
                </FormControl>
              </div>
            </div>

            <FormControlLabel style={{paddingTop: '20px', marginLeft: '0px'}}
              label="Enlèvement pour stockage autorisé"
              labelPlacement="start" value={checkEnlevement} control={<BlueSwitch id={'enlevementSwitcher'}
                name="enlevement-checked" checked={checkEnlevement}
                onChange={() => setCheckEnlevement(!checkEnlevement)}/>}
            />

            <div style={{marginTop: '20px'}}>
              <Typography className={classes.libelle}>Votre message</Typography>
              <TextFieldMultiline id={'message'} marginDense={true} rows={4} value={formData.commentaire}
                onChange={handleChange('commentaire')}/>
            </div>

            {responseReturn === -1 &&
              <Typography className={`${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
        {responseReturn <= 0 && showElementCalcul &&
          <div className={classes.elementsCalcul}>
            <ElementsCalcul
              listElements={formData?.elementsCalcul!}
              onSave={handleSaveElementCalcul}
              onCloseElementsCalcul={() => setShowElementCalcul(!showElementCalcul)}
            />
          </div>
        }
        {responseReturn === 201 &&
          <Box style={{width: '100%', height: '75vh'}}>
            <div className={classes.divOK}>
              <Typography variant='body1' component='div' className={classes.titre}>
                Votre modification de prise en charge a bien été envoyée
              </Typography>
              <div>
                <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'}
                  onClick={props.onCloseAndRefresh}/>
              </div>
            </div>
          </Box>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <Box style={{width: '100%', height: '75vh'}}>
            <div className={classes.divOK}>
              <Typography variant='body1' component='div' className={classes.titre}>
                Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
              </Typography>
              <div>
                <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'}
                  onClick={props.onCloseAndRefresh}/>
              </div>
            </div>
          </Box>
        }
      </div>
      {responseReturn <= 0 && !showElementCalcul &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <>
                <ButtonLink
                  id={'boutonAjoutElementCalcul'}
                  libelle={libelleBoutonElementsCalcul}
                  isLibelleUpperCase={true}
                  isLibelleBold={true}
                  onClick={() => setShowElementCalcul(!showElementCalcul)}
                />
                <ButtonBlueSend id={'boutonEnvoyer'} onClick={handleSubmit}/>
              </>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default SD05Form;
