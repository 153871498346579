import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {BlocAdresse} from '../../common/adresse/BlocAdresse';
import HorairesComponent from './HorairesComponent';
import {dateToDateText} from '../../../common/utils/DateUtils';
import {customTheme} from '../../../common/GlobalTheme';
import moment from 'moment';
import {ButtonSeeLess} from '../../common/buttons/ButtonSeeLess';
import {ButtonSeeMore} from '../../common/buttons/ButtonSeeMore';
import {RaisonSocialeProps} from '../../../model/priseRDV/PartenaireModel';
import {AdresseProps} from '../../../model/priseRDV/AdresseModel';
import {DebutFinProps, HoraireProps} from '../../../model/priseRDV/HoraireModel';
import {CongesProps} from '../../../model/priseRDV/CongeModel';
import {ActiviteProps} from '../../../model/priseRDV/ActiviteModel';
import {CoordonneesProps} from '../../../model/priseRDV/CoordonneeModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface PartenaireCardProps {
  isUpdateRdv: boolean,
  raisonSociale: RaisonSocialeProps,
  distance: number,
  adresse: AdresseProps,
  coordonnees: CoordonneesProps,
  horaires: HoraireProps,
  conges: CongesProps,
  activites: ActiviteProps[],
  services: string[],
}

const styles = (theme: Theme) => ({
  card: {
    'width': '25%',
    'marginRight': '2%',
    'marginBottom': '8px',
    'height': '680px',
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '10px',
  },
  puce: {
    width: '6px',
    height: '6px',
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.primary,
    marginRight: '8px',
    marginBottom: '2px',
  },
});

const PartenaireCard = (props: PartenaireCardProps) => {
  const classes = useEmotionStyles(styles);
  const {periodes} = props.conges || {};
  const {isUpdateRdv, services, coordonnees} = props;

  const congesTries = periodes?.length > 0 ?
    [...periodes].sort((conge1, conge2) => {
      if (conge1.debut < conge2.debut) {
        return -1
      } else {
        return 1
      }
    }) : [];

  const activite = props.activites?.length ? props.activites[0] : undefined;
  const marquesReparees = activite?.marquesReparees;
  const isToutesMarques = !!(marquesReparees?.includes('Toutes marques (TTESMARQUES)'));

  const marquesTriees = marquesReparees?.length ?
    [...marquesReparees].sort((marque1, marque2) => {
      if (marque1 < marque2) {
        return -1
      } else {
        return 1
      }
    }) : [];

  const [servicesProposes, setServicesProposes] = useState<string[]>(services.slice(0, 3));
  const [conges, setConges] = useState<DebutFinProps[]>(congesTries?.slice(0, 3));
  const [expandedConges, setExpandedConges] = useState<boolean>(false);
  const [expandedServices, setExpandedServices] = useState<boolean>(false);

  useEffect(() => {
    setServicesProposes(services.slice(0, 3))
  }, []);

  const handleExpandConges = () => {
    setExpandedConges(!expandedConges);
    setConges(congesTries)
  };
  const handleCollapseConges = () => {
    setExpandedConges(!expandedConges);
    setConges(congesTries.slice(0, 3))
  };

  const handleExpandServices = () => {
    setExpandedServices(!expandedServices);
    setServicesProposes(services)
  };
  const handleCollapseServices = () => {
    setExpandedServices(!expandedServices);
    setServicesProposes(services.slice(0, 3))
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{marginBottom: '24px'}}>
          <Typography variant={'h3'} style={{marginBottom: '0px'}}>{props.raisonSociale.nom}</Typography>
          {!isUpdateRdv && <Typography>{props.distance} km</Typography>}
        </div>
        <div>
          <BlocAdresse title={'Coordonnées'} adresse={props.adresse}/>
          <Typography>{coordonnees?.telephone || coordonnees?.portable || coordonnees?.email}</Typography>
        </div>
        <div style={{marginTop: '24px'}}>
          <Typography style={customTheme.styledTypography.libelle}>Horaires d&apos;ouverture</Typography>
          <HorairesComponent {...props.horaires}/>
        </div>
        <div style={{marginTop: '24px'}}>
          <Typography style={customTheme.styledTypography.libelle}>Congés</Typography>
          {conges?.length > 0 && conges.map((conge, index) => {
            const keyDiv = `${index}`
            return (
              <div key={keyDiv}>
                <span>
                  {moment(conge.debut).isSame(moment(conge.fin)) || !conge.fin ?
                    'Le ' + dateToDateText(moment(conge.debut)) :
                    'Du ' + dateToDateText(moment(conge.debut)) + ' au ' + dateToDateText(moment(conge.fin))
                  }
                </span>
              </div>
            )
          })}
          {periodes?.length > 3 && expandedConges &&
            <ButtonSeeLess
              id={'bouton-voir-moins-conges'}
              iconPosition={'left'}
              libelleButton={'Voir moins de congés'}
              onClick={handleCollapseConges}
            />
          }
          {periodes?.length > 3 && !expandedConges &&
            <ButtonSeeMore
              id={'bouton-voir-plus-conges'}
              iconPosition={'left'}
              libelleButton={'Voir plus de congés'}
              onClick={handleExpandConges}
            />
          }
          {!periodes?.length && <Typography><i>Aucun congé renseigné</i></Typography>}
        </div>
        <div style={{marginTop: '24px'}}>
          <Typography style={customTheme.styledTypography.libelle}>Marques réparées</Typography>
          {isToutesMarques &&
            <Typography>Toutes les marques</Typography>
          }
          {!isToutesMarques && marquesTriees.length > 0 &&
            <Typography>
              {marquesTriees.map((marque, index) => {
                return (
                  <>
                    {marque.substring(0, marque.lastIndexOf('(') - 1)}
                    {marquesTriees.length !== index+1 && ', '}
                  </>
                )
              })}
            </Typography>
          }
          {(!marquesReparees || marquesReparees.length === 0) &&
            <Typography><i>Aucune marque renseignée</i></Typography>
          }
        </div>
        <div style={{marginTop: '24px'}}>
          <Typography style={customTheme.styledTypography.libelle}>Services</Typography>
          {servicesProposes.length > 0 && servicesProposes.map((service, index) => {
            const keyTypography = `${index}`
            return (
              <Typography key={keyTypography}>
                <span className={classes.puce}/>{service}
              </Typography>
            )
          })}
          {services.length > 3 && expandedServices &&
            <ButtonSeeLess
              id={'bouton-voir-moins-services'}
              iconPosition={'left'}
              libelleButton={'Voir moins de services'}
              onClick={handleCollapseServices}
            />
          }
          {services.length > 3 && !expandedServices &&
            <ButtonSeeMore
              id={'bouton-voir-plus-services'}
              iconPosition={'left'}
              libelleButton={'Voir plus de services'}
              onClick={handleExpandServices}
            />
          }
          {!services.length && <Typography><i>Aucun service renseigné</i></Typography>}
        </div>
      </CardContent>
    </Card>
  );
};

export default PartenaireCard;
