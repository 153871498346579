import React from 'react';
import {Modal, Typography} from '@mui/material';
import {ButtonBlue} from './buttons/ButtonBlue';
import {ButtonLink} from './buttons/ButtonLink';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

interface ModalConfirmationProps {
  open: boolean,
  message: string,
  onConfirm: () => void,
  onClose: () => void,
}

/** Design */
const styles = (theme: Theme) => ({
  content: {
    position: 'absolute' as const,
    top: '35%',
    right: '35%',
    backgroundColor: theme.palette.white.main,
    padding: '24px',
    borderRadius: '12px',
  },
  message: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  footer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '24px',
  },
  buttonLink: {
    marginRight: '24px',
  },
})

const ModalConfirmation = (props: ModalConfirmationProps) => {
  const classes = useEmotionStyles(styles);
  const {open, message, onClose, onConfirm} = props

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-confirmation-RAD"
      aria-describedby="modal-confirmation-RAD"
    >
      <div id={'modalConfirmationRAD'} className={classes.content}>
        <Typography className={classes.message}>
          {message}
        </Typography>
        <div className={classes.footer}>
          <ButtonLink
            id={'boutonAnnulerRAD'}
            className={classes.buttonLink}
            libelle={'Annuler'}
            isLibelleUpperCase={true}
            isLibelleBold={false}
            onClick={onClose}
          />
          <ButtonBlue
            id={'boutonConfirmerRAD'}
            libelle={'Confirmer'}
            onClick={onConfirm}/>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
