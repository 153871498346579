import {Card, Typography} from '@mui/material'
import React from 'react'
import {CardModel} from '../../../model/rechercheDossier/CardModel'
import {StyledTypography} from '../../common/StyledTypography'
import {useNavigate, useLocation} from 'react-router-dom';
import {ReactComponent as VehiculeIcon} from '../../../images/icones/icn-vehicule.svg'
import {TokenModel} from '../../../model/common/TokenModel'
import {UserContext} from '../../../store/UserContext';
import ArchiveIcon from '@mui/icons-material/Archive';
import {customTheme} from '../../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

const base64 = require('base-64')

export interface BlocCardProps {
  id: string,
  card? : CardModel,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    width: '313px',
    height: '100%',
    cursor: 'pointer',
    ['@media (max-width:2100px)']: {
      width: '13.5vw',
    },
    ['@media (max-width:2000px)']: {
      width: '13.4vw',
    },
    ['@media (max-width:1800px)']: {
      width: '19.5vw',
    },
    ['@media (max-width:1600px)']: {
      width: '19.3vw',
    },
    ['@media (max-width:1500px)']: {
      width: '19vw',
    },
    ['@media (max-width:1300px)']: {
      width: '20.2vw',
    },
    ['@media (max-width:1100px)']: {
      width: '27.4vw',
    },
    ['@media (max-width:900px)']: {
      width: '40.6vw',
    },
    ['@media (max-width:800px)']: {
      width: '40.2vw',
    },
    ['@media (max-width:700px)']: {
      width: '39.3vw',
    },
    ['@media (max-width:600px)']: {
      width: '50vw',
    },
    ['@media (max-width:500px)']: {
      width: '86.4vw',
    },
  },
  header: {
    padding: '10px',
    textAlign: 'right' as const,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
  },
  divIcon: {
    position: 'relative' as const,
    top: '-20px',
    left: '15px',
  },
  icon: {
    width: '25px',
    height: '25px',
    padding: '5px',
    borderRadius: '50px',
    border: '2px solid ' + theme.palette.white.main,
  },
  archiveIcon: {
    backgroundColor: theme.palette.secondary.dark2,
    color: theme.palette.white.main,
  },
  vehiculeIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  dossier: {
    fontSize: '14px',
    color: theme.palette.white.main,
  },
  infos: {
    padding: '0px 24px 24px',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '75%',
  },
  sinistre: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  date: {
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.secondary.dark2,
  },
  assureur: {
    fontSize: '16px',
    letterSpacing: '0px',
    color: theme.palette.secondary.dark2,
  },
  barre: {
    marginTop: '20px',
    marginBottom: '20px',
  },
})

export const BlocCard = ({card, id}: BlocCardProps) => {
  const classes = useEmotionStyles(styles)
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = React.useContext(UserContext)

  const handleLink = () => {
    sessionStorage.setItem('fromPath', location.pathname)
    const tokenMission: TokenModel = {
      referenceSinistre: card!.refSinistre,
      numeroMission: card!.numMission,
      codeGTA: card!.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
      immatriculation: card!.immatriculation,
      isDossierComplet: false,
    }
    const token = base64.encode(JSON.stringify(tokenMission))
    navigate(`/extranet/dossiers/mission/`+ token)
  }

  return (
    <Card id={id} className={classes.card} role='button'
      tabIndex={0} onClick={handleLink}>
      <div className={classes.header} style={{backgroundColor: card!.indicateurArchivage === 'O' ?
          customTheme.palette.secondary.dark2 : customTheme.palette.primary.main}}>
        <Typography id={'title-card'} className={classes.dossier}>
          DOSSIER {card!.indicateurArchivage === 'O' ? ' ARCHIVÉ' : ' SINISTRE'}
        </Typography>
      </div>
      <div className={classes.divIcon}>
        {card!.indicateurArchivage === 'O' ?
          <ArchiveIcon className={`${classes.icon} ${classes.archiveIcon}`}/> :
          <VehiculeIcon className={`${classes.icon} ${classes.vehiculeIcon}`}/>
        }
      </div>
      <div className={classes.infos}>
        <div>
          <Typography id={'refSinistre-' + id} className={classes.sinistre}>{card!.refSinistre}</Typography>
          <Typography id={'dateCreation-' +id} className={classes.date}>Créé le {card!.dateCreationMission}</Typography>
          <StyledTypography id={'numMission-' + id} title={'Numéro de mission'} text={card!.numMission} />
          <StyledTypography id={'immat-' + id} title={'Immatriculation'} text={card!.immatriculation} />
          <StyledTypography title={'Bien concerné'} text={card!.bienConcerne} />
        </div>
        <div style={{marginTop: 'auto'}}>
          <hr className={classes.barre}/>
          <Typography id={'assureur-' + id} className={classes.assureur}>{card!.assureurConcerne}</Typography>
        </div>
      </div>
    </Card>
  )
}
