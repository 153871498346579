import React, {ReactElement} from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface CatchErreurSIVProps {
  titre: string,
  footerSiv: ReactElement
}

/** Design */
const styles = () => ({
  container: {
    padding: '32px',
  },
  titre: {
    fontSize: '24px',
    color: '#241E46',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0px 10px 20px #6469a61a',
  },
  cardContent: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
  },
  divLibelleVal: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  libelleElement: {
    color: '#7c7c7c',
    fontSize: '14px',
    minWidth: 'max-content',
  },
  valeurElement: {
    color: '#241E46',
    fontWeight: 'bold',
    fontSize: '14px',
  },
});

export const CatchErreurSIV = (props: CatchErreurSIVProps) => {
  const classes = useEmotionStyles(styles);
  const {titre, footerSiv} = props;

  return (
    <div className={classes.container}>
      <Typography className={classes.titre}>{titre}</Typography>
      <div className={classes.cardContainer} style={{margin: 'auto', width: '60%'}}>
        <div className={classes.cardContent}>
          <div className={`${classes.flex} ${classes.divLibelleVal}`} style={{padding: '12px'}}>
            <Typography className={classes.libelleElement}>Erreur :&nbsp;</Typography>
            <Typography className={classes.valeurElement}>
              Une erreur vient de se produire pendant votre session de travail
            </Typography>
          </div>
        </div>
      </div>
      {footerSiv}
    </div>
  )
};
