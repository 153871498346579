import {IPecBgForm, IInfosVehicule, ISinistre} from './ModelPECBG';
import {IDommage} from '../components/inputDommagesField/Models';

export const infosVehiculeInitialState: IInfosVehicule = {
  numContrat: '',
  immatriculation: '',
  assureurGTA: '',
  kilometrage: '',
  dateSurvenance: '',
  heureSurvenance: '',
  codeNatureAssureur: 'GTA',
  codeAssureur: '',
  assureur: '',
  refReparateur: '',
};

export const sinistreInitialState: ISinistre = {
  circonstance: '',
  degatsCarrosserie: false,
  objetsVoles: false,
};

export const dommagesInitialState: IDommage[] = [];

export const dommageInitialState: IDommage = {
  id: 0,
  libelle: '',
  elementVitre: '',
  typeIntervention: '',
  descriptionOptique: '',
  completed: false,
  onError: false,
};

export const pecBgFormInitialState = () : IPecBgForm => {
  return <IPecBgForm> {
    infosVehicule: infosVehiculeInitialState,
    sinistre: sinistreInitialState,
    dommages: dommagesInitialState,
  }
};

// Liste des codes GTA des assureurs pour lesquels on a le champ "Référence réparateur"
export const refReparateurCases = ['147', '615', '372'];
// Liste des codes GTA des assureurs pour lesquels le champ "Référence réparateur" est obligatoire
export const refReparateurMandatoryCases = ['372'];

// Liste des codes GTA des assureurs pour lesquels on a la valeur "Lustrage" (Dommages -> Type d'intervention)
export const lustrageCases = ['147'];

// Liste des codes GTA des assureurs pour lesquels le champ "Kilométrage" est obligatoire
export const kilometrageCases = ['299', '392', '615'];
