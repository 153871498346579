import React from 'react';
import {CardContainer} from '../CardContainer';
import {getValueElement, getValueDateElement, getLibelleValeur} from '../Utils';

interface SituationAdministrativeProps {
  data: any,
  prefix: string
}

const dt9450 = [
  {
    'code': '0',
    'libelle': 'Compte tenu de la situation administrative, vous êtes invité à aller en préfecture.',
  },
  {
    'code': '1',
    'libelle': 'La situation administrative ne fait apparaître aucune irrégularité.',
  },
];

export const SituationAdministrative = (props: SituationAdministrativeProps) => {
  const {data, prefix} = props

  const libelleSituationAdministrative = () => {
    if (data.getElementsByTagName(prefix + 'csa_simple').length !== 0) {
      return ['0', '1'].includes(data.getElementsByTagName(prefix + 'csa_simple')[0].value) ?
          dt9450.map((donnee, _) => {
            if (donnee.code === data.getElementsByTagName(prefix + 'csa_simple')[0].value) {
              return donnee.libelle
            }
          }) : data.getElementsByTagName(prefix + 'csa_simple')[0].value
    }
    return ''
  }

  const libellesValeursSA = () => {
    return (
      [
        [
          getLibelleValeur(`N° de formule du certificat d'immatriculation :`,
              getValueElement(data, prefix, 'num_formule_CI')),
          getLibelleValeur(`Date du certificat d'immatriculation :`, getValueDateElement(data, prefix, 'date_CI')),
        ],
        [
          getLibelleValeur('Suspension pour motif VE :', getValueElement(data, prefix, 'suspension_pr_ve')),
          getLibelleValeur(`Présence de l'opposition VE :`, getValueElement(data, prefix, 'presence_opposition_ve')),
        ],
        [
          getLibelleValeur('Situation administrative :', libelleSituationAdministrative()),
        ],
      ]
    )
  };

  return (
    <CardContainer titreContainer={'Situation administrative du véhicule'}
      content={[{libellesValeurs: libellesValeursSA()}]}
    />
  )
};
