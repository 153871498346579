import React, {useContext, useState} from 'react';
import {MessageBubble} from '../../../common/MessageBubble';
import {SollicitationModel} from '../../../../model/detailsDossier/SuiviReparationModel';
import {customTheme} from '../../../../common/GlobalTheme';
import {Modal, Slide, Tooltip, Typography} from '@mui/material';
import {ReactComponent as WorkTodoIcon} from '../../../../images/icones/icn-work-todo.svg'
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg'
import {ReactComponent as FinishIcon} from '../../../../images/icones/icn-check-circle.svg'
import {ReactComponent as ArrowIcon} from '../../../../images/icones/icn-right-arrow.svg'
import {useMissionContext} from '../../../../store/MissionContext';
import {UserContext} from '../../../../store/UserContext';
import {IFRForm} from '../../../forms/suiviRep/infosRep/IFRForm';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import useHasRole from '../../../../hook/useHasRole';
import {permittedRolesDetailsDossierModifications} from '../../../../constantes/roles/Roles';

interface SollicitationProps {
    indexBubble?: number,
    sollicitaion: SollicitationModel
    itemSelected: boolean
    commentaires: string
    onClickPj: (tabPanelValue: number, idPJ: string) => void,
    onClickComment: (tabPanelValue: number, date: string) => void
}

/** Design */
const styles = (theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.link.main,
    padding: '0px 12px',
    marginTop: '10px',
  },
  divClick: {
    display: 'flex',
    cursor: 'pointer',
    width: 'max-content',
    marginLeft: '12px',
  },
});

const Sollicitation = (props: SollicitationProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = useContext(UserContext);
  const {mission} = useMissionContext();
  const {indexBubble, sollicitaion, itemSelected, commentaires, onClickPj, onClickComment} = props;
  const {
    idDoc, typeDoc, emetteurCodeAbonne, destinataireCodeAbonne, libelleDoc, emetteur,
    destinataire, envoyeRecu, date, infoSollicitation, etat, nbPieceJointe,
  } = sollicitaion;
  const acteur = envoyeRecu === 'recu' ? 'De : '.concat(emetteur) : 'À : '.concat(destinataire);
  const emetteurSRP = mission?.acteursDTO?.acteurs?.find((act) => act.codeAbonne === emetteurCodeAbonne);
  const hasRoleDossierModifications = useHasRole(permittedRolesDetailsDossierModifications);

  const expandable = infoSollicitation.length > 1;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [openIFRForm, setOpenIFRForm] = useState<boolean>(false);
  const showButtonComment = commentaires && commentaires.length > 202;
  const showBlocComment = commentaires && commentaires.length <= 202;
  const showButtonRepondre = (user.type === 'Carrossier' || user.type === 'RM') && etat === 0;

  const handleClickComment = () => {
    const codeAbonneActeur = `${envoyeRecu === 'recu' ? emetteurCodeAbonne.trim() : destinataireCodeAbonne.trim()}`;
    const identifiant = `${date}${typeDoc}${codeAbonneActeur}`;
    onClickComment(2, identifiant)
  };

  const slideContentIFR = (
    <div>
      <IFRForm
        destinataire={emetteurSRP}
        sollicitaion={sollicitaion}
        onClose={() => onCloseForm()}
        onCloseAndRefresh={() => {
          setOpenIFRForm(false);
          window.location.reload()
        }}
      />
    </div>
  );

  const onCloseForm = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
            'Souhaitez-vous continuer ?');
    if (result) {
      setOpenIFRForm(false);
    }
  };

  const getIconStatus = (state: number) => {
    switch (state) {
      case 0:
        return <Tooltip disableInteractive title="A traiter"><WorkTodoIcon/></Tooltip>;
      case 1:
        return <Tooltip disableInteractive title="En cours"><InProgressIcon/></Tooltip>;
      case 2:
        return <Tooltip disableInteractive title="Terminé"><FinishIcon/></Tooltip>;
      default:
        return <></>
    }
  };

  const getTitle = (expandable: boolean) => {
    return expandable ? `Motifs de la sollicitation (${infoSollicitation.length})` : 'Motif de la sollicitation'
  };

  const content = () => {
    return (
      <>
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography style={customTheme.styledTypography.libelle}>{getTitle(expandable)}</Typography>
            {getIconStatus(etat)}
          </div>
          <Typography style={{...customTheme.styledTypography.donneeGrise16, fontWeight: 'bold'}}>
            {infoSollicitation[0].motif}
          </Typography>
          {infoSollicitation[0].informationComplementaire &&
            <Typography style={{display: 'flex', ...customTheme.styledTypography.donneeGrise16}}>
              <ArrowIcon style={{minWidth: '32px'}}/>
              <span>{infoSollicitation[0].informationComplementaire}</span>
            </Typography>
          }
        </div>

        {(expandable || showBlocComment) && expanded &&
          <div style={{marginTop: '16px'}}>
            {expandable &&
              <div>
                <Typography style={customTheme.styledTypography.libelle}>Autres motifs</Typography>
                {infoSollicitation.slice(1).map((info, index) => {
                  const keyDiv = `${index}`
                  return (
                    <div key={keyDiv}>
                      <div>
                        <Typography style={{...customTheme.styledTypography.donneeGrise16, fontWeight: 'bold'}}>
                          {info.motif}
                        </Typography>
                      </div>
                      {info.informationComplementaire &&
                        <Typography style={{...customTheme.styledTypography.donneeGrise16}}>
                          <ArrowIcon style={{margin: '0px 5px'}}/>
                          <span>{info.informationComplementaire}</span>
                        </Typography>
                      }
                    </div>
                  )
                })}
              </div>
            }
            {showBlocComment &&
              <div style={{marginTop: '16px'}}>
                <Typography style={customTheme.styledTypography.libelle}>Commentaires</Typography>
                <Typography style={customTheme.styledTypography.donneeGrise16}>{commentaires}</Typography>
              </div>
            }
          </div>
        }

        {(expandable || nbPieceJointe > 0 || showButtonComment || showButtonRepondre || showBlocComment) &&
            <div className={classes.footer}>
              {showButtonComment && <div className={classes.divClick}>
                <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                  onClick={handleClickComment}
                >
                  COMMENTAIRES
                </Typography>
              </div>
              }
              {nbPieceJointe > 0 && <div className={classes.divClick}>
                <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                  onClick={() => onClickPj(3, idDoc)}
                >
                  PIÈCES JOINTES
                </Typography>
              </div>
              }
              {hasRoleDossierModifications && showButtonRepondre && <div className={classes.divClick}>
                <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                  onClick={() => setOpenIFRForm(true)}
                >
                  RÉPONDRE
                </Typography>
              </div>
              }
              {(expandable || showBlocComment) && !expanded &&
                <div className={classes.divClick} onClick={() => setExpanded(true)}>
                  <Typography
                    variant="button"
                    display="inline"
                    style={{fontSize: '14px'}}
                  >
                    VOIR PLUS
                  </Typography>
                </div>
              }
              {(expandable || showBlocComment) && expanded &&
                <div className={classes.divClick} onClick={() => setExpanded(false)}>
                  <Typography
                    variant="button"
                    display="inline"
                    style={{fontSize: '14px'}}
                  >
                    VOIR MOINS
                  </Typography>
                </div>
              }
            </div>
        }
      </>
    )
  };

  return (
    <div>
      <MessageBubble
        indexBubble={indexBubble}
        itemSelected={itemSelected}
        titleBold={acteur}
        subtitle={libelleDoc}
        contentBubble={content()}
        typeBubble={envoyeRecu}
        dateHeure={date.replace('-', 'à')}
      />
      <Modal
        open={openIFRForm}
        aria-labelledby="modal-form-IFR"
        aria-describedby="modal-formulaire-envoie-IFR"
      >
        <Slide in={openIFRForm} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentIFR}
        </Slide>
      </Modal>
    </div>
  );
};

export default Sollicitation;
