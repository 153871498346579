import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface RaisonSocialeProps {
  className?: string,
  name: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: React.JSX.Element | string,
  value: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const RaisonSociale = ({className, name, formulaire, onChangeValue, onBlur, onFocus, onError, helperText,
  value}: RaisonSocialeProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Raison sociale <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSearch
        id={'raisonSociale' + formulaire}
        name={name}
        fullWidth={true}
        onError={onError}
        helperText={helperText}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={formulaire === 'DA' ? 200 : 80}
        value={value}
        onChange={onChangeValue}
      />
    </div>
  )
};
