import {InputAdornment, TextField, Tooltip, Typography} from '@mui/material';
import InputMask from 'react-input-mask-3.0';
import React from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import HelpIcon from '@mui/icons-material/Help';
import {getIdFromName} from '../../utils/Utils';

interface InputMaskTextFieldProps extends FieldConfig {
  label: string
  mask: string
  mandatory?: boolean
  disabled?: boolean
  className?: string
  endAdornment?: string
  titleTooltip?: React.JSX.Element | string
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  title: {
    display: 'flex',
  },
  tooltip: {
    marginLeft: '8px',
    color: theme.palette.secondary.dark2,
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const InputMaskTextField = ({label, ...props}: InputMaskTextFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta] = useField<string>(props as FieldHookConfig<string>);
  const {mask, mandatory, className, disabled, endAdornment, titleTooltip} = props;

  const getLabel = <Typography className={classes.libelle}>
    {label} {mandatory && <span className={classes.red}>*</span>}
  </Typography>;

  return (
    <div className={className ?? classes.container}>
      {!titleTooltip &&
        getLabel
      }
      {titleTooltip &&
        <div className={classes.title}>
          {getLabel}
          <Tooltip
            className={classes.tooltip}
            disableInteractive
            placement="top"
            PopperProps={{disablePortal: true}}
            title={titleTooltip}
          >
            <HelpIcon/>
          </Tooltip>
        </div>
      }

      <InputMask
        mask={mask}
        maskPlaceholder={null}
        disabled={disabled}
        onChange={field.onChange}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
      >
        <TextField
          id={getIdFromName(field.name)}
          fullWidth
          margin='dense'
          size='small'
          type={'search'}
          InputProps={{
            classes: {
              root: classes.textfield,
            },
            endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : '',
          }}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      </InputMask>
    </div>
  )
};
