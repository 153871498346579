import React, {useState, ChangeEvent} from 'react';
import {FormControl, Typography} from '@mui/material';
import {checkNom, checkPrenom, checkTelMobile, ErrorFields} from '../PriseRDV';
import {Telephone} from '../../common/personne/Telephone';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import AutoCompleteLocalite from '../components/AutoCompleteLocalite';
import AutoCompleteAdresse from '../components/AutoCompleteAdresse';
import {InformationAssure} from '../../../model/priseRDV/RDVModel';
import {AdresseAutoCompleteProps} from '../../../model/common/AdresseAutoCompleteModel';
import {LocaliteProps} from '../../../model/priseRDV/LocaliteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {PrenomRdv} from '../../common/personne/PrenomRdv';
import {NomRdv} from '../../common/personne/NomRdv';

export interface InformationsAssureProps {
  values: InformationAssure,
  errorFields: ErrorFields,
  onChangeValue: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  setLocalite: (localite: LocaliteProps) => void,
  setAdresse: (adresse: AdresseAutoCompleteProps) => void,
  setErrorFields: (errorFields: ErrorFields) => void,
}

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px',
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '10px',
    columnGap: '24px',
    justifyContent: 'space-between',
  },
  marginTop12: {
    marginTop: '12px',
  },
  componentContainer: {
    width: 'calc(50% - 12px)',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  error: {
    fontSize: '12px',
  },
  red: {
    color: 'red',
  },
});

const InfoAssure = (props: InformationsAssureProps) => {
  const classes = useEmotionStyles(styles);
  const {values, onChangeValue, setLocalite, setAdresse, errorFields, setErrorFields} = props;

  const localityInitialState: LocaliteProps = {
    codePostal: values.adresse.codePostal || '',
    commune: values.adresse.commune || '',
  };

  const [selectedLocalite, setSelectedLocalite] = useState<LocaliteProps>(localityInitialState);
  const [selectedAdresse, setSelectedAdresse] = useState<AdresseAutoCompleteProps>(values.adresse);

  const onBlurNomUsage = (event: React.FocusEvent<HTMLInputElement>) => {
    if (checkNom(event.target.value, false).onError) {
      setErrorFields([...errorFields,
        {filedName: 'nom', errorMessage: checkNom(event.target.value, true).typeError},
      ])
    }
  };

  const onBlurPrenom = (event: React.FocusEvent<HTMLInputElement>) => {
    if (checkPrenom(event.target.value).onError) {
      setErrorFields([...errorFields,
        {filedName: 'prenom', errorMessage: checkPrenom(event.target.value).typeError},
      ])
    }
  };

  const onBlurTelephone = (event: React.FocusEvent<HTMLInputElement>) => {
    const telFormated = event.target.value.replaceAll(' ', '');
    const isErrorTel = event.target.value.length &&
      (!event.target.value.startsWith('06') && !event.target.value.startsWith('07') || telFormated.length != 10);
    const isErrorCanauxComm = event.target.value.length === 0 && !values.email;
    if (isErrorCanauxComm) {
      errorFields.push({filedName: 'canauxCommunication',
        errorMessage: 'Veuillez saisir une valeur'})
    }
    if (isErrorTel) {
      errorFields.push({filedName: 'telephone',
        errorMessage: checkTelMobile(event.target.value, false).typeError ?? ''})
    }
    setErrorFields([...errorFields]);
  };

  const onBlurEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isErrorEmail = event.target.value.length && !emailPattern.test(event.target.value);
    const isErrorCanauxComm = event.target.value.length === 0 && !values.telephone;
    if (isErrorCanauxComm) {
      errorFields.push({filedName: 'canauxCommunication',
        errorMessage: 'Veuillez saisir une valeur'})
    }
    if (isErrorEmail) {
      errorFields.push({filedName: 'email',
        errorMessage: 'Veuillez saisir une adresse email au bon format'})
    }
    setErrorFields([...errorFields]);
  };

  const onBlurLocalite = () => {
    const errorFieldsCopy = errorFields.filter((error) => error.filedName !== 'localite');
    const {codePostal, commune} = values.adresse;
    if (!codePostal || !commune) {
      setErrorFields([...errorFieldsCopy,
        {filedName: 'localite', errorMessage: 'Veuillez saisir une valeur'},
      ])
    }
  };

  const onFocusAdresse = () => {
    setErrorFields(errorFields.filter((error) => error.filedName !== 'adresse'))
  };

  const onFocusLocalite = () => {
    setErrorFields(errorFields.filter((error) => error.filedName !== 'localite'))
  };

  const onChangeLocalite = (event: ChangeEvent<{}>, newLocalite: LocaliteProps) =>{
    setSelectedLocalite(newLocalite);
    setLocalite(newLocalite);
    setSelectedAdresse({
      ...selectedAdresse,
      commune: newLocalite?.commune || '',
      codePostal: newLocalite?.codePostal || '',
    })
  };

  const onChangeAdresse = (event: ChangeEvent<{}>, newAdresse: AdresseAutoCompleteProps) => {
    if (newAdresse) {
      setSelectedAdresse(newAdresse);
      setAdresse(newAdresse);
      setSelectedLocalite({
        commune: newAdresse.commune || '',
        codePostal: newAdresse.codePostal || '',
      });
      setErrorFields(errorFields.filter((error) => error.filedName !== 'localite'));
    } else {
      const {codePostal, commune} = selectedLocalite || {};
      const adresseCopy = {
        nom: [codePostal, commune].join(' ').trim(),
        numero: '',
        voie: '',
        codePostal: codePostal || '',
        commune: commune || '',
      };
      setSelectedAdresse(adresseCopy);
      setAdresse(adresseCopy);
    }
  };

  const getOptionLabel = (adresse: AdresseAutoCompleteProps) =>
    [adresse.numero, adresse.voie].filter(Boolean).join(' ');

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    let errorTab = [...errorFields];
    errorTab = errorTab.filter((error) => error.filedName != event.target.name);
    if (event.target.name === 'telephone' || event.target.name === 'email') {
      errorTab = errorTab.filter((error) => error.filedName != 'canauxCommunication');
    }
    setErrorFields([...errorTab])
  };

  const isErrorField = (fieldName: string) => {
    return errorFields && errorFields.filter((error) => error.filedName === fieldName).length > 0 || false
  };

  const getMessageErrorFromList = (fieldName: string) => {
    const fieldOnError = errorFields?.filter((error) => error.filedName === fieldName);
    return fieldOnError?.length && fieldOnError[0]?.errorMessage || ''
  };

  return <div className={classes.cardContent}>
    <Typography variant={'h3'}>Informations de l&apos;assuré</Typography>
    <div>
      <div className={`${classes.flex} ${classes.marginTop12}`}>
        <PrenomRdv
          className={classes.componentContainer}
          fullWidth={true}
          formulaire={'RDV'}
          value={values.prenom}
          onChangeValue={onChangeValue('prenom')}
          onBlur={onBlurPrenom}
          onFocus={handleFocus}
          onError={isErrorField('prenom')}
          typeError={checkPrenom(values.prenom).typeError}
          maxLength={32}
        />
        <NomRdv
          className={classes.componentContainer}
          fullWidth={true}
          formulaire={'RDV'}
          nomUsage={false}
          value={values.nom}
          onChangeValue={onChangeValue('nom')}
          onBlur={onBlurNomUsage}
          onFocus={handleFocus}
          onError={isErrorField('nom')}
          typeError={checkNom(values.nom, false).typeError}
          maxLength={32}
        />
      </div>

      <div className={`${classes.flex} ${classes.marginTop12}`}>
        <AutoCompleteAdresse
          id='auto-complete-adresse'
          className={classes.componentContainer}
          label='Adresse de recherche'
          isMandatory={false}
          selectedAdresse={selectedAdresse}
          getOptionLabel={getOptionLabel}
          onChange={onChangeAdresse}
          errorMessage={getMessageErrorFromList('adresse')}
          onError={isErrorField('adresse')}
          onFocus={onFocusAdresse}
        />
        <AutoCompleteLocalite
          id='auto-complete-localite'
          className={classes.componentContainer}
          label='Localité'
          isMandatory={true}
          selectedLocalite={selectedLocalite}
          errorMessage={getMessageErrorFromList('localite')}
          onError={isErrorField('localite')}
          onErrorAdresse={isErrorField('adresse')}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          onChange={onChangeLocalite}
          onFocus={onFocusLocalite}
          onBlur={onBlurLocalite}
        />
      </div>

      <div className={`${classes.flex} ${classes.marginTop12}`}>
        <div className={classes.componentContainer}>
          <Telephone
            fullWidth={true}
            title={'Téléphone'}
            isMandatory={false}
            formulaire={'RDV'}
            defaultTelephone={values.telephone}
            onChangeValue={onChangeValue('telephone')}
            onBlur={onBlurTelephone}
            onFocus={handleFocus}
            onError={isErrorField('telephone') || isErrorField('canauxCommunication')}
            typeError={checkTelMobile(values.telephone ?? '', false)?.typeError}
          />
          <Typography className={`${classes.error} ${isErrorField('canauxCommunication') && classes.red}`}>
            Veuillez remplir au moins un des deux champs
          </Typography>
        </div>
        <div className={classes.componentContainer}>
          <Typography className={classes.libelle}>E-mail</Typography>
          <FormControl error fullWidth variant="outlined">
            <TextFieldSearch
              id='email'
              name='email'
              value={values.email ?? ''}
              maxLength={70}
              onError={isErrorField('email') || isErrorField('canauxCommunication')}
              onChange={onChangeValue('email')}
              onBlur={onBlurEmail}
              onFocus={handleFocus}
            />
            {errorFields && isErrorField('email') &&
              <Typography id="component-error-text" className={`${classes.error} ${classes.red}`}>
                {getMessageErrorFromList('email')}
              </Typography>
            }
          </FormControl>
        </div>
      </div>
    </div>
  </div>;
};

export default InfoAssure;
