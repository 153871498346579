import React from 'react';
import {Typography, Link} from '@mui/material';
import {ButtonBlue} from '../buttons/ButtonBlue';
import {useNavigate} from 'react-router';
import {customTheme} from '../../../common/GlobalTheme';
import useEmotionStyles from '../../../common/useEmotionStyles';

const ErrorIdigoImg = require('../../../images/icones/error-idigo.png')

interface ErrorAPIProps {
    retourToRdv: () => void,
}

/** Design */
const styles = () => ({
  container: {
    width: '75%',
    margin: '35px auto',
    textAlign: 'center' as const,
  },
  content: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  text: {
    color: customTheme.palette.primary.main,
  },
  email: {
    color: customTheme.palette.link.main,
    textDecoration: 'none' as const,
    margin: '12px 0px',
  },
  button: {
    margin: '24px 0px',
  },
})

const ErrorAPI = (props: ErrorAPIProps) => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <img src={ErrorIdigoImg} alt={'logo-warning'}/>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className={classes.content}>
          <Typography className={classes.text}>
            Une erreur est survenue, veuillez contacter notre service d&apos;assistance.
          </Typography>
          <Link
            className={classes.email}
            href={`mailto:assistance@darva.com?subject=Erreur Sinapps Auto Prise de RDV `}
          >
            assistance@darva.com
          </Link>
          <Typography className={classes.text}>
            0 820 030 031
          </Typography>
          <Typography variant={'caption'} className={classes.text}>
            L&apos;assistance DARVA est disponible du lundi au vendredi de 8h à 18h.
          </Typography>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <ButtonBlue
              id={'boutonRetourRdvNonConfirme'}
              className={classes.button}
              libelle={'Retour sur le portail Sinapps Auto'}
              onClick={() => navigate('/extranet/portail-sinapps-auto')}
            />
            <ButtonBlue
              id={'choisirNouveauCreneau'}
              className={classes.button}
              libelle={'Retour sur la prise de rendez-vous'}
              onClick={props.retourToRdv}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorAPI;
