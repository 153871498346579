import React, {useState} from 'react';
import {MenuItem, Typography} from '@mui/material';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import InputMask from 'react-input-mask-3.0';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {getComptesByNoClient} from '../../../api/user';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {Loader} from '../../common/Loader';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ICompte {
  noClient: string
  idClient: string
}

export interface CompteModel {
  user: string
  refCompte: string
}

interface SvpComponentProps {
  onChoiceClient: (clientId: string) => void
}

/** Design */
const styles = (theme: Theme) => ({
  title: {
    textAlign: 'left' as const,
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.primary.main,
    opacity: '1',
    marginBottom: '16px',
  },
  numeroClient: {
    width: '400px',
  },
  libelle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  textField: {
    width: '400px',
  },
  button: {
    marginTop: '15px',
  },
  loader: {
    margin: '20px 0px 0px 40px',
    display: 'block',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
});

const SvpComponent = (props: SvpComponentProps) => {
  const classes = useEmotionStyles(styles);
  const {onChoiceClient} = props;
  const initialState: ICompte = {noClient: '', idClient: ''};
  const [compte, setCompte] = useState<ICompte>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [comptesList, setComptesList] = useState<CompteModel[]>([]);

  const itemsListComptes = comptesList.map((compte, index) => {
    const keyMenuItem = `${index}`
    return (<MenuItem key={keyMenuItem} value={compte.user}>{compte.user}</MenuItem>)
  });

  const handleChangeNoClient = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompte({...compte, noClient: event.target.value});
    setIsNotFound(false);
    if (comptesList.length) {
      setComptesList([])
    }
  };

  const handleChangeIdClient = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompte({...compte, idClient: event.target.value})
  };

  const onClickSearch = () => {
    if (compte.noClient) {
      setIsLoading(true);
      getComptesByNoClient(compte.noClient)
          .then((response) => {
            const isHasComptes = response.comptes.length > 0;
            if (isHasComptes) {
              setComptesList(response.comptes)
            } else {
              setIsNotFound(true)
            }
          }).catch((error) => {
            console.error(error)
          }).finally(() => setIsLoading(false))
    }
  };

  const onClickApply = () => {
    onChoiceClient(compte.idClient)
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Typography className={classes.title}>Connexion SVP</Typography>
      </div>
      <div className={classes.numeroClient}>
        <Typography className={classes.libelle}>Numéro client</Typography>
        <InputMask
          mask="99999"
          maskPlaceholder={null}
          value={compte.noClient}
          onChange={handleChangeNoClient}
        >
          <TextFieldSearch
            id={'noClient'}
            placeHolderText={'Ex: 12345'}
          />
        </InputMask>
      </div>
      {isNotFound &&
        <Typography className={classes.error}>
          Ce numéro de client n&apos;est pas valide
        </Typography>
      }
      {!!comptesList.length &&
        <div style={{marginTop: '16px'}}>
          <div>
            <Typography className={classes.libelle}>Identifiant du compte</Typography>
          </div>
          <div>
            <TextFieldSelect
              className={classes.textField}
              id={'idClient'}
              name={'idClient'}
              marginDense={true}
              value={compte.idClient}
              itemsList={itemsListComptes}
              onChange={handleChangeIdClient}
            />
          </div>
        </div>
      }
      {!comptesList.length && !isLoading &&
        <div>
          <ButtonBlue
            id={'boutonRechercherSVP'}
            className={classes.button}
            libelle={'Rechercher'}
            disabled={compte.noClient.length < 5}
            onClick={onClickSearch}
          />
        </div>
      }
      {!comptesList.length && isLoading &&
        <Loader className={classes.loader} size={30}/>
      }
      {!!comptesList.length &&
        <div>
          <ButtonBlue
            id={'boutonAppliquerSVP'}
            className={classes.button}
            libelle={'Appliquer'}
            disabled={!compte.idClient}
            onClick={onClickApply}
          />
        </div>
      }
    </div>
  );
};

export default SvpComponent;
