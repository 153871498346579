import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {stylesCommon} from '../StylesCommon';
import {InfosVehicule} from './InfosVehicule';
import {CaracteristiquesTech} from './CaracteristiquesTech';
import {TitulaireLocataire} from './TitulaireLocataire';

interface InformationsTitulaireProps {
  data: any,
  prefix: string
}

export const InformationsTitulaire = (props: InformationsTitulaireProps) => {
  const classes = useEmotionStyles(stylesCommon);
  const {data, prefix} = props
  const isLocataire = data.getElementsByTagName('siv:locataire').length !== 0 ?
    data.getElementsByTagName('siv:locataire') : '';

  return (<>
    <div className={classes.flex} style={{columnGap: '30px', marginTop: '30px'}}>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}>
        <TitulaireLocataire data={data} prefix={prefix}/>
      </div>
      <div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}>
        {isLocataire ?
            <TitulaireLocataire isLocataire={true} data={isLocataire[0]} prefix={prefix}/> :
            <React.Fragment>
              <div style={{columnGap: '30px', display: 'flex'}}>
                <InfosVehicule data={data} prefix={prefix}/>
              </div>
              <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
                <CaracteristiquesTech data={data} prefix={prefix}/>
              </div>
            </React.Fragment>
        }
      </div>
    </div>
    {isLocataire ?
    <React.Fragment>
      <div className={classes.flex} style={{columnGap: '30px', marginTop: '30px'}}>
        <InfosVehicule data={data} prefix={prefix}/>
        <CaracteristiquesTech data={data} prefix={prefix}/>
      </div>
    </React.Fragment> : ''
    }
  </>
  )
};
