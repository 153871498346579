import React from 'react';
import {CardContainer} from '../CardContainer';
import {getLibelleValeur, getValueDateHeureElement} from '../Utils';

interface DeclarationProps {
    data: any,
    prefix: string
    formulaire: string
}

export const Declaration = (props: DeclarationProps) => {
  const {data, prefix, formulaire} = props

  const libellesValeursDeclaration = () => {
    return (
      [
        {
          libellesValeurs: [
            [
              getLibelleValeur(
                  (formulaire == 'DA') ? `Date et heure d'achat :` : `Date et heure de cession :`,
                  (formulaire == 'DA') ?
                      getValueDateHeureElement(data, prefix, 'horodate') :
                      getValueDateHeureElement(data, prefix, 'horodate_cession')),
              getLibelleValeur(
                  (formulaire == 'DA') ?
                      `Date et heure de la déclaration d'achat :` : 'Date et heure de la déclaration de cession :',
                  (formulaire == 'DA') ?
                      getValueDateHeureElement(data, prefix, 'horodate_declaration'):
                      getValueDateHeureElement(data, prefix, 'horodate_declaration_cession')),
            ],
          ],
        },
      ]
    )
  };

  return (
    <CardContainer titreContainer={
        (formulaire == 'DA') ?
            `Informations sur la date et l'heure de la déclaration d'achat'`:
            `Informations sur la date et l'heure de la déclaration de cession`
    }
    content={libellesValeursDeclaration()}/>
  )
};
