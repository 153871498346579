import React, {useEffect, useState} from 'react';
import {RefSinistre} from '../../../common/RefSinistre';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {NumMission} from '../../../common/NumMission';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {MenuItem, Typography} from '@mui/material';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Adresse} from '../../../common/adresse/Adresse';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import {InputDate} from '../../../common/InputDate';
import {Hour} from '../../../common/Hour';
import {SuspensionVE} from '../../../common/vehicule/SuspensionVE';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {getPreremplissageDADC, postSIVForm} from '../../../../api/siv';
import {Loader} from '../../../common/Loader';
import {
  ISIVFormDAAccesTransparent,
  SIVFormModel,
} from '../../../../model/common/SIVFormModel';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {UserContext} from '../../../../store/UserContext';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {TokenModel} from '../../../../model/common/TokenModel';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {customTheme} from '../../../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {useFormik} from 'formik';
import {validationSchemaDA} from './validationSchemaDA';
import {getInitialValueSIV, helperTextWithIcon} from '../../../../common/Utils';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    marginBottom: '16px',
  },
  otherCardContent: {
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  loaderPreremplir: {
    display: 'block',
    marginTop: '32px',
    marginLeft: '40px',
  },
  btnPreremplir: {
    marginTop: '32px',
  },
  remplissageOK: {
    color: theme.palette.green.main,
    marginTop: '8px',
  },
  remplissageKO: {
    color: 'red',
    marginTop: '8px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  marginTop: {
    marginTop: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
  dateHeure: {
    marginRight: '48px',
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FormDA = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormDAAccesTransparent;
  const {user} = React.useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [numMissionsList, setNumMissionsList] = useState<string[]>([]);
  const [showSelectMissions, setShowSelectMissions] = useState<boolean>(false);
  const [showPreremplir, setShowPreremplir] = useState<boolean>(true);
  const [showPreremplirOK, setShowPreremplirOK] = useState<boolean>(false);
  const [showPreremplirKO, setShowPreremplirKO] = useState<boolean>(false);
  const [isLoadingRemplissage, setIsLoadingRemplissage] = useState<boolean>(false);

  useEffect(() => {
    if (user.isTransparent) {
      setShowPreremplir(false)
    }
    if (formik.values.referenceSinistre?.length >= 6 && checkRefSinFormat(formik.values.referenceSinistre) &&
      checkRefSinSpecialChar(formik.values.referenceSinistre)) {
      if (!user.isTransparent) {
        preremplir();
      }
      setShowPreremplir(false)
    }
  }, []);

  useEffect(() => {
    setShowSelectMissions(numMissionsList && numMissionsList.length > 1)
  }, [numMissionsList]);

  const initialValues = {
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim()]),
    numeroMission: getInitialValueSIV([data?.numeroMission, mission?.infoMissionDTO?.numeroMission?.trim()]),
    titulaireActuel: {
      typePersonne: getInitialValueSIV(['pp', data?.titulaireActuel?.typePersonne]),
      identificationPP: getInitialValueSIV([data?.titulaireActuel?.identificationPP]),
      identificationPM: getInitialValueSIV([data?.titulaireActuel?.identificationPM]),
      siren: getInitialValueSIV([data?.titulaireActuel?.siren?.slice(0, 9)]),
      siret: getInitialValueSIV([data?.titulaireActuel?.siret?.slice(0, 14)]),
    },
    titulaireNouveau: {
      raisonSociale: getInitialValueSIV([user.raisonSocialeInfogeo, data?.titulaireNouveau?.raisonSociale]),
      siren: getInitialValueSIV([user.siren, data?.titulaireNouveau?.siren?.slice(0, 9)]),
      siret: getInitialValueSIV([user.siret, data?.titulaireNouveau?.siret?.slice(0, 14)]),
      adresse: {
        numero: getInitialValueSIV([user.adresse.numeroVoie, data?.titulaireNouveau?.adresse?.numero]),
        indice: getInitialValueSIV([user.adresse.indiceRepetition, data?.titulaireNouveau?.adresse?.indice]),
        type: getInitialValueSIV([user.adresse.typeVoie, data?.titulaireNouveau?.adresse?.type]),
        nom: getInitialValueSIV([user.adresse.libelleVoie, data?.titulaireNouveau?.adresse?.nom]),
        lieuDit: getInitialValueSIV([data?.titulaireNouveau?.adresse?.lieuDit]),
        etage: getInitialValueSIV([user.adresse.complementAdresse, data?.titulaireNouveau?.adresse?.etage]),
        immeuble: getInitialValueSIV([data?.titulaireNouveau?.adresse?.immeuble]),
        codePostal: getInitialValueSIV([user.adresse.codeCedex, user.adresse.codePostal,
          data?.titulaireNouveau?.adresse?.codePostal]),
        boitePostale: getInitialValueSIV([user.adresse.distributionSpeciale,
          data?.titulaireNouveau?.adresse?.boitePostale]),
        ville: getInitialValueSIV([user.adresse.libelleCedex, user.adresse.libelleCommune,
          data?.titulaireNouveau?.adresse?.ville]),
        pays: getInitialValueSIV(['FRANCE', data?.titulaireNouveau?.adresse?.pays]),
      },
    },
    immatriculation: getInitialValueSIV([data?.immatriculation]),
    numeroVin: getInitialValueSIV([data?.numeroVin]),
    presenceCertificat: data?.presenceCertificat || true,
    numeroFormule: getInitialValueSIV([data?.numeroFormule]),
    dateCertificat: getInitialValueSIV([data?.dateCertificat]),
    dateAchat: getInitialValueSIV([data?.dateAchat]),
    heureAchat: getInitialValueSIV([data?.heureAchat]),
    dateDeclaration: getInitialValueSIV([moment().format('YYYY-MM-DD'), data?.dateDeclaration]),
    heureDeclaration: getInitialValueSIV([moment().format('HH:mm'), data?.heureDeclaration]),
    ve: data?.ve || false,
    vei: data?.vei || false,
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'DA', referenceSinistre: formik.values.referenceSinistre,
      numMissionsList: [formik.values.numeroMission],
      titulaireActuel: {isPersonnePhysique: formik.values.titulaireActuel.typePersonne === 'pp',
        identificationPersonne: formik.values.titulaireActuel.typePersonne === 'pp' ?
          formik.values.titulaireActuel.identificationPP : formik.values.titulaireActuel.identificationPM,
        siren: formik.values.titulaireActuel.siren, siret: formik.values.titulaireActuel.siret},
      nouveauTitulaire: {identificationPersonne: formik.values.titulaireNouveau.raisonSociale,
        siren: formik.values.titulaireNouveau.siren, siret: formik.values.titulaireNouveau.siret,
        nomVoie: formik.values.titulaireNouveau.adresse.nom, numeroVoie: formik.values.titulaireNouveau.adresse.numero,
        type: formik.values.titulaireNouveau.adresse.type, extension: formik.values.titulaireNouveau.adresse.indice,
        lieuxDit: formik.values.titulaireNouveau.adresse.lieuDit, etage: formik.values.titulaireNouveau.adresse.etage,
        immeuble: formik.values.titulaireNouveau.adresse.immeuble,
        codePostal: formik.values.titulaireNouveau.adresse.codePostal,
        boitePostale: formik.values.titulaireNouveau.adresse.boitePostale,
        localite: formik.values.titulaireNouveau.adresse.ville, pays: formik.values.titulaireNouveau.adresse.pays},
      vehicule: {numeroImmatriculation: formik.values.immatriculation, numeroVin: formik.values.numeroVin,
        isPresenceCertificat: formik.values.presenceCertificat, numeroFormule: formik.values.numeroFormule,
        dateImmaticulation: formik.values.dateCertificat ? dateToDateText(new Date(formik.values.dateCertificat)) : ''},
      infoAchat: {dateAchat: dateToDateText(new Date(formik.values.dateAchat)), heureAchat: formik.values.heureAchat,
        dateDeclarationAchat: dateToDateText(new Date(formik.values.dateDeclaration)),
        heureDeclarationAchat: formik.values.heureDeclaration, isVehiculeSuspensionVE: formik.values.ve,
        isVehiculeEcoIrreparable: formik.values.vei}};
    const tokenMission: TokenModel = {
      referenceSinistre: formik.values.referenceSinistre,
      numeroMission: formik.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    sessionStorage.setItem('tokenMission', JSON.stringify(tokenMission));
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string, entity?: string) => () => {
    if (entity && entity !== '') {
      formik.setFieldTouched(`${entity}.${field}`, false, false)
    } else {
      formik.setFieldTouched(field, false, false)
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaDA,
    onSubmit: onSubmit,
  });

  const onInputReferenceSinistre = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanSearch(
        e.target.value.length >= 6 && checkRefSinFormat(e.target.value) && checkRefSinSpecialChar(e.target.value),
    );
    setShowSelectMissions(false);
  };

  const onChangeNumeroMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
  };

  const disabled = !!(formik.touched.referenceSinistre && formik.errors.referenceSinistre) ||
    !!(formik.touched.numeroMission && formik.errors.numeroMission) ||
    !!(formik.touched.titulaireActuel?.identificationPP && formik.errors.titulaireActuel?.identificationPP) ||
    !!(formik.touched.titulaireActuel?.identificationPM && formik.errors.titulaireActuel?.identificationPM) ||
    !!(formik.touched.titulaireActuel?.siren && formik.errors.titulaireActuel?.siren) ||
    !!(formik.touched.titulaireActuel?.siret && formik.errors.titulaireActuel?.siret) ||
    !!(formik.touched.titulaireNouveau?.raisonSociale && formik.errors.titulaireNouveau?.raisonSociale) ||
    !!(formik.touched.titulaireNouveau?.siren && formik.errors.titulaireNouveau?.siren) ||
    !!(formik.touched.titulaireNouveau?.siret && formik.errors.titulaireNouveau?.siret) ||
    !!(formik.touched.titulaireNouveau?.adresse?.numero && formik.errors.titulaireNouveau?.adresse?.numero) ||
    !!(formik.touched.titulaireNouveau?.adresse?.indice && formik.errors.titulaireNouveau?.adresse?.indice) ||
    !!(formik.touched.titulaireNouveau?.adresse?.type && formik.errors.titulaireNouveau?.adresse?.type) ||
    !!(formik.touched.titulaireNouveau?.adresse?.nom && formik.errors.titulaireNouveau?.adresse?.nom) ||
    !!(formik.touched.titulaireNouveau?.adresse?.lieuDit && formik.errors.titulaireNouveau?.adresse?.lieuDit) ||
    !!(formik.touched.titulaireNouveau?.adresse?.etage && formik.errors.titulaireNouveau?.adresse?.etage) ||
    !!(formik.touched.titulaireNouveau?.adresse?.immeuble && formik.errors.titulaireNouveau?.adresse?.immeuble) ||
    !!(formik.touched.titulaireNouveau?.adresse?.codePostal && formik.errors.titulaireNouveau?.adresse?.codePostal) ||
    !!(formik.touched.titulaireNouveau?.adresse?.boitePostale &&
      formik.errors.titulaireNouveau?.adresse?.boitePostale) ||
    !!(formik.touched.titulaireNouveau?.adresse?.ville && formik.errors.titulaireNouveau?.adresse?.ville) ||
    !!(formik.touched.titulaireNouveau?.adresse?.pays && formik.errors.titulaireNouveau?.adresse?.pays) ||
    !!(formik.touched.immatriculation && formik.errors.immatriculation) ||
    !!(formik.touched.numeroVin && formik.errors.numeroVin) ||
    !!(formik.touched.numeroFormule && formik.errors.numeroFormule) ||
    !!(formik.touched.dateCertificat && formik.errors.dateCertificat) ||
    !!(formik.touched.dateAchat && formik.errors.dateAchat) ||
    !!(formik.touched.heureAchat && formik.errors.heureAchat) ||
    !!(formik.touched.dateDeclaration && formik.errors.dateDeclaration) ||
    !!(formik.touched.heureDeclaration && formik.errors.heureDeclaration);

  const preremplir = () => {
    const tokenMission: TokenModel = {
      referenceSinistre: formik.values.referenceSinistre,
      numeroMission: formik.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    if (tokenMission) {
      setIsLoadingRemplissage(true);
      getPreremplissageDADC(tokenMission, 'A', false).then((res) => {
        const numMissionsList = res.numMissionsList;
        if (numMissionsList && numMissionsList.length > 1) {
          setNumMissionsList(numMissionsList);
          setCanSearch(false)
        } else {
          formik.values.titulaireActuel.typePersonne = 'pp';
          formik.values.titulaireActuel.identificationPP = res.titulaireActuel.identificationPersonne || '';

          formik.values.titulaireNouveau.raisonSociale = res.nouveauTitulaire.identificationPersonne || '';
          formik.values.titulaireNouveau.siren = res.nouveauTitulaire.siren || '';
          formik.values.titulaireNouveau.siret = res.nouveauTitulaire.siret || '';
          formik.values.titulaireNouveau.adresse.numero = res.nouveauTitulaire.numeroVoie || '';
          formik.values.titulaireNouveau.adresse.indice = res.nouveauTitulaire.extension || '';
          formik.values.titulaireNouveau.adresse.type = res.nouveauTitulaire.type || '';
          formik.values.titulaireNouveau.adresse.nom = res.nouveauTitulaire.nomVoie || '';
          formik.values.titulaireNouveau.adresse.lieuDit = res.nouveauTitulaire.lieuxDit || '';
          formik.values.titulaireNouveau.adresse.etage = res.nouveauTitulaire.etage || '';
          formik.values.titulaireNouveau.adresse.immeuble = res.nouveauTitulaire.immeuble || '';
          formik.values.titulaireNouveau.adresse.codePostal = res.nouveauTitulaire.codePostal || '';
          formik.values.titulaireNouveau.adresse.boitePostale = res.nouveauTitulaire.boitePostale || '';
          formik.values.titulaireNouveau.adresse.ville = res.nouveauTitulaire.localite || '';

          formik.values.immatriculation = res.vehicule.numeroImmatriculation || '';
          formik.values.numeroVin = res.vehicule.numeroVin || '';
          formik.values.presenceCertificat = res.vehicule.isPresenceCertificat || false;
          formik.values.numeroFormule = res.vehicule.numeroFormule || '';
          formik.values.dateCertificat = res.vehicule.dateImmaticulation || '';

          formik.values.ve = res.infoAchat.isVehiculeSuspensionVE || false;
          formik.values.vei = res.infoAchat.isVehiculeEcoIrreparable || false;
          setShowPreremplirOK(true)
        }
        setIsLoadingRemplissage(false);
      }).catch(() => {
        setShowPreremplirKO(true);
        setIsLoadingRemplissage(false)
      })
    }
  };

  return (
    <div onClick={() => {
      setShowPreremplirKO(false);
      setShowPreremplirOK(false)
    }}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'DA'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            onInput={(e) => onInputReferenceSinistre(e)}
            isMandatory={true}
            value={formik.values.referenceSinistre}
            onError={(formik.touched.referenceSinistre && !!formik.errors.referenceSinistre) || false}
            helperText={formik.touched.referenceSinistre && formik.errors.referenceSinistre ?
              formik.errors.referenceSinistre : ''
            }
          />

          {showSelectMissions &&
            <div>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>
                N° de mission <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textfield}
                id={'numMissionsDA'}
                name={'numeroMission'}
                marginDense={true}
                value={formik.values.numeroMission}
                onChange={(e) => {
                  formik.handleChange(e);
                  onChangeNumeroMission(e)
                }}
                itemsList={numMissionsList.map((mission) =>
                  <MenuItem key={mission} value={mission}>{mission}</MenuItem>,
                )}
                onBlur={formik.handleBlur}
                onFocus={onFocus('numeroMission')}
                onError={(formik.touched.numeroMission && !!formik.errors.numeroMission) || false}
                helperText={formik.touched.numeroMission && formik.errors.numeroMission ?
                  formik.errors.numeroMission : ''}
              />
              {showSelectMissions && !formik.values.numeroMission &&
                <div className={classes.remplissageKO}>
                 Sélectionner un N° de mission.
                </div>
              }
            </div>
          }

          {!showSelectMissions &&
            <NumMission
              className={classes.textfield}
              name={'numeroMission'}
              formulaire={'DA'}
              value={formik.values.numeroMission}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('numeroMission')}
              onError={(formik.touched.numeroMission && !!formik.errors.numeroMission) || false}
              helperText={formik.touched.numeroMission && formik.errors.numeroMission ?
                formik.errors.numeroMission : ''
              }
            />
          }

          {showPreremplir &&
            <div>
              {isLoadingRemplissage ?
                <Loader className={classes.loaderPreremplir} size={30}/> :
                <ButtonBlue
                  className={classes.btnPreremplir}
                  id={'boutonPreremplirDA'}
                  libelle={'Préremplir'}
                  disabled={!canSearch}
                  onClick={() => preremplir()}
                />
              }
            </div>
          }
        </div>

        {showPreremplirOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }
        {showPreremplirKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assuré</Typography>
          <PersonnePhysiqueMorale
            name={'titulaireActuel.typePersonne'}
            formulaire={'DA'}
            onChangeValue={formik.handleChange}
            value={formik.values.titulaireActuel.typePersonne}
          />
          {formik.values.titulaireActuel.typePersonne === 'pp' &&
            <IdentificationPersonne
              className={`${classes.textfield} ${classes.marginTop}`}
              name={'titulaireActuel.identificationPP'}
              formulaire={'DA'}
              value={formik.values.titulaireActuel.identificationPP}
              typePersonne={formik.values.titulaireActuel.typePersonne}
              onBlur={formik.handleBlur}
              onFocus={onFocus('identificationPP', 'titulaireActuel')}
              onChangeValue={formik.handleChange}
              onError={(formik.touched.titulaireActuel?.identificationPP &&
                !!formik.errors.titulaireActuel?.identificationPP) || false
              }
              helperText={formik.touched.titulaireActuel?.identificationPP && formik.errors.titulaireActuel &&
                formik.errors.titulaireActuel.identificationPP ? formik.errors.titulaireActuel.identificationPP : ''
              }
            />
          }

          {formik.values.titulaireActuel.typePersonne === 'pm' &&
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <IdentificationPersonne
                className={classes.textfield}
                name={'titulaireActuel.identificationPM'}
                formulaire={'DA'}
                value={formik.values.titulaireActuel.identificationPM}
                typePersonne={formik.values.titulaireActuel.typePersonne}
                onBlur={formik.handleBlur}
                onFocus={onFocus('identificationPM', 'titulaireActuel')}
                onChangeValue={formik.handleChange}
                onError={(formik.touched.titulaireActuel?.identificationPM &&
                  !!formik.errors.titulaireActuel?.identificationPM) || false
                }
                helperText={formik.touched.titulaireActuel?.identificationPM && formik.errors.titulaireActuel &&
                formik.errors.titulaireActuel.identificationPM ? formik.errors.titulaireActuel.identificationPM : ''
                }
              />
              <Siren
                className={classes.textfield}
                id={'sirenTitulaire'}
                name={'titulaireActuel.siren'}
                formulaire={'DA'}
                value={formik.values.titulaireActuel.siren}
                isMandatory={false}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siren', 'titulaireActuel')}
                onError={(formik.touched.titulaireActuel?.siren && !!formik.errors.titulaireActuel?.siren) || false}
                helperText={helperTextWithIcon(formik, 'siren', 'titulaireActuel', classes.helpIcon)}
              />
              <Siret
                className={classes.textfield}
                id={'siretTitulaire'}
                name={'titulaireActuel.siret'}
                formulaire={'DA'}
                value={formik.values.titulaireActuel.siret}
                isMandatory={false}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siret', 'titulaireActuel')}
                onError={(formik.touched.titulaireActuel?.siret && !!formik.errors.titulaireActuel?.siret) || false}
                helperText={helperTextWithIcon(formik, 'siret', 'titulaireActuel', classes.helpIcon)}
              />
            </div>
          }
        </div>
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Identité du nouveau titulaire : Assureur</Typography>
        <div className={classes.flex}>
          <RaisonSociale
            className={classes.textfield}
            name={'titulaireNouveau.raisonSociale'}
            formulaire={'DA'}
            value={formik.values.titulaireNouveau.raisonSociale}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('raisonSociale', 'titulaireNouveau')}
            onError={(formik.touched.titulaireNouveau?.raisonSociale &&
              !!formik.errors.titulaireNouveau?.raisonSociale) || false}
            helperText={formik.touched.titulaireNouveau?.raisonSociale && formik.errors.titulaireNouveau &&
            formik.errors.titulaireNouveau.raisonSociale ? formik.errors.titulaireNouveau.raisonSociale : ''
            }
          />
          <Siren
            className={classes.textfield}
            id={'sirenNewTitulaire'}
            name={'titulaireNouveau.siren'}
            formulaire={'DA'}
            value={formik.values.titulaireNouveau.siren}
            isMandatory={false}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('siren', 'titulaireNouveau')}
            onError={(formik.touched.titulaireNouveau?.siren && !!formik.errors.titulaireNouveau?.siren) || false}
            helperText={helperTextWithIcon(formik, 'siren', 'titulaireNouveau', classes.helpIcon)}
          />
          <Siret
            className={classes.textfield}
            id={'siretNewTitulaire'}
            name={'titulaireNouveau.siret'}
            formulaire={'DA'}
            value={formik.values.titulaireNouveau.siret}
            isMandatory={formik.values.titulaireNouveau.adresse.pays === 'FRANCE'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('siret', 'titulaireNouveau')}
            onError={(formik.touched.titulaireNouveau?.siret && !!formik.errors.titulaireNouveau?.siret) || false}
            helperText={helperTextWithIcon(formik, 'siret', 'titulaireNouveau', classes.helpIcon)}
          />
        </div>
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Coordonnées du nouveau titulaire : Assureur</Typography>
        <Adresse
          formulaire={'DA'}
          formik={formik}
          entity={'titulaireNouveau.adresse'}
          formikValues={formik.values.titulaireNouveau.adresse}
          formikTouched={formik.touched.titulaireNouveau?.adresse}
          formikErrors={formik.errors.titulaireNouveau?.adresse}
        />
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Véhicule</Typography>
        <Vehicule
          formulaire={'DA'}
          formik={formik}
          entity={''}
          formikValues={{immatriculation: formik.values.immatriculation, numeroVin: formik.values.numeroVin,
            presenceCertificat: formik.values.presenceCertificat, numeroFormule: formik.values.numeroFormule,
            dateCertificat: formik.values.dateCertificat}
          }
          formikTouched={formik.touched}
          formikErrors={formik.errors}
        />
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Information sur la déclaration d&apos;achat</Typography>
        <div className={classes.flex}>
          <span className={`${classes.flex} ${classes.dateHeure}`}>
            <InputDate
              className={classes.textfieldSmall}
              id={'dateAchat'}
              name={'dateAchat'}
              formulaire={'DA'}
              isMandatory={true}
              title={'Achat du véhicule'}
              value={formik.values.dateAchat}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('dateAchat')}
              onError={(formik.touched.dateAchat && !!formik.errors.dateAchat) || false}
              helperText={formik.touched.dateAchat && formik.errors.dateAchat && formik.errors.dateAchat ?
                formik.errors.dateAchat : ''
              }
            />
            <Hour
              className={classes.textfieldSmall}
              id={'heureAchat'}
              name={'heureAchat'}
              formulaire={'DA'}
              isMandatory={false}
              title={'\xa0'}
              value={formik.values.heureAchat}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('heureAchat')}
              onError={(formik.touched.heureAchat && !!formik.errors.heureAchat) || false}
              helperText={formik.touched.heureAchat && formik.errors.heureAchat ? formik.errors.heureAchat : ''}
            />
          </span>
          <span className={classes.flex}>
            <InputDate
              className={classes.textfieldSmall}
              id={'dateDeclaration'}
              name={'dateDeclaration'}
              formulaire={'DA'}
              isMandatory={true}
              title={'Déclaration d\'achat'}
              value={formik.values.dateDeclaration}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('dateDeclaration')}
              onError={(formik.touched.dateDeclaration && !!formik.errors.dateDeclaration) || false}
              helperText={formik.touched.dateDeclaration && formik.errors.dateDeclaration ?
                formik.errors.dateDeclaration : ''
              }
            />
            <Hour
              className={classes.textfieldSmall}
              id={'heureDeclaration'}
              name={'heureDeclaration'}
              formulaire={'DA'}
              isMandatory={false}
              title={'\xa0'}
              value={formik.values.heureDeclaration}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('heureDeclaration')}
              onError={(formik.touched.heureDeclaration && !!formik.errors.heureDeclaration) || false}
              helperText={formik.touched.heureDeclaration && formik.errors.heureDeclaration ?
                formik.errors.heureDeclaration : ''
              }
            />
          </span>
        </div>
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Information expertise</Typography>
        <SuspensionVE
          nameVE={'ve'}
          nameVEI={'vei'}
          isVE={formik.values.ve}
          isVEI={formik.values.vei}
          onChangeValue={formik.handleChange}
        />
      </div>

      <Typography style={{fontSize: '14px'}}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      {responseReturn === -1 &&
        <Typography className={`${classes.red} ${classes.error}`}>
          Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
        </Typography>
      }

      <div style={{textAlign: 'right'}}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={'boutonEnvoyerDA'} disabled={disabled} onClick={formik.submitForm}/>
        }
      </div>
    </div>
  )
};
