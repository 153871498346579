import * as yup from 'yup';
import {
  checkAdresse,
  checkIdentificationPM,
  checkImmat, checkNom,
  checkNumMission, checkPrenom, checkRaisonSoc,
  checkRefSinFormat,
  checkRefSinSpecialChar, getTypeImmat,
} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment/moment';

export const validationSchemaDC = yup.object({
  referenceSinistre: yup.string()
      .required('Veuillez saisir une valeur')
      .min(6, 'La référence sinistre doit faire au moins 6 caractères')
      .test('test-referenceSinistre-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
      'identiques d\'affilée',
      function(value) {
        return checkRefSinFormat(value || '')
      })
      .test('test-referenceSinistre-char', 'Le champ contient des caractères interdits',
          function(value) {
            return checkRefSinSpecialChar(value || '')
          }),
  numeroMission: yup.string()
      .test('test-numeroMission', 'Le champ contient des caractères interdits',
          function(value) {
            return checkNumMission(value ?? '')
          }),
  titulaireActuel: yup.object({
    identificationPM: yup.string()
        .required('Veuillez saisir une valeur')
        .test('test-identificationPM', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
            function(value) {
              return checkIdentificationPM(value || '')
            }),
    siren: yup.string()
        .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
    siret: yup.string()
        .required('Veuillez saisir une valeur')
        .min(14, 'Veuillez saisir une valeur au format attendu, voir'),
  }),
  titulaireNouveau: yup.object({
    nom: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pp',
          then: () => yup.string()
              .required('Veuillez saisir une valeur')
              .test('test-nom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                  function(value) {
                    return checkNom(value || '')
                  }),
        }),
    nomUsage: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pp',
          then: () => yup.string()
              .test('test-nomUsage', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                  function(value) {
                    return checkNom(value ?? '')
                  }),
        }),
    prenom: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pp',
          then: () => yup.string()
              .required('Veuillez saisir une valeur')
              .test('test-prenom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                  function(value) {
                    return checkPrenom(value || '')
                  }),
        }),
    sexe: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pp',
          then: () => yup.string()
              .required('Veuillez saisir une valeur'),
        }),
    societeCommerciale: yup.boolean(),
    raisonSociale: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .required('Veuillez saisir une valeur')
              .test('test-raisonSociale', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                  function(value) {
                    return checkRaisonSoc(value || '')
                  }),
        }),
    siren: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
        }),
    siret: yup.string()
        .when('typePersonne', {
          is: (value: string) => value === 'pm',
          then: () => yup.string()
              .min(14, 'Veuillez saisir une valeur au format attendu, voir')
              .test('test-siret', 'Veuillez saisir une valeur',
                  function(value, ctx) {
                    if (ctx.parent.societeCommerciale && ctx.parent.adresse.pays === 'FRANCE') {
                      return value !== undefined && value !== ''
                    }
                    return true
                  }),
        }),
    adresse: yup.object({
      numero: yup.string(),
      indice: yup.string(),
      type: yup.string(),
      nom: yup.string()
          .required('Veuillez saisir une valeur')
          .test('test-nom', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value || '')
              }),
      lieuDit: yup.string()
          .test('test-lieuDit', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value ?? '')
              }),
      etage: yup.string()
          .test('test-etage', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value ?? '')
              }),
      immeuble: yup.string()
          .test('test-immeuble', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value ?? '')
              }),
      codePostal: yup.string()
          .required('Veuillez saisir une valeur')
          .min(5, 'Veuillez saisir une valeur au format attendu, voir'),
      boitePostale: yup.string()
          .test('test-boitePostale', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value ?? '')
              }),
      ville: yup.string()
          .required('Veuillez saisir une valeur')
          .test('test-ville', 'Les caractères ! ? * ° : € # ; & + @ % ne sont pas autorisés',
              function(value) {
                return checkAdresse(value || '')
              }),
      pays: yup.string().required('Veuillez saisir une valeur'),
    }),
  }),
  immatriculation: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu, voir',
          function(value) {
            return checkImmat(value || '')
          }),
  numeroVin: yup.string()
      .required('Veuillez saisir une valeur'),
  presenceCertificat: yup.boolean(),
  numeroFormule: yup.string()
      .when(['immatriculation', 'presenceCertificat'], {
        is: (immatriculation: string, presenceCertificat: boolean) =>
          (getTypeImmat(immatriculation) === 'SIV' || getTypeImmat(immatriculation) === 'CYCLO') && presenceCertificat,
        then: () => yup.string()
            .required('Veuillez saisir une valeur'),
      }),
  dateCertificat: yup.date()
      .transform((current, origin) => origin === '' ? null : current)
      .nullable()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateCertificat1', 'Veuillez saisir une valeur',
          function(value, ctx) {
            if (ctx.parent.presenceCertificat && getTypeImmat(ctx.parent.immatriculation) === 'FNI') {
              return value !== undefined
            }
            return true
          })
      .test('test-dateCertificat2', 'La date du certificat d\'immatriculation ne peut être postérieure ' +
        'à la date de cession',
      function(value, ctx) {
        if (ctx.parent.dateCession && value) {
          return value <= ctx.parent.dateCession
        }
        return true
      }),
  dateCession: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  heureCession: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heureCession', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
        'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateCession;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      }),
  dateDeclaration: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateDeclaration', 'La date de la déclaration de cession ne peut être antérieure à la date de cession',
          function(value, ctx) {
            if (ctx.parent.dateCession) {
              return ctx.parent.dateCession <= value
            }
            return true
          }),
  heureDeclaration: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test1-heureDeclaration', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
      'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateDeclaration;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      })
      .test('test2-heureDeclaration', 'L\'heure de la déclaration de cession doit être postérieure à ' +
        'l\'heure de cession pour une même date',
      function(value, ctx) {
        if (ctx.parent.heureCession &&
          moment(ctx.parent.dateCession, 'YYYY-MM-DD').isSame(moment(ctx.parent.dateDeclaration, 'YYYY-MM-DD'))) {
          return value >= ctx.parent.heureCession
        }
        return true
      }),
});
