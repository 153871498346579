import React from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import BlueSwitch from '../BlueSwitch';
import {Theme} from '@emotion/react';

interface SocieteCommercialeProps {
  className?: string,
  name: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  value?: boolean,
}

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const SocieteCommerciale = ({className, name, onChangeValue, value}: SocieteCommercialeProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={`${className} ${classes.container}`}>
      <Typography className={classes.libelle}>
        Société commerciale <span className={classes.red}>*</span>
      </Typography>

      <BlueSwitch
        id={'switchSocieteComm'}
        name={name}
        onChange={onChangeValue}
        checked={value}
      />
    </div>
  )
};
