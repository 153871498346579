import {CardContainer} from '../CardContainer';
import React from 'react';
import {
  getLibelleValeur,
  getLibelleValeurCoordonneesTitulaire,
  getValueElement,
} from '../Utils';

interface InfosTitulaireProps {
  data: any,
  prefix: string
  isLocataire?: boolean
}

export const TitulaireLocataire = (props: InfosTitulaireProps) => {
  const {data, prefix, isLocataire} = props

  const libelleTitulaireOuAcquereur = (compl: string) => {
    if (data.getElementsByTagName(prefix + 'titulaire').length !== 0) {
      return 'Informations sur le titulaire ' + compl
    }
    return (
      data.getElementsByTagName(prefix + 'acquereur').length !== 0 ?
        'Informations sur l\'acquereur ' + compl : '' + compl
    )
  }

  const libellesValeurs = () => {
    const libelleValeurIdentite = () => {
      if (data.getElementsByTagName(prefix + 'raison_sociale').length !== 0) {
        return (
          [
            getLibelleValeur('Raison sociale :', getValueElement(data, prefix, 'raison_sociale')),
            getLibelleValeur('N° SIREN :', getValueElement(data, prefix, 'siren')),
            getLibelleValeur('N° SIRET :', getValueElement(data, prefix, 'siret')),
          ]
        )
      } else {
        return (
          [
            getLibelleValeur('Nom :', getValueElement(data, prefix, 'nom_naissance')),
            getLibelleValeur(`Nom d'usage :`, getValueElement(data, prefix, 'nom_usage')),
            getLibelleValeur('Prénom :', getValueElement(data, prefix, 'prenom')),
          ]
        )
      }
    }
    return (
      [
        {
          titre: isLocataire ? 'Identité du locataire' : 'Identité du titulaire',
          libellesValeurs: [
            libelleValeurIdentite(),
          ],
        },
        getLibelleValeurCoordonneesTitulaire(data, prefix, !!isLocataire),
      ]
    )
  };

  const titreContainer = () => {
    if (isLocataire) {
      return 'Informations sur le locataire'
    } else {
      return libelleTitulaireOuAcquereur(data.getElementsByTagName(prefix + 'raison_sociale').length !== 0 ?
        '(cas d\'une personne morale)' : '(cas d\'une personne physique)')
    }
  }

  return (
    <CardContainer titreContainer={titreContainer()}
      content={libellesValeurs()}
    />
  )
};
