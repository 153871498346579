import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Typography} from '@mui/material';
import {IVehicle} from '../../model/ModelPECBG';

export interface VehicleProps {
  vehicule: IVehicle,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '14px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

const Vehicle = (props: VehicleProps) => {
  const {vehicule} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Informations sur le véhicule
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.grid}>
            <div>
              <span className={classes.libelle}>Modèle du véhicule : </span>
              <span>{vehicule.vehModele}</span>
            </div>
            <div>
              <span className={classes.libelle}>Marque du véhicule : </span>
              <span>{vehicule.vehMarque}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
