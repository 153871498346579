import React, {useContext, useState} from 'react';
import {Button, Card, CardContent, IconButton, Link, Tooltip, Typography} from '@mui/material';
import orderBy from 'lodash/orderBy';
import {Loader} from '../../../common/Loader';
import {ReactComponent as DownloadIcon} from '../../../../images/icones/btn-download-small.svg';
import {customTheme} from '../../../../common/GlobalTheme';
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import Picture from './Picture';
import {AttachmentProps, downloadAllAttachments} from '../DownloadAttachments';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../store/UserContext';

interface AlbumMessagesProps {
  groupeMessage: _.Dictionary<PhotoModel[]>,
  idDoc: string,
  referenceSinistre?: string,
  click: (id: string, album: PhotoModel[], index: number, photo: PhotoModel, from: string) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
    boxShadow: 'none',
    marginTop: '16px',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '16px',
  },
  lientitre: {
    cursor: 'pointer',
  },
  subTitle: {
    textAlign: 'left' as const,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.primary.main,
    opacity: '1',
  },
  date: {
    marginLeft: '8px',
    textAlign: 'left' as const,
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '1',
    display: 'inline',
  },
  lineAlbum: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  buttonPhoto: {
    padding: '4px',
    border: 'none',
  },
  loader: {
    display: 'flex',
    margin: '0px 0px 0px 15px',
  },
});

export const AlbumMessages = ({groupeMessage, idDoc, referenceSinistre, click}: AlbumMessagesProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorDownload, setOnErrorDownload] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);

  const attachments = () => {
    const tab: AttachmentProps[] = [];
    groupeMessage[idDoc].forEach((photo) => {
      tab.push({
        id: photo.id,
        name: photo.name,
        date: photo.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.title}>
          <Link key={'lientitre'} className={classes.lientitre} onClick={() => click(groupeMessage[idDoc][0].id,
              groupeMessage[idDoc], 0, groupeMessage[idDoc][0], 'message')}
          >
            <Typography
              className={classes.subTitle}
              id={'title-photo-album-message-' + groupeMessage[idDoc][0].typeDoc}
            >
              {groupeMessage[idDoc][0].typeDoc} - {groupeMessage[idDoc][0].libelleDoc}
            </Typography>
          </Link>
          <Typography className={classes.date} id={'info-photo-album-message-' + groupeMessage[idDoc][0].typeDoc}>
            {groupeMessage[idDoc]?.length} photo{groupeMessage[idDoc]?.length>1 ? 's déposées ' : ' déposée '}
            le {orderBy(groupeMessage[idDoc], 'date', 'desc')[0].date?.replace('-', 'à')}
            &nbsp;par {groupeMessage[idDoc][0].author}
          </Typography>
        </div>
        {groupeMessage[idDoc] &&
          <div className={classes.lineAlbum}>
            <div>
              {groupeMessage[idDoc]?.slice(0, 9).map((image, index2) => {
                return (
                  <Button key={image.id} className={classes.buttonPhoto} type="button"
                    onClick={() => click(image.id, groupeMessage[idDoc], index2, image, 'message')}
                  >
                    <Picture imgId={image.id}/>
                  </Button>
                )
              })}
            </div>
            {isLoading ? <Loader className={classes.loader} size={20}/> :
                <Tooltip disableInteractive title='Télécharger' PopperProps={{disablePortal: true}}>
                  <IconButton style={{marginLeft: '5px', width: '50px', height: '50px'}}
                    onClick={() => downloadAllAttachments({attachments: attachments(),
                      author: groupeMessage[idDoc][0].author ?? '',
                      typeDoc: groupeMessage[idDoc][0].typeDoc || '', referenceSinistre: referenceSinistre ?? '',
                      numClient: user.numClient, setIsLoading, setIsEndDownload,
                      setIsFilesInZip, setOnErrorDownload, setNbFilesInZip})}>
                    <DownloadIcon style={{fill: customTheme.palette.link.main}}/>
                  </IconButton>
                </Tooltip>
            }
            {onErrorDownload &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Erreur lors du téléchargement
              </Typography>
            }
            {!isFilesInZip &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Aucune photo valide à télécharger
              </Typography>
            }
            {isFilesInZip && isEndDownload && nbFilesInZip !== groupeMessage[idDoc]?.length &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                {nbFilesInZip}
                {nbFilesInZip === 1 ? ' image téléchargée' : ' images téléchargées'} sur {groupeMessage[idDoc]?.length}
              </Typography>
            }
          </div>
        }
      </CardContent>
    </Card>
  )
};
