import {dateToDateEtHeure, dateToDateText} from '../../../../common/utils/DateUtils';
import unescape from 'lodash/unescape';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';

export const getValueElement = (data: any, prefix: string, tagName1: string, tagName2?: string) => {
  if (!tagName2) {
    if (data.getElementsByTagName(prefix + tagName1).length !== 0) {
      return unescape(data.getElementsByTagName(prefix + tagName1)[0].value)
    } else return ''
  } else if (data.getElementsByTagName(prefix + tagName1)[0]
      .getElementsByTagName(prefix + tagName2).length !== 0) {
    return unescape(data.getElementsByTagName(prefix + tagName1)[0]
        .getElementsByTagName(prefix + tagName2)[0].value)
  } else return ''
}

export const getValueDateElement = (data: any, prefix: string, tagName: any) => {
  if (data.getElementsByTagName(prefix + tagName).length !== 0) {
    return dateToDateText(data.getElementsByTagName(prefix + tagName)[0].value
        .replace('Z', ''))
  } else return ''
}

export const getValueDateHeureElement = (data: any, prefix: string, tagName: any) => {
  if (data.getElementsByTagName(prefix + tagName).length !== 0) {
    return dateToDateEtHeure(data.getElementsByTagName(prefix + tagName)[0].value)
  } else return ''
}

export const getValueElementWithoutSANS = (data: any, prefix: string, tagName: any) => {
  if (data.getElementsByTagName(prefix + tagName).length !== 0 &&
      data.getElementsByTagName(prefix + tagName)[0].value !== 'SANS') {
    return unescape(data.getElementsByTagName(prefix + tagName)[0].value)
  } else return ''
}

export const getLibelleValeur = (libelle: string, valeur: string) => {
  return (
    {'libelle': libelle, 'valeur': valeur}
  )
}

const indiceVoie = (data: any, prefix: string, libelleIndiceVoie?: ItemGetValeursCodesModel[]) => {
  if (libelleIndiceVoie) {
    if (data.getElementsByTagName(prefix + 'complement_numero').length !== 0) {
      return libelleIndiceVoie.length > 0 ? libelleIndiceVoie[0].libelleedition :
          data.getElementsByTagName(prefix + 'complement_numero')[0].value
    }
    return ''
  } else {
    getValueElement(data, prefix, 'complement_numero')
  }
}
const typeVoie = (data: any, prefix: string, libelleTypeVoie?: ItemGetValeursCodesModel[]) => {
  if (libelleTypeVoie) {
    if (data.getElementsByTagName(prefix + 'type_voie').length !== 0) {
      if (data.getElementsByTagName(prefix + 'type_voie')[0].value !== 'SANS') {
        return libelleTypeVoie.length > 0 ?
            libelleTypeVoie[0].libelleedition : data.getElementsByTagName(prefix + 'type_voie')[0].value
      }
      return ''
    } else {
      return ''
    }
  } else return getValueElementWithoutSANS(data, prefix, 'type_voie')
}
export const getLibelleValeurCoordonneesTitulaire = (data: any, prefix: string, isLocataire: boolean,
    libelleIndiceVoie?: ItemGetValeursCodesModel[], libelleTypeVoie?: ItemGetValeursCodesModel[]) => {
  return (
    {
      titre: isLocataire ? 'Coordonnées du locataire' : 'Coordonnées du titulaire',
      libellesValeurs: [
        [
          getLibelleValeur('Numéro dans la voie :', getValueElementWithoutSANS(data, prefix, 'numero')),
          getLibelleValeur('Extension-Indice-Répétition :', indiceVoie(data, prefix, libelleIndiceVoie)),
        ],
        [
          getLibelleValeur('Type de voie :', typeVoie(data, prefix, libelleTypeVoie)),
          getLibelleValeur('Nom de la voie :', getValueElement(data, prefix, 'libelleVoie')),
        ],
        [
          getLibelleValeur('Etage-Escalier-Appartement :',
              getValueElement(data, prefix, 'etage_escalier_appartement')),
          getLibelleValeur('Immeuble-Batiment-Résidence :',
              getValueElement(data, prefix, 'immeubleResidenceBatiment')),
        ],
        [
          getLibelleValeur('Lieu dit :', getValueElement(data, prefix, 'lieuDit')),
          getLibelleValeur('Boite postale :', getValueElement(data, prefix, 'boite_postale')),
        ],
        [
          getLibelleValeur('Code postal :', getValueElement(data, prefix, 'codePostal')),
          getLibelleValeur('Ville :', getValueElement(data, prefix, 'commune')),
        ],
        isLocataire ? [] : [
          getLibelleValeur('Pays :', getValueElement(data, prefix, 'pays')),
        ],
      ],
    }
  )
}
