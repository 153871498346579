import React from 'react';
import {CardContainer} from '../CardContainer';
import {getValueElement, getValueDateElement, getLibelleValeur} from '../Utils';

interface InfosVehiculeProps {
  data: any,
  prefix: string
}

export const InfosVehicule = (props: InfosVehiculeProps) => {
  const {data, prefix} = props

  const numeroVIN = () => {
    if (data.getElementsByTagName(prefix + 'numero_vin').length !== 0) {
      return getValueElement(data, prefix, 'numero_vin')
    } else {
      return getValueElement(data, prefix, 'vin')
    }
  }

  const libellesValeursIV = () => {
    return (
      [
        [
          getLibelleValeur('N° VIN (E) : ', numeroVIN()),
        ],
        [
          getLibelleValeur('Date de 1ère immatriculation : ',
              getValueDateElement(data, prefix, 'date_premiere_immat')),
          getLibelleValeur('Marque (D.1) : ', getValueElement(data, prefix, 'marque')),
        ],
        [
          getLibelleValeur('Type variante version (D.2) : ', getValueElement(data, prefix, 'type_variante_version')),
          getLibelleValeur('Dénomination commerciale (D.3) : ',
              getValueElement(data, prefix, 'denomination_commerciale')),
        ],
        [
          getLibelleValeur('Libellé couleur / nuance : ', getValueElement(data, prefix, 'denomination_commerciale')),
          getLibelleValeur('Code couleur / nuance : ', getValueElement(data, prefix, 'couleur')),
        ],
      ]
    )
  };

  return (
    <CardContainer titreContainer={'Véhicule'}
      content={[{libellesValeurs: libellesValeursIV()}]}
    />
  )
};
