import React, {useMemo, useReducer} from 'react'
import {ResultDataState, resultReducer} from '../reducers/ResultReducer'
import {ResultActions, getResultActions} from '../action/result.action'

interface ResultContextProviderProps {
  children: React.ReactNode,
}

const initialState: ResultDataState = {
  dispatch: () => undefined,
  actions: {} as ResultActions,
};

const ResultContext = React.createContext(initialState);

export const ResultContextProvider = (props: ResultContextProviderProps) => {
  const [state, dispatch] = useReducer(resultReducer, initialState);
  const actions = getResultActions(state, dispatch);
  const valueResultContextProvider = useMemo(() => ({...state, dispatch, actions}), [dispatch, actions]);

  return (
    <ResultContext.Provider value={valueResultContextProvider}>
      {props.children}
    </ResultContext.Provider>
  )
};

export const useResultContext = () => React.useContext(ResultContext);
