import AxiosService from '../services/AxiosService';
import {IPecBgForm} from '../components/forms/pec-bg/model/ModelPECBG';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Envoie un message PEC BG
 *
 * @return {Promise}
 * @param pecBgForm
 */
export function postPECBGForm(pecBgForm: IPecBgForm) {
  return axiosInstance.post(`${baseUrl}/postPecBg`, pecBgForm)
}
