import {InputAdornment, TextField, Tooltip, Typography} from '@mui/material';
import React from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {FieldConfig, FieldHookConfig, FormikValues, useField, useFormikContext} from 'formik';
import {Theme} from '@emotion/react';
import HelpIcon from '@mui/icons-material/Help';
import {getIdFromName} from '../../utils/Utils';

interface InputCurrencyTextFieldProps extends FieldConfig {
  label: string
  mandatory?: boolean
  disabled?: boolean
  className?: string
  maskWholeNumberPart: string
  titleTooltip?: React.JSX.Element | string
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  title: {
    display: 'flex',
  },
  tooltip: {
    marginLeft: '8px',
    color: theme.palette.secondary.dark2,
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const InputCurrencyTextField = (props: InputCurrencyTextFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta] = useField<string>(props as FieldHookConfig<string>);
  const {setFieldValue}: FormikValues = useFormikContext();
  const {label, mandatory, disabled, className, maskWholeNumberPart, titleTooltip} = props;

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // permet de remplacer un point par une virgule
    e.target.value = e.target.value.replace('.', ',');
    // permet de remplacer tout ce qui n'est pas un chiffre, un point ou une virgule par vide ('')
    e.target.value = e.target.value.replace(/[^\d,.]/, '');
    // permet de limiter à n chiffres maximum avant la virgule
    e.target.value = e.target.value.replace(new RegExp('^(\\d{' + (maskWholeNumberPart.length) + '})[^,]'), '$1');
    // permet de ne pas prendre en compte une seconde virgule qui serait saisie
    e.target.value = e.target.value.replace(/(,\d*),/, '$1');
    // permet de limiter à deux chiffres après la virgule
    e.target.value = e.target.value.replace(/(,\d{2}).*/, '$1');
    // permet d'ajouter un 0 devant le montant si il commence par une virgule
    e.target.value = e.target.value.replace(/^,(\d)/, '0,$1');
    // permet de supprimer le 0 en début de chaine si il est suivi par d'autres chiffres
    e.target.value = e.target.value.replace(/^0(\d)/, '$1');
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== '' && !e.target.value.includes(',')) {
      setFieldValue(field.name, e.target.value + ',00')
    }
  };

  const getLabel = <Typography className={classes.libelle}>
    {label} {mandatory && <span className={classes.red}>*</span>}
  </Typography>;

  return (
    <div className={className ?? classes.container}>
      {!titleTooltip &&
        getLabel
      }
      {titleTooltip &&
        <div className={classes.title}>
          {getLabel}
          <Tooltip
            className={classes.tooltip}
            disableInteractive
            placement="top"
            PopperProps={{disablePortal: true}}
            title={titleTooltip}
          >
            <HelpIcon/>
          </Tooltip>
        </div>
      }

      <TextField
        id={getIdFromName(field.name)}
        fullWidth
        margin='dense'
        size='small'
        type={'search'}
        disabled={Boolean(disabled)}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        onInput={onInput}
        onBlur={onBlur}
        name={field.name}
        onChange={field.onChange}
        value={field.value}
        InputProps={{
          classes: {
            root: classes.textfield,
          },
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    </div>
  )
};
