import React from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';

interface NumeroFormuleProps {
  className?: string,
  name: string,
  formulaire: string,
  isMandatory: boolean,
  value: string
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const NumeroFormule = (props: NumeroFormuleProps) => {
  const {className, name, formulaire, isMandatory, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        N° de formule du certificat d&apos;immatriculation {isMandatory && <span className={classes.red}>*</span>}
      </Typography>

      <TextFieldSearch
        id={'numeroFormule' + formulaire}
        name={name}
        fullWidth={true}
        maxLength={11}
        value={value}
        onChange={onChangeValue}
        onError={onError}
        helperText={helperText}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
};
