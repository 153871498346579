import AxiosService from '../services/AxiosService';
import Cookies from 'js-cookie';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne la liste des comptes par numéro client
 *
 * @param {String} noClient - Numéro client
 * @return {Promise} response data
 *
 * @example
 *     getComptesByNoClient('00007')
 */
export async function getComptesByNoClient(noClient: string) {
  const response = await axiosInstance(`${baseUrl}/comptes`,
      {
        params: {
          noClient: noClient,
        },
      });
  return response.data
}

/**
 * Retourne le détail utilisateur à partir de son identifiant récupéré côté Back via Keycloak
 *
 * @return {Promise} response data
 *
 * @example
 *     getUserbyIdClient()
 */
export async function getUserbyIdClient() {
  const response = await axiosInstance(`${baseUrl}/infoUser`,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      });
  return response.data
}

/**
 * Retourne le détail partenaire à partir de son code abonné
 *
 * @param {String} codeAbonne - code abonné partenaire
 * @param {String} codeService - code service
 * @return {Promise} response data
 *
 * @example
 *     getUserByCodeAbonne('A79879S00001', 'S084')
 */
export async function getUserByCodeAbonne(codeAbonne: string, codeService: string) {
  const response = await axiosInstance(`${baseUrl}/getUserByCodeAbonne`,
      {
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        params: {
          codeAbonne: codeAbonne,
          codeService: codeService,
        },
      });
  return response.data
}

/**
 * Retourne la liste des scénarios à partir d'un code abonné
 *
 * @param {String} numClient - numéro client
 * @param {String} codeAbonne - code abonné
 * @param {String} codeGTA - code GTA
 * @param {String} codeService - code service
 * @return {Promise} response data
 *
 * @example
 *     getScenariosByCodeAbonne('00007', 'A79049737475', '372', 'S084')
 */
export async function getScenariosByCodeAbonne(numClient: string, codeAbonne: string, codeGTA: string,
    codeService: string) {
  const response = await axiosInstance(`${baseUrl}/scenarios`,
      {
        params: {
          numClient: numClient,
          codeAbonne: codeAbonne,
          codeGTA: codeGTA,
          codeService: codeService,
        },
      });
  return response.data
}

