import React from 'react';
import InfosVehiculeBloc from './InfosVehiculeBloc';
import SinistreBloc from './SinistreBloc';
import DommagesBloc from './DommagesBloc';
import useEmotionStyles from '../../../../../common/useEmotionStyles';

const styles = () => ({
  container: {
    marginBottom: '16px',
  },
});

const ConsultationPecBg = () => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={classes.container}>
      <InfosVehiculeBloc/>
      <SinistreBloc/>
      <DommagesBloc/>
    </div>
  );
};

export default ConsultationPecBg;
