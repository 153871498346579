import React from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Typography} from '@mui/material';
import {IResponseInformation} from '../../model/ModelPECBG';

export interface ResponseInformationProps {
  reponseInformation: IResponseInformation,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '16px',
  },
  gridElement: {
    display: 'grid',
    gridTemplateColumns: '125px 1fr',
    marginBottom: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  marginBottom16: {
    marginBottom: '16px',
  },
  marginBottom8: {
    marginBottom: '8px',
  },
  greenTag: {
    padding: '5px 8px 5px 8px',
    backgroundColor: theme.palette.green.main,
    borderRadius: '8px',
    color: theme.palette.white.main,
  },
  redTag: {
    padding: '5px 8px 5px 8px',
    backgroundColor: theme.palette.red.main,
    borderRadius: '8px',
    color: theme.palette.white.main,
  },
  greenBorder: {
    border: 'solid 1px ' + theme.palette.green.main,
  },
  redBorder: {
    border: 'solid 1px ' + theme.palette.red.main,
  },
});

const ResponseInformation = (props: ResponseInformationProps) => {
  const {reponseInformation} = props;
  const classes = useEmotionStyles(styles);

  const getTagStyle = (label: string) => {
    switch (label.toLowerCase()) {
      case 'oui':
        return classes.greenTag;
      case 'non':
        return classes.redTag;
      default:
        return ''
    }
  }

  const getBorderStyle = (label: string) => {
    switch (label.toLowerCase()) {
      case 'oui':
        return classes.greenBorder;
      case 'non':
        return classes.redBorder;
      default:
        return ''
    }
  }

  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Informations sur la réponse
      </Typography>
      <div className={classes.card}>
        <div className={`${classes.cardContent} ${classes.marginBottom8}`}>
          <div className={classes.marginBottom16}>
            <span className={classes.libelle}>Code réponse : </span>
            <span>{reponseInformation.reponse.codeReponse}</span>
          </div>
          <div className={classes.gridElement}>
            <span className={classes.libelle}>Libellé réponse : </span>
            <div>
              {reponseInformation.reponse.libelleReponse}
            </div>
          </div>
          <div className={classes.grid}>
            <div>
              <span className={classes.libelle}>Numéro de sinistre : </span>
              <span>{reponseInformation.numeroSinistre}</span>
            </div>
            <div>
              <span className={classes.libelle}>Prise en charge : </span>
              <span className={
                getTagStyle(reponseInformation.priseEnCharge)
              }>
                {reponseInformation.priseEnCharge}
              </span>
            </div>
          </div>
        </div>
        <div className={`${classes.cardContent}
          ${getBorderStyle(reponseInformation.garantie.garantieBDG)}`
        }
        >
          <div className={classes.grid}>
            <div>
              <span className={classes.libelle}>Garantie : </span>
              <span className={
                getTagStyle(reponseInformation.garantie.garantieBDG)
              }>
                {reponseInformation.garantie.garantieBDG}</span>
            </div>
            <div>
              <span className={classes.libelle}>Pourcentage : </span>
              <span>{reponseInformation.garantie.pourcentage}</span>
            </div>
            <div>
              <span className={classes.libelle}>Montant de la franchise : </span>
              <span>{reponseInformation.garantie.montantFranchise}</span>
            </div>
            <div>
              <span className={classes.libelle}>Type de franchise : </span>
              <span>{reponseInformation.garantie.typeFranchise}</span>
            </div>
            <div>
              <span className={classes.libelle}>Montant maximum : </span>
              <span>{reponseInformation.garantie.montantMax}</span>
            </div>
            <div>
              <span className={classes.libelle}>TVA récupérable : </span>
              <span>{reponseInformation.tvaRecuperable}</span>
            </div>
            <div>
              <span className={classes.libelle}>Montant minimum : </span>
              <span>{reponseInformation.garantie.montantMin}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseInformation;
