import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';
import CheckBoxField from '../../../multiStepsForm/fields/CheckBoxField';
import {FormikValues, useFormikContext} from 'formik';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  selectfield: {
    width: '360px',
  },
  switch: {
    display: 'flex',
    alignContent: 'center',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    marginTop: '8px',
  },
});

const SinistreBloc = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Sinistre</Typography>
      <div className={classes.flex}>
        <InputSelectDtField
          className={classes.selectfield}
          label='Circonstance du sinistre'
          name={'sinistre.circonstance'}
          mandatory={true}
          domain={'AR2'}
          typeDT={'92260101'}
          filters={['98', '99']}
        />
      </div>

      {values.sinistre.circonstance !== '01' &&
        <div>
          <div className={`${classes.switch} ${classes.marginTop24}`}>
            <CheckBoxField
              name='sinistre.degatsCarrosserie'
              checked={values.sinistre.degatsCarrosserie}
            />
            <Typography className={classes.libelle}>Il y a des dégâts sur la carrosserie</Typography>
          </div>
          <div className={`${classes.switch} ${classes.marginTop16}`}>
            <CheckBoxField
              name='sinistre.objetsVoles'
              checked={values.sinistre.objetsVoles}
            />
            <Typography className={classes.libelle}>Il y a des objets volés dans le véhicule</Typography>
          </div>
        </div>
      }
    </div>
  );
};

export default SinistreBloc;
