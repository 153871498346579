import React, {useEffect} from 'react';
import {useMissionContext} from '../../../../store/MissionContext';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import {DetailedBlocHistorique} from './DetailedBlocHistorique';
import {UserContext} from '../../../../store/UserContext';

interface ArchivedHistoriqueProps {
  setMessagesLength: (messagesLength?: number) => void,
}

export const ArchivedHistorique = (props: ArchivedHistoriqueProps) => {
  const {setMessagesLength} = props;
  const {mission} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const list = mission?.historiqueDTO?.list;
  const messages = list?.filter((message) => message.typeCode !== '900501' ? message : '');

  useEffect(() => {
    setMessagesLength(messages?.length);
  }, []);

  return (
    <div style={{marginTop: '20px'}}>
      {messages?.map( (item: ItemHistoriqueModel, index: number) => {
        return (
          <DetailedBlocHistorique
            id={`message-${index}`}
            key = {item.idDocument}
            libelle = {item.libelle}
            statut = {item.statut}
            etat = {item.etat}
            envoyeRecu={item.envoyeRecu}
            emetteur = {item.emetteur}
            destinataire = {item.destinataire}
            dateReception = {item.dateReception}
            dateEmission = {item.dateEmission}
            idDocument = {item.idDocument}
            codeService={user.service}
            ssType = {item.ssType}
            type = {item.type}
            typeCode = {item.typeCode}
            isnIdentifier = {item.isnIdentifier}
            archive = {item.archive}
            docVisualisable = {item.docVisualisable}
            nbPieceJointe = {item.nbPieceJointe}
            idPieceJointe = {item.idPieceJointe}
            click={() => ''}
            referenceSinistre = {mission?.infoMissionDTO?.referenceSinistre}
            isDossierSimple = {mission?.dossierSimple}
            userCodeAbonne={user.codeAbo}
            isAssistance={user.isAssistance}
          />
        )
      })}
    </div>
  )
};
