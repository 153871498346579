import React from 'react';
import {ButtonBlue} from '../../../../common/buttons/ButtonBlue';
import {RetourDossier} from '../../../../common/buttons/RetourDossier';
import {useNavigate} from 'react-router-dom';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {UserContext} from '../../../../../store/UserContext';

interface FooterReponseSIVProps {
  formulaire?: string,
  from: string | undefined,
  fromPath: string | undefined,
  token: string | undefined,
}

/** Design */
const styles = () => ({
  fermer: {
    margin: '48px 0px 15px',
    textTransform: 'none' as const,
  },
  buttons: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '15px',
  },
});

export const FooterReponseSIV = ({formulaire, from, fromPath, token}: FooterReponseSIVProps) => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const {user} = React.useContext(UserContext);
  const isLienTransparent = user.isTransparent;

  if (from === 'historique' || isLienTransparent) {
    /* Si on vient d'un message de l'historique ou d'un lien transparent */
    return (
      <div style={{textAlign: 'center'}}>
        <ButtonBlue id={'boutonFermer'} className={classes.fermer} libelle={'Fermer'} onClick={() => window.close()}/>
      </div>
    )
  } else if (from === 'formulaire') {
    return (
      <div className={classes.buttons}>
        {/* + si on vient d'un dossier */}
        {sessionStorage.getItem('mission') &&
          <RetourDossier isButton={true} token={token}/>
        }

        {/* + si on vient de la recherche dossier */}
        {fromPath === '/extranet/dossiers' &&
          <ButtonBlue
            id={'boutonRetour'}
            libelle={'Retour'}
            onClick={() => {
              sessionStorage.removeItem('tokenMission');
              navigate(`/extranet/dossiers/`);
            }}
          />
        }

        {/* + si on vient du portail OU si on ne vient pas d'un dossier et qu'on vient de la prise de rdv */}
        {(fromPath?.includes('/extranet/portail-sinapps-auto') ||
            (!sessionStorage.getItem('mission') && fromPath?.includes('/extranet/dossiers/prise-rdv'))) &&
          <ButtonBlue
            id={'boutonRetour'}
            libelle={'Retour sur le portail Sinapps Auto'}
            onClick={() => navigate('/extranet/portail-sinapps-auto')}
          />
        }

        {/* présent tout le temps */}
        <ButtonBlue
          id={'boutonNouvelleDemande'}
          libelle={'Nouvelle demande'}
          onClick={() => {
            sessionStorage.removeItem('tabSiv');
            sessionStorage.removeItem('tokenMission');
            window.location.reload();
          }}
        />

        {/* + si on vient de faire une DA */}
        {formulaire === 'DA' &&
          <ButtonBlue
            id={'boutonDC'}
            libelle={'Déclaration de cession'}
            onClick={() => {
              sessionStorage.setItem('tabSiv', 'DC');
              window.location.reload();
            }}
          />
        }
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
};
