import React from 'react'
import {FormControl, RadioGroup} from '@mui/material';
import {RadioBtn} from '../formsComponents/RadioBtn';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface PersonnePhysiqueMoraleProps {
  className?: string,
  name: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string,
}

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row' as const,
  },
});

export const PersonnePhysiqueMorale = ({className, name, formulaire, onChangeValue,
  value}: PersonnePhysiqueMoraleProps) => {
  const classes = useEmotionStyles(styles);
  return (
    <div className={className}>
      <FormControl id={'formControl' + formulaire} component="fieldset">
        <RadioGroup
          id={'radioGroup' + formulaire}
          name={name}
          className={classes.container}
          value={value}
          onChange={onChangeValue}
        >
          <RadioBtn
            id={'radioBtnpp' + formulaire}
            style={{marginRight: '30px'}}
            value={'pp'}
            label={'Cas d\'une personne physique'}
          />
          <RadioBtn
            id={'radioBtnpm' + formulaire}
            value={'pm'}
            label={'Cas d\'une personne morale'}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
};
