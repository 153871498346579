export interface IAction {
  id: string
  libelle: string
}

export interface IActionObject {
  [key: string]: IAction
}

export const ACTION: IActionObject = {
  DECONNEXION: {
    id: 'boutonDeconnexion',
    libelle: 'Déconnexion',
  },
  RETOUR_PORTAIL: {
    id: 'boutonRetourPortail',
    libelle: 'Retour sur le portail Sinapps Auto',
  },
};
