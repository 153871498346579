import {
  ISIVFormCTAccesTransparent,
  ISIVFormDAAccesTransparent,
  ISIVFormDCAccesTransparent,
  ISIVFormIRAccesTransparent,
  ISIVFormITAccesTransparent,
  ISIVFormSAAccesTransparent,
} from '../../../model/common/SIVFormModel';

export const mapperIT = (params: any): ISIVFormITAccesTransparent => {
  return {
    immatriculation: params['immatriculation'] || '',
    referenceSinistre: params['referenceSinistre'] || '',
    date: params['date'],
    heure: params['heure'],
  } as ISIVFormITAccesTransparent;
};

export const mapperSA = (params: any): ISIVFormSAAccesTransparent => {
  return {
    immatriculation: params['immatriculation'] || '',
    referenceSinistre: params['referenceSinistre'] || '',
    date: params['date'],
    heure: params['heure'],
  } as ISIVFormSAAccesTransparent;
};

export const mapperCT = (params: any): ISIVFormCTAccesTransparent => {
  return {
    immatriculation: params['immatriculation'] || '',
    policeAssurance: params['policeAssurance'] || '',
    referenceSinistre: params['referenceSinistre'] || '',
    typePersonne: params['typePersonne'] || '',
    nom: params['nom'] || '',
    nomUsage: params['nomUsage'] || '',
    prenom: params['prenom'] || '',
    societeCommerciale: params['societeCommerciale'] ? JSON.parse(params['societeCommerciale']) : false,
    raisonSociale: params['raisonSociale'] || '',
    siren: params['siren'] || '',
    siret: params['siret'] || '',
  } as ISIVFormCTAccesTransparent;
};

export const mapperDA = (params: any): ISIVFormDAAccesTransparent => {
  return {
    numeroMission: params['numeroMission'],
    referenceSinistre: params['referenceSinistre'] || '',
    immatriculation: params['immatriculation'] || '',
    titulaireActuel: {
      typePersonne: params['typePersonne'] || '',
      identificationPP: params['identificationPP'],
      identificationPM: params['identificationPM'],
      siren: params['sirenTA'],
      siret: params['siretTA'],
    },
    titulaireNouveau: {
      raisonSociale: params['raisonSocialeTN'],
      siren: params['sirenTN'],
      siret: params['siretTN'],
      adresse: {
        numero: params['numero'],
        indice: params['indice'],
        type: params['typeVoie'],
        nom: params['nomVoie'],
        lieuDit: params['lieuDit'],
        etage: params['etage'],
        immeuble: params['immeuble'],
        codePostal: params['codePostal'],
        boitePostale: params['boitePostale'],
        ville: params['ville'],
        pays: params['pays'],
      },
    },
    numeroVin: params['numeroVin'],
    presenceCertificat: params['presenceCertificat'] ? JSON.parse(params['presenceCertificat']) : false,
    numeroFormule: params['numeroFormule'],
    dateCertificat: params['dateCertificat'],
    dateAchat: params['dateAchat'],
    heureAchat: params['heureAchat'],
    dateDeclaration: params['dateDeclaration'],
    heureDeclaration: params['heureDeclaration'],
    ve: params['ve'] ? JSON.parse(params['ve']) : false,
    vei: params['vei'] ? JSON.parse(params['vei']) : false,
  } as ISIVFormDAAccesTransparent;
};

export const mapperDC = (params: any): ISIVFormDCAccesTransparent => {
  return {
    numeroMission: params['numeroMission'],
    referenceSinistre: params['referenceSinistre'] || '',
    immatriculation: params['immatriculation'] || '',
    titulaireActuel: {
      identificationPM: params['identificationPM'],
      siren: params['sirenTA'],
      siret: params['siretTA'],
    },
    titulaireNouveau: {
      typePersonne: params['typePersonne'],
      nom: params['nom'],
      nomUsage: params['nomUsage'],
      prenom: params['prenom'],
      sexe: params['sexe'],
      societeCommerciale: params['societeCommerciale'] ? JSON.parse(params['societeCommerciale']) : false,
      raisonSociale: params['raisonSocialeTN'],
      siren: params['sirenTN'],
      siret: params['siretTN'],
      adresse: {
        numero: params['numero'],
        indice: params['indice'],
        type: params['typeVoie'],
        nom: params['nomVoie'],
        lieuDit: params['lieuDit'],
        etage: params['etage'],
        immeuble: params['immeuble'],
        codePostal: params['codePostal'],
        boitePostale: params['boitePostale'],
        ville: params['ville'],
        pays: params['pays'],
      },
    },
    numeroVin: params['numeroVin'],
    presenceCertificat: params['presenceCertificat'] ? JSON.parse(params['presenceCertificat']) : false,
    numeroFormule: params['numeroFormule'],
    dateCertificat: params['dateCertificat'],
    dateCession: params['dateCession'],
    heureCession: params['heureCession'],
    dateDeclaration: params['dateDeclaration'],
    heureDeclaration: params['heureDeclaration'],
  } as ISIVFormDCAccesTransparent;
};

export const mapperIR = (params: any): ISIVFormIRAccesTransparent => {
  return {
    referenceSinistre: params['referenceSinistre'] || '',
    immatriculation: params['immatriculation'] || '',
    dateEvenement: params['dateEvenement'],
    typePersonne: params['typePersonne'],
    identificationPP: params['identificationPP'],
    identificationPM: params['identificationPM'],
    numeroFormule: params['numeroFormule'],
    dateCertificat: params['dateCertificat'],
    dateDeclaration: params['dateDeclaration'],
    heureDeclaration: params['heureDeclaration'],
  } as ISIVFormIRAccesTransparent;
};
