import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  AppBar,
  Box,
  Card,
  CardContent,
  FormLabel,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {BlocCommentaire} from './BlocCommentaire';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useMissionContext} from '../../../../store/MissionContext';
import moment from 'moment';
import {ICommentaire} from '../../../../model/detailsDossier/ItemCommentaireModel';
import {ScrollTop} from '../../../common/ScrollTop';
import {getMaxDate, getMinDate} from '../../../../common/utils/DateUtils';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

export interface PanelProps {
  index?: number,
  value?: number,
  children: React.ReactElement
}

interface CommentairesProps {
  itemSelected: string,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
  moreComments: {
    textAlign: 'center' as const,
  },
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'none',
  },
  tabs: {
    padding: '0rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    fontSize: '1rem',
  },
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
  main: {
    lineHeight: '55px',
  },
  containerPrincipal: {
    backgroundColor: theme.palette.white.main,
    border: '1px solid #DBDBDB',
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
    margin: '30px 0px',
  },
  search: {
    paddingBottom: '10px',
    color: theme.palette.secondary.dark2,
  },
  textField: {
    minWidth: '300px',
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    borderRadius: '4px',
  },
  labelDate: {
    marginLeft: '10px',
    border: 'none',
    verticalAlign: 'middle',
  },
  inputDate: {
    'backgroundColor': theme.palette.white.main,
    '&:before': {
      border: 'none',
    },
  },
  noComment: {
    textAlign: 'center' as const,
  },
  iconRoot: {
    margin: '0px',
  },
  tab: {
    '&.MuiTab-root': {
      'textTransform': 'none' as const,
      'textAlign': 'left' as const,
      'font': 'normal normal bold 16px/21px Helvetica',
      'letterSpacing': '0px',
      'opacity': '1',
      'minHeight': '48px',
      'color': theme.palette.link.main,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: 'inherit',
      },
      '&:disabled': {
        color: theme.palette.disabled.main,
      },
    },
    '&.Mui-selected': {},
  },
});

const getItemSelected = (commentaire: ICommentaire) => {
  const itemSelected = commentaire.date + commentaire.typeDoc;

  if (commentaire.envoyeRecu === 'recu') {
    return itemSelected + commentaire.emetteurCodeAbonne;
  } else if (commentaire.envoyeRecu === 'envoye') {
    return itemSelected + commentaire.destinataireCodeAbonne;
  } else {
    return itemSelected + commentaire.emetteurCodeAbonne + commentaire.destinataireCodeAbonne;
  }
};

const Content = (props: {commentairesList: ICommentaire[], itemSelected: string}) => {
  const {commentairesList, itemSelected} = props;
  let i = -1;
  return (
    <React.Fragment>
      {commentairesList?.map((item: ICommentaire) => {
        i++;
        return (
          <BlocCommentaire
            key={i}
            index={i}
            itemSelected={itemSelected === getItemSelected(item)}
            commentaire={item}
          />
        )
      })}
    </React.Fragment>
  )
};

const NoMessage = (props: {commentairesList: ICommentaire[]}) => {
  const {commentairesList} = props;
  const classes = useEmotionStyles(styles);
  return commentairesList?.length === 0 ?
    <div className={classes.noComment}><Typography>Aucun résultat</Typography></div> : <></>
};

const TabPanel= (props: PanelProps) => {
  const {value, index, children} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
};

export const Commentaires = ({itemSelected} : CommentairesProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const comments = mission?.commentairesDTO?.commentaires ?? [];
  const [commentairesLength, setCommentairesLength] = useState(comments.length);
  const [value, setValue] = React.useState(0);
  const [acteur, setActeur] = React.useState('');
  const [commentsToDisplay, setCommentsToDisplay] = useState<ICommentaire[]>(comments);
  const commentsSent = commentsToDisplay?.filter((comment) => comment.envoyeRecu === 'envoye' ? comment : '');
  const commentsReceived = commentsToDisplay?.filter((comment) => comment.envoyeRecu === 'recu' ? comment : '');

  const acteurs: string[] = [];
  comments?.map((comment) => {
    acteurs.push(comment.emetteur)
    acteurs.push(comment.destinataire)
  });
  const listActeurs = Array.from(new Set([...acteurs]));

  const itemsListActeurs = listActeurs.map((acteur) => {
    return (
      <MenuItem key={acteur} value={acteur}>{acteur}</MenuItem>
    )
  });

  useEffect(() => {
    if (value === 0) {
      setCommentairesLength(commentsToDisplay.length)
    } else if (value === 1) {
      setCommentairesLength(commentsSent.length)
    } else {
      setCommentairesLength(commentsReceived.length)
    }
  }, [value, commentsToDisplay]);

  useEffect(() => {
    const selectedElement = document.getElementById(itemSelected);
    if (selectedElement) {
      selectedElement.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [itemSelected]);

  useEffect(() => {
    comments.slice().sort(function(a, b) {
      const dateA = moment(a.date.replace(' - ', ' ' ), 'DD/MM/YYYY HH:mm:ss').toDate();
      const dateB = moment(b.date.replace(' - ', ' ' ), 'DD/MM/YYYY HH:mm:ss').toDate();
      return dateB > dateA ? 1 : -1
    })
  }, []);

  const minDate = () => {
    const dates = comments.map((item) => {
      return moment(item.date.substring(0, 10), 'DD/MM/YYYY').toDate()
    });
    const min = getMinDate(dates);
    return moment(min).format( 'YYYY-MM-DD')
  };

  const maxDate = () => {
    const dates = comments.map((item) => {
      return moment(item.date.substring(0, 10), 'DD/MM/YYYY').toDate()
    });
    const max = getMaxDate(dates);
    return moment(max).format( 'YYYY-MM-DD')
  };

  const [dateDebut, setDateDebut] = React.useState(minDate());
  const [dateFin, setDateFin] = React.useState(maxDate());

  const handleChange = (event: ChangeEvent<{}>, value: number) => setValue(value);

  const changeDateDebut = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setDateDebut(event.target.value)
  };

  const changeDateFin = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setDateFin(event.target.value)
  };

  const changeActeur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActeur(event.target.value)
  };

  const resetFilters = () => {
    setActeur('');
    setDateDebut(minDate());
    setDateFin(maxDate());
    setCommentsToDisplay(comments)
  };

  const changeFilters = () => {
    let results = comments;

    if (acteur!=='') {
      results = results?.filter((comment) => {
        if (comment.emetteur === acteur || comment.destinataire === acteur) {
          return comment;
        } else {
          return '';
        }
      }) ?? [];
      setCommentsToDisplay(results);
    } else {
      setCommentsToDisplay(comments)
    }

    if (dateDebut!=='') {
      results = results?.filter((comment) => moment(comment.date.substring(0, 10),
          'DD/MM/YYYY').toDate() >= moment(dateDebut).toDate() ? comment : '') ?? [];
      setCommentsToDisplay(results)
    }

    if (dateFin!=='') {
      results = results?.filter((comment) => moment(comment.date.substring(0, 10),
          'DD/MM/YYYY').toDate() <= moment(dateFin).toDate() ? comment : '') ?? [];
      setCommentsToDisplay(results)
    }
  };

  useEffect(() => {
    changeFilters()
  }, [acteur, dateDebut, dateFin]);

  const getTitle = () => {
    if (commentairesLength && commentairesLength > 1) {
      return 'Commentaires'
    } else {
      return 'Commentaire'
    }
  };

  const a11yProps = (index : number) =>{
    return {
      'id': `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div>
          <Typography variant={'h3'}>{getTitle()} ({commentairesLength})</Typography>
        </div>
        <AppBar position="static" className={classes.appbar}>
          <Tabs
            indicatorColor="primary" textColor="inherit"
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            TabIndicatorProps={{style: {backgroundColor: '#241E46', height: '3px'}}}
          >
            <Tab
              label="Tous les messages" {...a11yProps(0)} className={classes.tab}/>
            <Tab
              label="Envoyés" {...a11yProps(1)} disabled={commentsSent.length === 0} className={classes.tab}/>
            <Tab
              label="Reçus" {...a11yProps(2)} disabled={commentsReceived.length === 0} className={classes.tab}/>
          </Tabs>
        </AppBar>
        <div className={classes.main}>
          <form noValidate autoComplete="off">
            <div className={classes.containerPrincipal}>
              <Typography className={classes.search}>Rechercher un commentaire</Typography>
              <TextFieldSelect
                id={'acteur'}
                name={'acteur'}
                label="Acteur"
                className={classes.textField}
                marginDense={true}
                value={listActeurs.length !== 1 ? acteur : listActeurs[0]}
                withEmptyItem={true}
                emptyItemLabel={'Tous'}
                disabled={listActeurs.length === 1}
                itemsList={itemsListActeurs}
                onChange={changeActeur}
              />
              <span style={{display: 'inline-block'}}>
                <FormLabel className={classes.labelDate}>Depuis le </FormLabel>
                <TextField
                  id="dateDebut"
                  label=""
                  type="date"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={dateDebut}
                  placeholder="Saisir une date"
                  className={classes.dateField}
                  InputProps={{
                    classes: {
                      root: classes.inputDate,
                    },
                  }}
                  inputProps={minDate() !== maxDate() ? {
                    min: minDate(),
                    max: maxDate(),
                  } : {}}
                  disabled={minDate() === maxDate()}
                  onChange={changeDateDebut}
                />
              </span>
              <span style={{display: 'inline-block'}}>
                <FormLabel className={classes.labelDate}>Jusqu&apos;au </FormLabel>
                <TextField
                  id="dateFin"
                  label=""
                  type="date"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={dateFin}
                  placeholder="Saisir une date"
                  className={classes.dateField}
                  InputProps={{
                    classes: {
                      root: classes.inputDate,
                    },
                  }}
                  inputProps={minDate() !== maxDate() ? {
                    min: minDate(),
                    max: maxDate(),
                  } : {}}
                  disabled={minDate() === maxDate()}
                  onChange={changeDateFin}
                />
              </span>
              <span style={{display: 'inline-block'}}>
                <Tooltip disableInteractive title="Réinitialiser la recherche">
                  <IconButton onClick={() => resetFilters()}>
                    <RefreshIcon classes={{root: classes.iconRoot}}/>
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          </form>
        </div>
        <TabPanel value={value} index={0}>
          <React.Fragment>
            <NoMessage commentairesList={commentsToDisplay}/>
            <Content commentairesList={commentsToDisplay} itemSelected={itemSelected}/>
          </React.Fragment>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <React.Fragment>
            <NoMessage commentairesList={commentsSent}/>
            <Content commentairesList={commentsSent} itemSelected={itemSelected}/>
          </React.Fragment>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <React.Fragment>
            <NoMessage commentairesList={commentsReceived}/>
            <Content commentairesList={commentsReceived} itemSelected={itemSelected}/>
          </React.Fragment>
        </TabPanel>
        <ScrollTop/>
      </CardContent>
    </Card>
  )
};
