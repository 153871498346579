import React, {useEffect, useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {RefSinistre} from '../../../common/RefSinistre';
import {NumMission} from '../../../common/NumMission';
import {DialogContent, IconButton, MenuItem, Modal, Typography} from '@mui/material';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {Siren} from '../../../common/personne/Siren';
import {Siret} from '../../../common/personne/Siret';
import {RaisonSociale} from '../../../common/personne/RaisonSociale';
import {Nom} from '../../../common/personne/Nom';
import {Prenom} from '../../../common/personne/Prenom';
import {SocieteCommerciale} from '../../../common/personne/SocieteCommerciale';
import {Adresse} from '../../../common/adresse/Adresse';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import {InputDate} from '../../../common/InputDate';
import {Hour} from '../../../common/Hour';
import {Sexe} from '../../../common/personne/Sexe';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment';
import {ISIVFormDCAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {postSIVForm, getPreremplissageDADC} from '../../../../api/siv';
import {Loader} from '../../../common/Loader';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {TokenModel} from '../../../../model/common/TokenModel';
import {UserContext} from '../../../../store/UserContext';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import SearchPersonneMorale, {Row} from '../../../common/personne/SearchPersonneMorale';
import {customTheme} from '../../../../common/GlobalTheme';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperTextWithIcon} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaDC} from './validationSchemaDC';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    marginBottom: '16px',
  },
  otherCardContent: {
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  loaderPreremplir: {
    display: 'block',
    marginTop: '32px',
    marginLeft: '40px',
  },
  btnPreremplir: {
    marginTop: '32px',
  },
  remplissageOK: {
    color: theme.palette.green.main,
    marginTop: '8px',
  },
  remplissageKO: {
    color: 'red',
    marginTop: '8px',
  },
  containerSearchIcon: {
    marginTop: '24px',
  },
  searchIcon: {
    color: theme.palette.link.main,
    marginRight: '0px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  marginTop: {
    marginTop: '24px',
  },
  dateHeure: {
    marginRight: '48px',
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FormDC = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormDCAccesTransparent;
  const {user} = React.useContext(UserContext);
  const tokenFromDA = sessionStorage.getItem('tokenMission');
  const tokenDA = tokenFromDA ? JSON.parse(tokenFromDA) : {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [numMissionsList, setNumMissionsList] = useState<string[]>([]);
  const [showSelectMissions, setShowSelectMissions] = useState<boolean>(false);
  const [showSearchCorporation, setShowSearchCorpoartion] = useState<boolean>(false);
  const [showPreremplir, setShowPreremplir] = useState<boolean>(true);
  const [showPreremplirOK, setShowPreremplirOK] = useState<boolean>(false);
  const [showPreremplirKO, setShowPreremplirKO] = useState<boolean>(false);
  const [isLoadingRemplissage, setIsLoadingRemplissage] = useState<boolean>(false);

  useEffect(() => {
    setShowSelectMissions(numMissionsList && numMissionsList.length > 1)
  }, [numMissionsList]);
  const initialValues = {
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim(), tokenDA?.referenceSinistre]),
    numeroMission: getInitialValueSIV([data?.numeroMission, mission?.infoMissionDTO?.numeroMission?.trim()]),
    titulaireActuel: {
      identificationPM: getInitialValueSIV([user.raisonSocialeInfogeo, data?.titulaireActuel?.identificationPM]),
      siren: getInitialValueSIV([user.siren, data?.titulaireActuel?.siren]),
      siret: getInitialValueSIV([user.siret, data?.titulaireActuel?.siret]),
    },
    titulaireNouveau: {
      typePersonne: getInitialValueSIV(['pp', data?.titulaireNouveau?.typePersonne]),
      nom: getInitialValueSIV([data?.titulaireNouveau?.nom]),
      nomUsage: getInitialValueSIV([data?.titulaireNouveau?.nomUsage]),
      prenom: getInitialValueSIV([data?.titulaireNouveau?.prenom]),
      sexe: getInitialValueSIV([data?.titulaireNouveau?.sexe]),
      societeCommerciale: data?.titulaireNouveau?.societeCommerciale || false,
      raisonSociale: getInitialValueSIV([data?.titulaireNouveau?.raisonSociale]),
      siren: getInitialValueSIV([data?.titulaireNouveau?.siren]),
      siret: getInitialValueSIV([data?.titulaireNouveau?.siret]),
      adresse: {
        numero: getInitialValueSIV([data?.titulaireNouveau?.adresse?.numero]),
        indice: getInitialValueSIV([data?.titulaireNouveau?.adresse?.indice]),
        type: getInitialValueSIV([data?.titulaireNouveau?.adresse?.type]),
        nom: getInitialValueSIV([data?.titulaireNouveau?.adresse?.nom]),
        lieuDit: getInitialValueSIV([data?.titulaireNouveau?.adresse?.lieuDit]),
        etage: getInitialValueSIV([data?.titulaireNouveau?.adresse?.etage]),
        immeuble: getInitialValueSIV([data?.titulaireNouveau?.adresse?.immeuble]),
        codePostal: getInitialValueSIV([data?.titulaireNouveau?.adresse?.codePostal]),
        boitePostale: getInitialValueSIV([data?.titulaireNouveau?.adresse?.boitePostale]),
        ville: getInitialValueSIV([data?.titulaireNouveau?.adresse?.ville]),
        pays: getInitialValueSIV(['FRANCE', data?.titulaireNouveau?.adresse?.pays]),
      },
    },
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation]),
    numeroVin: getInitialValueSIV([data?.numeroVin]),
    presenceCertificat: data?.presenceCertificat || true,
    numeroFormule: getInitialValueSIV([data?.numeroFormule]),
    dateCertificat: getInitialValueSIV([data?.dateCertificat]),
    dateCession: getInitialValueSIV([data?.dateCession]),
    heureCession: getInitialValueSIV([data?.heureCession]),
    dateDeclaration: getInitialValueSIV([moment().format('YYYY-MM-DD'), data?.dateDeclaration]),
    heureDeclaration: getInitialValueSIV([moment().format('HH:mm'), data?.heureDeclaration]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'DC', referenceSinistre: formik.values.referenceSinistre,
      numMissionsList: [formik.values.numeroMission], titulaireActuel: {isPersonnePhysique: false,
        identificationPersonne: formik.values.titulaireActuel.identificationPM,
        siren: formik.values.titulaireActuel.siren, siret: formik.values.titulaireActuel.siret},
      nouveauTitulaire: {isPersonnePhysique: formik.values.titulaireNouveau.typePersonne === 'pp',
        nom: formik.values.titulaireNouveau.nom, nomUsage: formik.values.titulaireNouveau.nomUsage,
        prenom: formik.values.titulaireNouveau.prenom, sexe: formik.values.titulaireNouveau.sexe,
        isSocieteCommerciale: formik.values.titulaireNouveau.societeCommerciale,
        identificationPersonne: formik.values.titulaireNouveau.raisonSociale,
        siren: formik.values.titulaireNouveau.siren, siret: formik.values.titulaireNouveau.siret,
        nomVoie: formik.values.titulaireNouveau.adresse.nom, numeroVoie: formik.values.titulaireNouveau.adresse.numero,
        type: formik.values.titulaireNouveau.adresse.type, extension: formik.values.titulaireNouveau.adresse.indice,
        lieuxDit: formik.values.titulaireNouveau.adresse.lieuDit, etage: formik.values.titulaireNouveau.adresse.etage,
        immeuble: formik.values.titulaireNouveau.adresse.immeuble,
        codePostal: formik.values.titulaireNouveau.adresse.codePostal,
        boitePostale: formik.values.titulaireNouveau.adresse.boitePostale,
        localite: formik.values.titulaireNouveau.adresse.ville, pays: formik.values.titulaireNouveau.adresse.pays},
      vehicule: {numeroImmatriculation: formik.values.immatriculation, numeroVin: formik.values.numeroVin,
        isPresenceCertificat: formik.values.presenceCertificat, numeroFormule: formik.values.numeroFormule,
        dateImmaticulation: formik.values.dateCertificat ? dateToDateText(new Date(formik.values.dateCertificat)) : ''},
      infoCession: {dateCession: dateToDateText(new Date(formik.values.dateCession)),
        heureCession: formik.values.heureCession,
        dateDeclarationCession: dateToDateText(new Date(formik.values.dateDeclaration)),
        heureDeclarationCession: formik.values.heureDeclaration}};
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string, entity?: string) => () => {
    if (entity && entity !== '') {
      formik.setFieldTouched(`${entity}.${field}`, false, false)
    } else {
      formik.setFieldTouched(field, false, false)
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaDC,
    onSubmit: onSubmit,
  });

  const onInputReferenceSinistre = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCanSearch(
        e.target.value.length >= 6 && checkRefSinFormat(e.target.value) && checkRefSinSpecialChar(e.target.value),
    );
    setShowSelectMissions(false);
  };

  const onChangeNumeroMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setCanSearch(true)
    } else {
      setCanSearch(false)
    }
  };

  const disabled = !!(formik.touched.referenceSinistre && formik.errors.referenceSinistre) ||
    !!(formik.touched.numeroMission && formik.errors.numeroMission) ||
    !!(formik.touched.titulaireActuel?.identificationPM && formik.errors.titulaireActuel?.identificationPM) ||
    !!(formik.touched.titulaireActuel?.siren && formik.errors.titulaireActuel?.siren) ||
    !!(formik.touched.titulaireActuel?.siret && formik.errors.titulaireActuel?.siret) ||
    !!(formik.touched.titulaireNouveau?.nom && formik.errors.titulaireNouveau?.nom) ||
    !!(formik.touched.titulaireNouveau?.nomUsage && formik.errors.titulaireNouveau?.nomUsage) ||
    !!(formik.touched.titulaireNouveau?.prenom && formik.errors.titulaireNouveau?.prenom) ||
    !!(formik.touched.titulaireNouveau?.sexe && formik.errors.titulaireNouveau?.sexe) ||
    !!(formik.touched.titulaireNouveau?.raisonSociale && formik.errors.titulaireNouveau?.raisonSociale) ||
    !!(formik.touched.titulaireNouveau?.siren && formik.errors.titulaireNouveau?.siren) ||
    !!(formik.touched.titulaireNouveau?.siret && formik.errors.titulaireNouveau?.siret) ||
    !!(formik.touched.titulaireNouveau?.adresse?.numero && formik.errors.titulaireNouveau?.adresse?.numero) ||
    !!(formik.touched.titulaireNouveau?.adresse?.indice && formik.errors.titulaireNouveau?.adresse?.indice) ||
    !!(formik.touched.titulaireNouveau?.adresse?.type && formik.errors.titulaireNouveau?.adresse?.type) ||
    !!(formik.touched.titulaireNouveau?.adresse?.nom && formik.errors.titulaireNouveau?.adresse?.nom) ||
    !!(formik.touched.titulaireNouveau?.adresse?.lieuDit && formik.errors.titulaireNouveau?.adresse?.lieuDit) ||
    !!(formik.touched.titulaireNouveau?.adresse?.etage && formik.errors.titulaireNouveau?.adresse?.etage) ||
    !!(formik.touched.titulaireNouveau?.adresse?.immeuble && formik.errors.titulaireNouveau?.adresse?.immeuble) ||
    !!(formik.touched.titulaireNouveau?.adresse?.codePostal && formik.errors.titulaireNouveau?.adresse?.codePostal) ||
    !!(formik.touched.titulaireNouveau?.adresse?.boitePostale &&
      formik.errors.titulaireNouveau?.adresse?.boitePostale) ||
    !!(formik.touched.titulaireNouveau?.adresse?.ville && formik.errors.titulaireNouveau?.adresse?.ville) ||
    !!(formik.touched.titulaireNouveau?.adresse?.pays && formik.errors.titulaireNouveau?.adresse?.pays) ||
    !!(formik.touched.immatriculation && formik.errors.immatriculation) ||
    !!(formik.touched.numeroVin && formik.errors.numeroVin) ||
    !!(formik.touched.numeroFormule && formik.errors.numeroFormule) ||
    !!(formik.touched.dateCertificat && formik.errors.dateCertificat) ||
    !!(formik.touched.dateCession && formik.errors.dateCession) ||
    !!(formik.touched.heureCession && formik.errors.heureCession) ||
    !!(formik.touched.dateDeclaration && formik.errors.dateDeclaration) ||
    !!(formik.touched.heureDeclaration && formik.errors.heureDeclaration);

  const preremplir = () => {
    const tokenFromDA = sessionStorage.getItem('tokenMission') ?
        JSON.parse(sessionStorage.getItem('tokenMission')!) : '';
    const tokenMission: TokenModel = {
      referenceSinistre: formik.values.referenceSinistre,
      numeroMission: formik.values.numeroMission,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    if (tokenMission || tokenFromDA !== '') {
      setIsLoadingRemplissage(true);
      const tokenToSend = tokenFromDA !== '' ? tokenFromDA : tokenMission;
      const isFromDA = tokenFromDA !== '';
      getPreremplissageDADC(tokenToSend, 'C', isFromDA).then((res) => {
        const numMissionsList = res.numMissionsList;
        if (numMissionsList && numMissionsList.length > 1) {
          setNumMissionsList(numMissionsList);
          setCanSearch(false)
        } else {
          formik.values.titulaireActuel.identificationPM = res.titulaireActuel.identificationPersonne || '';
          formik.values.titulaireActuel.siren = res.titulaireActuel.siren || '';
          formik.values.titulaireActuel.siret = res.titulaireActuel.siret || '';

          formik.values.titulaireNouveau.typePersonne = 'pm';
          formik.values.titulaireNouveau.societeCommerciale = true;
          formik.values.titulaireNouveau.raisonSociale = res.nouveauTitulaire.identificationPersonne || '';
          formik.values.titulaireNouveau.siren = res.nouveauTitulaire.siren || '';
          formik.values.titulaireNouveau.siret = res.nouveauTitulaire.siret || '';
          formik.values.titulaireNouveau.adresse.numero = res.nouveauTitulaire.numeroVoie || '';
          formik.values.titulaireNouveau.adresse.indice = res.nouveauTitulaire.extension || '';
          formik.values.titulaireNouveau.adresse.type = res.nouveauTitulaire.type || '';
          formik.values.titulaireNouveau.adresse.nom = res.nouveauTitulaire.nomVoie || '';
          formik.values.titulaireNouveau.adresse.lieuDit = res.nouveauTitulaire.lieuxDit || '';
          formik.values.titulaireNouveau.adresse.etage = res.nouveauTitulaire.etage || '';
          formik.values.titulaireNouveau.adresse.immeuble = res.nouveauTitulaire.immeuble || '';
          formik.values.titulaireNouveau.adresse.codePostal = res.nouveauTitulaire.codePostal || '';
          formik.values.titulaireNouveau.adresse.boitePostale = res.nouveauTitulaire.boitePostale || '';
          formik.values.titulaireNouveau.adresse.ville = res.nouveauTitulaire.localite || '';

          formik.values.immatriculation = res.vehicule.numeroImmatriculation || '';
          formik.values.numeroVin = res.vehicule.numeroVin || '';
          formik.values.presenceCertificat = res.vehicule.isPresenceCertificat || false;
          formik.values.numeroFormule = res.vehicule.numeroFormule || '';
          formik.values.dateCertificat = res.vehicule.dateImmaticulation || '';

          formik.values.dateCession = res.infoCession.dateCession || '';
          formik.values.heureCession = res.infoCession.heureCession || '';
          setShowPreremplirOK(true)
        }
        setIsLoadingRemplissage(false);
      }).catch(() => {
        setShowPreremplirKO(true);
        setIsLoadingRemplissage(false)
      })
    }
  };

  const handleSelectPersonneMorale = (row: Row) => {
    formik.values.titulaireNouveau.raisonSociale = row.raisonSociale;
    formik.values.titulaireNouveau.siren = row.siren;
    formik.values.titulaireNouveau.siret = row.siret;
    formik.values.titulaireNouveau.adresse.numero = row.numeroVoie || '';
    formik.values.titulaireNouveau.adresse.indice = row.indiceRepetition || '';
    formik.values.titulaireNouveau.adresse.type = row.typeVoie || '';
    formik.values.titulaireNouveau.adresse.nom = row.libelleVoie || '';
    formik.values.titulaireNouveau.adresse.etage = row.complementAdresse || '';
    formik.values.titulaireNouveau.adresse.codePostal = row.codePostal || '';
    formik.values.titulaireNouveau.adresse.ville = row.localite || '';
  };

  useEffect(() => {
    if (user.isTransparent) {
      setShowPreremplir(false)
    }
    if (formik.values.referenceSinistre?.length >= 6 && checkRefSinFormat(formik.values.referenceSinistre) &&
      checkRefSinSpecialChar(formik.values.referenceSinistre)) {
      if (!user.isTransparent) {
        preremplir();
      }
      setShowPreremplir(false)
    }
  }, []);

  return (
    <div onClick={()=>{
      setShowPreremplirKO(false);
      setShowPreremplirOK(false)
    }}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'DC'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            onInput={(e) => onInputReferenceSinistre(e)}
            isMandatory={true}
            value={formik.values.referenceSinistre}
            onError={(formik.touched.referenceSinistre && !!formik.errors.referenceSinistre) || false}
            helperText={formik.touched.referenceSinistre && formik.errors.referenceSinistre ?
              formik.errors.referenceSinistre : ''
            }
          />

          {showSelectMissions &&
            <div>
              <Typography style={{color: customTheme.palette.secondary.dark2}}>
                N° de mission <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textfield}
                id={'numMissionsDC'}
                name={'numeroMission'}
                marginDense={true}
                value={formik.values.numeroMission}
                onChange={(e) => {
                  formik.handleChange(e);
                  onChangeNumeroMission(e)
                }}
                itemsList={numMissionsList.map((mission) =>
                  <MenuItem key={mission} value={mission}>{mission}</MenuItem>,
                )}
                onBlur={formik.handleBlur}
                onFocus={onFocus('numeroMission')}
                onError={(formik.touched.numeroMission && !!formik.errors.numeroMission) || false}
                helperText={formik.touched.numeroMission && formik.errors.numeroMission ?
                  formik.errors.numeroMission : ''}
              />
              {showSelectMissions && !formik.values.numeroMission &&
                <div className={classes.remplissageKO}>
                  Sélectionner un N° de mission.
                </div>
              }
            </div>
          }

          {!showSelectMissions &&
            <NumMission
              className={classes.textfield}
              name={'numeroMission'}
              formulaire={'DC'}
              value={formik.values.numeroMission}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('numeroMission')}
              onError={(formik.touched.numeroMission && !!formik.errors.numeroMission) || false}
              helperText={formik.touched.numeroMission && formik.errors.numeroMission ?
                formik.errors.numeroMission : ''}
            />
          }

          {showPreremplir &&
            <div>
              {isLoadingRemplissage ?
                <Loader className={classes.loaderPreremplir} size={30}/> :
                <ButtonBlue
                  className={classes.btnPreremplir}
                  id={'boutonPreremplirDC'}
                  libelle={'Préremplir'}
                  disabled={!canSearch}
                  onClick={() => preremplir()}
                />
              }
            </div>
          }
        </div>

        {showPreremplirOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }
        {showPreremplirKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assureur</Typography>
          <div className={classes.flex}>
            <IdentificationPersonne
              className={classes.textfield}
              name={'titulaireActuel.identificationPM'}
              formulaire={'DC'}
              value={formik.values.titulaireActuel.identificationPM}
              typePersonne={'pm'}
              onBlur={formik.handleBlur}
              onFocus={onFocus('identificationPM', 'titulaireActuel')}
              onChangeValue={formik.handleChange}
              onError={(formik.touched.titulaireActuel?.identificationPM &&
                !!formik.errors.titulaireActuel?.identificationPM) || false
              }
              helperText={formik.touched.titulaireActuel?.identificationPM && formik.errors.titulaireActuel &&
                formik.errors.titulaireActuel.identificationPM ? formik.errors.titulaireActuel.identificationPM : ''
              }
            />
            <Siren
              className={classes.textfield}
              id={'sirenTitulaire'}
              name={'titulaireActuel.siren'}
              formulaire={'DC'}
              value={formik.values.titulaireActuel.siren}
              isMandatory={false}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('siren', 'titulaireActuel')}
              onError={(formik.touched.titulaireActuel?.siren && !!formik.errors.titulaireActuel?.siren) || false}
              helperText={helperTextWithIcon(formik, 'siren', 'titulaireActuel', classes.helpIcon)}
            />
            <Siret
              className={classes.textfield}
              id={'siretTitulaire'}
              name={'titulaireActuel.siret'}
              formulaire={'DC'}
              value={formik.values.titulaireActuel.siret}
              isMandatory={true}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('siren', 'titulaireActuel')}
              onError={(formik.touched.titulaireActuel?.siret && !!formik.errors.titulaireActuel?.siret) || false}
              helperText={helperTextWithIcon(formik, 'siret', 'titulaireActuel', classes.helpIcon)}
            />
          </div>
        </div>
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Identité du nouveau titulaire</Typography>
        <PersonnePhysiqueMorale
          name={'titulaireNouveau.typePersonne'}
          formulaire={'DC'}
          onChangeValue={formik.handleChange}
          value={formik.values.titulaireNouveau.typePersonne}
        />

        {formik.values.titulaireNouveau.typePersonne === 'pp' &&
          <>
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <Nom
                className={classes.textfield}
                name={'titulaireNouveau.nom'}
                value={formik.values.titulaireNouveau.nom}
                fullWidth={true}
                formulaire={'DC'}
                nomUsage={false}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('nom', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.nom && !!formik.errors.titulaireNouveau?.nom) || false}
                helperText={formik.touched.titulaireNouveau?.nom && formik.errors.titulaireNouveau?.nom ?
                formik.errors.titulaireNouveau.nom : ''
                }
                maxLength={80}
              />
              <Nom
                className={classes.textfield}
                name={'titulaireNouveau.nomUsage'}
                value={formik.values.titulaireNouveau.nomUsage}
                fullWidth={true}
                formulaire={'DC'}
                nomUsage={true}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('nomUsage', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.nomUsage && !!formik.errors.titulaireNouveau?.nomUsage) ||
                false
                }
                helperText={formik.touched.titulaireNouveau?.nomUsage && formik.errors.titulaireNouveau?.nomUsage ?
                formik.errors.titulaireNouveau.nomUsage : ''
                }
                maxLength={80}
              />
              <Prenom
                className={classes.textfield}
                name={'titulaireNouveau.prenom'}
                value={formik.values.titulaireNouveau.prenom}
                fullWidth={true}
                formulaire={'DC'}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('prenom', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.prenom && !!formik.errors.titulaireNouveau?.prenom) || false}
                helperText={formik.touched.titulaireNouveau?.prenom && formik.errors.titulaireNouveau?.prenom ?
                formik.errors.titulaireNouveau.prenom : ''
                }
                maxLength={80}
              />
            </div>
            <div className={classes.marginTop}>
              <Sexe
                className={classes.textfieldSmall}
                name={'titulaireNouveau.sexe'}
                value={formik.values.titulaireNouveau.sexe}
                formulaire={'DC'}
                onChangeValue={formik.handleChange}
                onFocus={onFocus('sexe', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.sexe && !!formik.errors.titulaireNouveau?.sexe) || false}
                helperText={formik.touched.titulaireNouveau?.sexe && formik.errors.titulaireNouveau?.sexe ?
                  formik.errors.titulaireNouveau.sexe : ''
                }
              />
            </div>
          </>
        }

        {formik.values.titulaireNouveau.typePersonne === 'pm' &&
          <>
            <SocieteCommerciale
              className={classes.marginTop}
              name={'titulaireNouveau.societeCommerciale'}
              value={formik.values.titulaireNouveau.societeCommerciale}
              onChangeValue={formik.handleChange}
            />
            <div className={`${classes.flex} ${classes.marginTop}`}>
              <RaisonSociale
                className={classes.textfield}
                name={'titulaireNouveau.raisonSociale'}
                value={formik.values.titulaireNouveau.raisonSociale}
                formulaire={'DC'}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('raisonSociale', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.raisonSociale &&
                  !!formik.errors.titulaireNouveau?.raisonSociale) || false
                }
                helperText={formik.touched.titulaireNouveau?.raisonSociale &&
                  formik.errors.titulaireNouveau?.raisonSociale ? formik.errors.titulaireNouveau.raisonSociale : ''
                }
              />
              <Siren
                className={classes.textfield}
                id={'siren'}
                name={'titulaireNouveau.siren'}
                value={formik.values.titulaireNouveau.siren}
                formulaire={'DC'}
                isMandatory={false}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siren', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.siren && !!formik.errors.titulaireNouveau?.siren) || false}
                helperText={helperTextWithIcon(formik, 'siren', 'titulaireNouveau', classes.helpIcon)}
              />
              <Siret
                className={classes.textfield}
                id={'siret'}
                name={'titulaireNouveau.siret'}
                value={formik.values.titulaireNouveau.siret}
                formulaire={'DC'}
                isMandatory={formik.values.titulaireNouveau.societeCommerciale &&
                  formik.values.titulaireNouveau.adresse.pays === 'FRANCE'
                }
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siret', 'titulaireNouveau')}
                onError={(formik.touched.titulaireNouveau?.siret && !!formik.errors.titulaireNouveau?.siret) || false}
                helperText={helperTextWithIcon(formik, 'siret', 'titulaireNouveau', classes.helpIcon)}
              />
              <div className={classes.containerSearchIcon}>
                <IconButton id={'boutonRecherche'} onClick={() => setShowSearchCorpoartion(true)}>
                  <SearchIcon className={classes.searchIcon}/>
                </IconButton>
              </div>
            </div>
          </>
        }
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Coordonnées du nouveau titulaire</Typography>
        <Adresse
          formulaire={'DC'}
          formik={formik}
          entity={'titulaireNouveau.adresse'}
          formikValues={formik.values.titulaireNouveau.adresse}
          formikTouched={formik.touched.titulaireNouveau?.adresse}
          formikErrors={formik.errors.titulaireNouveau?.adresse}
        />
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Véhicule</Typography>
        <Vehicule
          formulaire={'DC'}
          formik={formik}
          entity={''}
          formikValues={{immatriculation: formik.values.immatriculation, numeroVin: formik.values.numeroVin,
            presenceCertificat: formik.values.presenceCertificat, numeroFormule: formik.values.numeroFormule,
            dateCertificat: formik.values.dateCertificat}
          }
          formikTouched={formik.touched}
          formikErrors={formik.errors}
        />
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Information sur la déclaration de cession</Typography>
        <div className={classes.flex}>
          <span className={`${classes.flex} ${classes.dateHeure}`}>
            <InputDate
              className={classes.textfieldSmall}
              id={'dateCession'}
              name={'dateCession'}
              formulaire={'DC'}
              isMandatory={true}
              title={'Cession du véhicule'}
              value={formik.values.dateCession}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('dateCession')}
              onError={(formik.touched.dateCession && !!formik.errors.dateCession) || false}
              helperText={formik.touched.dateCession && formik.errors.dateCession && formik.errors.dateCession ?
                formik.errors.dateCession : ''
              }
            />
            <Hour
              className={classes.textfieldSmall}
              id={'heureCession'}
              name={'heureCession'}
              formulaire={'DC'}
              isMandatory={false}
              title={'\xa0'}
              value={formik.values.heureCession}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('heureCession')}
              onError={(formik.touched.heureCession && !!formik.errors.heureCession) || false}
              helperText={formik.touched.heureCession && formik.errors.heureCession ? formik.errors.heureCession : ''}
            />
          </span>
          <span className={classes.flex}>
            <InputDate
              className={classes.textfieldSmall}
              id={'dateDeclaration'}
              name={'dateDeclaration'}
              formulaire={'DC'}
              isMandatory={true}
              title={'Déclaration de cession'}
              value={formik.values.dateDeclaration}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('dateDeclaration')}
              onError={(formik.touched.dateDeclaration && !!formik.errors.dateDeclaration) || false}
              helperText={formik.touched.dateDeclaration && formik.errors.dateDeclaration ?
                formik.errors.dateDeclaration : ''
              }
            />
            <Hour
              className={classes.textfieldSmall}
              id={'heureDeclaration'}
              name={'heureDeclaration'}
              formulaire={'DC'}
              isMandatory={false}
              title={'\xa0'}
              value={formik.values.heureDeclaration}
              onChangeValue={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={onFocus('heureDeclaration')}
              onError={(formik.touched.heureDeclaration && !!formik.errors.heureDeclaration) || false}
              helperText={formik.touched.heureDeclaration && formik.errors.heureDeclaration ?
                formik.errors.heureDeclaration : ''
              }
            />
          </span>
        </div>
      </div>

      <Typography style={{fontSize: '14px'}}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      {responseReturn === -1 &&
        <Typography className={`${classes.red} ${classes.error}`}>
          Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
        </Typography>
      }

      <div style={{textAlign: 'right'}}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={'boutonEnvoyerDC'} disabled={disabled} onClick={formik.submitForm}/>
        }
      </div>

      <Modal
        open={showSearchCorporation}
        aria-labelledby="modal-search">
        <DialogContent>
          <SearchPersonneMorale
            onClose={() => setShowSearchCorpoartion(false)}
            siren={formik.values.titulaireNouveau.siren}
            siret={formik.values.titulaireNouveau.siret}
            onSelect={handleSelectPersonneMorale}
          />
        </DialogContent>
      </Modal>
    </div>
  )
};
