import React, {useEffect} from 'react'
import {IconButton, Grid, Typography} from '@mui/material'
import {getMissions} from '../../../api/dossier'
import {useResultContext} from '../../../store/ResultContext'
import {useSearchContext} from '../../../store/SearchContext'
import {BlocCard} from './BlocCard'
import {Loader} from '../../common/Loader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

const styles = (theme: Theme) => ({
  root: {
    textAlign: 'left' as const,
    marginTop: '25px',
    marginBottom: '15px',
  },
  result: {
    textAlign: 'left' as const,
    width: '100%',
  },
  bar: {
    display: 'inline-flex',
    width: '100%',
    marginBottom: '10px',
  },
  buttonBar: {
    display: 'flex',
    verticalAlign: 'initial',
    justifyContent: 'flex-end',
  },
  iconBtn: {
    color: theme.palette.link.main,
    display: 'inline',
  },
  iconRoot: {
    marginRight: '0px',
    display: 'flex',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '1.4em',
    gridRowGap: '1.4em',
    ['@media (max-width:1800px)']: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridColumnGap: '3em',
      gridRowGap: '3em',
    },
    ['@media (max-width:1300px)']: {
      gridColumnGap: '1.4em',
      gridRowGap: '1.4em',
    },
    ['@media (max-width:1100px)']: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    ['@media (max-width:900px)']: {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '3em',
      gridRowGap: '3em',
    },
    ['@media (max-width:600px)']: {
      gridTemplateColumns: '1fr',
      gridRowGap: '1.4em',
    },
  },
});

export const ResultComponent: React.FC = () => {
  const {search} = useSearchContext();
  const {result, actions} = useResultContext();
  const classes = useEmotionStyles(styles);
  const [isPending, setIsPending] = React.useState(true);
  const [disablePreviousButton, setDisablePreviousButton] = React.useState(true);
  const [disableNextButton, setDisableNextButton] = React.useState(false);
  const [title, setTitle] = React.useState('Derniers dossiers créés');

  useEffect(() => {
    if (search) {
      setIsPending(true);
      getMissions(search).then((res) => {
        actions.setResult(res);
        res.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
        setIsPending(false);
      });
      if (search.referenceSinistre || search.immatriculation || search.dateDebut || search.dateFin ||
        search.referenceCourtier || search.referenceExpert || search.numeroPolice || search.dateEvenement) {
        setTitle('Résultats');
      } else {
        setTitle('Derniers dossiers créés');
      }
    }
  }, [search]);

  useEffect(()=> {
    if (result && !search) {
      setIsPending(false)
    }
  }, [result]);

  const resultSize = result?.list ? result.list.length : 0;

  const handleFirstPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const searchFirst = {...search, codePagination: 'D', navigationOrder: 'D'};
    setIsPending(true);
    getMissions(searchFirst).then((result) => {
      if (result) {
        actions.setResult(result);
        setIsPending(false);
        result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
        setDisablePreviousButton(true)
      }
    },
    )
  };
  const handlePreviousPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const firstElement = result!.list![0];
    const searchPrevious = {...search, isnReprise: firstElement.isn, codePagination: 'P', navigationOrder: 'C'};
    setIsPending(true);
    getMissions(searchPrevious).then((result) => {
      if (!!result && !!result.list && result.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        actions.setResult(result);
        result.indiceFin ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
        setIsPending(false);
        setDisableNextButton(false)
      }
    },
    )
  };
  const handleNextPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const lastElement = result!.list![result!.list!.length - 1];
    const searchNext = {...search, isnReprise: lastElement.isn, codePagination: 'S', navigationOrder: 'D'};
    setIsPending(true);
    getMissions(searchNext).then((result) => {
      if (result) {
        actions.setResult(result);
        result.indiceFin ? setDisableNextButton(true) : setDisableNextButton(false);
        setIsPending(false);
        setDisablePreviousButton(false)
      }
    },
    )
  };
  const handleLastPage = (_: React.MouseEvent<HTMLButtonElement>) => {
    const searchLast = {...search, codePagination: 'D', navigationOrder: 'C'};
    setIsPending(true);
    getMissions(searchLast).then((result) => {
      if (!!result && !!result.list && result.list.length > 0) {
        const reversedResult = [...result.list].reverse();
        result = {...result, list: reversedResult};
        actions.setResult(result);
        setIsPending(false);
        result.indiceFin ? setDisablePreviousButton(true) : setDisablePreviousButton(false);
        setDisableNextButton(true)
      }
    },
    )
  };

  if (isPending) {
    return (<Loader />)
  } else {
    return (
      <div className={classes.root}>
        {resultSize > 0 ?
          <Grid container>
            <Grid container item className={classes.bar}>
              <Grid item xs={4} sm={8}>
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                  <Typography variant={'h3'} style={{margin: '0px'}}>{title}</Typography>
                </div>
              </Grid>
              <Grid item className={classes.buttonBar} xs={8} sm={4}>
                <IconButton id={'boutonPremierePage'} className={classes.iconBtn} onClick={handleFirstPage}
                  disabled={disablePreviousButton}>
                  <FirstPageIcon classes={{root: classes.iconRoot}}/>
                </IconButton>
                <IconButton id={'boutonPrecedent'} className={classes.iconBtn} onClick={handlePreviousPage}
                  disabled={disablePreviousButton}>
                  <ArrowBackIosIcon classes={{root: classes.iconRoot}}/>
                </IconButton>
                <IconButton id={'boutonSuivant'} className={classes.iconBtn} onClick={handleNextPage}
                  disabled={disableNextButton}>
                  <ArrowForwardIosIcon classes={{root: classes.iconRoot}}/>
                </IconButton>
                <IconButton id={'boutonDernierePage'} className={classes.iconBtn} onClick={handleLastPage}
                  disabled={disableNextButton}>
                  <LastPageIcon classes={{root: classes.iconRoot}}/>
                </IconButton>
              </Grid>
            </Grid>
            <div className={classes.grid}>
              {result?.list && result.list.map((item, index) => {
                return (
                  <div key={index}>
                    <BlocCard card={item} id={`carteDossier-${index}`}/>
                  </div>)
              })
              }
            </div>
          </Grid> :
          <div>
            <div className={classes.bar}>
              <Typography variant={'h3'} style={{margin: '0px'}}>Résultat</Typography>
            </div>
            <div className={classes.result}>
              Aucun résultat
            </div>
          </div>
        }
      </div>
    )
  }
};
