import React from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';
import {StyledTypography} from '../../../common/StyledTypography';
import {BlocAdresse} from '../../../common/adresse/BlocAdresse';
import {useMissionContext} from '../../../../store/MissionContext';
import moment from 'moment';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
const styles = () => ({
  card: {
    boxShadow: 'none',
  },
})

export const InfosDossierPrint = () => {
  const classes = useEmotionStyles(styles)
  const {mission} = useMissionContext()
  const informationMission = mission?.infoMissionDTO
  const referenceSinistre = informationMission?.referenceSinistre
  const numeroMission = informationMission?.numeroMission
  const typeMission = informationMission?.typeMission
  const natureSinistre = informationMission?.natureSinistre
  const dateMissionnement = moment(informationMission?.dateMissionnement)
  const immatriculation = informationMission?.immatriculation
  const lieuExpertise = informationMission?.lieuExpertiseAdresse
  const commentaire = informationMission?.lieuExpertiseCommentaire

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant={'h3'}>Informations du dossier</Typography>
        <Grid container>
          <Grid item xs={4}>
            <StyledTypography title={'N° de sinistre'} text={referenceSinistre} />
            <StyledTypography title={'Immatriculation'} text={immatriculation} />
            <StyledTypography title={'Nature du sinistre'} text={natureSinistre} />
          </Grid>
          <Grid item xs={4}>
            <StyledTypography title={'N° de la mission'} text={numeroMission} />
            <StyledTypography title={'Type de la mission'} text={typeMission} />
            {dateMissionnement.isValid() &&
              <StyledTypography title={'Date du missionnement'} text={dateMissionnement.format('DD/MM/YYYY')}/>
            }
          </Grid>
          <Grid item xs={4}>
            <BlocAdresse title={'Lieu d\'expertise'} adresse={lieuExpertise}
              commentaire={commentaire} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
