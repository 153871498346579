import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface PrenomProps {
  className?: string,
  classNameTextField?: string,
  name: string,
  fullWidth?: boolean,
  formulaire: string,
  value: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: React.JSX.Element | string,
  maxLength?: number,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const Prenom = ({className, classNameTextField, name, fullWidth, formulaire, value, onChangeValue, onBlur,
  onFocus, onError, helperText, maxLength}: PrenomProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Prénom <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSearch
        className={classNameTextField}
        id={'prenom' + formulaire}
        name={name}
        fullWidth={fullWidth}
        value={value}
        maxLength={maxLength}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
};
