import axios, {AxiosHeaders} from 'axios';
import Keycloak from 'keycloak-js';

const _axios = axios.create();

const configure = (keycloak: Keycloak) => {
  _axios.interceptors.request.use((config) => {
    if (keycloak.authenticated) {
      (config.headers as AxiosHeaders).set('Authorization', `Bearer ${keycloak.token}`);
      if (sessionStorage.getItem('choixService')) {
        (config.headers as AxiosHeaders).set('X-darva-code-service', sessionStorage.getItem('choixService'));
      }
      if (keycloak.realmAccess?.roles.includes('SinappsAuto_Assistance')) {
        (config.headers as AxiosHeaders).set('X-darva-assistance-compte', sessionStorage.getItem('loginUser'));
      }
    }
    return Promise.resolve(config);
  });
};

const getAxiosClient = () => _axios;

const AxiosService = {
  configure,
  getAxiosClient,
};

export default AxiosService;
