import React, {ChangeEvent, useState} from 'react';
import {AppBar, Box, IconButton, Tab, Tabs, Typography} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import {PanelProps} from '../Details';
import {AlbumActeurs} from './AlbumActeurs';
import {AlbumMessages} from './AlbumMessages';
import {useMissionContext} from '../../../../store/MissionContext';
import groupBy from 'lodash/groupBy';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

export interface AlbumsProps {
  album: PhotoModel[],
  click: (id: string, album: PhotoModel[], index: number, photo: PhotoModel, from: string) => void,
  onClose: () => void,
  from: string,
}

/** Design */
const styles = (theme: Theme) => ({
  paper: {
    'height': '650px',
    'width': '70%',
    'position': 'absolute' as const,
    'top': '50%',
    'left': '50%',
    'transform': 'translate(-50%, -50%)',
    'backgroundColor': theme.palette.white.main,
    'borderRadius': '8px',
    'padding': '24px',
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
    ['@media (max-width:1400px)']: {
      height: '80%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  buttonClose: {
    'marginLeft': 'auto',
    'padding': '0px',
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'none',
    width: '50%',
    margin: '0px auto',
  },
  tabs: {
    padding: '0rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    fontSize: '1rem',
  },
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
  main: {
    marginTop: '2%',
  },
  tab: {
    '&.MuiTab-root': {
      'textTransform': 'none' as const,
      'textAlign': 'left' as const,
      'fontWeight': 'bold',
      'fontSize': '16px',
      'letterSpacing': '0px',
      'opacity': '1',
      'minHeight': '48px',
      'color': theme.palette.link.main,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: 'inherit',
      },
    },
    '&.Mui-selected': {},
  },
});

const TabPanel = (props: PanelProps) => {
  const {value, index, children} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </div>
  )
};

export const Albums = ({album, click, onClose, from}: AlbumsProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const referenceSinistre = mission?.infoMissionDTO?.referenceSinistre;
  const groupeAuthor = groupBy(album, 'author');
  const groupeMessage = groupBy(album, 'idDoc');
  const [value, setValue] = useState(from === 'message' ? 1 : 0);

  const handleChange = (event: ChangeEvent<{}>, value: number) => setValue(value);

  const a11yProps = (index : number) =>{
    return {
      'id': `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <div id={'popupAlbumPhotos'} className={classes.paper}>
      <div className={classes.header}>
        <Typography variant={'h3'}>Album photo</Typography>
        <IconButton className={classes.buttonClose} onClick={onClose}>
          <CancelIcon style={{marginRight: '0px'}}/>
        </IconButton>
      </div>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          indicatorColor="primary" textColor="inherit"
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{style: {backgroundColor: '#241E46', height: '3px'}}}
        >
          <Tab label="Album par acteurs" {...a11yProps(0)} className={classes.tab}/>
          <Tab label="Album par messages" {...a11yProps(1)} className={classes.tab}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.main}>
          {Object.keys(groupeAuthor).map((acteur) => {
            return (
              <AlbumActeurs key={acteur.trim()} groupeAuthor={groupeAuthor} acteur={acteur}
                referenceSinistre={referenceSinistre} click={click}/>
            )
          })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.main}>
          {Object.keys(groupeMessage).map((idDoc) => {
            return (
              <AlbumMessages key={idDoc} groupeMessage={groupeMessage} idDoc={idDoc}
                referenceSinistre={referenceSinistre} click={click}/>
            )
          })}
        </div>
      </TabPanel>
    </div>
  )
};
