import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {IInsured} from '../../model/ModelPECBG';

export interface InsuredProps {
  assure: IInsured,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  gridElement: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    marginBottom: '16px',
  },
  addressElement: {
    marginBottom: '8px',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
});

const Insured = (props: InsuredProps) => {
  const {assure} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Assuré
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.grid}>
            <div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Civilité : </span>
                <span>{assure.civilite}</span>
              </div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Nom : </span>
                <span>{assure.nom.toUpperCase()}</span>
              </div>
              <div>
                <span className={classes.libelle}>Prénom : </span>
                <span>{assure.prenom}</span>
              </div>
            </div>
            <div>
              <div className={classes.gridElement}>
                <span className={classes.libelle}>Adresse : </span>
                <div>
                  <div className={assure.adresse1 &&
                  (assure.adresse2 || assure.adresse3) ? classes.addressElement : ''}>
                    {assure.adresse1}
                  </div>
                  <div className={assure.adresse3 &&
                  (assure.adresse2 || assure.adresse1) ? classes.addressElement : ''}>
                    {assure.adresse2}
                  </div>
                  <div>{assure.adresse3}</div>
                </div>
              </div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Code postal  : </span>
                <span>{assure.codePostal}</span>
              </div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Localité : </span>
                <span>{assure.localite}</span>
              </div>
              <div>
                <span className={classes.libelle}>Pays : </span>
                <span>{assure.pays}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insured;
