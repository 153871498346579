import AxiosService from '../services/AxiosService';
import Cookies from 'js-cookie';
import {SearchModel} from '../model/common/SearchModel';
import {dateToApiFormat} from '../common/utils/DateUtils';
import {CardsModel} from '../model/rechercheDossier/CardsModel';
import {TokenModel} from '../model/common/TokenModel';
import {DetailsModel} from '../model/detailsDossier/DetailsModel';
import {IDocumentForm, IPjForm} from '../components/liensTransparents/model/models';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne les missions qui correspondent aux paramètres choisis
 *
 * @param {SearchModel} search - Objet contenant les infos sur le client (numClient, codeAbonne, codeService),
 * sur les paramètres choisis (referenceSinistre, immatriculation, dateDebut, dateFin, referenceCourtier,
 * referenceExpert, numeroPolice, dateEvenement) et des infos supplémentaires (nbMaxElement, navigationOrder,
 * codePagination, isnReprise)
 * @return {Promise} missions
 */
export async function getMissions(search: SearchModel) {
  const response = await axiosInstance(`${baseUrl}/dossiers`,
      {
        params: {
          numClient: search?.numClient,
          userCompte: search?.userCompte,
          codeAbonne: search?.codeAbo,
          codeService: search?.codeService,
          nbMaxElement: search?.nbMaxElement,
          navigationOrder: search?.navigationOrder,
          codePagination: search?.codePagination,
          isnReprise: search?.isnReprise,
          referenceSinistre: search?.referenceSinistre,
          immatriculation: search?.immatriculation,
          ...search.dateDebut && {dateDebutRecherche: dateToApiFormat(search.dateDebut)},
          ...search.dateFin && {dateFinRecherche: dateToApiFormat(search.dateFin)},
          referenceCourtier: search?.referenceCourtier,
          referenceExpert: search?.referenceExpert,
          numeroPolice: search?.numeroPolice,
          ...search.dateEvenement && {dateEvenement: dateToApiFormat(search.dateEvenement)},
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
      });
  const missions: CardsModel = {list: response.data.mdossierCards, indiceFin: response.data.indiceFin};
  return missions
}

/**
 * Retourne le détail d'une mission
 *
 * @param {TokenModel} token - Objet contenant les infos sur le client (numClient, codeGTA, codeAbonne, codeService) et
 * sur la mission recherchée (referenceSinistre, numeroMission, isDossierComplet)
 * @param {boolean} isAccesTransparent - Booléen qui indique si on est en lien transparent
 * @return {Promise} mission
 */
export async function getDetailMission(token: TokenModel, isAccesTransparent: boolean) {
  const response = await axiosInstance(`${baseUrl}/detaildossiers`,
      {
        params: {
          numClient: token.numClient,
          userCompte: token.userCompte,
          codeGTA: token.codeGTA,
          codeAbonne: token.codeAbonne,
          codeService: token.codeService,
          referenceSinistre: token.referenceSinistre,
          numeroMission: token.numeroMission,
          isDossierComplet: token.isDossierComplet,
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
          'X-darva-lien-transparent': isAccesTransparent ? 'O' : 'N',
        },
      });

  const mission: DetailsModel = {
    gestionDTO: response.data.gestionDTO,
    societaireDTO: response.data.societaireDTO,
    infoMissionDTO: response.data.infoMissionDTO,
    historiqueDTO: response.data.historiqueDTO,
    notificationDTO: response.data.notificationDTO,
    acteursDTO: response.data.acteursDTO,
    pjsDTO: response.data.pjsDTO,
    photosDTO: response.data.photosDTO,
    indicateurArchivage: response.data.indicateurArchivage,
    commentairesDTO: response.data.commentairesDTO,
    dossierSimple: response.data.dossierSimple,
    dossierChargerComplet: response.data.dossierChargerComplet,
    scenario: response.data.scenario,
    codeGTA: response.data.codeGTA,
    documentSaisissable: response.data.documentSaisissable,
    isDossierNonBG: response.data.isDossierNonBG,
    intermediaireDTO: response.data.intermediaire,
    suiviReparationsDTO: response.data.suiviReparationsDTO,
    rendezVousDTO: response.data.rendezVousDTO,
  };
  return mission
}

/**
 * Retourne la vue EDI du message
 *
 * @param {String} idDocument - ID du document
 * @param {TokenModel} token - Objet contenant les infos sur le client (numClient, codeGTA, codeAbonne, codeService) et
 * sur la mission (referenceSinistre, numeroMission)
 * @param {String} type - Type du document
 * @param {String} ssType - Sous-type du document
 * @param {String} isnIdentifier
 * @param {boolean} isAccesTransparent - Booléen qui indique si on est en lien transparent
 * @return {Promise} response data
 */
export async function getDocumentEdi(idDocument: string, token: TokenModel, type: string, ssType: string,
    isnIdentifier: string, isAccesTransparent: boolean) {
  const response = await axiosInstance(`${baseUrl}/documentEdi`,
      {
        params: {
          idDocument: idDocument,
          numClient: token.numClient,
          userCompte: token.userCompte,
          codeGTA: token.codeGTA,
          codeAbonne: token.codeAbonne,
          codeService: token.codeService,
          referenceSinistre: token.referenceSinistre?.trim(),
          numeroMission: token.numeroMission?.trim(),
          type: type,
          ssType: ssType,
          isnIdentifier: isnIdentifier,
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
          'X-darva-lien-transparent': isAccesTransparent ? 'O' : 'N',
        },
      },
  );
  return response.data
}

/**
 * Retorune un Blob correspondant à la pièce jointe à télécharger
 *
 * @param {String} filename - Nom du fichier à télécharger
 * @param {string} numClient - Numéro Client
 * @return {Promise} response
 */
export async function getPieceJointe(filename: string, numClient: string) {
  return axiosInstance(`${baseUrl}/downloadFile`,
      {
        params: {
          filename: filename,
          numClient: numClient,
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
        },
        responseType: 'blob',
      });
}

/**
 * Retorune un Blob correspondant à l'identifiant de la pièce jointe
 *
 * @param {IPjForm} pjForm - formulaire pièce jointe
 * contenant numClient, codeGta, codeService, referenceSinistre, numMission, idPj
 * @return {Promise} response
 */
export async function getPieceJointeById(pjForm: IPjForm) {
  const {numClient, codeGta, codeService, referenceSinistre, numeroMission, idPj} = pjForm
  return axiosInstance(`${baseUrl}/getPjById`,
      {
        params: {
          numClient: numClient,
          codeGTA: codeGta,
          codeService: codeService,
          referenceSinistre: referenceSinistre,
          numMission: numeroMission,
          idPj: idPj,
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
          'X-darva-lien-transparent': 'O',
        },
        responseType: 'blob',
      });
}

/**
 * Retorune un objet Json Document
 *
 * @param {IDocumentForm} documentForm - formulaire document
 * contenant numClient, codeGta, codeService, referenceSinistre, idMessage
 * @return {Promise} response
 */
export async function getMessageById(documentForm: IDocumentForm) {
  const {numClient, codeGta, codeService, referenceSinistre, numeroMission, idMessage} = documentForm
  return axiosInstance(`${baseUrl}/getMessageById`,
      {
        params: {
          numClient: numClient,
          codeGTA: codeGta,
          codeService: codeService,
          referenceSinistre: referenceSinistre,
          numMission: numeroMission,
          idMessage: idMessage,
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('token')}`,
          'X-darva-lien-transparent': 'O',
        },
      });
}

/**
 * Retourne le document PDF du message
 *
 * @param {String} idDocument - ID du document
 * @param {TokenModel} token - Objet contenant les infos sur le client (numClient, codeGTA, codeAbonne, codeService) et
 * sur la mission (referenceSinistre, numeroMission)
 * @param {String} type - Type du document
 * @param {String} ssType - Sous-type du document
 * @param {String} isnIdentifier
 * @param {boolean} isAccesTransparent - Booléen qui indique si on est en lien transparent
 * @return {Promise} response data
 */
export async function getDocumentPdf(idDocument: string, token: TokenModel, type: string, ssType: string,
    isnIdentifier: string, isAccesTransparent: boolean) {
  const response = await axiosInstance(`${baseUrl}/documentPdf`,
      {
        params: {
          idDocument: idDocument,
          numClient: token.numClient,
          userCompte: token.userCompte,
          codeGTA: token.codeGTA,
          codeAbonne: token.codeAbonne,
          codeService: token.codeService,
          referenceSinistre: token.referenceSinistre?.trim(),
          numeroMission: token.numeroMission?.trim(),
          type: type,
          ssType: ssType,
          isnIdentifier: isnIdentifier,
        },
        responseType: 'blob',
        headers: {
          'Pragma': 'no-cache',
          'Authorization': `Bearer ${Cookies.get('token')}`,
          'X-darva-lien-transparent': isAccesTransparent ? 'O' : 'N',
        },
      });
  return response.data
}

/**
 * Retourne la liste des documents en attente
 *
 * @param {string} numClient - Numéro Client
 * @param {string} userCompte - Compte client connecté
 * @param {string} codeService - Code Service
 * @return {Promise} response
 * @example
 *     getDocumentsEnAttente('14596atw', 'S085')
 */
export async function getDocumentsEnAttente(numClient: string, userCompte: string, codeService: string) {
  const response = await axiosInstance(`${baseUrl}/documentsEnAttente`,
      {
        params: {
          numClient: numClient,
          userCompte: userCompte,
          codeService: codeService,
        },
      });
  return response.data
}

/**
 * Retourne une liste de rendez-vous à partir d'une référence sinistre
 *
 * @param {string} referenceSinistre - la référence sinistre
 * @param {String} codeGTA - Code GTA du user connecté
 * @param {String} numClient - Numéro Client
 * @param {String} userCompte - User connecté
 * @return {Promise} response
 * @example
 *     getListRdvByRefSinistre(TESTJMOI090822)
 */
export async function getListRdvByRefSinistre(referenceSinistre: string, codeGTA: string, numClient: string,
    userCompte: string) {
  return axiosInstance(`${baseUrl}/getRendezVousBySinistre`,
      {
        params: {
          referenceSinistre: referenceSinistre,
          codeGTA: codeGTA,
          numClient: numClient,
          userCompte: userCompte,
        },
      },
  );
}

/**
 * Désarchive un dossier
 *
 * @param {Object} data - Objet contenant les données du client et celles du dossier à désarchiver
 * @return {Promise}
 */
export function postUnarchive(data: any) {
  return axiosInstance.post(`${baseUrl}/unarchive`, data)
}

