import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, Typography} from '@mui/material';
import {BlocAdresse} from '../../../common/adresse/BlocAdresse';
import {useMissionContext} from '../../../../store/MissionContext';
import moment from 'moment';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextTruncate from 'react-text-truncate';
import useEmotionStyles from '../../../../common/useEmotionStyles';

const styles = () => ({
  card: {
    ['@media print']: {
      boxShadow: 'none',
      pageBreakInside: 'avoid' as const,
    },
  },
  hideForPrint: {
    ['@media print']: {
      display: 'none !important',
    },
  },
  container: {
    display: 'flex',
    columnGap: '30px',
  },
  left: {
    width: '50%',
  },
  right: {
    width: '50%',
  },
  subTitle: {
    font: 'normal normal normal 14px/19px Helvetica',
    letterSpacing: '0px',
    color: '#7C7C7C',
    marginTop: '12px',
  },
  text: {
    letterSpacing: '0px',
    color: '#505050',
  },
  iconButton: {
    color: '#0095db',
    padding: '0px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  printBloc: {
    display: 'none',
    ['@media print']: {
      display: 'block !important',
    },
  },
})

const Sinistre = () => {
  const classes = useEmotionStyles(styles)
  const {mission} = useMissionContext()
  const adresseSinistre = mission?.gestionDTO?.informationSinistreDTO?.adresseSinistre
  const descriptifDommage = mission?.gestionDTO?.informationSinistreDTO?.descriptifDommage
  const circonstances = mission?.gestionDTO?.informationSinistreDTO?.circonstance
  const dateSinistre = moment(mission?.gestionDTO?.informationSinistreDTO?.dateSinistre)
  const [expanded, setExpanded] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const formattedDescriptifDommage = () => {
    let formatted = ''
    descriptifDommage?.map((descriptif, index) => {
      formatted += index === descriptifDommage.length-1 ? descriptif : descriptif + ' '
    })
    return formatted.trim()
  }

  const formattedCirconstances = () => {
    let formatted = ''
    circonstances?.map((circonstance, index) => {
      formatted += index === circonstances.length-1 ? circonstance : circonstance + ' - '
    })
    return formatted.trim()
  }

  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    (async function() {
      setIsMounted(true)
    })()
  }, [])

  useEffect(() => {
    if (isMounted) {
      const isButtonMore = document.getElementById('buttonCirconstances') ??
      document.getElementById('buttonDescriptif')
      isButtonMore ? setShowButton(true) : setShowButton(false)
    }
  }, [isMounted])

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant={'h3'}>
          Sinistre{dateSinistre.isValid() ? ' du ' + dateSinistre.format('DD/MM/YYYY') : ''}
        </Typography>
        <div className={classes.container}>
          <div className={classes.left}>
            <BlocAdresse title={'Adresse du sinistre'} adresse={adresseSinistre} />
            <div>
              <Typography className={classes.subTitle}>Circonstances déclarées</Typography>
              <Typography className={`${classes.text} ${classes.hideForPrint}`}>
                {!expanded ?
                  <TextTruncate line={2} element='span' truncateText='...' text={formattedCirconstances()}
                    textTruncateChild={<Button style={{display: 'none'}} id='buttonCirconstances'
                      onClick={() => setExpanded(!expanded)}/>}
                  /> :
                  formattedCirconstances()
                }
              </Typography>
              <Typography className={`${classes.text} ${classes.printBloc}`}>{formattedCirconstances()}</Typography>
            </div>
          </div>
          <div className={classes.right}>
            <Typography className={classes.subTitle}>Descriptif des dommages déclarés</Typography>
            <Typography className={`${classes.text} ${classes.hideForPrint}`}>
              {!expanded ?
                <TextTruncate line={5} element='span' truncateText='...' text={formattedDescriptifDommage()}
                  textTruncateChild={<Button style={{display: 'none'}} id='buttonDescriptif'
                    onClick={() => setExpanded(!expanded)}/>}
                /> :
                formattedDescriptifDommage()
              }
            </Typography>
            <Typography className={`${classes.text} ${classes.printBloc}`}>{formattedDescriptifDommage()}</Typography>
          </div>
        </div>
        <div style={{textAlign: 'right'}} className={classes.hideForPrint}>
          {showButton ?
              <Button className={classes.iconButton} onClick={() => {
                setExpanded(!expanded)
                document.getElementById('buttonCirconstances')?.click()
                document.getElementById('buttonDescriptif')?.click()
              }}>
                {expanded ?
                  <span className={classes.label}>
                    <ExpandLessIcon/>Voir moins d&apos;infos</span> :
                  <span className={classes.label}>
                    <ExpandMoreIcon/>Voir plus d&apos;infos
                  </span>
                }
              </Button> : ''
          }
        </div>
      </CardContent>
    </Card>
  );
}

export default Sinistre;
