import AxiosService from '../services/AxiosService';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

/**
 * Retourne la liste des personnes morales en fonction des numéros Siren et/ou Siret passés en paramètre
 *
 * @param {string} siren - Numéro Siren
 * @param {string} siret - Numéro Siret
 * @param {string} numClient - Numéro Client
 * @return {Promise} response
 * @example
 *     getCorporation('', '77570970201646', 00007)
 */
export async function getCorporation(siren: string, siret: string, numClient: string) {
  return axiosInstance(`${baseUrl}/getSociete`,
      {
        params: {
          siren: siren,
          siret: siret,
          numClient: numClient,
        },
      })
}

/**
 * Retourne les coordonnées GPS pour une adresse passée en paramètre
 *
 * @param {string} adresse - Adresse
 * @param {string} numClient - Numéro Client
 * @return {Promise} response
 * @example
 *     getCoordonneesGPS('Niort 79000', '00007')
 */
export async function getCoordonneesGPS(adresse: string, numClient: string) {
  return axiosInstance(`${baseUrl}/coordonneesGPS`,
      {
        params: {
          adresse: adresse,
          numClient: numClient,
        },
      },
  )
}

/**
 * Retourne les localités d'un nom de commune ou un code postal
 *
 * @param {string} termes - termes à rechercher
 * @param {String} numClient - Numéro Client
 * @return {Promise} response
 * @example
 *     getLocalites(Nio)
 */
export async function getLocalites(termes: string, numClient: string) {
  const response = await axiosInstance(`${baseUrl}/getLocalites`,
      {
        params: {
          termes: termes,
          numClient: numClient,
        },
      },
  );
  return response.data
}

/**
 * Retourne une liste d'adresses postales géolocalisées en France à partir des termes d'une requête
 *
 * @param {string} termes - Termes de la requête représentant une adresse
 * @param {String} numClient - Numéro Client
 * @return {Promise} response
 * @example
 *     getAdresses(Nio)
 */
export async function getAdresses(termes: string, numClient: string) {
  const response = await axiosInstance(`${baseUrl}/getAdresses`,
      {
        params: {
          termes: termes,
          numClient: numClient,
        },
      },
  );
  return response.data
}
