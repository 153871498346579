import produce from 'immer'
import {Dispatch} from 'react'

import {CardsModel} from '../model/rechercheDossier/CardsModel'
import {ResultActions} from '../action/result.action'

type ResultActionType =
    | 'SET_RESULT'
    | 'SET_ERREUR'

export interface ResultAction {
    type: ResultActionType
    result?: CardsModel
    error? : string
}

export interface ResultDataState {
    result?: CardsModel
    error?: string
    dispatch: Dispatch<ResultAction>
    actions: ResultActions
}


export const resultReducer = (state: ResultDataState, action: ResultAction) => {
  switch (action.type) {
    case 'SET_RESULT':
      return produce(state, (draftState) => {
        draftState.result = action.result
        return draftState
      })
    case 'SET_ERREUR':
      return produce(state, (draftState) => {
        draftState.error = action.error
        return draftState
      })
    default:
      return {...state}
  }
}

export interface ResultReducerState {
  resultReducer: ResultDataState
}
