import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, {useState} from 'react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ButtonBlueDelete} from '../../../common/formsComponents/ButtonBlueDelete';
import InputSelectDTField from '../../multiStepsForm/fields/InputSelectDTField';
import {DOMAIN} from '../../../../constantes/constantesNormeAuto';
import {FormikValues, useFormikContext} from 'formik';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {findElementByCodeInArray} from '../../../../common/Utils';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import InputTextField from '../../multiStepsForm/fields/InputTextField';
import {InputMaskTextField} from '../../multiStepsForm/fields/InputMaskTextField';
import CheckBoxField from '../../multiStepsForm/fields/CheckBoxField';
import {InputCurrencyTextField} from '../../multiStepsForm/fields/InputCurrencyTextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import {IElementCalcul} from '../../ordre-mission/model/ModelOM';
import {
  checkErrorsElementTypeVehEtHVeh,
  ICheckElements,
} from '../../ordre-mission/steps/garantie/elementsCalcul/ElementsCalculs';

interface ElementCalculBlocProps {
  id: number
  idExpanded: number
  setIdExpanded: (id: number) => void
  setErrorsElementTypeVehEtHVeh: (errors: ICheckElements) => void
}

const styles = (theme: Theme) => ({
  container: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'baseline',
    'marginBottom': '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  accordion: {
    borderRadius: '8px 8px 0px 0px',
    width: '90%',
  },
  summary: {
    'backgroundColor': theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummaryExpandIcon: {
    padding: '12px 0px',
  },
  green: {
    color: theme.palette.green.main,
  },
  inProgress: {
    fill: theme.palette.primary.main,
    marginRight: '10px',
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  details: {
    display: 'block',
    padding: '16px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginTop8: {
    marginTop: '8px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginTop24: {
    marginTop: '24px',
  },
  fieldFullWidth: {
    marginRight: '0px',
  },
  field: {
    width: '360px',
    marginRight: '0px',
  },
  title: {
    fontWeight: 'bold',
  },
  switch: {
    display: 'flex',
    alignContent: 'center',
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  delete: {
    display: 'flex',
    width: '10%',
  },
  marginAuto: {
    margin: 'auto',
  },
});

// Liste des codes de la donnée "Éléments de prise en charge" pouvant être présents plusieurs fois
const MULTIPLE_VALID_ELEMENTS_LIST = ['Z'];

export const ElementCalculBloc = (props: ElementCalculBlocProps) => {
  const classes = useEmotionStyles(styles);
  const {id, idExpanded, setIdExpanded, setErrorsElementTypeVehEtHVeh} = props;
  const {values, setFieldValue, errors, touched, setFieldTouched, setTouched,
    setErrors}: FormikValues = useFormikContext();
  const [elementPriseEnChargeNormes, setElementPriseEnChargeNormes] = useState<ItemGetValeursCodesModel[]>();
  const isError = Boolean(errors?.elementsCalcul);
  const isTouched= Boolean(touched?.elementsCalcul);

  const isErrorInForm = isError && isTouched ?
    (errors.elementsCalcul[id]?.elementPriseEnCharge && touched.elementsCalcul[id]?.elementPriseEnCharge) ||
    (errors.elementsCalcul[id]?.tauxPriseEnCharge && touched.elementsCalcul[id]?.tauxPriseEnCharge) : false;

  const callback = (tab: ItemGetValeursCodesModel[]) => {
    setElementPriseEnChargeNormes(tab)
  };

  const elementPriseEnCharge = findElementByCodeInArray(elementPriseEnChargeNormes,
      values.elementsCalcul[id].elementPriseEnCharge)?.libelleedition;

  const getCodesElementsPriseEnCharge = () => {
    const codesElementsPriseEnCharge: string[] = [];
    values.elementsCalcul.forEach((element: IElementCalcul) => {
      codesElementsPriseEnCharge.push(element.elementPriseEnCharge)
    });
    return codesElementsPriseEnCharge
  };

  const isDisabledValidate = () => {
    return isErrorInForm
  };

  const validateElement = () => {
    setFieldTouched('elementsCalcul[' + id + '].elementPriseEnCharge', true);
    if (!isError) {
      setFieldValue('elementsCalcul[' + id + '].isValid', true);
      if (values.elementsCalcul[id].elementPriseEnCharge === '2') {
        setErrorsElementTypeVehEtHVeh(checkErrorsElementTypeVehEtHVeh(values));
      }
      setIdExpanded(-1);
    }
  };

  // suppression de l'élément de calcul dans values, touched et errors du context Formik
  const deleteElement = () => {
    const elementsCalculCopy: IElementCalcul[] = [...values.elementsCalcul].filter((element) =>
      values.elementsCalcul[id].id != element.id,
    );
    elementsCalculCopy.forEach((element, index) => {
      element.id = index;
    });
    setFieldValue('elementsCalcul', elementsCalculCopy);
    setErrorsElementTypeVehEtHVeh(checkErrorsElementTypeVehEtHVeh(elementsCalculCopy));

    if (isTouched) {
      const touchedCopy = JSON.parse(JSON.stringify(touched));
      touchedCopy.elementsCalcul.splice(id, 1);
      setTouched(touchedCopy, false);
    }
    if (isError) {
      const errorsCopy = JSON.parse(JSON.stringify(errors));
      errorsCopy.elementsCalcul.splice(id, 1);
      setErrors(errorsCopy, false);
    }
  };

  return (
    <div className={classes.container}>
      <Accordion
        className={classes.accordion}
        expanded={idExpanded === id}
        onChange={() => setIdExpanded(idExpanded !== id ? id : -1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls='panel1a-content'
          id={'panel1a-header-' + id}
          className={classes.summary}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expandIconWrapper: classes.accordionSummaryExpandIcon,
          }}
        >
          {values.elementsCalcul[id].isValid &&
            <CheckCircleIcon className={classes.green}/>
          }
          {!values.elementsCalcul[id].isValid &&
            <>
              {isError && isTouched && isErrorInForm ?
                <CancelIcon style={{color: '#F0283F'}}/> :
                <InProgressIcon className={classes.inProgress}/>
              }
            </>
          }
          <Typography className={classes.libelleAccordion}>
            {id + 1} - {elementPriseEnCharge || <i>Ajoutez un élément de calcul</i>}
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          <InputSelectDTField
            className={classes.fieldFullWidth}
            name={'elementsCalcul[' + (id) + '].elementPriseEnCharge'}
            label={'Élément de prise en charge'}
            mandatory={true}
            domain={DOMAIN.AR2}
            typeDT={'90410101'}
            callback={callback}
            contextList={getCodesElementsPriseEnCharge()}
            multipleValidElementsList={MULTIPLE_VALID_ELEMENTS_LIST}
          />

          <div className={`${classes.flex} ${classes.marginTop16}`}>
            <InputTextField
              name={'elementsCalcul[' + (id) + '].descriptif'}
              label={'Descriptif de l\'élément'}
              mandatory={false}
              maxLength={20}
            />
            <InputMaskTextField
              className={classes.field}
              name={'elementsCalcul[' + (id) + '].montantFranchiseForfaitaire'}
              label={'Montant franchise forfaitaire'}
              mandatory={false}
              mask={'99999'}
              endAdornment={'€'}
              titleTooltip={'Montant compris entre 0 et 99999€'}
            />
          </div>

          <Typography className={`${classes.title} ${classes.marginTop24}`}>Franchise proportionnelle</Typography>
          <div className={`${classes.flex} ${classes.marginTop16}`}>
            <InputMaskTextField
              name={'elementsCalcul[' + (id) + '].tauxFranchiseProportionnelle'}
              label={'Taux'}
              mandatory={false}
              mask={'99'}
              endAdornment={'%'}
              titleTooltip={'Taux compris entre 0 et 99%'}
            />
            <InputMaskTextField
              name={'elementsCalcul[' + (id) + '].montantPlancherFranchiseProportionnelle'}
              label={'Montant plancher'}
              mandatory={false}
              mask={'99999'}
              endAdornment={'€'}
              titleTooltip={'Montant compris entre 0 et 99999€'}
            />
            <InputMaskTextField
              className={classes.field}
              name={'elementsCalcul[' + (id) + '].montantPlafondFranchiseProportionnelle'}
              label={'Montant plafond'}
              mandatory={false}
              mask={'99999'}
              endAdornment={'€'}
              titleTooltip={'Montant compris entre 0 et 99999€'}
            />
          </div>

          <Typography className={`${classes.title} ${classes.marginTop24}`}>Prise en charge</Typography>
          <div className={classes.marginTop16} style={{display: 'flex'}}>
            <InputMaskTextField
              name={'elementsCalcul[' + (id) + '].tauxPriseEnCharge'}
              label={'Taux'}
              mandatory={false}
              mask={'999'}
              endAdornment={'%'}
              titleTooltip={'Taux compris entre 0 et 100%'}
            />
            <InputCurrencyTextField
              className={classes.field}
              name={'elementsCalcul[' + (id) + '].montantPlafondPriseEnCharge'}
              label={'Montant plafond'}
              mandatory={false}
              maskWholeNumberPart={'9999999'}
              titleTooltip={'Montant compris entre 0 et 9999999,99€'}
            />
          </div>

          <div className={`${classes.switch} ${classes.marginTop24}`}>
            <CheckBoxField
              name={'elementsCalcul[' + (id) + '].tvaRecuperable'}
              checked={values.elementsCalcul[id].tvaRecuperable}
            />
            <Typography className={classes.marginTop8}>TVA récupérable par l&apos;assuré</Typography>
          </div>
          <div className={`${classes.switch} ${classes.marginTop16}`}>
            <CheckBoxField
              name={'elementsCalcul[' + (id) + '].elementComprisReparation'}
              checked={values.elementsCalcul[id].elementComprisReparation}
            />
            <Typography className={classes.marginTop8}>Élément compris dans la réparation</Typography>
          </div>
          <div className={`${classes.switch} ${classes.marginTop16}`}>
            <CheckBoxField
              name={'elementsCalcul[' + (id) + '].abattementADeduire'}
              checked={values.elementsCalcul[id].abattementADeduire}
            />
            <Typography className={classes.marginTop8}>Abattement pour usure à déduire</Typography>
          </div>

          <div className={classes.containerButton}>
            <ButtonBlue
              id={'boutonValider-' + id}
              libelle={'Valider'}
              disabled={isDisabledValidate()}
              onClick={validateElement}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <div className={classes.delete}>
        <div className={classes.marginAuto}>
          <ButtonBlueDelete
            id={'boutonSupprimer-' + id}
            onClick={deleteElement}
          />
        </div>
      </div>
    </div>
  )
};
