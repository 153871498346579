export const servicesProposes = [
  {
    code: 'CONVOYAGEVEH',
    libelle: 'Convoyage de véhicule',
  },
  {
    code: 'SERVICEDEBOSS',
    libelle: 'Accueil d\'un service de débosselage',
  },
  {
    code: 'VEHPRET',
    libelle: 'Véhicule de prêt',
  },
  {
    code: 'VEHPRETPART',
    libelle: 'Véhicule particulier de prêt',
  },
  {
    code: 'VEHUTILPRET',
    libelle: 'Véhicule utilitaire de prêt',
  },
];
