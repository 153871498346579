import moment, {Moment} from 'moment';
import 'moment/locale/fr';

/*
* Prend en paramètre une string représentant une date au format "AAAA-MM-JJ" et la retourne au format "JJ/MM/AAAA"
* Exemple de retour : "01/01/2022"
*/
export const dateToApiFormat = (date: string) => {
  if (date && !date.includes('/')) {
    const deconstruct = date.split('-');
    return deconstruct[2] + '/' + deconstruct[1] + '/' + deconstruct[0]
  } else {
    return date
  }
};

/*
* Prend en paramètre un objet Date et la retourne au format DD/MM/YYYY
* Exemple de retour : 01/01/2022
*/
export const dateToDateText = (date?: Date | Moment) =>{
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  } else {
    return ' '
  }
};

/*
* Prend en paramètre un objet Date ou Moment et le retourne au format dddd D MMMM Y à HH:mm
* Exemple de retour : samedi 1 janvier 2022 à 09:00
*/
export const dateToDateAndHourFullText = (date?: Date | Moment) => {
  if (date) {
    return moment(date).locale('fr').format('dddd D MMMM Y') +
      ' à ' + moment(date).locale('fr').format('HH:mm')
  } else {
    return ' '
  }
};

/*
* Prend en paramètre un objet Date ou Moment et le retourne au format dddd D MMMM Y à HH:mm
* Exemple de retour : samedi 1 janvier 2022 à 09:00
*/
export const dateToDateFullText = (date?: Date | Moment) => {
  if (date) {
    const dateText = moment(date).locale('fr').format('dddd D MMMM Y');
    const heureText = moment(date).locale('fr').format('HH:mm');
    return `${dateText} à ${heureText}`;
  } else {
    return ' '
  }
};

/*
* Prend en paramètre un objet Date et la retourne au format DD/MM/YYYY HH:mm
* Exemple de retour : 01/01/2022 00:00
*/
export const dateToDateEtHeure = (date?: Date) =>{
  if (date) {
    const day = moment(date).format('DD/MM/YYYY');
    const time = moment(date).format('HH:mm');
    return day + ' ' + time
  } else {
    return ' '
  }
};

/*
* Prend en paramètre un objet Date et la retourne au format HH:mm
* Exemple de retour : 00:00
*/
export const dateToHeure = (date?: Date) =>{
  if (date) {
    return moment(date).format('HH:mm')
  } else {
    return ' '
  }
};

/*
* Prend en paramètre un tableau de dates et retourne la date la plus petite
*/
export const getMinDate = (dates: Date[]) => {
  return new Date(Math.min(...dates.map(Number)));
};

/*
* Prend en paramètre un tableau de dates et retourne la date la plus grande
*/
export const getMaxDate = (dates: Date[]) => {
  return new Date(Math.max(...dates.map(Number)));
};

/**
 * Retourne une nouvelle date avec l'heure arroundie
 *
 * @param {String} date - date au format UTC
 * @return {moment} nouvelle date avec l'heure arrondie
 *
 * @example  roundTime('2023-04-13T07:30:00.000Z')
 */
export const roundTime = (date: string) => {
  const minutes = moment(date).minutes();
  let diffMinutes = 0;
  if (minutes < 30 && minutes > 0) {
    diffMinutes = 30 - minutes
  } else if (minutes < 60 && minutes > 30) {
    diffMinutes = 60 - minutes
  }
  return moment(date).add(diffMinutes, 'minutes')
};

