import {CoordonneesGPS} from './CoordonneeGPSModel';
import {PartenaireProps} from './PartenaireModel';
import {AdresseAutoCompleteProps} from '../common/AdresseAutoCompleteModel';
import {CreneauxDisponibiliteProps} from './CreneauDisponibiliteModel';

export interface InformationsRdv {
  referenceSinistre: string,
  typeRdv: {
    code: string,
    libelle: string
  },
  sousActivite: {
    code: string,
    libelle: string
  }
}

export interface IMarque {
  valeur: string,
  libelleedition: string,
}
export const emptyMarque: IMarque = {
  valeur: '',
  libelleedition: '',
};

export interface InformationAssure {
  prenom: string,
  nom: string,
  telephone?: string,
  email?: string,
  immatriculation: string,
  marque?: IMarque,
  modele?: string,
  coordonneesGPS: CoordonneesGPS,
  adresse: AdresseAutoCompleteProps,
}

export interface Duree {
  valeur: number,
  libelle: string,
}

export interface RdvModel {
  informationsRdv: InformationsRdv,
  informationsAssure: InformationAssure,
  partenaire: {
    infosPartenaire: PartenaireProps,
    nextDispo: CreneauxDisponibiliteProps,
  },
  creneau: string,
  duree: Duree,
  idRdv?: string,
}
