import React from 'react';
import {Attachments, FilesDragAndDrop} from '../../../components/Attachment/FilesDragAndDrop';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {FormControl, FormHelperText} from '@mui/material';

export interface InputFilesDragAndDropField extends FieldConfig{
  onSavePj?: () => void,
  onClosePj?: () => void,
}

const InputFilesDragAndDropField = ({onSavePj, onClosePj, ...props} : InputFilesDragAndDropField) => {
  const [field, meta, helper] = useField<Attachments>(props as FieldHookConfig<Attachments>);

  const onSave = (attachments: Attachments) => {
    helper.setValue(attachments)
    if (onSavePj) {
      onSavePj()
    }
  };

  const onClose = () => {
    if (onClosePj) {
      onClosePj()
    }
  }


  return (
    <FormControl>
      <FilesDragAndDrop
        attachments={field.value}
        onSave={onSave}
        onCloseAttachmentForms={onClose}
      />
      {meta.touched && meta.error &&
        <FormHelperText id="upload-files-help-text">
          {meta.error}
        </FormHelperText>
      }
    </FormControl>
  );
};

export default InputFilesDragAndDropField;
