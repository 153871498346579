import React from 'react';
import {Typography} from '@mui/material';
import {customTheme} from '../../../common/GlobalTheme';
import {ButtonBlue} from '../buttons/ButtonBlue';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface AccessDeniedProps {
  message: string,
  isReturnButton?: boolean,
  buttonLabel?: string,
  onClickButton?: () => void,
}

/** Design */
const styles = () => ({
  container: {
    width: '100%',
    minHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as const,
    justifyContent: 'center',
  },
  message: {
    color: customTheme.palette.tertiary.main,
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  button: {
    marginTop: '32px',
  },
});

const AccessDenied = (props: AccessDeniedProps) => {
  const {message, isReturnButton, buttonLabel, onClickButton} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div className={classes.container}>
      <Typography variant='body1' component='div' className={classes.message}>
        {message}
      </Typography>
      {isReturnButton && onClickButton &&
        <ButtonBlue
          id={'boutonRetourDroitInsuffisant'}
          className={classes.button}
          libelle={buttonLabel}
          onClick={onClickButton}
        />
      }
    </div>
  );
};

export default AccessDenied;
