import React from 'react';
import RequestInformation from './components/RequestInformation';
import ManagementCenter from './components/ManagementCenter';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import ResponseInformation from './components/ResponseInformation';
import Insured from './components/Insured';
import Vehicle from './components/Vehicle';
import TechnicalInformation from './components/TechnicalInformation';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ReactComponent as EnvoyerBubbleIcon} from '../../../../images/icones/icn-arrow-back.svg';
import {IServiceResponse} from '../model/ModelPECBG';
import {GenericError} from '../../../common/errorsComponents/GenericError';

export interface ResponsePECBGProps {
  servicePecBgResponse: IServiceResponse,
  onClickNewRequest: () => void,
  onClickGoBack: () => void,
}

const styles = () => ({
  root: {
    border: 'none',
    margin: '25px',
    marginRight: '35px',
    marginLeft: '35px',
    opacity: '1',
  },
  container: {
    paddingBottom: '24px',
  },
  title: {
    marginBottom: '24px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '24px',
  },
  buttonsGrid: {
    marginTop: '8px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '24px',
  },
  gridButton1: {
    justifySelf: 'end',
  },
  gridButton2: {
    justifySelf: 'start',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '8px 0px',
  },
});

const ResponsePECBG = (props: ResponsePECBGProps) => {
  const {servicePecBgResponse, onClickNewRequest, onClickGoBack} = props;
  const pecBgResponse = servicePecBgResponse.response;
  const codeStatus = servicePecBgResponse.codeStatus
  const classes = useEmotionStyles(styles);

  const isRequestInformation = () => {
    const demandeInformation = pecBgResponse.demandeInformation;
    return demandeInformation.kilometrage ||
      demandeInformation.assureur ||
      demandeInformation.numeroImmatriculation ||
      demandeInformation.heureSurvenance ||
      demandeInformation.dateSurvenance ||
      demandeInformation.numeroContrat;
  }

  const isResponseInformation = () => {
    const reponseInformation = pecBgResponse.reponseInformation;
    return reponseInformation.reponse.libelleReponse ||
      reponseInformation.reponse.codeReponse ||
      reponseInformation.erreur.libelleErreur ||
      reponseInformation.erreur.codeErreur ||
      reponseInformation.garantie.garantieBDG ||
      reponseInformation.garantie.montantMin ||
      reponseInformation.garantie.montantMax ||
      reponseInformation.garantie.typeFranchise ||
      reponseInformation.garantie.pourcentage ||
      reponseInformation.garantie.montantFranchise ||
      reponseInformation.tvaRecuperable ||
      reponseInformation.priseEnCharge ||
      reponseInformation.numeroSinistre;
  }

  const isVehicle = () => {
    const vehicule = pecBgResponse.vehicule;
    return vehicule.vehMarque ||
      vehicule.vehModele;
  }

  const isManagementCenter = () => {
    const centreGestion = pecBgResponse.centreGestion;
    return centreGestion.adresse1 ||
      centreGestion.adresse2 ||
      centreGestion.adresse3 ||
      centreGestion.telephone ||
      centreGestion.codePostal ||
      centreGestion.localite ||
      centreGestion.pays;
  }

  const isInsured = () => {
    const assure = pecBgResponse.assure;
    return assure.adresse1 ||
      assure.adresse2 ||
      assure.adresse3 ||
      assure.civilite ||
      assure.nom ||
      assure.prenom ||
      assure.codePostal ||
      assure.localite ||
      assure.pays;
  }

  const isTechnicalInformation = () => {
    const informationsTechniques = pecBgResponse.informationsTechniques;
    return informationsTechniques.serviceAuto ||
      informationsTechniques.delaiReponseDarva ||
      informationsTechniques.delaiReponseAssureur;
  }

  const messageError404 = pecBgResponse?.reponseInformation?.erreur

  return (
    <div className={classes.root}>
      {codeStatus === 200 &&
        <div className={classes.container}>
          <Typography variant={'h4'} className={classes.title}>
            Réponse - Consultation de la prise en charge bris de glace
          </Typography>
          <div className={classes.grid}>
            <div>
              {isRequestInformation() &&
                <RequestInformation demandeInformation={pecBgResponse.demandeInformation}/>
              }
              {isResponseInformation() &&
                <ResponseInformation reponseInformation={pecBgResponse.reponseInformation}/>
              }
              {isVehicle() &&
                <Vehicle vehicule={pecBgResponse.vehicule}/>
              }
            </div>
            <div>
              {isManagementCenter() &&
                <ManagementCenter centreGestion={pecBgResponse.centreGestion}/>
              }
              {isInsured() &&
                <Insured assure={pecBgResponse.assure}/>
              }
              {isTechnicalInformation() &&
                <TechnicalInformation informationsTechniques={pecBgResponse.informationsTechniques}/>
              }
            </div>
          </div>
          <div className={classes.buttonsGrid}>
            <ButtonBlue
              className={classes.gridButton1}
              id={'bouton-retour'}
              libelle={'Retour'}
              onClick={onClickGoBack}
              icon={<EnvoyerBubbleIcon style={{marginRight: '10px'}}/>}
            />
            <ButtonBlue
              className={classes.gridButton2}
              id={'bouton-nouvelle-demande'}
              libelle={'Nouvelle demande'}
              onClick={onClickNewRequest}
            />
          </div>
        </div>
      }
      {codeStatus > 200 &&
        <div className={classes.errorContainer}>
          <GenericError
            errorMessage={codeStatus === 404 ?
              (messageError404.libelleErreur || messageError404.descriptionErreur) :
              'Un problème technique est survenu avec le service. Veuillez contacter DARVA pour assistance.'
            }
            {...(codeStatus === 404) &&
              {detailMessage: `Veuillez contacter ${pecBgResponse.demandeInformation.assureur}.`}
            }
            isClientContact={codeStatus === 404}
          />
          <ButtonBlue
            id={'bouton-retour-formulaire'}
            className={classes.button}
            libelle={'Retour au formulaire'}
            onClick={onClickNewRequest}
          />
        </div>
      }
    </div>
  );
};

export default ResponsePECBG;
