import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {Attachment} from './FilesDragAndDrop';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ButtonBlueDelete} from '../../../common/formsComponents/ButtonBlueDelete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg'
import {customTheme} from '../../../../common/GlobalTheme';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

export interface AttachmentFormProps {
  expanded: boolean,
  attachment: Attachment
  codesValeursNaturePieceJointe: ItemGetValeursCodesModel[],
  codesValeursTypePieceJointe: ItemGetValeursCodesModel[],
  onChangePanel: (panel: string) => void,
  onSave: (attachment: Attachment) => void,
  onDelete: (attachment: Attachment) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  summary: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  textField: {
    width: '100%',
  },
  fileOnError: {
    color: '#F0283F',
    fontSize: '14px',
  },
});

const AttachmentForm = (props: AttachmentFormProps) => {
  const classes = useEmotionStyles(styles);
  const {
    expanded, attachment, codesValeursNaturePieceJointe, codesValeursTypePieceJointe,
    onChangePanel, onSave, onDelete,
  } = props;
  const [formData, setFormData] = useState<Attachment>(attachment);
  const [errorFields, setErrorFields] = useState({libelleError: false, typeError: false});

  const codesValeursNaturePieceJointeSort = [...codesValeursNaturePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const codesValeursTypePieceJointeSort = [...codesValeursTypePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const itemsListNaturePieceJointe = codesValeursNaturePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  const itemsListTypePieceJointe = codesValeursTypePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.lowercase}</MenuItem>)
  });

  useEffect(() => {
    setFormData({...attachment})
  }, [attachment]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'type') setErrorFields({...errorFields, typeError: false});
      if (input === 'libelle') setErrorFields({...errorFields, libelleError: false});
      setFormData({...formData, [input]: event.target.value})
    };

  const handleSubmit = () => {
    setErrorFields({libelleError: !formData.libelle, typeError: !formData.type});
    if (formData.libelle && formData.type) {
      onSave(formData)
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <Accordion style={{width: '90%', borderRadius: '8px 8px 0px 0px'}}
          expanded={attachment.onError ? false : expanded}
          onChange={() => onChangePanel(`panel-${attachment.id}`)}
          disabled={attachment.onError}
        >
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls='panel1a-content'
            id={`panel1a-header-${attachment.id}`}
            className={classes.summary}
            classes={{root: classes.summaryRoot, content: classes.summaryContent}}
          >
            {attachment.onError &&
              <CancelIcon style={{color: '#F0283F'}}/>
            }
            {attachment.completed &&
              <CheckCircleIcon style={{color: '#1EA851'}}/>
            }
            {!attachment.onError && !attachment.completed &&
              <InProgressIcon style={{fill: customTheme.palette.primary.main, marginRight: '10px'}}/>
            }
            <Typography className={classes.libelleAccordion}>{attachment.file.name}</Typography>
          </AccordionSummary>
          {!attachment.onError &&
            <AccordionDetails style={{display: 'block', padding: '16px'}}>
              <div>
                <Typography className={classes.libelle}>
                  Nom de la pièce jointe<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextFieldSearch id={'nomPJ'} className={classes.textField} value={formData.libelle}
                  maxLength={50} onChange={handleChange('libelle')} onError={errorFields.libelleError}
                />
                {errorFields.libelleError &&
                  <Typography id={'nomPJRequired'} className={`${classes.red} ${classes.error}`}>
                    Veuillez saisir le nom de la pièce jointe
                  </Typography>
                }
              </div>

              <div style={{marginTop: '10px'}}>
                <Typography className={classes.libelle}>Type<span style={{color: 'red'}}> *</span>
                </Typography>
                <TextFieldSelect
                  className={classes.textField}
                  id={'type'}
                  name={'type'}
                  marginDense={true}
                  value={formData.type}
                  itemsList={itemsListTypePieceJointe}
                  onChange={handleChange('type')}
                  onError={errorFields.typeError}
                />
                {errorFields.typeError &&
                  <Typography id={'typePJRequired'} className={`${classes.red} ${classes.error}`}>
                    Veuillez sélectionner le type de la pièce jointe
                  </Typography>
                }
              </div>

              <div style={{marginTop: '10px'}}>
                <Typography className={classes.libelle}>Nature</Typography>
                <TextFieldSelect
                  className={classes.textField}
                  id={'nature'}
                  name={'nature'}
                  marginDense={true}
                  value={formData.nature}
                  withEmptyItem={true}
                  emptyItemLabel={'Aucune'}
                  itemsList={itemsListNaturePieceJointe}
                  onChange={handleChange('nature')}
                />
              </div>

              <div style={{marginTop: '10px'}}>
                <Typography className={classes.libelle}>Version</Typography>
                <TextFieldSearch
                  id={'version'}
                  className={classes.textField}
                  value={formData.version}
                  maxLength={10}
                  onChange={handleChange('version')}
                />
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '16px'}}>
                <ButtonBlue
                  id={'boutonValider' + attachment.id}
                  libelle={'Valider'}
                  onClick={handleSubmit}
                  disabled={errorFields.libelleError || errorFields.typeError}
                />
              </div>
            </AccordionDetails>
          }
        </Accordion>
        <div style={{display: 'flex', width: '10%'}}>
          <div style={{margin: 'auto'}}>
            <ButtonBlueDelete id={'boutonSupprimer' + attachment.id} onClick={() => onDelete(attachment)}/>
          </div>
        </div>
      </div>
      {attachment.onError && attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : format de fichier non autorisé</Typography>
      }
      {attachment.onError && !attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : fichier trop volumineux</Typography>
      }
    </div>
  );
};

export default AttachmentForm;
