/* Noms de domaine dans la norme DARVA */
export const DOMAIN = {
  AR2: 'AR2',
  SPEC_SIV: 'SPEC_SIV',
  SIV: 'SIV',
};

/* Valeurs de code des éléments de calcul de type "Véhicule" */
export const elementsCalculTypeV: string[] = [
  '3', '5', '6', '8', 'A', 'B', 'F', 'I', 'J', 'Z',
];

/* Valeurs de code des éléments de calcul de type "Hors véhicule" */
export const elementsCalculTypeHV: string[] = [
  '4', '7', '9', 'G', 'H', 'K', 'L',
];

/* ************* */
/* DT 9242.01.01 */
/* ************* */
/* Valeur de code de l'élément vitré 'optique' */
export const elementVitreOptique = '6';
/* Valeur de code de l'élément vitré 'clignotant' */
export const elementVitreClignotant = 'A';
/* Valeur de code de l'élément vitré 'feux arriere' */
export const elementVitreFeuxArriere = 'B';
