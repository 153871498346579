import React from 'react';
import CommentaireBloc from './CommentaireBloc';
import PiecesJointesBloc from './PiecesJointesBloc';

const PiecesJointesCommentaire = () => {
  return (
    <div style={{marginBottom: '16px'}}>
      <CommentaireBloc/>
      <PiecesJointesBloc/>
    </div>
  );
};

export default PiecesJointesCommentaire;
