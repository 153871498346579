import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Numero} from './Numero';
import {Indice} from './Indice';
import {TypeVoie} from './TypeVoie';
import {NomVoie} from './NomVoie';
import {LieuDit} from './LieuDit';
import {Etage} from './Etage';
import {Immeuble} from './Immeuble';
import {CodePostal} from './CodePostal';
import {BoitePostale} from './BoitePostale';
import {Ville} from './Ville';
import {Pays} from './Pays';
import {helperTextWithIcon} from '../../../common/Utils';

interface AdresseProps {
  formulaire: string,
  formik: any,
  entity: string,
  formikValues: {
    numero: string,
    indice: string,
    type: string,
    nom: string,
    lieuDit: string,
    etage: string,
    immeuble: string,
    codePostal: string,
    boitePostale: string,
    ville: string,
    pays: string,
  },
  formikTouched: any,
  formikErrors: any,
}

const styles = () => ({
  flex: {
    'display': 'flex',
    'flexWrap': 'wrap' as const,
    'marginBottom': '24px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldMedium: {
    width: '200px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '168px',
    marginRight: '24px',
  },
  textfieldExtraSmall: {
    width: '134px',
    marginRight: '24px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
});

export const Adresse = (props: AdresseProps) => {
  const {formulaire, formik, entity, formikValues, formikTouched, formikErrors} = props;
  const classes = useEmotionStyles(styles);

  const onFocus = (field: string) => () => {
    const tab = entity !== '' ? entity.split('.') : [];
    let fieldTouched = '';

    tab.forEach((element, index) => {
      fieldTouched = fieldTouched + (index !== 0 ? '.' : '') + `${element}`;
    });
    fieldTouched = (fieldTouched !== '' ? fieldTouched + '.' : '') + `${field}`;

    formik.setFieldTouched(fieldTouched, false, false)
  };

  return (
    <div>
      <div className={classes.flex}>
        <Numero
          className={classes.textfieldExtraSmall}
          name={entity + '.numero'}
          formulaire={formulaire}
          value={formikValues.numero}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('numero')}
          onError={(formikTouched?.numero && !!formikErrors?.numero) || false}
          helperText={formikTouched?.numero && formikErrors?.numero ? formikErrors.numero : ''}
        />

        <Indice
          className={classes.textfieldMedium}
          name={entity + '.indice'}
          formulaire={formulaire}
          value={formikValues.indice}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('indice')}
          onError={(formikTouched?.indice && !!formikErrors?.indice) || false}
          helperText={formikTouched?.indice && formikErrors?.indice ? formikErrors.indice : ''}
        />

        <TypeVoie
          className={classes.textfield}
          name={entity + '.type'}
          formulaire={formulaire}
          value={formikValues.type}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('type')}
          onError={(formikTouched?.type && !!formikErrors?.type) || false}
          helperText={formikTouched?.type && formikErrors?.type ? formikErrors.type : ''}
        />

        <NomVoie
          className={classes.textfield}
          name={entity + '.nom'}
          formulaire={formulaire}
          value={formikValues.nom}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('nom')}
          onError={(formikTouched?.nom && !!formikErrors?.nom) || false}
          helperText={formikTouched?.nom && formikErrors?.nom ? formikErrors.nom : ''}
        />
      </div>

      <div className={classes.flex}>
        <LieuDit
          className={classes.textfield}
          name={entity + '.lieuDit'}
          formulaire={formulaire}
          value={formikValues.lieuDit}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('lieuDit')}
          onError={(formikTouched?.lieuDit && !!formikErrors?.lieuDit) || false}
          helperText={formikTouched?.lieuDit && formikErrors?.lieuDit ? formikErrors.lieuDit : ''}
        />

        <Etage
          className={classes.textfield}
          name={entity + '.etage'}
          formulaire={formulaire}
          value={formikValues.etage}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('etage')}
          onError={(formikTouched?.etage && !!formikErrors?.etage) || false}
          helperText={formikTouched?.etage && formikErrors?.etage ? formikErrors.etage : ''}
        />

        <Immeuble
          className={classes.textfield}
          name={entity + '.immeuble'}
          formulaire={formulaire}
          value={formikValues.immeuble}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('immeuble')}
          onError={(formikTouched?.immeuble && !!formikErrors?.immeuble) || false}
          helperText={formikTouched?.immeuble && formikErrors?.immeuble ? formikErrors.immeuble : ''}
        />
      </div>

      <div className={classes.flex}>
        <CodePostal
          className={classes.textfieldSmall}
          name={entity + '.codePostal'}
          formulaire={formulaire}
          value={formikValues.codePostal}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('codePostal')}
          onError={(formikTouched?.codePostal && !!formikErrors?.codePostal) || false}
          helperText={helperTextWithIcon(formik, 'codePostal', entity, classes.helpIcon)}
        />

        <BoitePostale
          className={classes.textfieldSmall}
          name={entity + '.boitePostale'}
          formulaire={formulaire}
          value={formikValues.boitePostale}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('boitePostale')}
          onError={(formikTouched?.boitePostale && !!formikErrors?.boitePostale) || false}
          helperText={formikTouched?.boitePostale && formikErrors?.boitePostale ? formikErrors.boitePostale : ''}
        />

        <Ville
          className={classes.textfield}
          name={entity + '.ville'}
          formulaire={formulaire}
          value={formikValues.ville}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('ville')}
          onError={(formikTouched?.ville && !!formikErrors?.ville) || false}
          helperText={formikTouched?.ville && formikErrors?.ville ? formikErrors.ville : ''}
        />

        <Pays
          className={classes.textfield}
          name={entity + '.pays'}
          formulaire={formulaire}
          value={formikValues.pays}
          onChangeValue={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={onFocus('pays')}
          onError={(formikTouched?.pays && !!formikErrors?.pays) || false}
          helperText={formikTouched?.pays && formikErrors?.pays ? formikErrors.pays : ''}
        />
      </div>
    </div>
  )
};
