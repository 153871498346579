import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as FormsIcon} from '../../images/icones/icn-forms.svg';
import {ReactComponent as AppointmentIcon} from '../../images/icones/icn-appointment.svg';
import {InfosRendezVousModel} from '../../model/detailsDossier/RendezVousModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Divider, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ErrorIcon from '@mui/icons-material/Error';

interface MenuComponentProps {
  isSIVItem: boolean,
  isFDVItem: boolean,
  isRDVItem: boolean,
  isFormsItem: boolean,
}

const styles = (theme: Theme) => ({
  menuIcon: {
    margin: '0px',
    fontSize: '2.5rem',
  },
  dividerVerticalMenu: {
    height: '55%',
    backgroundColor: 'white',
    marginLeft: '30px',
    marginRight: '30px',
  },
  menuItemRoot: {
    padding: '0px',
  },
  menuLinks: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: '100%',
  },
  linkContent: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  appointmentIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
});

export const MenuComponent = (props: MenuComponentProps) => {
  const classes = useEmotionStyles(styles);
  const {isSIVItem, isFDVItem, isRDVItem, isFormsItem} = props;

  const isInsideDossier = location.pathname.includes('/extranet/dossiers/mission/');

  const strListRdv = sessionStorage.getItem('listRdv');
  const listRdv : InfosRendezVousModel[] = strListRdv ? JSON.parse(strListRdv) : [];

  const token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenu);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget)
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null)
  };

  const onClickSIV = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  };

  const onClickForms = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  }

  const onClickRDV = () => {
    handleCloseMenu();
    sessionStorage.setItem('fromPath', location.pathname)
  };

  const toLinkForms = {
    pathname: '/extranet/dossiers/formulaires/',
  }

  const toLink = {
    pathname: (isInsideDossier || location.pathname.includes('/extranet/dossiers/fdv/')) ?
      '/extranet/dossiers/formulaires-siv/' + token :
      '/extranet/dossiers/formulaires-siv',
  };

  const getLinkRdv = () => {
    if (isInsideDossier) {
      if (listRdv.length === 0) {
        return '/extranet/dossiers/prise-rdv/prendre-rdv/' + token;
      }
    }
    return '/extranet/dossiers/prise-rdv/rechercher-rdv';
  };
  const toLinkRDV = {
    pathname: getLinkRdv(),
  };

  return (
    <>
      <>
        <Tooltip title='Menu'>
          <IconButton id={'boutonMenu'} aria-label="menu" aria-controls="menu" aria-haspopup="true"
            color="inherit" style={{padding: '12px 0px'}} onClick={handleClickMenu}
          >
            <MenuIcon className={classes.menuIcon}/>
          </IconButton>
        </Tooltip>
        <Divider classes={{root: classes.dividerVerticalMenu}} orientation={'vertical'}/>
      </>

      <Menu
        id="menu"
        anchorEl={anchorMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu}
        onClose={handleCloseMenu}
        hidden={!isRDVItem && !isSIVItem && !isFDVItem && !isFormsItem}
      >
        {isFormsItem &&
          <MenuItem classes={{root: classes.menuItemRoot}}>
            <Link id={'menuItemForms'} className={classes.menuLinks} to={toLinkForms} onClick={onClickForms}>
              <div className={classes.linkContent}>
                <FormsIcon className={classes.appointmentIcon}/>Formulaires
              </div>
            </Link>
          </MenuItem>
        }
        {isSIVItem &&
          <MenuItem classes={{root: classes.menuItemRoot}}>
            <Link id={'menuItemSIV'} className={classes.menuLinks} to={toLink} onClick={onClickSIV}>
              <div className={classes.linkContent}><InsertDriveFileIcon/>Gestion SIV</div>
            </Link>
          </MenuItem>
        }
        {isFDVItem &&
          <MenuItem classes={{root: classes.menuItemRoot}}>
            <Link id={'menuItemFDV'} className={classes.menuLinks} to={'/extranet/dossiers/fdv/' + token}
              onClick={handleCloseMenu}>
              <div className={classes.linkContent}><ErrorIcon/>Fiche de vigilance</div>
            </Link>
          </MenuItem>
        }
        {isRDVItem &&
          <MenuItem classes={{root: classes.menuItemRoot}}>
            <Link id={'menuItemRDV'} className={classes.menuLinks} to={toLinkRDV} onClick={onClickRDV}>
              <div className={classes.linkContent}>
                <AppointmentIcon className={classes.appointmentIcon}/>Rendez-vous
              </div>
            </Link>
          </MenuItem>
        }
      </Menu>
    </>
  )
};
