import React, {useEffect} from 'react'
import {getValueElement} from '../Utils';
import {Entete} from './Entete';

interface RequeteSAProps {
  data: any,
}

export const RequeteSA = (props: RequeteSAProps) => {
  const {data} = props

  useEffect(() => {
    document.title = 'Requête SA';
  }, []);

  return (
    <div style={{columnGap: '30px', width: '60%', margin: 'auto'}}>
      <Entete data={data} prefix={'ns1:'} formulaire={'SA'}
        withoutTitreContainer={true}
        refSinistre={getValueElement(data, 'ns1:', 'numero_sinistre')}
      />
    </div>
  )
};
