/**
 * class d'envrionnement
 */
class ConfigEnvironnement {
  ssoBaseUrl:string;
  realm:string;
  clientId:string;
  sslRequired:string;
  redirecturi:string;
  publicClient:string;
  service:string;
  /**
  * constructeur
  */
  constructor(ssoBaseUrl: string, realm:string, clientId:string, sslRequired:string,
      redirecturi:string, publicClient:string, service:string) {
    this.ssoBaseUrl = ssoBaseUrl
    this.realm = realm
    this.clientId = clientId
    this.sslRequired = sslRequired
    this.redirecturi = redirecturi
    this.publicClient = publicClient
    this.service = service
  }
}

export default ConfigEnvironnement;
