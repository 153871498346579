import React, {useContext, useState} from 'react';
import {useIconExtensionFile} from '../../../../common/Utils';
import {AttachmentProps, downloadOneAttachment} from '../DownloadAttachments';
import {Loader} from '../../../common/Loader';
import {Typography} from '@mui/material';
import {Attachment} from './MessageBloc';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../store/UserContext';

interface AttachmentFileProps {
  attachment: Attachment,
  author: string,
  typeDoc: string,
  referenceSinistre: string,
  dateReceive: string,
}

type StylesProps = { onErrorDownloadOne: boolean }
const styles = (theme: Theme, {onErrorDownloadOne}: StylesProps) => ({
  divAttachment: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: 'max-content',
    marginBottom: onErrorDownloadOne ? '5px' : '15px',
  },
  iconPj: {
    width: '50px',
  },
  loader: {
    height: '30px',
    marginTop: '-20px',
    marginLeft: '10px',
  },
  libelle: {
    flexGrow: 4,
    flexBasis: '0px',
  },
  error: {
    color: theme.palette.red.main,
    fontSize: '14px',
    margin: '0px 0px 15px 10px',
  },
});

export const AttachmentFile = (props: AttachmentFileProps) => {
  const {user} = useContext(UserContext);
  const {attachment, author, typeDoc, referenceSinistre, dateReceive} = props;
  const [isLoadingPJ, setIsLoadingPJ] = useState(false);
  const [onErrorDownloadOne, setOnErrorDownloadOne] = useState(false);
  const classes = useEmotionStyles<StylesProps>(styles, {onErrorDownloadOne});

  const IconAttachment = () => useIconExtensionFile(attachment.format);

  const att: AttachmentProps = {
    id: attachment.id,
    name: attachment.name,
    date: dateReceive.substring(0, 10).replaceAll('/', ''),
  };

  return (
    <>
      <div
        className={classes.divAttachment}
        onClick={() => downloadOneAttachment({attachment: att, author, typeDoc, referenceSinistre,
          immatriculation: '', numClient: user.numClient,
          setOnErrorDownload: setOnErrorDownloadOne, setIsLoading: setIsLoadingPJ})}
      >
        <div className={classes.iconPj}>
          {!isLoadingPJ &&
            <IconAttachment/>
          }
          {isLoadingPJ &&
            <Loader className={classes.loader} size={15}/>
          }
        </div>
        <Typography id={'type-name-pj-file-' + attachment.name.replaceAll('.', '')}
          className={classes.libelle}>{attachment.type} - {attachment.name}
        </Typography>
      </div>
      {onErrorDownloadOne &&
        <Typography className={classes.error}>
          Erreur lors du téléchargement
        </Typography>
      }
    </>
  )
};
