import {Dispatch} from 'react'

import {ResultAction, ResultDataState} from '../reducers/ResultReducer'
import {CardsModel} from '../model/rechercheDossier/CardsModel'

export const getResultActions = (state: ResultDataState, dispatch: Dispatch<ResultAction>) => ({
  setResult: (result : CardsModel ) => {
    dispatch( {type: 'SET_RESULT', result: result} )
  },
  setErreur: (error : string ) => {
    dispatch( {type: 'SET_ERREUR', error: error} )
  },
})

/**
 * This hack allow us to get the list of the methods names without maintaining a enum
 */
// get an "instance" of the function
// eslint-disable-next-line
// @ts-ignore
const getResultActionsDummy = (false as true) && getResultActions(undefined, undefined)
export type ResultActions = typeof getResultActionsDummy; // create a type from that instance


