import React from 'react';
import {ButtonBlue} from '../buttons/ButtonBlue';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Tooltip} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ButtonBlueDeleteProps {
  className?: string,
  id: string,
  disabled?: boolean,
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}

/** Design */
const styles = () => ({
  button: {
    padding: '5px',
    minWidth: 'inherit',
    width: '30px',
    height: '30px',
    wordBreak: 'normal' as const,
  },
})

export const ButtonBlueDelete = ({className, id, disabled, onClick}: ButtonBlueDeleteProps) => {
  const classes = useEmotionStyles(styles);
  return (
    <ButtonBlue
      id={id}
      className={`${className} ${classes.button}`}
      icon={<Tooltip disableInteractive title='Supprimer' PopperProps={{disablePortal: true}}>
        <DeleteForeverIcon style={{margin: '0px', width: '22px'}}/>
      </Tooltip>}
      disabled={disabled}
      onClick={onClick}
    />
  )
}
