import React, {useEffect, useState} from 'react';
import {
  Card,
  CardContent,
  Modal,
  Slide,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import {EtapeReparation, Etiquette, SousEtapeReparation} from '../../../../model/detailsDossier/SuiviReparationModel';
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg';
import {ReactComponent as CheckCircleIcon} from '../../../../images/icones/icn-check-circle.svg';
import {ReactComponent as ArrowBackIcon} from '../../../../images/icones/icn-arrow-back.svg';
import {ReactComponent as ArrowForwardIcon} from '../../../../images/icones/icn-arrow-forward.svg';
import {ReactComponent as WarningIcon} from '../../../../images/icones/icn-warning.svg';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {customTheme} from '../../../../common/GlobalTheme';
import {useMissionContext} from '../../../../store/MissionContext';
import {IFRForm} from '../../../forms/suiviRep/infosRep/IFRForm';
import {UserContext} from '../../../../store/UserContext';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import EtiquetteBloc from './EtiquetteBloc';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {StepIconProps} from '@mui/material/StepIcon';

interface SuiviReparationsBlocProps {
  etapesReparation: EtapeReparation[]
  etiquette?: Etiquette
  isInOngletGestion: boolean
  onClickEtiquette?: (tabPanelValue: number, date: string) => void
  onClickStepper?: (tabPanelValue: number) => void
}

interface StepIconComponentProps {
  active?: boolean,
  completed?: boolean,
  isEtape: boolean,
}

type StylesProps = {
  color?: string,
  isPointer?: boolean,
}

const styles = (theme: Theme, {color, isPointer}: StylesProps) => ({
  cardPrint: {
    ['@media print']: {
      boxShadow: 'none',
    },
  },
  card: {
    minHeight: '200px',
  },
  button: {
    margin: '-2px 0px 15px',
  },
  content: {
    display: 'flex',
    marginTop: '10px',
  },
  myStepper: {
    'width': '100%',
    '& .MuiStepLabel-label.Mui-completed': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepLabel-label': {
      color: theme.palette.secondary.dark2,
    },
    '& .MuiPaper-root': {
      padding: '0px',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  stepLabelRoot: {
    '&.Mui-disabled': {
      cursor: isPointer ? 'pointer' : '',
    },
  },
  iconStepperEtape: {
    fill: color,
    width: '40px',
    height: '40px',
    marginTop: '-6px',
  },
  iconStepperSsEtape: {
    fill: color,
    width: '20px',
    height: '20px',
    marginTop: '3px',
  },
  date: {
    fontSize: '12px',
    color: theme.palette.secondary.dark2,
  },
  warningIcon: {
    marginRight: '5px',
  },
  libelle: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  arrowButton: {
    '&:disabled': {
      backgroundColor: 'initial',
    },
  },
  arrowIcon: {
    width: '45px',
    height: '45px',
  },
  etiquette: {
    height: '30px',
    cursor: 'pointer',
  },
  stepConnector: {
    '& .Mui-active': {
      '& .MuiStepConnector-line': {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        height: 2,
      },
    },
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        height: 2,
      },
    },
    '& .MuiStepConnector-line': {
      border: 'none',
      backgroundColor: theme.palette.secondary.main,
      height: 2,
    },
  },
});

const StepIconComponent = ({active, completed, isEtape}: StepIconComponentProps) => {
  const color = active || completed ? customTheme.palette.primary.main : customTheme.palette.secondary.main;
  const classes = useEmotionStyles(styles, {color});
  return (
    <>
      {active &&
        <InProgressIcon className={isEtape ? classes.iconStepperEtape : classes.iconStepperSsEtape}/>
      }
      {completed &&
        <CheckCircleIcon className={isEtape ? classes.iconStepperEtape : classes.iconStepperSsEtape}/>
      }
      {!active && !completed &&
        <RadioButtonUncheckedIcon className={isEtape ? classes.iconStepperEtape : classes.iconStepperSsEtape}
          style={{marginRight: '0px'}}/>
      }
    </>
  );
};

/**
 * Fonction qui retourne un icone personnalisé pour le stepper
 */
function customStepIconComponent(props: StepIconProps, isEtape: boolean) {
  const {active, completed} = props;
  return <StepIconComponent active={active} completed={completed} isEtape={isEtape}/>
}

/**
 * Fonction qui retourne si l'objet passé en param est une EtapeReparation
 */
function isEtape(etape: EtapeReparation | SousEtapeReparation): etape is EtapeReparation {
  return (etape as EtapeReparation).numero !== undefined;
}

const SuiviReparationsBloc = (props: SuiviReparationsBlocProps) => {
  const {etapesReparation, etiquette, onClickEtiquette, onClickStepper} = props;
  const {mission} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const isIfrOrSrp = mission?.historiqueDTO?.list.some((item) => item.type === 'SRP' || item.type === 'IFR');
  const isPointer = isIfrOrSrp && props.isInOngletGestion;
  const classes = useEmotionStyles(styles, {isPointer})
  const destAssureur = mission?.acteursDTO?.acteurs?.find((acteur) => acteur.role === 'ASSUREUR');
  const [openIFRForm, setOpenIFRForm] = useState(false);
  const [indexEnd, setIndexEnd] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [etapesEtSousEtapesReparations, setEtapesEtSousEtapesReparations] =
    useState<Array<EtapeReparation | SousEtapeReparation>>([]);

  // etapeEnCours = étape avec l'état à 1 (1 = en cours)
  const etapeEnCours = etapesReparation.filter((etape) => etape.etat === 1);
  // Si pas d'étape 0, activeStep=numero-1 (-> le stepper commence à 0)
  const activeStep = etapesReparation.filter((etape) => etape.numero === 0).length > 0 ?
    etapeEnCours[0]?.numero : etapeEnCours[0]?.numero-1;

  useEffect(() => {
    const etapesReparationCopy = JSON.parse(JSON.stringify(etapesReparation));
    const tabTmp: Array<EtapeReparation | SousEtapeReparation> = [];
    etapesReparationCopy.map((etape: EtapeReparation) => {
      tabTmp.push(etape);
      etape.sousEtapesReparation?.map((ssEtape: SousEtapeReparation) => {
        ssEtape.etat = etape.etat;
        tabTmp.push(ssEtape);
      })
    });
    setEtapesEtSousEtapesReparations(tabTmp);
    setIndexEnd(tabTmp.length);
    setIndexStart(tabTmp.length - 4);
  }, [etapesReparation]);

  const isInfoOrAlerte = etiquette && (etiquette.typeEtiquette === 'INFORMATION' ||
      etiquette.typeEtiquette === 'ALERTE');
  const isSollicitationEnAttente= etiquette && etiquette.typeEtiquette === 'SOLLICITATION';

  const slideContentIFR = (
    <div>
      <IFRForm destinataire={destAssureur} onClose={() => onCloseForm()} onCloseAndRefresh={() => {
        setOpenIFRForm(false);
        window.location.reload()
      }}/>
    </div>
  );

  const onCloseForm = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setOpenIFRForm(false);
    }
  };

  const goBack = () => {
    setIndexStart(indexStart - 1);
    setIndexEnd(indexEnd - 1);
  };

  const goNext = () => {
    setIndexStart(indexStart + 1);
    setIndexEnd(indexEnd + 1);
  };

  return (
    <>
      <Card className={!props.isInOngletGestion ? classes.card : classes.cardPrint}>
        <CardContent>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
              <Typography
                variant={'h3'}
                id={'suiviReparationTitle'}
                className={isIfrOrSrp && props.isInOngletGestion && onClickStepper ? classes.pointer : ''}
                onClick={() => isIfrOrSrp && props.isInOngletGestion && onClickStepper && onClickStepper(4)}
              >
                Suivi des réparations
              </Typography>
              {props.isInOngletGestion && user.type === 'Carrossier' && isSollicitationEnAttente &&
                <div style={{marginLeft: '12px'}}>
                  <EtiquetteBloc
                    etiquette={etiquette}
                    onClickEtiquette={onClickEtiquette}
                  />
                </div>
              }
              {props.isInOngletGestion && user.type === 'Assureur' && isInfoOrAlerte &&
                 <div style={{marginLeft: '12px'}}>
                   <EtiquetteBloc
                     etiquette={etiquette}
                     onClickEtiquette={onClickEtiquette}
                   />
                 </div>
              }
            </div>
          </div>
          <div className={classes.content}>
            {!props.isInOngletGestion && etapesEtSousEtapesReparations.length > 4 &&
              <div>
                <ButtonLink
                  id={'boutonBack'}
                  className={classes.arrowButton}
                  onClick={goBack}
                  disabled={indexStart === 0}
                  icon={<ArrowBackIcon className={classes.arrowIcon}/>}
                />
              </div>
            }
            <div id={'stepper-avancement'} className={classes.myStepper}>
              <Stepper alternativeLabel activeStep={activeStep}
                connector={<StepConnector className={classes.stepConnector}/>}>
                {/* Onglet Gestion */}
                {props.isInOngletGestion && etapesReparation.map((etape, index) => {
                  const keyStep = `${index}`
                  return (
                    <Step
                      key={keyStep}
                      className={isIfrOrSrp && onClickStepper ? classes.pointer : ''}
                      completed={etape.etat === 2}
                      active={etape.etat === 1}
                      onClick={() => isIfrOrSrp && onClickStepper && onClickStepper(4)}
                    >
                      <StepLabel
                        classes={{root: classes.stepLabelRoot, disabled: (etape.etat === 0).toString()}}
                        StepIconComponent={customStepIconComponent}
                      >
                        <Typography id={'gestion-stepper-etape-' + index} className={classes.libelle}>
                          {etape.libelle}</Typography>
                      </StepLabel>
                    </Step>
                  )
                })}
                {/* Onglet Suivi des réparations */}
                {!props.isInOngletGestion &&
                  etapesEtSousEtapesReparations.slice(indexStart, indexEnd).map((etape, index) => {
                    const titleTooltip = !isEtape(etape) && etape.infosComplementaires !== '' ?
                      <span>
                        <Typography style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Informations complémentaires
                        </Typography>
                        <Typography style={{fontSize: '12px'}}>{etape.infosComplementaires}</Typography>
                      </span> : '';
                    const keyStep = `${index}`
                    return (
                      <Step key={keyStep} completed={etape.etat === 2} active={etape.etat === 1}>
                        <StepLabel classes={{disabled: (etape.etat === 0).toString()}}
                          StepIconComponent={(e) => customStepIconComponent(e, isEtape(etape))}
                        >
                          {!isEtape(etape) &&
                            <Typography className={classes.date}>
                              {etape.date.slice(0, 10)}
                            </Typography>
                          }
                          <Tooltip disableInteractive placement="bottom" title={titleTooltip}>
                            <Typography id={'srp-stepper-etape-' + index} className={classes.libelle}>
                              {!isEtape(etape) && etape.type === 'alerte' &&
                                <WarningIcon className={classes.warningIcon}/>
                              }
                              {etape.libelle}
                            </Typography>
                          </Tooltip>
                        </StepLabel>
                      </Step>
                    )
                  })
                }
              </Stepper>
            </div>
            {!props.isInOngletGestion && etapesEtSousEtapesReparations.length > 4 &&
              <div>
                <ButtonLink
                  id={'boutonNext'}
                  className={classes.arrowButton}
                  onClick={goNext}
                  disabled={indexEnd === etapesEtSousEtapesReparations.length}
                  icon={<ArrowForwardIcon className={classes.arrowIcon}/>}
                />
              </div>
            }
          </div>
        </CardContent>
      </Card>

      <Modal
        open={openIFRForm}
        aria-labelledby="modal-form-IFR"
        aria-describedby="modal-formulaire-envoie-IFR"
      >
        <Slide in={openIFRForm} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentIFR}
        </Slide>
      </Modal>
    </>
  );
};

export default SuiviReparationsBloc;
