import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface NomRdvProps {
  className?: string,
  classNameTextField?: string,
  fullWidth?: boolean,
  formulaire: string,
  nomUsage: boolean,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: string,
  typeError?: string,
  value: string,
  maxLength?: number,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
});

export const NomRdv = ({className, classNameTextField, fullWidth, formulaire, nomUsage, onChangeValue, onBlur,
  onFocus, onError, helperText, typeError, value, maxLength}: NomRdvProps) => {
  const classes = useEmotionStyles(styles);

  const getErrorMessage = () => {
    switch (typeError) {
      case '':
        return 'Veuillez saisir une valeur';
      case 'errorChar':
        return 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés';
      default:
        return ''
    }
  };

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        {nomUsage ? 'Nom d\'usage ' : 'Nom '}{!nomUsage && <span className={classes.red}>*</span>}
      </Typography>

      <TextFieldSearch
        className={classNameTextField}
        id={nomUsage ? 'nomUsage' + formulaire : 'nom' + formulaire}
        name='nom'
        fullWidth={fullWidth}
        maxLength={maxLength}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
      />
      {onError &&
        <Typography className={`${classes.red} ${classes.error}`}>
          {getErrorMessage()}
        </Typography>
      }
    </div>
  )
};
