import React from 'react'
import {IPartenaire} from '../components/forms/pec-bg/model/ModelPECBG';

export interface UserContext {
  user : UserContextType,
  setDataUser : (user : UserContextType) => void,
}

export interface UserContextType {
    userCompte: string,
    nom: string,
    type: string,
    roles: string[],
    codeAbo: string,
    service: string,
    listeServices: Service[],
    codeGTA: string,
    numClient: string,
    isAssistance: boolean,
    isTransparent: boolean,
    isSSO: boolean,
    noClient?: string,
    raisonSociale: string,
    raisonSocialeInfogeo: string,
    siret: string,
    siren: string,
    telephone: string,
    token: string,
    adresse: {
      complementAdresse: string,
      numeroVoie: string,
      indiceRepetition: string,
      typeVoie: string,
      libelleVoie: string,
      codePostal: string,
      codeCedex: string,
      distributionSpeciale: string,
      libelleCommune: string,
      libelleCedex: string,
    },
  filiales: Filiale[],
  agrements: Agrement[]
  agrementsBG: IPartenaire[]
}

export interface Filiale {
  raisonSociale: string,
  codeAbonne: string,
  codeGta: string,
  numeroClient: string,
  siret: string,
}

export interface Service {
    codeService: string,
    nomService: string,
}

export interface Agrement {
  codeAgrement: string,
  _ref_partenaire: string,
  numero_client: string,
  nom: string,
  codeService: string,
  codeRole: string,
  refChezAssureur: string,
  raisonSociale: string,
  codeGTA: string,
  telephone: string,
  uri: string
}

const userCompte = '';
const nom = '';
const type = '';
const roles = [''];
const codeAbo = '';
const service = '';
const codeGTA = '';
const numClient = '';
const isAssistance = false;
const isTransparent = false;
const isSSO = false;
const raisonSociale = '';
const raisonSocialeInfogeo = '';
const siret = '';
const siren = '';
const telephone = '';
const token = '';
const listeServices: Service[] = [];
const adresse = {
  complementAdresse: '',
  numeroVoie: '',
  indiceRepetition: '',
  typeVoie: '',
  libelleVoie: '',
  codePostal: '',
  codeCedex: '',
  distributionSpeciale: '',
  libelleCommune: '',
  libelleCedex: '',
};
const filiales: Filiale[] = [];
const agrements: Agrement[] = [];
const agrementsBG: IPartenaire[] = [];

export const userInitialState = {
  userCompte,
  nom,
  type,
  roles,
  codeAbo,
  service,
  listeServices,
  codeGTA,
  numClient,
  isAssistance,
  isTransparent,
  isSSO,
  raisonSociale,
  raisonSocialeInfogeo,
  siret,
  siren,
  telephone,
  token,
  adresse,
  filiales,
  agrements,
  agrementsBG,
};

const setDataUser = (user : UserContextType) => {
  // This is intentional
};


export const UserContext = React.createContext<UserContext>(
    {
      user: userInitialState,
      setDataUser,
    },
);
