import React from 'react';
import {customTheme} from '../../common/GlobalTheme';
import ConfigEnvironnement from '../../ConfigEnvironnement';
import {UserContext, UserContextType} from '../../store/UserContext';
import {IconButton, Menu, MenuItem, Typography} from '@mui/material';
import {AccountCircle, Home, ExitToApp} from '@mui/icons-material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

interface MenuAccountProps {
  configEnvironnement: ConfigEnvironnement,
  setUser?: (User: UserContextType) => void,
  onClickPortail: () => void,
  isInsideDossier: boolean,
  disabled: boolean,
}

const styles = (theme: Theme) => ({
  iconButton: {
    padding: '12px 0px',
  },
  accountCircle: {
    fontSize: '3rem',
  },
  name: {
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.white.main,
    opacity: '1',
  },
  userMenu: {
    minWidth: '200px',
  },
  itemPortail: {
    margin: '10px 0px',
    color: theme.palette.link.main,
  },
  separator: {
    width: '85%',
    borderTop: '1px solid ' + theme.palette.secondary.dark1,
    borderBottom: 'none',
  },
  titleService: {
    padding: '6px 16px',
    fontWeight: 'bold',
    color: theme.palette.tertiary.main,
  },
  listServices: {
    padding: '0px 12px',
  },
  itemDeconnexion: {
    marginBottom: '10px',
    color: theme.palette.link.main,
  },
});

export const MenuAccount = (props: MenuAccountProps) => {
  const {configEnvironnement, setUser, onClickPortail, isInsideDossier, disabled} = props;
  const classes = useEmotionStyles(styles, {isDisabled: disabled});
  const {user} = React.useContext(UserContext);

  const [anchorAccount, setAnchorAccount] = React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorAccount);

  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccount(event.currentTarget)
  };
  const handleCloseAccount = () => {
    setAnchorAccount(null)
  };

  const changeService = (codeService: string) => {
    handleCloseAccount();
    const newUser = {...user, service: codeService};
    if (setUser) {
      setUser(newUser);
    }
    sessionStorage.setItem('user', JSON.stringify(newUser));
    sessionStorage.setItem('choixService', codeService);
    window.location.reload()
  };

  const doLogout = () => {
    const {ssoBaseUrl, realm, redirecturi} = configEnvironnement;
    const redirectUrl = user.isSSO ? `${redirecturi}?kc_idp_hint=groupama-dev` : redirecturi
    const logoutUrl = `${ssoBaseUrl}/auth/realms/${realm}/protocol/openid-connect/logout?id_token_hint=${user.token}
    &post_logout_redirect_uri=${redirectUrl}`;

    handleCloseAccount();
    sessionStorage.clear();
    localStorage.removeItem('isTraked');
    const fileLink = document.createElement('a');
    fileLink.href = logoutUrl;
    fileLink.click()
  };

  return (
    <>
      <IconButton
        id={'boutonUtilisateur'}
        aria-label="utilisateur"
        aria-controls="menu-account"
        aria-haspopup="true"
        className={classes.iconButton}
        color="inherit"
        onClick={handleClickAccount}
        disabled={disabled}
        sx={{
          '&.Mui-disabled': {
            color: customTheme.palette.secondary.dark2,
          },
        }}
      >
        <AccountCircle className={classes.accountCircle}/>
        <Typography className={classes.name}>{user.nom ? user.nom : user.raisonSociale}</Typography>
      </IconButton>

      <Menu
        id="menu-account"
        anchorEl={anchorAccount}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openAccount}
        onClose={handleCloseAccount}
        classes={{
          paper: classes.userMenu,
        }}
      >
        <MenuItem className={classes.itemPortail} onClick={onClickPortail}>
          <Home/>Portail
        </MenuItem>

        <hr className={classes.separator}/>

        <div className={classes.titleService}>Service</div>
        <div className={classes.listServices}>
          {user.listeServices.map((service, index) => {
            const keyMenuItem = `${index}`
            return (
              <MenuItem
                key={keyMenuItem}
                style={{backgroundColor: user.service === service.codeService ? customTheme.palette.primary.main : '',
                  color: user.service === service.codeService ? customTheme.palette.white.main : '',
                }}
                value={service.codeService}
                disabled={isInsideDossier}
                onClick={() => changeService(service.codeService)}
              >
                {service.nomService}
              </MenuItem>
            )
          })}
        </div>

        <hr className={classes.separator}/>

        <MenuItem className={classes.itemDeconnexion} onClick={doLogout}>
          <ExitToApp/>Déconnexion
        </MenuItem>
      </Menu>
    </>
  )
};
