import React from 'react'
import {FormControl, Typography} from '@mui/material';
import InputMask from 'react-input-mask-3.0';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface TelephoneProps {
  title: string,
  isMandatory: boolean | undefined,
  className?: string,
  classNameTextField?: string,
  style?: object,
  fullWidth?: boolean,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError?: boolean,
  typeError?: string | null,
  defaultTelephone?: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const Telephone = ({title, isMandatory, className, classNameTextField, style, fullWidth, formulaire,
  onChangeValue, onBlur, onFocus, onError, typeError, defaultTelephone}: TelephoneProps) => {
  const classes = useEmotionStyles(styles);

  const getErrorMessage = () => {
    switch (typeError) {
      case '':
        return 'Veuillez saisir une valeur';
      case 'errorChar':
        return 'Saisissez un n° de téléphone mobile au bon format';
      default:
        return ''
    }
  };

  return (
    <div className={className} style={style}>
      <Typography className={classes.libelle}>
        {title}{isMandatory?<span style={{color: 'red'}}> *</span>:''}
      </Typography>
      <InputMask
        mask="99 99 99 99 99"
        maskPlaceholder={null}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <FormControl error fullWidth={fullWidth}>
          <TextFieldSearch
            id={'telephone' + formulaire}
            className={classNameTextField}
            name='telephone'
            maxLength={80}
            onError={onError}
            onChange={onChangeValue}
            onBlur={onBlur}
            onFocus={onFocus}
            value={defaultTelephone}
          />
          {onError &&
            <Typography className={`${classes.red} ${classes.error}`}>
              {getErrorMessage()}
            </Typography>
          }
        </FormControl>
      </InputMask>
    </div>
  )
};
