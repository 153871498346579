import React, {useContext} from 'react';
import {Typography} from '@mui/material';
import {getDocumentPdf} from '../../../../api/dossier';
import {useParams} from 'react-router';
import {ParamsMissionType} from '../../DetailsDossier';
import {ItemHistoriqueModel, PaireModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import {Link} from 'react-router-dom';
import {TokenEdiModel} from '../../../../model/common/TokenEdiModel';
import {ReactComponent as WarningIcon} from '../../../../images/icones/icn-warning.svg'
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg'
import {MessageBubble} from '../../../common/MessageBubble';
import {customTheme} from '../../../../common/GlobalTheme';
import {Loader} from '../../../common/Loader';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {TokenModel} from '../../../../model/common/TokenModel';
import {UserContext} from '../../../../store/UserContext';

const base64 = require('base-64');

interface SimplifiedBlocHistoriqueProps {
  indexBubble?: number,
  item: ItemHistoriqueModel,
  click: (tabPanelValue: number, idPJ: string) => void,
  onClickComment?: (tabPanelValue: number, date: string) => void
}

/** Design */
const styles = (theme: Theme) => ({
  lien: {
    color: theme.palette.link.main,
    textDecoration: 'none',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '20px',
    gridRowGap: '10px',
    gridTemplateAreas: `'left right'
    'footer footer'`,
  },
  itemLeft: {
    gridArea: 'left',
  },
  itemRight: {
    gridArea: 'right',
  },
  itemFooter: {
    gridArea: 'footer',
  },
  footer: {
    color: theme.palette.link.main,
    padding: '0px 10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  divClick: {
    cursor: 'pointer',
    width: 'max-content',
    marginLeft: '10px',
  },
  loader: {
    marginRight: '10px',
  },
  etatMessageErreur: {
    width: 'max-content',
    float: 'left' as const,
    color: '#FF5733',
  },
  etatMessageAttente: {
    width: 'max-content',
    float: 'left' as const,
    color: '#229954',
  },
});

export const SimplifiedBlocHistorique = ({indexBubble, item, click, onClickComment}:
                                           SimplifiedBlocHistoriqueProps ) => {
  const classes = useEmotionStyles(styles);
  const params = useParams<keyof ParamsMissionType>() as ParamsMissionType;
  const {user} = useContext(UserContext);
  const search = JSON.parse(base64.decode(params.token));

  const [pdfIsPending, setPdfIsPending] = React.useState<boolean>(false);

  const handlePdf = () => {
    if (item.idDocument) {
      setPdfIsPending(true);
      const urlParams: TokenModel = !user.isTransparent ? search : {
        ...search,
        codeGTA: user.codeGTA,
        codeAbonne: user.codeAbo,
        userCompte: user.userCompte,
      };
      getDocumentPdf(item.idDocument, urlParams, item.typeCode, item.ssType ?? '', item.isnIdentifier,
          user.isTransparent)
          .then((response) => {
          // Create a Blob from the PDF Stream
            const file = new Blob(
                [response],
                {type: 'application/pdf'});
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // Open the URL on new Window
            if (window.navigator.msSaveOrOpenBlob) {
            // IE11
              window.navigator.msSaveOrOpenBlob(file, 'document.pdf')
            } else {
              window.open(fileURL)
            }
            setPdfIsPending(false)
          }).catch(() => {
            setPdfIsPending(false)
          })
    }
  };

  const ARCHIVE = 'O';
  const EN_COURS_DESARCHIVAGE = 'A';
  const EN_ERREUR = 'EN ERREUR';
  const EN_ATTENTE_GESTION = 'EN ATTENTE DE GESTION';
  const INACTIF_SD = 'INACTIF PAR SD';

  const ediButton = () => {
    const isSIVsynchro = ('CSIT00' === item.typeCode ||
        'CSSA00' === item.typeCode || 'CSCT00' === item.typeCode || 'ODDA00' === item.typeCode ||
        'ODDC00' === item.typeCode || 'ODIR00' === item.typeCode || 'AQIR00' === item.typeCode ||
        'ISIT00' === item.typeCode || 'ISSA00' === item.typeCode || 'ISCT00' === item.typeCode ||
        'AQDA00' === item.typeCode || 'AQDC00' === item.typeCode);
    const token: TokenEdiModel = {
      idDocument: item.idDocument,
      search: search,
      type: item.typeCode,
      ssType: item.ssType,
      isnIdentifier: item.isnIdentifier,
    };
    const tokenEdi = base64.encode(JSON.stringify(token));
    return (
      isSIVsynchro &&
        <Link id={`visualizeButton-${indexBubble}`}
          className={classes.lien}
          to={'/extranet/mission/vue-edi/' + tokenEdi}
          target={'_blank'}>
          <Typography variant="button" display="inline" style={{fontSize: '14px'}}>
            VISUALISER
          </Typography>
        </Link>
    )
  };

  const canOpenPdf = () => {
    return !('DI0000' === item.typeCode || 'RP0000' === item.typeCode || 'CSIT00' === item.typeCode ||
      'CSSA00' === item.typeCode || 'CSCT00' === item.typeCode || 'ODDA00' === item.typeCode ||
      'ODDC00' === item.typeCode || 'ODIR00' === item.typeCode || 'AQIR00' === item.typeCode ||
      'ISIT00' === item.typeCode || 'ISSA00' === item.typeCode || 'ISCT00' === item.typeCode ||
      'AQDA00' === item.typeCode || 'AQDC00' === item.typeCode || ('410301' === item.typeCode &&
        '31' === item.ssType) || item.archive === ARCHIVE || item.archive === EN_COURS_DESARCHIVAGE) &&
      item.docVisualisable;
  };

  const onClickCommentDate = () => {
    if (item.envoyeRecu === 'recu') {
      return (item.dateReception! + item.typeCode + item.emetteurAbo!.trim())
    } else if (item.envoyeRecu === 'envoye') {
      return (item.dateReception! + item.typeCode + item.destinataireAbo!.trim())
    } else {
      // Mode support
      return (item.dateReception! + item.typeCode + item.emetteurAbo!.trim() + item.destinataireAbo!.trim())
    }
  }

  const content = () => {
    return (
      <>
        {item.specificMetier && item.specificMetier.length > 0 &&
          <div className={classes.content}>
            {item.specificMetier.length > 0 && item.specificMetier.map((paire: PaireModel, index: number) => {
              const className = () => {
                if (item.specificMetier?.length === 3) {
                  if (index === 0) return classes.itemLeft;
                  if (index === 1) return classes.itemRight;
                  if (index === 2) return classes.itemFooter;
                }
                return undefined
              };
              const keyDiv = `${index}`
              return (
                <div id={`inlineLabel-${indexBubble}-${index}`} key={keyDiv} className={className()?className():''}>
                  <Typography display="inline">
                    {paire.libelle && paire.valeur ?
                      <span>
                        <span style={customTheme.styledTypography.libelle}>{paire.libelle} : </span>
                        <span style={customTheme.styledTypography.donneeGrise16}>
                          {paire.valeur === 'O' && 'Oui'}
                          {paire.valeur === 'N' && 'Non'}
                          {paire.valeur !== 'O' && paire.valeur !== 'N' && paire.valeur}
                        </span>
                      </span> :
                      <span>{paire.libelle}{paire.valeur}</span>
                    }
                  </Typography>
                </div>
              )
            })}
          </div>
        }
        <div className={classes.footer} style={{marginTop: !item.specificMetier || item.specificMetier.length < 1 ?
            '0px' : '10px'}}>
          <div>
            {item.etat?.toUpperCase() === EN_ATTENTE_GESTION && (
              <div className={classes.etatMessageAttente}>
                <InProgressIcon style={{fill: '#229954', marginBottom: '-3px', marginLeft: '5px'}}/>
                <Typography display="inline" style={{fontSize: '12px', margin: '5px'}}>
                  En attente de gestion
                </Typography>
              </div>
            )}
            {item.etat?.toUpperCase() === EN_ERREUR && (
              <span className={classes.etatMessageErreur}>
                <WarningIcon style={{marginBottom: '-3px', marginLeft: '5px'}}/>
                <Typography display="inline" style={{fontSize: '12px', margin: '5px'}}>
                  En erreur
                </Typography>
              </span>
            )}
          </div>
          <div style={{display: 'flex'}}>
            <div id={`commentButton-${indexBubble}`} className={classes.divClick}>
              {item.isComment && (
                <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                  onClick={(_) => onClickComment ?
                      onClickComment(2, onClickCommentDate()) : ''}
                >
                  COMMENTAIRES
                </Typography>
              )}
            </div>
            <div id={`pJButton-${indexBubble}`} className={classes.divClick}>
              {item.nbPieceJointe > 0 && item.etat.toUpperCase() !== EN_ATTENTE_GESTION &&
                item.etat.toUpperCase() !== INACTIF_SD &&
                <Typography variant="button" display="inline" style={{fontSize: '14px'}}
                  onClick={() => click(3, item.idPieceJointe)}>
                  PIECES JOINTES
                </Typography>
              }
            </div>
            <div id={`viewButton-${indexBubble}`} className={classes.divClick}>
              {pdfIsPending &&
                <Loader className={classes.loader} size={20}/>
              }
              {!pdfIsPending && canOpenPdf() &&
                <Typography variant="button" display="inline" style={{fontSize: '14px'}} onClick={handlePdf}>
                  CONSULTER
                </Typography>
              }
              {ediButton()}
            </div>
          </div>
        </div>
      </>
    )
  };

  return (
    <MessageBubble indexBubble={indexBubble} titleBold={item.type + ' - ' + item.libelle} contentBubble={content()}
      subtitle={item.envoyeRecu === 'recu' ? 'De : ' + item.emetteur : 'À : ' + item.destinataire}
      dateHeure={item?.dateReception?.replace('-', 'à')} typeBubble={item.envoyeRecu}/>
  )
};
