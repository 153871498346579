import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  multiline: {
    width: '100%',
  },
  title: {
    marginBottom: '8px',
  },
});

const CommentaireBloc = () => {
  const classes = useEmotionStyles(styles);
  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'} className={classes.title}>
        Votre message
      </Typography>
      <div className={classes.flex}>
        <InputTextField
          name='commentaire'
          multiline={true}
          minRows={6}
          maxRows={6}
          className={classes.multiline}
        />
      </div>
    </div>
  );
};

export default CommentaireBloc;
