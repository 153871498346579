import React from 'react'
import {Tooltip, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import InputMask from 'react-input-mask-3.0';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface SiretProps {
  id: string,
  className?: string,
  name: string,
  formulaire: string,
  isMandatory: boolean,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: React.JSX.Element | string,
  value: string,
}

const styles = (theme: Theme) => ({
  title: {
    display: 'flex',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  tooltip: {
    marginLeft: '10px',
    color: theme.palette.secondary.dark2,
  },
});

export const Siret = ({id, className, name, formulaire, isMandatory, onChangeValue, onBlur, onFocus, onError,
  helperText, value}: SiretProps) => {
  const classes = useEmotionStyles(styles);

  const titleTooltip = <span>Saisissez le numéro SIRET à 14 chiffres</span>;

  return (
    <div className={className}>
      <div className={classes.title}>
        <Typography className={classes.libelle}>
          SIRET&nbsp;{isMandatory && <span className={classes.red}>*</span>}
        </Typography>
        <Tooltip
          className={classes.tooltip}
          disableInteractive
          placement="top"
          title={titleTooltip}
          PopperProps={{disablePortal: true}}
        >
          <HelpIcon/>
        </Tooltip>
      </div>

      <InputMask
        mask="99999999999999"
        maskPlaceholder={null}
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <TextFieldSearch
          id={id + formulaire}
          name={name}
          fullWidth={true}
          onError={onError}
          helperText={helperText}
        />
      </InputMask>
    </div>
  )
};
