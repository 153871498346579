import React from 'react';
import {CardContainer} from '../CardContainer';
import {getLibelleValeur, getValueElement} from '../Utils';

interface TitulaireActuelProps {
  data: any,
  prefix: string
  formulaire: string
}

export const TitulaireActuel = (props: TitulaireActuelProps) => {
  const {data, prefix, formulaire} = props

  const libellesValeursTitulaireActuel = () => {
    if (formulaire == 'DC') {
      return (
        [
          {
            libellesValeurs: [
              [
                getLibelleValeur('Identification de la personne morale :',
                    getValueElement(data, prefix, 'titulaire_actuel')),
              ],
              [
                getLibelleValeur('SIREN :', getValueElement(data, prefix, 'titulaire_actuel_siren')),
                getLibelleValeur(`SIRET :`, getValueElement(data, prefix, 'titulaire_actuel_siret')),
              ],
            ],
          },
        ]
      )
    } else {
      const libelleValeurIdentiteTitulaire = () => {
        if (data.getElementsByTagName(prefix + 'identification_pers_morale').length !== 0) {
          return ([
            [
              getLibelleValeur('Identification de la personne morale :',
                  getValueElement(data, prefix, 'identification_pers_morale')),
            ],
            [
              getLibelleValeur('SIREN :', getValueElement(data, prefix, 'titulaire_actuel', 'siren')),
              getLibelleValeur('SIRET :', getValueElement(data, prefix, 'titulaire_actuel', 'siret')),
            ],
          ])
        } else {
          return ([
            [
              getLibelleValeur('Identification de la personne physique :',
                  getValueElement(data, prefix, 'identification_pers_phy')),
            ],
          ])
        }
      }
      return (
        [
          {
            libellesValeurs: libelleValeurIdentiteTitulaire(),
          },
        ]
      )
    }
  };

  return (
    <CardContainer
      titreContainer={formulaire == 'DC' ? 'Titulaire (actuel) : Assureur' : 'Titulaire (actuel) : Assuré'}
      content={libellesValeursTitulaireActuel()}/>
  )
};
