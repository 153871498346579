import React, {ChangeEvent} from 'react';
import {Autocomplete, FormControl, TextField, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {FieldConfig, FieldHookConfig, useField} from 'formik';

export interface InputSelectAutocompleteProps extends FieldConfig {
  id: string,
  itemsList: ItemGetValeursCodesModel[],
  label: string,
  isMandatory: boolean,
  disabled?: boolean,
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

const InputSelectAutocompleteField = (props: InputSelectAutocompleteProps) => {
  const {id, itemsList, isMandatory, label, disabled} = props;
  const classes = useEmotionStyles(styles);
  const [field, meta, helper] = useField<ItemGetValeursCodesModel>(props as FieldHookConfig<ItemGetValeursCodesModel>);

  const onSelectItem = (event: ChangeEvent<{}>, value: any) => {
    helper.setValue(value)
  };

  const renderInputAutoCompletion = (params: any) => {
    return <FormControl fullWidth>
      <TextField
        {...params}
        name={field.name}
        margin="dense"
        size="small"
        variant="outlined"
        disabled={Boolean(disabled)}
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.textfield,
          },
        }}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </FormControl>
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.libelle}>
        {label} {isMandatory && <span style={{color: 'red'}}>*</span>}
      </Typography>
      <Autocomplete
        id={id}
        autoHighlight
        value={field.value}
        options={itemsList}
        getOptionLabel={(item: ItemGetValeursCodesModel) => item.libelleedition?.trim() ?? ''}
        onChange={onSelectItem}
        disabled={Boolean(disabled)}
        noOptionsText={'Aucun résultat trouvé'}
        renderInput={renderInputAutoCompletion}
      />
    </div>
  );
};

export default InputSelectAutocompleteField;
