import {CircularProgress} from '@mui/material'
import React from 'react'
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface LoaderProps {
  className?: string,
  size?: number,
}

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15%',
  },
  loader: {
    '&.MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.main,
    },
  },
});

export const Loader = ({className, size = 120}: LoaderProps) => {
  const classes = useEmotionStyles(styles);
  return (
    <div className={`${classes.container} ${className}`}>
      <CircularProgress
        color={'primary'}
        className={classes.loader}
        size={size}
        thickness={5}
        disableShrink
      />
    </div>
  )
};
