import React from 'react';
import {Card, CardContent, Divider, IconButton} from '@mui/material';
import moment from 'moment';
import TableAgenda from './TableAgenda';
import {Creneau, DayOfWeek, RowCalendar} from '../../../model/priseRDV/CreneauModel';
import {ReactComponent as ArrowBackIcon} from '../../../images/icones/icn-arrow-back.svg';
import {ReactComponent as ArrowForwardIcon} from '../../../images/icones/icn-arrow-forward.svg';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface CreneauxProps {
  weekLabel: string,
  weekNumber: number,
  weekCalendar?: DayOfWeek[],
  rows: RowCalendar[],
  isLoading: boolean,
  firstAvailability: string,
  selectedCreneau: string,
  expertId: string,
  handleClickCreneau: (creneau: Creneau) => void
  handleNext: () => void
  handlePrevious: () => void
}

const styles = (theme: Theme) => ({
  card: {
    height: '450px',
  },
  cardContent: {
    height: 'calc(100% - 32px)',
  },
  header: {
    display: 'flex',
    marginBottom: '8px',
  },
  buttons: {
    display: 'flex',
    border: '1px solid ' + theme.palette.secondary.dark1,
    borderRadius: '8px',
  },
  iconBtn: {
    display: 'inline',
    color: theme.palette.link.main,
    padding: '6px',
  },
  arrowButton: {
    padding: '0px 5px',
  },
  semaine: {
    alignSelf: 'center',
    marginLeft: '16px',
    fontWeight: 'bold',
  },
});

const Agenda = (props: CreneauxProps) => {
  const classes = useEmotionStyles(styles);
  const {
    weekLabel, weekNumber, weekCalendar, rows, isLoading, firstAvailability, selectedCreneau, expertId,
    handleClickCreneau, handleNext, handlePrevious,
  } = props;
  const weekNumberFirstAvailability = moment(firstAvailability).week();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.buttons}>
            <IconButton
              className={classes.iconBtn}
              disabled={weekNumber <= weekNumberFirstAvailability || isLoading}
              onClick={handlePrevious}
            >
              <ArrowBackIcon className={classes.arrowButton}/>
            </IconButton>
            <Divider orientation="vertical"/>
            <IconButton
              className={classes.iconBtn}
              disabled={weekNumber >= weekNumberFirstAvailability + 8 || isLoading}
              onClick={handleNext}
            >
              <ArrowForwardIcon className={classes.arrowButton}/>
            </IconButton>
          </div>
          <div className={classes.semaine}>{weekLabel}</div>
        </div>
        <div>
          <TableAgenda
            weekCalendar={weekCalendar}
            rows={rows}
            isLoading={isLoading}
            selectedCreneau={selectedCreneau}
            expertId={expertId}
            handleClickCreneau={handleClickCreneau}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Agenda;
