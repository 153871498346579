import React from 'react';
import {TextField} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface TextFieldMultilineProps {
  id: string,
  style?: object,
  backgroundColorHelperText?: string,
  marginDense?: boolean,
  maxLength?: number,
  disabled?: boolean,
  value?: string,
  rows?: number,
  helperText?: React.JSX.Element | string,
  onError?: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

type StylesProps = { backgroundColorHelperText?: string }
const styles = (theme: Theme, {backgroundColorHelperText}: StylesProps) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  helperText: {
    textAlign: 'right' as const,
    backgroundColor: backgroundColorHelperText,
    margin: '0px',
    padding: '2px 10px 0px',
  },
});

export const TextFieldMultiline = ({id, style, backgroundColorHelperText, marginDense, maxLength, disabled,
  value, rows, helperText, onError, onChange}: TextFieldMultilineProps) => {
  const classes = useEmotionStyles(styles, {backgroundColorHelperText});

  return (
    <TextField
      id={id}
      helperText={helperText ?? ''}
      margin={marginDense ? 'dense' : 'none'}
      size={marginDense ? 'small' : undefined}
      variant="outlined"
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
      style={style}
      InputProps={{
        inputProps: {
          maxLength: maxLength,
        },
        classes: {
          root: classes.textfield,
        },
      }}
      disabled={disabled}
      value={value}
      fullWidth
      multiline
      minRows={rows}
      maxRows={rows}
      error={onError}
      onChange={onChange}
    />
  )
};
