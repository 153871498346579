import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface PrenomRdvProps {
  className?: string,
  classNameTextField?: string,
  fullWidth?: boolean,
  formulaire: string,
  value: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  typeError: string,
  helperText?: React.JSX.Element | string,
  maxLength?: number,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
});

export const PrenomRdv = ({className, classNameTextField, fullWidth, formulaire, value, onChangeValue, onBlur,
  onFocus, onError, typeError, helperText, maxLength}: PrenomRdvProps) => {
  const classes = useEmotionStyles(styles);

  const getErrorMessage = () => {
    switch (typeError) {
      case '':
        return 'Veuillez saisir une valeur';
      case 'errorChar':
        return 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés';
      default:
        return ''
    }
  };

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Prénom <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSearch
        className={classNameTextField}
        id={'prenom' + formulaire}
        name='prenom'
        fullWidth={fullWidth}
        value={value}
        maxLength={maxLength}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {onError &&
        <Typography className={`${classes.red} ${classes.error}`}>
          {getErrorMessage()}
        </Typography>
      }
    </div>
  )
};
