import React, {useEffect, useState} from 'react';
import {getValeursCodes} from '../../../../api/norme';
import {IAttachment} from '../initialStateFormFDV';
import Attachment from './Attachment';

export interface AttachmentsProps {
    attachments: IAttachment[]
    onChangeAttachments: (attachments: IAttachment[]) => void,
    onSaveAttachments: (attachments: IAttachment[]) => void,
    onDeleteAttachment: (attachments: IAttachment[]) => void,
}

const Attachments = (props: AttachmentsProps) => {
  const {attachments, onSaveAttachments, onDeleteAttachment, onChangeAttachments} = props;
  const [codesValeursNaturePieceJointe, setCodesValeursNaturePieceJointe] = useState([]);
  const [expanded, setExpanded] = useState<string>('panel-0');
  const [attachmentsList, setAttachmentsList] = useState<IAttachment[]>(attachments);

  useEffect(() => {
    getValeursCodes('AR2', '98690101').then((result) => setCodesValeursNaturePieceJointe(result));
  }, []);

  useEffect(() => {
    setAttachmentsList(attachments)
    const panel = `panel-${findFirstAttachmentNotCompleted(attachments)?.id}`;
    setExpanded(panel)
  }, [attachments]);

  const handleChangePanel = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '')
  };

  const findFirstAttachmentNotCompleted = (listAttachments: IAttachment[]) => {
    return listAttachments.find((attachment) => !attachment.completed)
  };

  const onChangeAttachment = (attachment: IAttachment) => {
    const attachmentsTemporary: IAttachment[] = [...attachmentsList];
    attachmentsTemporary.splice(attachment.id, 1, attachment);
    setAttachmentsList(attachmentsTemporary);
    onChangeAttachments(attachmentsTemporary)
  };

  const onSaveForm = (pj: IAttachment) => {
    pj.completed = true;
    const attachmentsTemporary: IAttachment[] = [...attachmentsList];
    attachmentsTemporary.splice(pj.id, 1, pj);
    setAttachmentsList(attachmentsTemporary);
    onSaveAttachments([...attachmentsTemporary]);
    const panel = `panel-${findFirstAttachmentNotCompleted([...attachmentsTemporary])?.id}`;
    setExpanded(panel)
  };

  const handleDeleteAttachment = (currentAttachment: IAttachment) => {
    const attachmentsCopy: IAttachment[] = [...attachmentsList].filter((attachment) =>
      attachment.id != currentAttachment.id,
    );
    attachmentsCopy.forEach((attachment, index) => {
      attachment.id = index
    });
    setAttachmentsList([...attachmentsCopy]);
    onDeleteAttachment([...attachmentsCopy])
  };

  return (
    <div>
      {attachmentsList.map((attachment: IAttachment) => {
        return (
          <div key={attachment.id} style={{marginTop: '16px'}} id={'panel-' + attachment.id}>
            <Attachment
              expanded={expanded === `panel-${attachment.id}`}
              attachment={attachment}
              codesValeursNaturePieceJointe={codesValeursNaturePieceJointe}
              onChangePanel={(panel) => handleChangePanel(panel)}
              onChangeAttachment={onChangeAttachment}
              onSave={onSaveForm}
              onDelete={handleDeleteAttachment}
            />
          </div>
        )
      })}
    </div>
  );
};

export default Attachments;
