import React, {useEffect, useState} from 'react';
import {RefSinistre} from '../../../common/RefSinistre';
import {InputDate} from '../../../common/InputDate';
import {Typography} from '@mui/material';
import {PersonnePhysiqueMorale} from '../../../common/personne/PersonnePhysiqueMorale';
import {IdentificationPersonne} from '../../../common/personne/IdentificationPersonne';
import {Vehicule} from '../../../common/vehicule/Vehicule';
import {Hour} from '../../../common/Hour';
import moment from 'moment';
import {checkRefSinFormat, checkRefSinSpecialChar} from '../../../../common/utils/InputValuesUtils';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {postSIVForm, getPreremplissageDADC} from '../../../../api/siv';
import {ISIVFormIRAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {Loader} from '../../../common/Loader';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {TokenModel} from '../../../../model/common/TokenModel';
import {UserContext} from '../../../../store/UserContext';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaIR} from './validationSchemaIR';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    marginBottom: '16px',
  },
  otherCardContent: {
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    marginTop: '16px',
  },
  marginTop: {
    marginTop: '24px',
  },
  remplissageOK: {
    color: theme.palette.green.main,
  },
  remplissageKO: {
    color: 'red',
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FormIR = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormIRAccesTransparent;
  const {user} = React.useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseReturn, setResponseReturn] = useState<number>(0);
  const [showPreremplirOK, setShowPreremplirOK] = useState<boolean>(false);
  const [showPreremplirKO, setShowPreremplirKO] = useState<boolean>(false);

  const initialValues = {
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim()]),
    dateEvenement: getInitialValueSIV([data?.dateEvenement]),
    typePersonne: getInitialValueSIV(['pp', data?.typePersonne]),
    identificationPP: getInitialValueSIV([data?.identificationPP]),
    identificationPM: getInitialValueSIV([data?.identificationPM]),
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation]),
    numeroFormule: getInitialValueSIV([data?.numeroFormule]),
    dateCertificat: getInitialValueSIV([data?.dateCertificat]),
    dateDeclaration: getInitialValueSIV([moment().format('YYYY-MM-DD'), data?.dateDeclaration]),
    heureDeclaration: getInitialValueSIV([moment().format('HH:mm'), data?.heureDeclaration]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'IR', referenceSinistre: formik.values.referenceSinistre,
      dateSinistre: dateToDateText(new Date(formik.values.dateEvenement)), titulaireActuel: {
        isPersonnePhysique: formik.values.typePersonne === 'pp',
        identificationPersonne: formik.values.typePersonne === 'pp' ? formik.values.identificationPP :
          formik.values.identificationPM}, vehicule: {numeroImmatriculation: formik.values.immatriculation,
        numeroFormule: formik.values.numeroFormule, dateImmaticulation: formik.values.dateCertificat ?
          dateToDateText(new Date(formik.values.dateCertificat)) : ''},
      infoRefus: {dateDeclarationRefus: dateToDateText(new Date(formik.values.dateDeclaration)),
        heureDeclarationRefus: formik.values.heureDeclaration}};
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        })
        .catch((error) => {
          if (error.response) {
            onChangeResponse(error.response);
          } else {
            setResponseReturn(-1);
          }
        })
        .finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formik.setFieldTouched(field, false, false)
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaIR,
    onSubmit: onSubmit,
  });

  const disabled = !!(formik.touched.referenceSinistre && formik.errors.referenceSinistre) ||
    !!(formik.touched.dateEvenement && formik.errors.dateEvenement) ||
    !!(formik.touched.identificationPP && formik.errors.identificationPP) ||
    !!(formik.touched.identificationPM && formik.errors.identificationPM) ||
    !!(formik.touched.immatriculation && formik.errors.immatriculation) ||
    !!(formik.touched.numeroFormule && formik.errors.numeroFormule) ||
    !!(formik.touched.dateCertificat && formik.errors.dateCertificat) ||
    !!(formik.touched.dateDeclaration && formik.errors.dateDeclaration) ||
    !!(formik.touched.heureDeclaration && formik.errors.heureDeclaration);

  const preremplir = () => {
    const tokenMission: TokenModel = {
      referenceSinistre: formik.values.referenceSinistre,
      codeGTA: user.codeGTA,
      numClient: user.numClient,
      userCompte: user.userCompte,
      codeAbonne: user.codeAbo,
      codeService: user.service,
    };
    getPreremplissageDADC(tokenMission, 'R', false).then((res) => {
      formik.values.dateEvenement = res.dateSinistre || '';
      formik.values.typePersonne = 'pp';
      formik.values.identificationPP = res.titulaireActuel.identificationPersonne || '';
      formik.values.immatriculation = res.vehicule.numeroImmatriculation || '';
      formik.values.dateCertificat = res.vehicule.dateImmaticulation || '';
      formik.values.numeroFormule = res.vehicule.numeroFormule || '';
      setShowPreremplirOK(true)
    }).catch(() =>
      setShowPreremplirKO(true),
    )
  };

  useEffect(() => {
    if (formik.values.referenceSinistre.length >= 6 && checkRefSinFormat(formik.values.referenceSinistre) &&
      checkRefSinSpecialChar(formik.values.referenceSinistre)) {
      if (!user.isTransparent) {
        preremplir()
      }
    }
  }, []);

  return (
    <div onClick={() => {
      setShowPreremplirKO(false);
      setShowPreremplirOK(false)
    }}>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'IR'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={true}
            value={formik.values.referenceSinistre}
            onError={(formik.touched.referenceSinistre && !!formik.errors.referenceSinistre) || false}
            helperText={formik.touched.referenceSinistre && formik.errors.referenceSinistre ?
              formik.errors.referenceSinistre : ''
            }
          />
          <InputDate
            className={classes.textfieldSmall}
            id={'dateEvenement'}
            name={'dateEvenement'}
            formulaire={'IR'}
            isMandatory={true}
            title={'Date de l\'événement'}
            value={formik.values.dateEvenement}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('dateEvenement')}
            onError={(formik.touched.dateEvenement && !!formik.errors.dateEvenement) || false}
            helperText={formik.touched.dateEvenement && formik.errors.dateEvenement && formik.errors.dateEvenement ?
              formik.errors.dateEvenement : ''
            }
          />
        </div>

        {showPreremplirOK &&
          <div className={classes.remplissageOK}>
            Votre formulaire a bien été prérempli avec les données du dossier.
          </div>
        }

        {showPreremplirKO &&
          <div className={classes.remplissageKO}>
            Aucun dossier portant cette référence n&apos;a été retrouvé.
          </div>
        }

        <div>
          <Typography className={classes.marginTop} variant={'h3'}>Titulaire (actuel) : Assuré</Typography>
          <PersonnePhysiqueMorale
            name={'typePersonne'}
            formulaire={'IR'}
            onChangeValue={formik.handleChange}
            value={formik.values.typePersonne}
          />

          <div className={classes.marginTop} >
            {formik.values.typePersonne === 'pp' &&
              <IdentificationPersonne
                className={classes.textfield}
                name={'identificationPP'}
                formulaire={'IR'}
                value={formik.values.identificationPP}
                typePersonne={formik.values.typePersonne}
                onBlur={formik.handleBlur}
                onFocus={onFocus('identificationPP')}
                onChangeValue={formik.handleChange}
                onError={(formik.touched.identificationPP && !!formik.errors.identificationPP) || false}
                helperText={formik.touched.identificationPP && formik.errors.identificationPP ?
                  formik.errors.identificationPP : ''
                }
              />
            }

            {formik.values.typePersonne === 'pm' &&
              <IdentificationPersonne
                className={classes.textfield}
                name={'identificationPM'}
                formulaire={'IR'}
                value={formik.values.identificationPM}
                typePersonne={formik.values.typePersonne}
                onBlur={formik.handleBlur}
                onFocus={onFocus('identificationPM')}
                onChangeValue={formik.handleChange}
                onError={(formik.touched.identificationPM && !!formik.errors.identificationPM) || false}
                helperText={formik.touched.identificationPM && formik.errors.identificationPM ?
                  formik.errors.identificationPM : ''
                }
              />
            }
          </div>
        </div>
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Véhicule</Typography>
        <Vehicule
          formulaire={'IR'}
          formik={formik}
          entity={''}
          formikValues={{immatriculation: formik.values.immatriculation, numeroFormule: formik.values.numeroFormule,
            dateCertificat: formik.values.dateCertificat}
          }
          formikTouched={formik.touched}
          formikErrors={formik.errors}
        />
      </div>

      <div className={`${classes.cardContent} ${classes.otherCardContent}`}>
        <Typography variant={'h3'}>Information sur la déclaration de refus</Typography>
        <div className={classes.flex}>
          <InputDate
            className={classes.textfieldSmall}
            id={'dateDeclaration'}
            name={'dateDeclaration'}
            formulaire={'IR'}
            isMandatory={true}
            title={'Déclaration de refus'}
            value={formik.values.dateDeclaration}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('dateDeclaration')}
            onError={(formik.touched.dateDeclaration && !!formik.errors.dateDeclaration) || false}
            helperText={formik.touched.dateDeclaration && formik.errors.dateDeclaration ?
              formik.errors.dateDeclaration : ''
            }
          />
          <Hour
            className={classes.textfieldSmall}
            id={'heureDeclaration'}
            name={'heureDeclaration'}
            formulaire={'IR'}
            isMandatory={false}
            title={'\xa0'}
            value={formik.values.heureDeclaration}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('heureDeclaration')}
            onError={(formik.touched.heureDeclaration && !!formik.errors.heureDeclaration) || false}
            helperText={formik.touched.heureDeclaration && formik.errors.heureDeclaration ?
              formik.errors.heureDeclaration : ''
            }
          />
        </div>
      </div>

      <Typography style={{fontSize: '14px'}}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      {responseReturn === -1 &&
        <Typography className={`${classes.red} ${classes.error}`}>
          Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
        </Typography>
      }

      <div style={{textAlign: 'right'}}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={'boutonEnvoyerIR'} disabled={disabled} onClick={formik.submitForm}/>
        }
      </div>
    </div>
  )
};
