import React from 'react';
import {ButtonBlue} from '../buttons/ButtonBlue';
import {ReactComponent as EnvoyerBubbleIcon} from '../../../images/icones/icn-envoyer.svg'

interface ButtonBlueSendProps {
  id: string,
  className?: string,
  disabled?: boolean,
  onClick: () => void,
}

export const ButtonBlueSend = ({id, className, disabled, onClick}: ButtonBlueSendProps) => {
  return (
    <ButtonBlue
      id={id}
      className={className}
      icon={<EnvoyerBubbleIcon style={{marginRight: '10px'}}/>}
      libelle={'Envoyer'}
      disabled={disabled}
      onClick={onClick}
    />
  )
}
