import React, {useContext, useState} from 'react';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {useMissionContext} from '../../../store/MissionContext';
import {UserContext} from '../../../store/UserContext';
import {TextFieldMultiline} from '../../common/formsComponents/TextFiledMultiline';
import {ButtonBlueSend} from '../../common/formsComponents/ButtonBlueSend';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {Loader} from '../../common/Loader';
import {ButtonLink} from '../../common/buttons/ButtonLink';
import {FilesDragAndDrop, Attachments} from '../components/Attachment/FilesDragAndDrop';
import {postSD99Form} from '../../../api/message';
import {FormModel} from '../../../model/forms/FormModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

type StylesProps = {
  showPjForm: boolean,
  isLoading: boolean,
}

/** Design */
const styles = (theme: Theme, props: StylesProps) => ({
  form: {
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    height: '100vh',
    width: '40%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: props.showPjForm ? '100vh' : '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  body: {
    padding: '20px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  attachments: {
    padding: '10px 0px 0px 20px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: props.isLoading ? 'flex-end': 'space-between',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    margin: '0px 20px 0px 0px',
  },
});

const SD99Form = (props: FormModel) => {
  const {mission} = useMissionContext();
  const {user} = useContext(UserContext);
  const [errorMessageField, setErrorMessageField] = useState(false);
  const [responseReturn, setResponseReturn] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPjForm, setShowPjForm] = useState(false);
  const [commentaire, setCommentaire] = useState('');
  const classes = useEmotionStyles(styles, {showPjForm, isLoading});
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');

  const initialState = {
    referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
    numMission: mission?.infoMissionDTO?.numeroMission,
    dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
    codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
    nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
    coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
    codeGTA: mission?.codeGTA,
    codeService: user.service,
    numeroAssure: mission?.societaireDTO?.numero,
    codeAbonneEmeteur: user.codeAbo,
    codeAbonneDestinataire: props.destinataire?.codeAbonne,
    commentaire: '',
    scenario: mission?.scenario,
    intermediaire: mission?.intermediaireDTO,
    numClient: user.numClient,
    userCompte: user.userCompte,
    nomCompteAssistance: nomCompteAssistance ?? '',
  };
  const initialStatePj = {
    attachments: [],
    isValid: true,
  };
  const [formData, setFormData] = useState(initialState);
  const [piecesJointesList, setPiecesJointesList] = useState<Attachments>(initialStatePj);

  const libelleLink = (!piecesJointesList || piecesJointesList.attachments.length === 0) ?
        'Ajouter des pièces jointes' :
        'Gérer les pièces jointes (' + piecesJointesList.attachments.length + ')';


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessageField(false);
    setCommentaire(event.target.value);
    setFormData({...formData, commentaire: event.target.value})
  };

  const onUpload = (attachments: Attachments) => {
    setShowPjForm(!showPjForm);
    setPiecesJointesList(attachments)
  };

  const onCloseAttachmentForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPjForm(!showPjForm)
    }
  };

  const handleClick = () => {
    setErrorMessageField(false);
    if (formData.commentaire === '' && piecesJointesList.attachments.length <= 0) {
      setErrorMessageField(true);
    } else {
      setIsLoading(true);
      const payload = buildPayLoad();
      postSD99Form(payload)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  const buildPayLoad = () => {
    const payLoad = new FormData();
    const piecesJointes = piecesJointesList.attachments.map((attachment, index) => {
      payLoad.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      };
    });

    payLoad.append('metadata',
        new Blob([JSON.stringify({...formData, piecesJointes: piecesJointes})],
            {type: 'application/json'}));
    return payLoad;
  };

  return (
    <div className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Envoyer un message à {props.destinataire?.libelle}</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}}
              onClick={responseReturn <= 0 ? props.onClose : props.onCloseAndRefresh}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        {responseReturn <= 0 && !showPjForm &&
          <div className={classes.body}>
            <Typography className={classes.libelle}>
              Votre message{!piecesJointesList.attachments.length && <span className={classes.red}> *</span>}
            </Typography>
            <TextFieldMultiline id={'message'} style={{marginTop: '15px'}} marginDense={true} rows={14}
              onChange={handleChange} onError={errorMessageField} value={commentaire}/>
            {errorMessageField ?
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez saisir un message
              </Typography> : ''
            }
            {responseReturn === -1 &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
        {(responseReturn <= 0) && showPjForm &&
          <div className={classes.attachments}>
            <FilesDragAndDrop
              attachments={piecesJointesList}
              onSave={onUpload}
              onCloseAttachmentForms={onCloseAttachmentForms}
            />
          </div>
        }
        {(responseReturn === 201) &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Votre message a bien été envoyé
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onCloseAndRefresh}/>
          </div>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onClose}/>
          </div>
        }
      </div>
      {responseReturn <= 0 && !showPjForm &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <>
                <ButtonLink
                  id={'boutonAjoutPJ'}
                  libelle={libelleLink}
                  isLibelleUpperCase={true}
                  isLibelleBold={true}
                  onClick={() => setShowPjForm(!showPjForm)}
                />
                <ButtonBlueSend id={'boutonEnvoyer'} disabled={errorMessageField} onClick={handleClick}/>
              </>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default SD99Form;
