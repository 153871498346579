import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {ErrorFields} from '../PriseRDV';
import InfoRdv from './InfoRdv';
import InfoAssure from './InfoAssure';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {InfoVehicule} from './InfoVehicule';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {InformationAssure, InformationsRdv} from '../../../model/priseRDV/RDVModel';
import {LocaliteProps} from '../../../model/priseRDV/LocaliteModel';
import {AdresseAutoCompleteProps} from '../../../model/common/AdresseAutoCompleteModel';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {IdigoParameterValueModel} from '../../../model/priseRDV/IdigoParameterValueModel';

interface InformationsProps {
  informationsRdv: InformationsRdv,
  informationsAssure: InformationAssure,
  codesValeursMarque: ItemGetValeursCodesModel[],
  selectedMarque: ItemGetValeursCodesModel,
  setMarque: (value: ItemGetValeursCodesModel) => void,
  sousActivitesList: IdigoParameterValueModel[],
  errorFields: ErrorFields,
  onChangeValue: (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  setLocalite: (localite: LocaliteProps) => void,
  setAdresse: (adresse: AdresseAutoCompleteProps) => void,
  setErrorFields: (errorFields: ErrorFields) => void,
  handleNextStep: () => void,
}

/** Design */
const styles = () => ({
  card: {
    marginBottom: '20px',
  },
  container: {
    display: 'flex',
    columnGap: '20px',
  },
  infosAssure: {
    width: '60%',
    display: 'flex',
  },
  infosVehicule: {
    width: '40%',
    display: 'flex',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  typo: {
    fontSize: '14px',
  },
  red: {
    color: 'red',
  },
});

const Informations = (props: InformationsProps) => {
  const classes = useEmotionStyles(styles);
  const {
    informationsRdv, informationsAssure, codesValeursMarque, setMarque, selectedMarque, sousActivitesList,
    errorFields, onChangeValue, setLocalite, setAdresse, setErrorFields, handleNextStep,
  } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <InfoRdv
          informationsRdv={informationsRdv}
          activite={informationsRdv.sousActivite.code}
          sousActivitesList={sousActivitesList}
          errorFields={errorFields}
          onChangeValue={onChangeValue}
          setErrorFields={(errFields) => setErrorFields(errFields)}
        />
        <div className={classes.container}>
          <div className={classes.infosAssure}>
            <InfoAssure
              values={informationsAssure}
              errorFields={errorFields}
              onChangeValue={onChangeValue}
              setLocalite={setLocalite}
              setAdresse={setAdresse}
              setErrorFields={(errFields) => setErrorFields(errFields)}
            />
          </div>
          <div className={classes.infosVehicule}>
            <InfoVehicule
              values={informationsAssure}
              codesValeursMarque={codesValeursMarque}
              selectedMarque={selectedMarque}
              setMarque={setMarque}
              onChangeValue={onChangeValue}
              errorFields={errorFields}
              setErrorFields={(errFields) => setErrorFields(errFields)}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <Typography className={classes.typo}>
            Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
          </Typography>
          <ButtonBlue id={'suivant'} libelle={'Suivant'} onClick={handleNextStep} disabled={errorFields.length > 0}/>
        </div>
      </CardContent>
    </Card>
  );
};

export default Informations;
