export interface IPartenaireSSO {
    codePartenaire: string
    numeroClient: string
    codeAbonne: string
    codeGTA: string
}

export const PartenairesSSO: IPartenaireSSO[] = [
  {
    codePartenaire: 'GCA',
    numeroClient: '15393',
    codeAbonne: 'A79879S00001',
    codeGTA: '879',
  },
  {
    codePartenaire: 'GDOC',
    numeroClient: '15393',
    codeAbonne: 'A81881S00001',
    codeGTA: '831',
  },
]

export const getPartenaireSSOByCode = (codePartenaire: string) : IPartenaireSSO | undefined => {
  return PartenairesSSO.find((partenaire) => partenaire.codePartenaire === codePartenaire)
}
