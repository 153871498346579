import * as yup from 'yup';
import {
  checkIdentificationPM,
  checkIdentificationPP, checkImmat,
  checkRefSinFormat,
  checkRefSinSpecialChar,
} from '../../../../common/utils/InputValuesUtils';
import moment from 'moment/moment';

export const validationSchemaIR = yup.object({
  referenceSinistre: yup.string()
      .required('Veuillez saisir une valeur')
      .min(6, 'La référence sinistre doit faire au moins 6 caractères')
      .test('test-referenceSinistre-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
      'identiques d\'affilée',
      function(value) {
        return checkRefSinFormat(value || '')
      })
      .test('test-referenceSinistre-char', 'Le champ contient des caractères interdits',
          function(value) {
            return checkRefSinSpecialChar(value || '')
          }),
  dateEvenement: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateEvenement', 'La date de l\'événement ne peut être antérieure à la date du ' +
        'certificat d\'immatriculation',
      function(value, ctx) {
        if (ctx.parent.dateCertificat) {
          return ctx.parent.dateCertificat <= value
        }
        return true
      }),
  identificationPP: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-identificationPP', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkIdentificationPP(value || '')
                }),
      }),
  identificationPM: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-identificationPM', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                function(value) {
                  return checkIdentificationPM(value || '')
                }),
      }),
  immatriculation: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu, voir',
          function(value) {
            return checkImmat(value || '')
          }),
  numeroFormule: yup.string(),
  dateCertificat: yup.date()
      .transform((current, origin) => origin === '' ? null : current)
      .nullable()
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour'),
  dateDeclaration: yup.date()
      .required('Veuillez saisir une valeur')
      .min('1900-01-01', 'Veuillez saisir une date postérieure au 01/01/1900')
      .max(moment(), 'Veuillez saisir une date antérieure à celle du jour')
      .test('test-dateDeclaration', 'La date de déclaration du refus ne peut être antérieure à la date de l\'événement',
          function(value, ctx) {
            if (ctx.parent.dateEvenement) {
              return ctx.parent.dateEvenement <= value
            }
            return true
          }),
  heureDeclaration: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-heureDeclaration', 'L\'heure doit être antérieure à l\'heure actuelle pour une date ' +
      'égale à celle du jour',
      function(value, ctx) {
        const date = ctx.parent.dateDeclaration;
        if (date && moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))) {
          return value <= moment().format('HH:mm')
        }
        return true
      }),
});
