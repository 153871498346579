import React, {useEffect, useState} from 'react';
import {MenuItem, Typography} from '@mui/material';
import {getValeursCodes} from '../../../api/norme';
import {TextFieldSelect} from '../formsComponents/TextFieldSelect';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface SexeProps {
  className?: string,
  name: string,
  formulaire: string,
  value: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const Sexe = ({className, name, formulaire, value, onChangeValue, onFocus, onError, helperText}: SexeProps) => {
  const classes = useEmotionStyles(styles);
  const [sexesList, setSexesList] = useState([]);

  useEffect(() => {
    getValeursCodes('SIV', '94450101').then((response) => setSexesList(response));
  }, []);

  sexesList?.sort((a: ItemGetValeursCodesModel, b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const itemsList = sexesList.map((sexe: ItemGetValeursCodesModel) => {
    return (
      <MenuItem key={sexe.valeur} value={sexe.valeur}>{sexe.libelleedition}</MenuItem>
    )
  });

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Sexe <span className={classes.red}>*</span>
      </Typography>

      <TextFieldSelect
        id={'sexe' + formulaire}
        name={name}
        fullWidth={true}
        marginDense={true}
        value={value}
        onChange={onChangeValue}
        itemsList={itemsList}
        onFocus={onFocus}
        onError={onError}
        helperText={helperText}
      />
    </div>
  )
};
