import {Card, CardContent, Typography} from '@mui/material'
import {StyledTypography} from '../../common/StyledTypography'
import {BlocAdresse} from '../../common/adresse/BlocAdresse'
import React from 'react'
import {useMissionContext} from '../../../store/MissionContext'
import {telToScreen} from '../../../common/Utils'

export const ContentAssure = () => {
  const {mission} = useMissionContext();
  const informationsSocietaire = mission?.societaireDTO;
  return (
    <>
      <StyledTypography title={'Nom'} text={informationsSocietaire?.nom} />
      <StyledTypography title={'N° de police / sociétaire'} text={informationsSocietaire?.numero} />
      <BlocAdresse title={'Adresse'} adresse={informationsSocietaire?.adresse} />
      <StyledTypography title={'N° téléphone personnel'}
        text={telToScreen(informationsSocietaire?.telephonePersonnel)} />
      <StyledTypography title={'N° téléphone mobile'} text={telToScreen(informationsSocietaire?.telephoneMobile)} />
    </>
  )
};

export const Assure = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant={'h3'}>Assuré</Typography>
        <ContentAssure/>
      </CardContent>
    </Card>
  )
};
