import React, {useEffect, useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {ReactComponent as IconVP} from '../../../../../images/icones/icones-vehicules-eclates/icn-vp.svg';
import {ReactComponent as IconMoto} from '../../../../../images/icones/icones-vehicules-eclates/icn-moto.svg';
import {ReactComponent as IconUtilitaire} from
  '../../../../../images/icones/icones-vehicules-eclates/icn-utilitaire.svg';
import {ReactComponent as IconCloseOrangeTag} from '../../../../../images/icones/icn-close-orange-tag.svg';
import {FormikValues, useFormikContext} from 'formik';
import {type2Roues, zonesVehicules, typeUtilitaire, typesVehicules} from '../../model/ConstantsOM';
import {IZone} from '../../model/ModelOM';
import BlueTag from './components/BlueTag';

export type TagType = 'Indéterminé' | 'Intérieur' | 'Dessous' | 'Ensemble';

export type TypeVehicule = 'voiture' | 'utilitaire' | 'moto';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '290px 1fr',
    columnGap: '40px',
  },
  switch: {
    display: 'flex',
    alignContent: 'center',
  },
  marginTop12: {
    marginTop: '12px',
  },
  leftCell: {
    justifySelf: 'center',
  },
  rightCell: {
  },
  iconVP: {
    width: '270px',
    margin: 'auto',
  },
  title: {
    color: theme.palette.primary.dark,
    fontSize: '16px',
    marginBottom: '15px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    fontSize: '14px',
  },
  tags: {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '15px',
    marginBottom: '24px',
    minHeight: '73px',
  },
  tag: {
    padding: '5px',
    borderRadius: '6px',
    height: 'min-content',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    paddingRight: '10px',
    marginRight: '10px',
  },
  orangeTag: {
    backgroundColor: '#FADEB8',
    color: '#D4551E',
  },
  blueTag: {
    backgroundColor: '#B2E3F9',
    color: '#241E46',
    cursor: 'pointer',
  },
  selectedBlueTag: {
    backgroundColor: '#241E46',
    color: '#FFF',
    cursor: 'pointer',
  },
  iconTag: {
    marginRight: '5px',
    cursor: 'pointer',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
});

const VehiculeBloc = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();
  const [chocs, setChocs] = useState<string[]>([]);
  const [indetermineTag, setIndetermineTag] = useState(false);
  const [interieurTag, setInterieurTag] = useState(false);
  const [dessousTag, setDessousTag] = useState(false);
  const [ensembleTag, setEnsembleTag] = useState(false);

  useEffect(() => {
    initElements();
  }, []);

  useEffect(() => {
    initElements();
    // Si VELO choisi alors supprimer l'immatriculation
    if (values.infosVehicule.genre === 'VELO') {
      values.infosVehicule.immatriculation = '';
    }
  }, [values.infosVehicule.genre]);

  const initElements = () => {
    values.zonesVehicule.forEach((element: IZone) => {
      if (zonesVehicules.some((item) => item.id === element.id)) {
        setElementBackgroundColor(zonesVehicules[element.id].zone, '#F4B35F');
      }
      switch (element.code) {
        case 'A':
          setInterieurTag(true);
          break;
        case 'B':
          setDessousTag(true);
          break;
        case 'C':
          setEnsembleTag(true);
          break;
        case 'Z':
          setIndetermineTag(true);
          break;
      }
    });
  }

  const setElementBackgroundColor = (elementId: string, backgroundColor: string) => {
    Array.from(document.getElementsByTagName('path'))
        .filter((element) => element.id === elementId)
        .forEach((elt) => {
          elt.style.fill = backgroundColor;
          elt.style.stroke = backgroundColor;
        });
  }

  const getClosestElement = (event: any, idVehicule: TypeVehicule) => {
    const svgElement = document.getElementById(idVehicule)
    const rect = svgElement ? svgElement.getBoundingClientRect() : undefined;
    if (rect) {
      const coordinates = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      }
      if (idVehicule === 'utilitaire') {
        return getUtilitaireId(idVehicule, coordinates);
      } else if (idVehicule === 'voiture') {
        return getVoitureId(idVehicule, coordinates);
      }
    }
    return idVehicule;
  }

  const getUtilitaireId = (idVehicule: TypeVehicule, coordinates: any) => {
    if ((coordinates.x >= 40 && coordinates.y <= 30 && coordinates.x <= 63 && coordinates.y >= 12) ||
      (coordinates.x >= 199 && coordinates.y <= 30 && coordinates.x <= 223 && coordinates.y >= 11)) {
      return 'retroviseur';
    } else if (coordinates.x >= 93 && coordinates.y >= 91 && coordinates.x <= 170 && coordinates.y <= 114) {
      return 'pare-brise';
    }
    return idVehicule;
  }

  const getVoitureId = (idVehicule: TypeVehicule, coordinates: any) => {
    if ((coordinates.x >= 32 && coordinates.y <= 23 && coordinates.x <= 62 && coordinates.y >= 7) ||
      (coordinates.x >= 207 && coordinates.y <= 23 && coordinates.x <= 236 && coordinates.y >= 7)) {
      return 'retroviseur';
    } else if (coordinates.x >= 85 && coordinates.y >= 132 && coordinates.x <= 184 && coordinates.y <= 160) {
      return 'pare-brise';
    } else if (coordinates.x >= 93 && coordinates.y <= 263 && coordinates.x <= 174 && coordinates.y >= 245) {
      return 'lunette-arriere';
    }
    return idVehicule;
  }

  const handleClick = (event: any) => {
    const idVehicule = event.target.id;
    const idElement = !typesVehicules.includes(idVehicule) ? idVehicule : getClosestElement(event, idVehicule);
    if (zonesVehicules.some((item) => item.zone === idElement)) {
      if (values.zonesVehicule.some((item: IZone) => item ===
        zonesVehicules.find((item) => item.zone === idElement))) {
        removeElement(idElement);
      } else {
        addElement(idElement);
      }
    }
  }

  const removeElement = (element: string) => {
    setChocs(chocs.filter((item) => item !== element));
    setElementBackgroundColor(element, '#D4D1D2');
    values.zonesVehicule = values.zonesVehicule.filter((item: IZone) => item.zone !== element);
  }

  const addElement = (element: string) => {
    setEnsembleTag(false);
    setIndetermineTag(false);
    setChocs([...chocs, element]);
    setElementBackgroundColor(element, '#F4B35F');
    values.zonesVehicule = [...values.zonesVehicule, zonesVehicules.find((item) => item.zone === element)];
    // Supprimer les valeurs des étiquettes Ensemble et Indéterminé
    removeElementByCode('C');
    removeElementByCode('Z');
  }
  const removeAllElements = () => {
    values.zonesVehicule.forEach((element: IZone) => {
      if (zonesVehicules.some((item: IZone) => item.id === element.id)) {
        setElementBackgroundColor(zonesVehicules[element.id].zone, '#D4D1D2');
      }
    });
    // Supprimer uniquement les éléments qui existent dans le tableau zonesVehicules.
    // Pour ne pas supprimer les étiquettes bleues sélectionnées
    values.zonesVehicule = values.zonesVehicule.filter(
        (element: IZone) => !zonesVehicules.some((item) => item.id === element.id));
  }

  const handleCloseTag = (element: string) => {
    removeElement(element)
  }

  const removeElementByCode = (code: string) => {
    values.zonesVehicule = values.zonesVehicule.filter((element: IZone) => element.code !== code);
  }

  const handleClickBlueTag = (tagName: TagType) => {
    switch (tagName) {
      case 'Indéterminé':
        indetermineTagProcess()
        break;
      case 'Dessous':
        dessousTagProcess()
        break;
      case 'Intérieur':
        interieurTagProcess()
        break;
      case 'Ensemble':
        ensembleTagProcess();
        break;
    }
  }

  const indetermineTagProcess = () => {
    if (!indetermineTag) {
      removeAllElements();
      values.zonesVehicule.push({id: 13, zone: 'INDETERMINE', code: 'Z', label: 'Indéterminé'});
    } else {
      removeElementByCode('Z');
    }
    setIndetermineTag(!indetermineTag);
    if (ensembleTag) {
      setEnsembleTag(indetermineTag);
      removeElementByCode('C');
    }
  }

  const dessousTagProcess = () => {
    if (!dessousTag) {
      values.zonesVehicule.push({id: 14, zone: 'DESSOUS', code: 'B', label: 'Dessous'});
    } else {
      removeElementByCode('B');
    }
    setDessousTag(!dessousTag);
  }

  const interieurTagProcess = () => {
    if (!interieurTag) {
      values.zonesVehicule.push({id: 15, zone: 'INTERIEUR', code: 'A', label: 'Intérieur'});
    } else {
      removeElementByCode('A');
    }
    setInterieurTag(!interieurTag);
  }

  const ensembleTagProcess = () => {
    if (!ensembleTag) {
      removeAllElements();
      values.zonesVehicule.push({id: 16, zone: 'ENSEMBLE', code: 'C', label: 'Ensemble'});
    } else {
      removeElementByCode('C');
    }
    setEnsembleTag(!ensembleTag);
    if (indetermineTag) {
      setIndetermineTag(ensembleTag);
      removeElementByCode('Z');
    }
  }

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>V&eacute;hicule</Typography>
      <div className={`${classes.grid} ${classes.marginTop12}`}>
        <div className={classes.leftCell}>
          <Typography className={classes.title}>S&eacute;lectionner les zones de d&eacute;formation</Typography>
          <div>
            <div className={classes.iconVP}>
              {type2Roues.includes(values.infosVehicule.genre) &&
                  <IconMoto onClick={(event) => handleClick(event)}/>
              }
              {typeUtilitaire.includes(values.infosVehicule.genre) &&
                  <IconUtilitaire onClick={(event) => handleClick(event)}/>
              }
              {!type2Roues.includes(values.infosVehicule.genre) &&
                !typeUtilitaire.includes(values.infosVehicule.genre) &&
                  <IconVP onClick={(event) => handleClick(event)}/>
              }
            </div>
          </div>
        </div>
        <div className={classes.rightCell}>
          <Typography className={classes.libelle}>Zones de d&eacute;formation selectionnées</Typography>
          <div className={classes.tags}>
            {
              values.zonesVehicule.map((item: IZone) => {
                if (zonesVehicules.some((element) => element.id === item.id)) {
                  return (
                    <div id={'orangeTag' + item.id} className={`${classes.tag} ${classes.orangeTag}`} key={item.id}>
                      <IconCloseOrangeTag className={classes.iconTag} onClick={() => handleCloseTag(item.zone)}/>
                      <label>{item.label}</label>
                    </div>
                  )
                }
              },
              )}
          </div>
          <Typography className={classes.libelle}>Autres zones de d&eacute;formation</Typography>
          <div className={classes.tags}>
            <BlueTag
              label={'Intérieur'}
              handleClickTag={() => handleClickBlueTag('Intérieur')}
              selected={interieurTag}
            />
            <BlueTag
              label={'Dessous'}
              handleClickTag={() => handleClickBlueTag('Dessous')}
              selected={dessousTag}
            />
            <BlueTag
              label={'Ensemble'}
              handleClickTag={() => handleClickBlueTag('Ensemble')}
              selected={ensembleTag}
            />
            <BlueTag
              label={'Indéterminé'}
              handleClickTag={() => handleClickBlueTag('Indéterminé')}
              selected={indetermineTag}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiculeBloc;


