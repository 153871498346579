import React, {useEffect, useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';
import {MenuItem, Typography} from '@mui/material';
import {UserContext} from '../../../../../store/UserContext';
import InputSelectField from '../../../multiStepsForm/fields/InputSelectField';
import {getScenariosByCodeAbonne} from '../../../../../api/user';
import {IScenario} from '../../../../../model/common/Scenario';
import {FormikValues, useFormikContext} from 'formik';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop12: {
    marginTop: '12px',
  },
  field: {
    width: '360px',
  },
});

const DossierBloc = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const [scenariosList, setScenariosList] = useState<React.ReactElement[]>([]);
  const [agrementsList, setAgrementsList] = useState<React.ReactElement[]>([]);
  const {values, setFieldValue} : FormikValues = useFormikContext();
  const isCourtier = values.dossier.isCourtier;
  const isAssureur = user.type === 'Assureur';

  const buildAgrementItems = () => {
    const {agrements} = user
    const filteredAgrements = agrements.filter((agrement) => {
      return ['_1', '_3'].includes(agrement.codeAgrement.substring(agrement.codeAgrement.lastIndexOf('_')))
    })
    const agrementsItems = filteredAgrements.map((agrement) => {
      const {codeAgrement, raisonSociale} = agrement
      return (
        <MenuItem
          key={codeAgrement}
          value={codeAgrement}>
          {raisonSociale}
        </MenuItem>
      )
    })
    setAgrementsList(agrementsItems)
  }

  const buildScenarioItems =
    (numClient: string, codeAbo: string, codeGTA: string, service: string, scenario: string) => {
      getScenariosByCodeAbonne(numClient, codeAbo, codeGTA, service)
          .then((response: IScenario[]) => {
            const listItems = response.map((item: IScenario) => {
              return (
                <MenuItem
                  key={item.codeScenario}
                  value={item.codeScenario}
                >
                  {item.libelleScenario}
                </MenuItem>
              )
            })
            setScenariosList(listItems)
            listItems.some((item) => item.key === scenario) ?
            setFieldValue('dossier.scenario', scenario) :
              setFieldValue('dossier.scenario', '');
          }).catch((e) => {
            console.error(e)
          })
    }

  useEffect(() => {
    if (isCourtier) {
      buildAgrementItems()
    }
  }, [])

  useEffect(() => {
    if (values.dossier.scenario && !!agrementsList.length) {
      buildScenarioList(values.dossier.scenario);
    }
  }, [agrementsList, values.dossier.assurance])

  useEffect(() => {
    if (!values.dossier.scenario || isAssureur) {
      buildScenarioList(values.dossier.scenario);
    }
  }, [values.dossier.assurance])

  const buildScenarioList = (scenario: string) => {
    const {numClient, codeAbo, service, agrements} = user
    const assurance = values.dossier.assurance
    if (isCourtier && !!agrementsList.length) {
      const agrement = agrements.find((agrement) => agrement.codeAgrement === assurance)
      buildScenarioItems(numClient, codeAbo, agrement?.codeGTA ?? '', service, scenario)
    } else {
      buildScenarioItems(numClient, codeAbo, user.codeGTA, service, scenario)
    }
  }

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Identification du dossier</Typography>
      <div className={`${classes.flex} ${classes.marginTop12}`}>
        <InputTextField
          name='dossier.numeroPolice'
          label='N° de police'
          mandatory={true}
          maxLength={17}
        />
        <InputTextField
          name='dossier.referenceSinistre'
          label='Référence sinistre'
          mandatory={true}
          maxLength={17}
        />
        <InputTextField
          name='dossier.numeroMission'
          label='N° de mission'
          mandatory={true}
          maxLength={10}
        />
        {(isAssureur || (isCourtier && !agrementsList.length)) &&
              <InputTextField
                name='dossier.assurance'
                label='Assurance'
                disabled={true}
                mandatory={true}
              />
        }
        {isCourtier && !!agrementsList.length &&
          <InputSelectField
            name='dossier.assurance'
            label='Assurance'
            mandatory={true}
            itemsList={agrementsList}
          />
        }
        {!!scenariosList.length &&
          <InputSelectField
            name='dossier.scenario'
            label='Scénario'
            mandatory={isCourtier}
            itemsList={scenariosList}
            emptyItemLabel={ !isCourtier ? 'Aucun' : ''}
          />
        }
      </div>
    </div>
  );
};

export default DossierBloc;
