import * as yup from 'yup';
import {
  checkImmat,
  checkNom,
  checkPrenom,
  checkRaisonSoc,
  checkRefSinFormat,
  checkRefSinSpecialChar,
} from '../../../../common/utils/InputValuesUtils';

export const validationSchemaCT = yup.object({
  immatriculation: yup.string()
      .required('Veuillez saisir une valeur')
      .test('test-immatriculation', 'Veuillez saisir une valeur au format attendu, voir',
          function(value) {
            return checkImmat(value || '')
          }),
  policeAssurance: yup.string()
      .required('Veuillez saisir une valeur'),
  referenceSinistre: yup.string()
      .min(6, 'La référence sinistre doit faire au moins 6 caractères')
      .test('test-referenceSinistre-format', 'La référence sinistre ne doit pas contenir plus de 9 caractères ' +
      'identiques d\'affilée',
      function(value) {
        return checkRefSinFormat(value ?? '')
      })
      .test('test-referenceSinistre-char', 'Le champ contient des caractères interdits',
          function(value) {
            return checkRefSinSpecialChar(value ?? '')
          }),
  nom: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-nom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkNom(value || '')
                }),
      }),
  nomUsage: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .test('test-nomUsage', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkNom(value ?? '')
                }),
      }),
  prenom: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pp',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-prenom', 'Les caractères ! ? * ° : € # ; & ne sont pas autorisés',
                function(value) {
                  return checkPrenom(value || '')
                }),
      }),
  raisonSociale: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .required('Veuillez saisir une valeur')
            .test('test-raisonSociale', 'Les caractères ! ? * ° : € # ; ne sont pas autorisés',
                function(value) {
                  return checkRaisonSoc(value || '')
                }),
      }),
  societeCommerciale: yup.boolean(),
  siren: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .min(9, 'Veuillez saisir une valeur au format attendu, voir')
            .test('test-siren', 'Veuillez saisir une valeur',
                function(value, ctx) {
                  if (ctx.parent.societeCommerciale) {
                    return value !== undefined && value !== ''
                  }
                  return true
                }),
      }),
  siret: yup.string()
      .when('typePersonne', {
        is: (value: string) => value === 'pm',
        then: () => yup.string()
            .min(14, 'Veuillez saisir une valeur au format attendu, voir')
            .test('test-siret', 'Veuillez saisir une valeur',
                function(value, ctx) {
                  if (ctx.parent.societeCommerciale) {
                    return value !== undefined && value !== ''
                  }
                  return true
                }),
      }),
});
