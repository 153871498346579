import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface NomProps {
  className?: string,
  classNameTextField?: string,
  name: string,
  fullWidth?: boolean,
  formulaire: string,
  nomUsage: boolean,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText?: string,
  value: string,
  maxLength?: number,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const Nom = ({className, classNameTextField, name, fullWidth, formulaire, nomUsage, onChangeValue, onBlur,
  onFocus, onError, helperText, value, maxLength}: NomProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        {nomUsage ? 'Nom d\'usage ' : 'Nom '}{!nomUsage && <span className={classes.red}>*</span>}
      </Typography>

      <TextFieldSearch
        className={classNameTextField}
        id={nomUsage ? 'nomUsage' + formulaire : 'nom' + formulaire}
        name={name}
        fullWidth={fullWidth}
        maxLength={maxLength}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
      />
    </div>
  )
};
