import React, {useState} from 'react';
import {Card, CardContent, Grid, Modal, Slide, Typography} from '@mui/material';
import {LatLngExpression} from 'leaflet';
import {ButtonBlue} from '../../common/buttons/ButtonBlue';
import {Loader} from '../../common/Loader';
import {ModalMsg} from './ModalMsg';
import {ErrorFields} from '../PriseRDV';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {IMarque, InformationAssure, RdvModel} from '../../../model/priseRDV/RDVModel';
import {ActiviteProps} from '../../../model/priseRDV/ActiviteModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {BlocInfosRdv} from './components/BlocInfosRdv';
import {BlocInfosAssure} from './components/BlocInfosAssure';
import {BlocInfosPartenaire} from './components/BlocInfosPartenaire';
import {BlocRendezVous} from './components/BlocRendezVous';
import {BlocLocalisation} from './components/BlocLocalisation';

interface RecapitulatifProps {
  data: RdvModel,
  codesValeursMarque: ItemGetValeursCodesModel[],
  selectedMarque: ItemGetValeursCodesModel,
  errorFields: ErrorFields,
  setErrorFields: (errorFields: ErrorFields) => void,
  onClickSaveInfosAssure: (prenom: string, nom: string, tel: string, email: string, marque?: IMarque,
                           modele?: string) => void,
  onClickSaveRemarques: (msg: string) => void,
  onClickConfirm: (activite: ActiviteProps) => void,
  isLoadingConfirmation: boolean,
  responseReturn: number,
}

/** Design */
const styles = (theme: Theme) => ({
  title: {
    margin: '0px',
    padding: '8px',
  },
  height100: {
    height: '100%',
  },
  buttonContainer: {
    textAlign: 'center' as const,
    marginTop: '60px',
  },
  error: {
    fontSize: '12px',
    color: 'red',
    marginBottom: '20px',
  },
  loader: {
    marginTop: '20px',
  },
});

export const Recapitulatif = (props: RecapitulatifProps) => {
  const classes = useEmotionStyles(styles);
  const {data, codesValeursMarque, selectedMarque, errorFields, setErrorFields, onClickSaveInfosAssure,
    onClickSaveRemarques, onClickConfirm, isLoadingConfirmation, responseReturn} = props;
  const [openMsgForm, setOpenMsgForm] = useState(false);
  const [message, setMessage] = useState('');
  const isUpdateRdv = !!data.idRdv;
  const confirm = 'Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?';

  const activites = data.partenaire.infosPartenaire.activites
  const activite = activites?.length ? activites[0] : undefined;
  const marquesReparees = activite?.marquesReparees;
  const isToutesMarques = !!(marquesReparees?.includes('Toutes marques (TTESMARQUES)'));

  const latLngTarget: LatLngExpression = [data.informationsAssure.coordonneesGPS.latitude,
    data.informationsAssure.coordonneesGPS.longitude];

  const latLngMarker: LatLngExpression = [data.partenaire.infosPartenaire.coordonneesAdresse.lat,
    data.partenaire.infosPartenaire.coordonneesAdresse.lng];

  const defaultBounds = data.informationsAssure.coordonneesGPS.nom ? [latLngTarget, latLngMarker] : [latLngMarker];

  const onCloseMsg = () => {
    const result = window.confirm(confirm);
    if (result) {
      setOpenMsgForm(false);
    }
  };

  const onClickSaveMsg = (msg : string) => {
    setMessage(msg);
    onClickSaveRemarques(msg);
    setOpenMsgForm(false);
  };

  const slideMsgForm = (
    <div>
      <ModalMsg
        destinataire={data.partenaire.infosPartenaire.raisonSociale.nom}
        message={message}
        onClose={onCloseMsg}
        onClickSave={onClickSaveMsg}
      />
    </div>
  );

  const mapCenter: LatLngExpression = data.informationsAssure.coordonneesGPS.nom !== '' ?
    [data.informationsAssure.coordonneesGPS.latitude, data.informationsAssure.coordonneesGPS.longitude] :
    [data.partenaire.infosPartenaire.coordonneesAdresse.lat, data.partenaire.infosPartenaire.coordonneesAdresse.lng];

  const infosAssureRecap: InformationAssure = {
    prenom: data.informationsAssure.prenom,
    nom: data.informationsAssure.nom,
    telephone: data.informationsAssure.telephone,
    email: data.informationsAssure.email,
    immatriculation: data.informationsAssure.immatriculation,
    marque: {
      valeur: selectedMarque.valeur.toUpperCase().trim(),
      libelleedition: selectedMarque.libelleedition.trim(),
    },
    modele: data.informationsAssure.modele,
    coordonneesGPS: {
      nom: data.informationsAssure.coordonneesGPS.nom,
      latitude: data.informationsAssure.coordonneesGPS.latitude,
      longitude: data.informationsAssure.coordonneesGPS.longitude,
    },
    adresse: {
      nom: data.informationsAssure.adresse.nom,
      numero: data.informationsAssure.adresse.numero,
      voie: data.informationsAssure.adresse.voie,
      codePostal: data.informationsAssure.adresse.codePostal,
      commune: data.informationsAssure.adresse.commune,
    },
  };

  const infosPartenaireRecap = {
    raisonSociale: data.partenaire.infosPartenaire.raisonSociale.nom,
    distance: data.partenaire.infosPartenaire.distance,
    adresse: data.partenaire.infosPartenaire.adresse,
    coordonnees: data.partenaire.infosPartenaire.coordonnees.telephone ||
      data.partenaire.infosPartenaire.coordonnees.portable ||
      data.partenaire.infosPartenaire.coordonnees.email,
    isToutesMarques: isToutesMarques,
    marquesReparees: marquesReparees ?? [],
    conges: data.partenaire.infosPartenaire.conges?.periodes || [],
    horaires: data.partenaire.infosPartenaire.horaires,
  };

  return (
    <>
      <div>
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant={'h3'} className={classes.title}>Récapitulatif des informations</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'h3'} className={classes.title}>Récapitulatif du choix du réparateur</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={12} md={6}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={12}>
                    <BlocInfosRdv
                      informationsRdv={data.informationsRdv}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BlocInfosAssure
                      from={'recapRdv'}
                      infosAssure={infosAssureRecap}
                      codesValeursMarque={codesValeursMarque}
                      hasRoleModificationRDV={true}
                      rdvActif={true}
                      errorFields={errorFields}
                      setErrorFields={setErrorFields}
                      onClickSaveInfosAssure={(prenom, nom, tel, email, marque, modele) => {
                        onClickSaveInfosAssure(prenom, nom, tel, email, marque, modele);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card style={{height: '100%'}}>
              <CardContent className={classes.height100}>
                <Grid className={classes.height100} container justifyContent='space-between' spacing={2}>
                  <Grid item sm={4}>
                    <BlocLocalisation
                      isUpdateRdv={isUpdateRdv}
                      zoom={isUpdateRdv ? 12 : undefined}
                      bounds={defaultBounds}
                      center={mapCenter}
                      positionMarkerAssure={latLngTarget}
                      positionMarkerPartenaire={latLngMarker}
                    />
                    <BlocRendezVous
                      dateDebut={data.creneau}
                      duree={data.duree.libelle}
                      hasRoleModificationRDV={false}
                      rdvActif={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <BlocInfosPartenaire
                      isUpdateRdv={isUpdateRdv}
                      setOpenMsgForm={setOpenMsgForm}
                      data={infosPartenaireRecap}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <div className={classes.buttonContainer}>
          {responseReturn === -1 &&
            <Typography className={`${classes.error}`}>
              Échec de la prise de rendez-vous. Le contenu de votre formulaire n&apos;est pas valide.
            </Typography>
          }
          {isLoadingConfirmation ?
            <Loader className={classes.loader} size={30}/> :
            <ButtonBlue
              id={'boutonConfirmer'}
              libelle={isUpdateRdv ? 'Confirmer la modification de rendez-vous' : 'Confirmer la prise de rendez-vous'}
              onClick={() => onClickConfirm(data.partenaire.infosPartenaire.activites.find((act) =>
                act.categorieReparateur === data.informationsRdv.sousActivite.code)!)}/>
          }
        </div>
      </div>

      <Modal
        open={openMsgForm}
        aria-labelledby="modal-form-msg"
        aria-describedby="modal-formulaire-envoie-msg"
      >
        <Slide in={openMsgForm}
          direction={'left'}
          timeout={800}
          mountOnEnter unmountOnExit>
          {slideMsgForm}
        </Slide>
      </Modal>
    </>
  )
};
