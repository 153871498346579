import {getPieceJointe} from '../../../api/dossier';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {extensionsList} from '../../../constantes/extensionsPJ/extensionsPJ';

export interface AttachmentProps {
  id: string,
  name?: string,
  date?: string,
}

const getExtension = (attachmentId: string) => {
  let extension = 'txt';
  const idSplit = attachmentId.split('.');

  if (idSplit.length > 1) {
    extension = idSplit.slice(-1).toString();
  }

  return extension
};

interface DownloadAllAttachmentsProps {
  attachments: AttachmentProps[],
  author: string,
  typeDoc: string,
  referenceSinistre: string
  numClient: string,
  setIsLoading: (isLoading: boolean) => void,
  setIsEndDownload: (isEndDownload: boolean) => void,
  setIsFilesInZip: (isFilesInZip: boolean) => void,
  setOnErrorDownload: (onErrorDownload: boolean) => void,
  setNbFilesInZip: (nbFilesInZip: number) => void,
}
export const downloadAllAttachments = async (props: DownloadAllAttachmentsProps) => {
  const {attachments, author, typeDoc, referenceSinistre, numClient, setIsLoading,
    setIsEndDownload, setIsFilesInZip, setOnErrorDownload, setNbFilesInZip} = props
  setIsLoading(true);
  setIsEndDownload(false);
  setIsFilesInZip(true);
  setOnErrorDownload(false);

  const blobs = attachments.map((att) => {
    return getPieceJointe(att.id, numClient)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e)
        })
  });

  // Type de pièce jointe dans le ZIP
  let typePJInZip = '';

  const zip = new JSZip();
  /**
   * Ajoute dans le zip les fichiers qui ne sont pas en erreur
   */
  async function setZip() {
    let index = 0;
    let indexImage = 0;
    let indexFichier = 0;
    let nbFiles = 0;
    for (const blob of blobs) {
      await blob.then((resp) => {
        if (resp !== null && resp !== undefined) {
          nbFiles++;
          const attachmentTmp = attachments[index];
          const extension = getExtension(attachmentTmp.id);
          const prefix = extensionsList.includes(extension.toUpperCase()) ? 'image' : 'fichier';
          if (prefix === 'image') {
            indexImage++;
          }
          if (prefix === 'fichier') {
            indexFichier++;
          }
          const nom = prefix + (prefix === 'image' ? indexImage : indexFichier) +
            (attachmentTmp.name ? '_' + attachmentTmp.name.trim().replaceAll('/', '_') : '') +
            (author ? '_' + author.trim() : '') +
            (typeDoc ? '_' + typeDoc.trim() : '') +
            (referenceSinistre ? '_' + referenceSinistre.trim() : '') +
            (attachmentTmp.date ? '_' + attachmentTmp.date : '') +
            '.' + extension;

          zip.file(nom, blob, {binary: true});

          const typePJ = extensionsList.includes(extension.toUpperCase()) ? 'album' : 'fichiers';
          switch (typePJInZip) {
            case '':
              typePJInZip = typePJ;
              break;
            case 'fichiers':
              if (typePJ !== 'fichiers') {
                typePJInZip = 'PJ';
              }
              break;
            case 'album':
              if (typePJ !== 'album') {
                typePJInZip = 'PJ';
              }
              break;
          }
        }
      });
      index++;
    }
    setNbFilesInZip(nbFiles);
  }

  setZip().then(() => {
    if (Object.keys(zip.files).length) {
      setIsFilesInZip(true);
      const zipName = typePJInZip +
        (author ? '_' + author.trim() : '') +
        (typeDoc ? '_' + typeDoc.trim() : '') +
        (referenceSinistre ? '_' + referenceSinistre.trim() : '') +
        (attachments[0].date ? '_' + attachments[0].date : '') + '.zip';

      zip.generateAsync({type: 'blob'})
          .then(function callback(blob) {
            saveAs(blob, zipName);
            setIsLoading(false);
          });
      setIsEndDownload(true);
    } else {
      setIsLoading(false);
      setIsFilesInZip(false);
    }
  }).catch(() => {
    setIsLoading(false);
    setOnErrorDownload(true);
  })
};

interface DownloadOneAttachmentProps {
  attachment: AttachmentProps,
  author: string,
  typeDoc: string,
  referenceSinistre: string
  immatriculation: string
  numClient: string,
  setOnErrorDownload: (onErrorDownload: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
}
export const downloadOneAttachment = (props: DownloadOneAttachmentProps) => {
  const {attachment, author, typeDoc, referenceSinistre, immatriculation, numClient,
    setOnErrorDownload, setIsLoading} = props
  setOnErrorDownload(false);
  setIsLoading(true);
  const extension = getExtension(attachment.id);
  const prefix = extensionsList.includes(extension.toUpperCase()) ? 'image' : 'fichier';

  const blob = getPieceJointe(attachment.id, numClient)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.error(e)
      });

  blob.then((response) => {
    const blobURL = URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = blobURL;

    a.download = (attachment.name ? attachment.name.trim().replaceAll('/', '_') : prefix) +
      (immatriculation ? '_' + immatriculation.trim() : '') +
      (author ? '_' + author.trim() : '') +
      (typeDoc ? '_' + typeDoc.trim() : '') +
      (referenceSinistre ? '_' + referenceSinistre.trim() : '') +
      (attachment.date ? '_' + attachment.date : '') +
      '.' + extension;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setIsLoading(false);
  }).catch(() => {
    setIsLoading(false);
    setOnErrorDownload(true);
  });
};
