import React from 'react';
import {DetailsModel} from '../../model/detailsDossier/DetailsModel';
import {useMissionContext} from '../../store/MissionContext';
import {ItemNotificationModel} from '../../model/detailsDossier/ItemNotificationModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Badge, Divider, IconButton, Menu, MenuItem, Tooltip, Typography} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

type StylesProps = {statutNotif: string | undefined, counterNotif: number}
const styles = (theme: Theme, props: StylesProps) => ({
  iconButton: {
    marginRight: '25px',
  },
  badgeNotif: {
    backgroundColor: props.statutNotif === 'info' ? theme.palette.green.main : getAlertColor(props, theme),
    color: theme.palette.white.main,
    width: (props.counterNotif >= 10) ? '26px' : 'auto',
  },
  iconNotif: {
    fontSize: '2.5rem',
    color: theme.palette.white.main,
    margin: '-8px',
  },
  menuNotif: {
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  title: {
    pointerEvents: 'none' as const,
    top: 0,
    backgroundColor: theme.palette.white.main,
    position: 'sticky' as const,
    zIndex: 1,
    height: '40px',
    alignItems: 'flex-start',
  },
  menuItem: {
    pointerEvents: 'none' as const,
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  infoIcon: {
    color: theme.palette.green.main,
  },
  alertIcon: {
    color: theme.palette.tertiary.main,
  },
  bold: {
    fontWeight: 'bold',
  },
});

const getAlertColor = (props: { statutNotif: any; counterNotif?: number; }, theme: Theme) => {
  return props.statutNotif === 'alert' ? theme.palette.tertiary.main : 'grey';
};

const ITEM_HEIGHT = 56;

export const NotificationsComponent = () => {
  const missionContext = useMissionContext().mission;
  const getItemMission = sessionStorage.getItem('mission');

  let mission: DetailsModel = {};
  if (missionContext === undefined) {
    if (getItemMission) {
      mission = JSON.parse(getItemMission);
    }
  } else {
    mission = missionContext;
  }

  const statutNotif = mission?.notificationDTO?.statut;
  const listNotif = mission?.notificationDTO?.list ?? [];
  const counterNotif = listNotif ? listNotif?.length : 0;

  const classes = useEmotionStyles(styles, {statutNotif, counterNotif});

  const [anchorNotif, setAnchorNotif] = React.useState<null | HTMLElement>(null);
  const openNotif = Boolean(anchorNotif);

  const handleClickNotif = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNotif(event.currentTarget)
  };

  const handleCloseNotif = () => {
    setAnchorNotif(null)
  };

  return (
    <>
      <Tooltip title='Notifications'>
        <div>
          <IconButton
            id={'boutonNotif'}
            className={classes.iconButton}
            onClick={handleClickNotif}
            disabled={counterNotif <= 0}
          >
            <Badge
              classes={{badge: classes.badgeNotif}}
              badgeContent={counterNotif}
              showZero={true}
              overlap={'rectangular'}
            >
              <NotificationsIcon className={classes.iconNotif}/>
            </Badge>
          </IconButton>
        </div>
      </Tooltip>

      <Menu
        id="menuNotif"
        anchorEl={anchorNotif}
        keepMounted
        classes={{paper: classes.menuNotif}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5,
          },
        }}
        open={openNotif}
        onClose={handleCloseNotif}
      >
        <MenuItem className={classes.title}>
          <Typography variant={'h3'}>Notifications</Typography>
        </MenuItem>
        {listNotif?.map( (item: ItemNotificationModel, index: number) => {
          const keyDiv = `${index}`
          return (
            <div key={keyDiv}>
              <MenuItem className={classes.menuItem}>
                <div className={classes.line}>
                  {item.type === 'info'?
                    <div>
                      <CheckCircleIcon className={classes.infoIcon}/>
                    </div> :
                    <div>
                      <ErrorIcon className={classes.alertIcon}/>
                    </div>
                  }
                  <div>
                    <span className={classes.bold}>{item.libelle}</span><br/>
                    <span>{item.detail}</span>
                  </div>
                </div>
              </MenuItem>
              <Divider variant={'middle'}/>
            </div>
          )
        })}
      </Menu>
    </>
  )
};
