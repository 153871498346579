import React from 'react';
import {Typography} from '@mui/material';
import {TextFieldSearch} from './formsComponents/TextFieldSearch';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface RefSinistreProps {
  className?: string,
  name?: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isMandatory: boolean,
  onError: boolean,
  value?: string,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const RefSinistre = ({className, name, formulaire, onChangeValue, onBlur, onFocus, onError, onInput,
  isMandatory, value, helperText}: RefSinistreProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        Référence sinistre {isMandatory ? <span className={classes.red}>*</span> : ''}
      </Typography>

      <TextFieldSearch
        id={'referenceSinistre' + formulaire}
        name={name}
        fullWidth={true}
        maxLength={17}
        value={value}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput}
      />
    </div>
  )
};
