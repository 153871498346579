// Format FNI
const regex1Immat = /^([1-9]\d?\d?\d?) ([A-Za-z][A-Za-z]?[A-Za-z]?) (0[1-9]|[1-9]\d\d?|2A|2B)$/;
// Format SIV
const regex2Immat = /^([A-Za-z][A-Za-z])-(\d\d\d)-([A-Za-z][A-Za-z])$/;
// Format Cyclo
const regex3Immat = /^([A-Za-z]?[A-Za-z]) (\d\d\d?) ([A-Za-z])$/;

export const checkImmat = (immat: string) => {
  return regex1Immat.test(immat) || regex2Immat.test(immat) || regex3Immat.test(immat);
};

export const getTypeImmat = (immat?: string) => {
  if (immat) {
    if (regex1Immat.test(immat)) {
      return 'FNI'
    } else if (regex2Immat.test(immat)) {
      return 'SIV'
    } else if (regex3Immat.test(immat)) {
      return 'CYCLO'
    } else {
      return ''
    }
  } else {
    return ''
  }
};

// eslint-disable-next-line max-len
const regexSpecialCharAccepted = /^[\w\sàáâãäåçèéêëìíîïðòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝ+=/.,;*'$£€µ%§#@?øØ²~`«»¨´&(){}°^|!:[\]-]*$/;

const regexRefSin = /(.)\1{9,}/;

export const checkRefSinFormat = (refSin: string) => {
  return !regexRefSin.test(refSin)
};

export const checkRefSinSpecialChar = (refSin: string) => {
  return regexSpecialCharAccepted.test(refSin)
};

export const checkNom = (nom: string) => {
  return !/[!?*°:€#;&]+/.test(nom)
};

export const checkPrenom = (prenom: string) => {
  return !/[!?*°:€#;&]+/.test(prenom)
};

export const checkRaisonSoc = (raisonSoc: string) => {
  return !/[!?*°:€#;]+/.test(raisonSoc)
};

export const checkNumMission = (numMission: string) => {
  return regexSpecialCharAccepted.test(numMission)
};

export const checkIdentificationPP = (identificationPP: string) => {
  return !/[!?*°:€#;&]+/.test(identificationPP)
};

export const checkIdentificationPM = (identificationPM: string) => {
  return !/[!?*°:€#;]+/.test(identificationPM)
};

export const checkAdresse = (adresse: string) => {
  return !/[!?*°:€#;&+@%]+/.test(adresse)
};

export const checkTelMobile = (inputTelephone: string, isMandatory: boolean) => {
  let onError: boolean = isMandatory ? inputTelephone.length === 0 : false;
  if (inputTelephone.length && !(/(0[67])(?:\s?(\d{2})){4}/.test(inputTelephone))) {
    onError = true;
  }
  return onError
};
