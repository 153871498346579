import React from 'react';
import {CoordonneesLese} from './CoordonneesLese';
import {CoordonneesReparateur} from './CoordonneesReparateur';

export const Coordonnees = () => {
  return (
    <div style={{marginBottom: '16px'}}>
      <CoordonneesLese/>
      <CoordonneesReparateur/>
    </div>
  )
};
