import React, {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InputMask from 'react-input-mask-3.0';
import {ButtonBlueDelete} from '../../common/formsComponents/ButtonBlueDelete';
import BlueSwitch from '../../common/BlueSwitch'
import {ElementCalculModel} from '../../../model/detailsDossier/ElementCalculModel';
import {TextFieldSelect} from '../../common/formsComponents/TextFieldSelect';
import {TextFieldSearch} from '../../common/formsComponents/TextFieldSearch';
import {TextFieldCurrency} from '../../common/formsComponents/TextFieldCurrency';
import HelpIcon from '@mui/icons-material/Help';
import {findElementByCodeInArray} from '../../../common/Utils';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  summary: {
    'backgroundColor': theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummaryExpandIcon: {
    padding: '12px 0px',
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
  textFieldFullWidth: {
    width: '100%',
  },
  textField: {
    width: '150px',
  },
});

export interface ElementCalculProps {
  codesValeursPriseEnCharge: ItemGetValeursCodesModel[],
  listElementsCalculs: ElementCalculModel[],
  expanded: boolean,
  data: ElementCalculModel,
  onChangeElement:
    (input: string,
     event: React.ChangeEvent<{ name?: string; value: unknown } | HTMLInputElement>,
     currentElement: ElementCalculModel) => void,
  onChangeCurrency: (currentElement: ElementCalculModel, value: string) => void,
  onChangePanel: (panel: string) => void,
  onDelete: (event: React.MouseEvent<HTMLElement>, currentElement: ElementCalculModel) => void,
}

const ElementCalcul = (props: ElementCalculProps) => {
  const classes = useEmotionStyles(styles);
  const {codesValeursPriseEnCharge, listElementsCalculs, expanded, data, onChangeElement, onDelete, onChangePanel,
    onChangeCurrency} = props;
  const [formData, setFormData] = useState(data);
  const [errorFields, setErrorFields] = useState(data.errorFields);
  const [valueCurrency, setValueCurrency] = useState(data.plafondPriseEnCharge);
  const [checkTvaAssure, setCheckTvaAssure] = useState(data.tvaRecuperableParAssure === 'O');
  const [checkElementReparation, setCheckElementReparation] = useState(data.elementComprisReparation === 'O');
  const [checkAbattementADeduire, setCheckAbattementADeduire] = useState(data.abattementADeduire === 'O');

  const itemsListPriseEnCharge = codesValeursPriseEnCharge.map((item, index) => {
    const isElement = !!listElementsCalculs.find((element) => element.priseEnCharge === item.valeur);
    return (
      <MenuItem
        disabled={isElement && item.valeur !== 'Z'}
        key={item.valeur}
        id={`priseEnCharge-${index}`}
        value={item.valeur}
      >
        {item.libelleedition}
      </MenuItem>
    )
  });

  useEffect(() => {
    setErrorFields(data.errorFields)
  }, [data.errorFields]);

  useEffect(() => {
    setFormData(data)
  }, [data]);

  useEffect(() => {
    setFormData({...formData, plafondPriseEnCharge: valueCurrency});
    onChangeCurrency(data, valueCurrency)
  }, [valueCurrency]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<{ name?: string; value: unknown }> | React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'priseEnCharge') {
        setErrorFields({...errorFields, priseEnChargeError: false});
      }
      if (input === 'tauxPriseEnCharge' && event.target.value as number > 100) {
        event.target.value = '100'
      }
      if (input === 'tauxFranchiseProportionnelle' && event.target.value as number > 100) {
        event.target.value = '100'
      }
      setFormData({...formData, [input]: event.target.value});
      if (input === 'tvaRecuperableParAssure') {
        setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
        setCheckTvaAssure((event as React.ChangeEvent<HTMLInputElement>).target.checked)
      }
      if (input === 'elementComprisReparation') {
        setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
        setCheckElementReparation((event as React.ChangeEvent<HTMLInputElement>).target.checked)
      }
      if (input === 'abattementADeduire') {
        setFormData({...formData, [input]: (event as React.ChangeEvent<HTMLInputElement>).target.checked ? 'O' : 'N'});
        setCheckAbattementADeduire((event as React.ChangeEvent<HTMLInputElement>).target.checked)
      }
      onChangeElement(input, event, formData)
    };

  const libellePriseEnCharge = findElementByCodeInArray(codesValeursPriseEnCharge,
      formData.priseEnCharge)?.libelleedition;

  return (
    <div className={classes.root}>
      <Accordion style={{borderRadius: '8px 8px 0px 0px', width: '90%'}}
        expanded={expanded}
        onChange={() => onChangePanel(`panel-${data.id}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls='panel1a-content'
          id={'panel1a-header-' + data.id}
          className={classes.summary}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expandIconWrapper: classes.accordionSummaryExpandIcon,
          }}
        >
          <Typography className={classes.libelleAccordion}>
            {props.data.id + 1} - {libellePriseEnCharge || <i>Ajoutez un élément de calcul</i>}
          </Typography>
        </AccordionSummary>

        <AccordionDetails style={{display: 'block', padding: '16px'}}>
          <Typography className={classes.libelle}>
            Élément de prise en charge <span style={{color: 'red'}}>*</span>
          </Typography>
          <TextFieldSelect
            className={classes.textFieldFullWidth}
            id={'element' + props.data.id}
            name={'element'}
            marginDense={true}
            withEmptyItem={true}
            emptyItemLabel={'Aucun'}
            value={formData.priseEnCharge}
            itemsList={itemsListPriseEnCharge}
            onError={errorFields?.priseEnChargeError}
            onChange={handleChange('priseEnCharge')}
          />
          {errorFields?.priseEnChargeError &&
            <Typography className={classes.error}>Veuillez sélectionner une valeur</Typography>
          }

          {formData.priseEnCharge && <>
            <div style={{display: 'flex', columnGap: '60px', marginTop: '10px'}}>
              <div style={{width: '50%'}}>
                <Typography className={classes.libelle}>Descriptif de l&apos;élément</Typography>
                <TextFieldSearch id={'descriptif' + props.data.id} className={classes.textFieldFullWidth}
                  value={formData.descriptif} maxLength={20} onChange={handleChange('descriptif')}/>
              </div>
              <div style={{width: '50%'}}>
                <Typography className={classes.libelle}>Montant franchise forfaitaire</Typography>
                <InputMask mask="99999" maskPlaceholder={null} value={formData.montantFranchiseForaitaire}
                  onChange={handleChange('montantFranchiseForaitaire')}
                >
                  <TextFieldSearch id={'montantFranchise' + props.data.id} className={classes.textFieldFullWidth}
                    endAdornment={'€'}/>
                </InputMask>
              </div>
            </div>

            <Typography style={{fontWeight: 'bold', marginTop: '20px'}}>Franchise proportionnelle</Typography>
            <div style={{display: 'flex', flexWrap: 'wrap', columnGap: '60px', marginTop: '5px'}}>
              <div>
                <Typography className={classes.libelle}>Taux</Typography>
                <InputMask mask='999' maskPlaceholder={null} value={formData.tauxFranchiseProportionnelle}
                  onChange={handleChange('tauxFranchiseProportionnelle')}
                >
                  <TextFieldSearch id={'tauxFranchise' + props.data.id} className={classes.textField}
                    endAdornment={'%'}/>
                </InputMask>
              </div>
              <div>
                <Typography className={classes.libelle}>Montant plancher</Typography>
                <InputMask mask='99999' maskPlaceholder={null} value={formData.montantPlancherFranchiseProportionnelle}
                  onChange={handleChange('montantPlancherFranchiseProportionnelle')}
                >
                  <TextFieldSearch id={'montantPlancher' + props.data.id} className={classes.textField}
                    endAdornment={'€'}/>
                </InputMask>
              </div>
              <div>
                <Typography className={classes.libelle}>Montant plafond</Typography>
                <InputMask mask='99999' maskPlaceholder={null} value={formData.montantPlafondFranchiseProportionnelle}
                  onChange={handleChange('montantPlafondFranchiseProportionnelle')}
                >
                  <TextFieldSearch id={'montantPlafondFranchise' + props.data.id} className={classes.textField}
                    endAdornment={'€'}/>
                </InputMask>
              </div>
            </div>

            <Typography style={{fontWeight: 'bold', marginTop: '20px'}}>Prise en charge</Typography>
            <div style={{display: 'flex', flexWrap: 'wrap', columnGap: '60px', marginTop: '5px'}}>
              <div>
                <Typography className={classes.libelle}>Taux</Typography>
                <InputMask mask='999' maskPlaceholder={null} value={formData.tauxPriseEnCharge}
                  onChange={handleChange('tauxPriseEnCharge')}
                >
                  <TextFieldSearch id={'tauxPriseEnCharge' + props.data.id} className={classes.textField}
                    endAdornment={'%'}/>
                </InputMask>
              </div>
              <div>
                <Typography className={classes.libelle} style={{display: 'flex'}}>
                  Montant plafond
                  <Tooltip
                    disableInteractive
                    style={{marginLeft: '10px'}}
                    placement="top"
                    title={'Montant compris entre 0 et 9999999,99'}
                    PopperProps={{disablePortal: true}}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <TextFieldCurrency id={'montantPlafondPriseEnCharge' + props.data.id} className={classes.textField}
                  value={valueCurrency} minValue='0000000.00' maxValue='9999999.99'
                  onChange={(event: React.ChangeEvent<{}>, value: string) => setValueCurrency(value)}
                />
              </div>
            </div>

            <div style={{width: '50%', marginTop: '20px'}}>
              <FormControlLabel style={{display: 'flex', justifyContent: 'space-between', margin: '0px'}}
                value={checkTvaAssure} label="TVA récupérable par l'assuré" labelPlacement="start"
                control={<BlueSwitch id={'tvaRecuperableSwitcher' + data.id } name="tvaRecuperableParAssure"
                  checked={checkTvaAssure}
                  onChange={handleChange('tvaRecuperableParAssure')}/>}
              />
              <FormControlLabel style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0px 0px'}}
                label="Elément compris dans la réparation" labelPlacement="start"
                control={<BlueSwitch id={'elementComprisReparationSwitcher' + data.id}
                  name="elementComprisReparation"
                  checked={checkElementReparation}
                  onChange={handleChange('elementComprisReparation')}
                />}
              />
              <FormControlLabel style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0px 0px'}}
                label="Abattement pour usure à déduire" labelPlacement="start"
                control={<BlueSwitch id={'abattementADeduireSwitcher' + data.id} name="abattementADeduire"
                  checked={checkAbattementADeduire}
                  onChange={handleChange('abattementADeduire')}
                />}
              />
            </div>
          </>}
        </AccordionDetails>
      </Accordion>
      <div style={{display: 'flex', width: '10%'}}>
        <div style={{margin: 'auto'}}>
          <ButtonBlueDelete id={'boutonSupprimer' + formData.id}
            onClick={(event: React.MouseEvent<HTMLElement>) => onDelete(event, data)}/>
        </div>
      </div>
    </div>
  );
};

export default ElementCalcul;
