import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface AutoCompleteMarqueProps {
  id: string,
  codesValeursMarque: ItemGetValeursCodesModel[],
  selectedMarque: ItemGetValeursCodesModel,
  disabled?: boolean,
  onChangeMarque: (event: React.ChangeEvent<{}>, value: any) => void
}

const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

const AutoCompleteMarque = (props: AutoCompleteMarqueProps) => {
  const {id, codesValeursMarque, selectedMarque, disabled, onChangeMarque} = props;
  const classes = useEmotionStyles(styles);

  return (
    <Autocomplete
      id={id}
      autoHighlight
      value={selectedMarque}
      options={codesValeursMarque}
      getOptionLabel={(item: ItemGetValeursCodesModel) => item.libelleedition?.trim() ?? ''}
      onChange={onChangeMarque}
      disabled={disabled}
      noOptionsText={'Aucun résultat trouvé'}
      renderInput={(params) =>
        <TextField
          {...params}
          id='marque'
          name='marque'
          margin="dense"
          size="small"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.textfield,
            },
          }}
        />
      }
    />
  );
};

export default AutoCompleteMarque;
