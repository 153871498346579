import React from 'react';
import {GarantieResponsabilite} from './GarantieResponsabilite';
import {ElementsCalculs} from './elementsCalcul/ElementsCalculs';

export const Garantie = () => {
  return (
    <div style={{marginBottom: '16px'}}>
      <GarantieResponsabilite/>
      <ElementsCalculs/>
    </div>
  )
};
