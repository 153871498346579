import Keycloak, {KeycloakConfig} from 'keycloak-js'
import AxiosService from './AxiosService';

type AuthenticatedCallback = (keycloak: Keycloak) => void

const KeyCloakService = (keycloakConfig: KeycloakConfig) => {
  // eslint-disable-next-line new-cap
  const keycloakInstance = new Keycloak(keycloakConfig);

  const initKeycloak = (onAuthenticatedCallback: AuthenticatedCallback) => {
    keycloakInstance.init({
      onLoad: 'login-required',
      enableLogging: true,
      checkLoginIframe: false,
      pkceMethod: 'S256',
    }).then((authenticated: boolean) => {
      if (!authenticated) {
        window.location.reload();
      } else {
        AxiosService.configure(keycloakInstance);
        onAuthenticatedCallback(keycloakInstance)
      }
    }).catch(console.error);
  };

  const initKeycloakSSO = (idpHint: string, onAuthenticatedCallback: AuthenticatedCallback) => {
    keycloakInstance.init({
      onLoad: 'check-sso',
    }).then((authenticated) => {
      if (!authenticated) {
        keycloakInstance.login({
          idpHint: idpHint,
        })
      } else {
        AxiosService.configure(keycloakInstance);
        onAuthenticatedCallback(keycloakInstance)
      }
    }).catch(console.error);
  }

  const updateToken = () => {
    keycloakInstance.onTokenExpired = () => {
      console.log('Authentication token expired (app)');
      keycloakInstance.updateToken(-1)
          .then(() => {
            console.log('Authentication token successfully updated (app)')
          })
          .catch(() => {
            console.error('Authentication token update FAILED (app)')
          })
    }
  };

  const isLoggedIn = () => !!keycloakInstance.token;

  return {keycloakInstance, initKeycloak, initKeycloakSSO, updateToken, isLoggedIn}
};

export default KeyCloakService
