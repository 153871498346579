import React, {useState} from 'react';
import {Button} from '@mui/material';
import TextTruncate from 'react-text-truncate';
import {MessageBubble} from '../../../common/MessageBubble';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {ICommentaire} from '../../../../model/detailsDossier/ItemCommentaireModel';

interface BlocCommentaireProps {
  index: number,
  commentaire : ICommentaire,
  itemSelected: boolean,
}

/** Design */
const styles = () => ({
  content: {
    'maxHeight': '280px',
    'overflowY': 'auto' as const,
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  btnText: {
    padding: '0px',
    color: '#0095DB',
  },
  btn: {
    textDecoration: 'underline',
  },
  btnVoirMoins: {
    padding: '0px 10px 5px 10px',
    textAlign: 'right' as const,
    marginRight: '30px',
  },
});

const Content = ({commentaire}: {commentaire: ICommentaire}) => {
  const classes = useEmotionStyles(styles);
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(true);

  const formatComments = (commentaires: string[]) => {
    let commentsFormated = '';
    for (const comment of commentaires) {
      // gestion du signe € + ajout d'un espace après chaque ligne
      commentsFormated += comment.replaceAll('\x80', '€') + ' '
    }
    return commentsFormated.trim()
  };

  return (
    <>
      <div className={classes.content}>
        {truncated &&
          <TextTruncate
            line={3}
            element='span'
            truncateText='...'
            text={formatComments(commentaire.contenu)}
            textTruncateChild={
              <span>
                <Button
                  classes={{text: classes.btnText}}
                  className={classes.btn}
                  id={'voirPlus'}
                  onClick={() => {
                    setTruncated(false);
                    setExpanded(!expanded)
                  }}
                >
                  Voir plus
                </Button>
              </span>
            }
          />
        }
        {expanded &&
          <React.Fragment>
            <span>{formatComments(commentaire.contenu)}</span>
          </React.Fragment>
        }
      </div>
      {expanded &&
        <div className={classes.btnVoirMoins}>
          <Button
            classes={{text: classes.btnText}}
            className={classes.btn}
            id={'voirMoins'}
            onClick={() => {
              setExpanded(!expanded);
              setTruncated(true)
            }}
          >
            Voir moins
          </Button>
        </div>
      }
    </>
  )
};

export const BlocCommentaire = ({index, itemSelected, commentaire}: BlocCommentaireProps) => {
  // gestion de l'affichage de l'émetteur et/ou du destinataire
  const getTitle = (commentaire: ICommentaire) => {
    if (commentaire.envoyeRecu === 'recu') {
      return `De : ${commentaire.emetteur}`
    } else if (commentaire.envoyeRecu === 'envoye') {
      return `À : ${commentaire.destinataire}`
    } else {
      return `De ${commentaire.emetteur} à ${commentaire.destinataire}`
    }
  };

  return (
    <MessageBubble
      indexBubble={index}
      itemSelected={itemSelected}
      titleBold={getTitle(commentaire)}
      subtitle={commentaire.libelleDoc}
      contentBubble={<Content commentaire={commentaire}/>}
      typeBubble={commentaire.envoyeRecu}
      dateHeure={commentaire.date.replace('-', 'à')}
      isBubbleCommentaire
    />
  )
};
