import React, {useContext, useEffect, useState} from 'react';
import {IconButton, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {customTheme} from '../../../../common/GlobalTheme';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import {Motif} from './Motif';
import {Attachments, FilesDragAndDrop} from '../../components/Attachment/FilesDragAndDrop';
import {postSRPForm} from '../../../../api/message';
import {UserContext} from '../../../../store/UserContext';
import {useMissionContext} from '../../../../store/MissionContext';
import {Loader} from '../../../common/Loader';
import {FormModel} from '../../../../model/forms/FormModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
type StylesProps = { showPjForm: boolean, isLoading: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  form: {
    height: '100vh',
    width: '40%',
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: props.showPjForm ? '100vh' : '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: '#241E46',
    color: 'white',
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  body: {
    height: 'calc(100% - 90px)',
    padding: '20px 20px 0px',
  },
  addMotif: {
    width: '100%',
    textAlign: 'right' as const,
    marginBottom: '20px',
  },
  motifsContainer: {
    'height': 'calc(100% - 253px)', // 175 + 15 + 58 + 5 = 253
    'overflowY': 'auto' as const,
    'padding': '0px 10px 5px 5px',
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  message: {
    marginTop: '25px',
  },
  attachments: {
    padding: '10px 0px 0px 20px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: props.isLoading ? 'flex-end': 'space-between',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    display: 'block',
    margin: '0px 20px 0px 0px',
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  error: {
    fontSize: '12px',
    color: 'red',
  },
});

const MOTIFS_NB_MAX = 9;

export const SRPForm = (props: FormModel) => {
  const {onClose, destinataire} = props;
  const {user} = useContext(UserContext);
  const {mission} = useMissionContext();
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');
  const initialStatePj = {
    attachments: [],
    isValid: true,
  };
  const [expanded, setExpanded] = useState('panel-1');
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [motifsTab, setMotifsTab] = useState([{id: 1, codeMotif: '', onErrorCodeMotif: false, detail: ''}]);
  const [message, setMessage] = useState('');
  const [piecesJointesList, setPiecesJointesList] = useState<Attachments>(initialStatePj);
  const [showPjForm, setShowPjForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseReturn, setResponseReturn] = useState(0);
  const classes = useEmotionStyles(styles, {showPjForm, isLoading});

  const listRefAccordion = React.useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    listRefAccordion.current = listRefAccordion.current.slice(0, motifsTab.length);
    listRefAccordion.current[motifsTab.length - 1]?.scrollIntoView()
  }, [motifsTab]);

  const libelleLink = (!piecesJointesList || piecesJointesList.attachments.length === 0) ?
    'Ajouter des pièces jointes' :
    'Gérer les pièces jointes (' + piecesJointesList.attachments.length + ')';

  const onUpload = (attachments: Attachments) => {
    setShowPjForm(!showPjForm);
    setPiecesJointesList(attachments)
  };

  const onCloseAttachmentForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPjForm(!showPjForm)
    }
  };

  const handleError = (tab = motifsTab) => {
    const motifsTabCopy = [...tab];
    motifsTabCopy.forEach((motif) =>{
      motif.onErrorCodeMotif = !motif.codeMotif
    });
    setMotifsTab(motifsTabCopy);

    return motifsTabCopy.filter((motif) =>
      motif.onErrorCodeMotif,
    ).length > 0
  };

  const handleClickAddMotif = () => {
    const isError = handleError();
    if (motifsTab.length >= MOTIFS_NB_MAX) {
      const result = window.confirm('Vous avez atteint le nombre maximum de motifs');
      if (result) {
        setExpanded(``)
      }
    } else if (isError) {
      const motifError = motifsTab.find((motif ) => motif.onErrorCodeMotif);
      setExpanded(`panel-${motifError?.id}`);
      setIsDisabledButton(true);
    } else {
      const newMotif = {id: motifsTab.length ? motifsTab[motifsTab.length-1].id + 1 : 1, codeMotif: '',
        onErrorCodeMotif: false, detail: ''};
      setMotifsTab([...motifsTab, newMotif]);
      setExpanded(`panel-${newMotif.id}`)
    }
  };

  const handleChangeMotif = (event: React.ChangeEvent<HTMLInputElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab];
    motifsTabCopy.forEach((motif) => {
      if (motif.id === motifId) {
        motif.codeMotif = event.target.value;
        motif.onErrorCodeMotif = false
      }
    });
    setMotifsTab(motifsTabCopy);
    setIsDisabledButton(false);
  };

  const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab];
    motifsTabCopy.forEach((motif) => {
      if (motif.id === motifId) {
        motif.detail = event.target.value
      }
    });
    setMotifsTab(motifsTabCopy)
  };

  const handleDeleteMotif = (e: React.MouseEvent<HTMLElement>, motifId: number) => {
    const motifsTabCopy = [...motifsTab].filter((element) =>
      motifId != element.id,
    );
    motifsTabCopy.forEach((motif, index) => {
      motif.id = index + 1
    });
    const isError = handleError(motifsTabCopy);
    setIsDisabledButton(isError);
    if (isError) {
      const motifError = motifsTabCopy.find((motif ) => motif.onErrorCodeMotif);
      setExpanded(`panel-${motifError?.id}`);
    } else {
      setExpanded('');
    }
    setMotifsTab([...motifsTabCopy]);
  };

  const handleChangePanel = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '')
  };

  const buildPayLoad = () => {
    const payLoad = new FormData();
    const piecesJointes = piecesJointesList.attachments.map((attachment, index) => {
      payLoad.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      };
    });

    const formData = {
      scenario: mission?.scenario,
      codeAbonneEmeteur: user.codeAbo,
      codeAbonneDestinataire: destinataire?.codeAbonne,
      codeGTA: mission?.codeGTA,
      codeService: user.service,
      numClient: user.numClient,
      numeroAssure: mission?.societaireDTO?.numero,
      referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
      numMission: mission?.infoMissionDTO?.numeroMission,
      dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
      codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
      nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
      coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
      listSollicitationsRep: motifsTab.map((motif) => {
        return {motif: motif.codeMotif, detail: motif.detail};
      }),
      commentaire: message,
      userCompte: user.userCompte,
      nomCompteAssistance: nomCompteAssistance ?? '',
    };

    payLoad.append('metadata', new Blob([JSON.stringify({...formData, piecesJointes: piecesJointes})],
        {type: 'application/json'}));
    return payLoad;
  };

  const handleSubmit = () => {
    const isError = handleError();
    setIsDisabledButton(isError);
    if (!isError) {
      setIsLoading(true);
      const payload = buildPayLoad();
      postSRPForm(payload)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    }
  };

  return (
    <div className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.titre}>Ajouter une sollicitation</Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}} onClick={onClose}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        {responseReturn <= 0 && !showPjForm &&
          <div className={classes.body}>
            <div className={classes.addMotif}>
              <ButtonBlue id={'boutonAjoutMotif'} icon={<AddCircleIcon/>} libelle={'Ajouter un motif'}
                onClick={handleClickAddMotif} disabled={isDisabledButton}/>
            </div>
            <div className={classes.motifsContainer}>
              {motifsTab.map((motif, index) => {
                const keyDiv = `${index}`
                return (
                  <div key={keyDiv} ref={(element) => listRefAccordion.current[index] = element}>
                    <Motif nbMotifs={motifsTab.length} id={motif.id} codeMotif={motif.codeMotif}
                      onErrorCodeMotif={motif.onErrorCodeMotif} detail={motif.detail}
                      expanded={expanded === `panel-${motif.id}`}
                      onChangePanel={(panel) => handleChangePanel(panel)}
                      handleChangeMotif={(e) => handleChangeMotif(e, motif.id)}
                      handleChangeDetail={(e) => handleChangeDetail(e, motif.id)}
                      onDelete={(e) => handleDeleteMotif(e, motif.id)}/>
                  </div>
                )
              })}
            </div>
            <div className={classes.message}>
              <Typography style={customTheme.styledTypography.libelle}>Votre message</Typography>
              <TextFieldMultiline
                id={'message'}
                marginDense={true}
                value={message}
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            {responseReturn === -1 &&
              <Typography className={`${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
        {responseReturn <= 0 && showPjForm &&
          <div className={classes.attachments}>
            <FilesDragAndDrop
              attachments={piecesJointesList}
              onSave={onUpload}
              onCloseAttachmentForms={onCloseAttachmentForms}
            />
          </div>
        }
        {responseReturn === 201 &&
          <div className={classes.divResponse}>
            <Typography id='messageSentLabel' variant='body1' component='div' className={classes.titre}>
              Votre message a bien été envoyé
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onCloseAndRefresh}/>
          </div>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onClose}/>
          </div>
        }
      </div>
      {responseReturn <= 0 && !showPjForm &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <>
                <ButtonLink
                  id={'boutonAjoutPJ'}
                  libelle={libelleLink}
                  isLibelleUpperCase={true}
                  isLibelleBold={true}
                  onClick={() => setShowPjForm(!showPjForm)}
                />
                <ButtonBlueSend id={'boutonEnvoyer'} onClick={handleSubmit} disabled={isDisabledButton}/>
              </>
            }
          </div>
        </div>
      }
    </div>
  )
};
