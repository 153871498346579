import {Typography} from '@mui/material';
import InputMask from 'react-input-mask-3.0';
import {TextFieldSearch} from '../formsComponents/TextFieldSearch';
import React from 'react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface NumeroProps {
  className?: string,
  name: string,
  formulaire: string,
  value: string
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  onError: boolean,
  helperText: string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
});

export const Numero = (props: NumeroProps) => {
  const {className, name, formulaire, value, onChangeValue, onBlur, onFocus, onError, helperText} = props;
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>N° dans la voie</Typography>
      <InputMask
        mask="9999"
        maskPlaceholder={null}
        value={value}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <TextFieldSearch
          id={'numVoie' + formulaire}
          name={name}
          fullWidth={true}
          onError={onError}
          helperText={helperText}
        />
      </InputMask>
    </div>
  )
};
