import React from 'react';
import {Switch, Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface SuspensionVEProps {
  className?: string,
  nameVE: string,
  nameVEI: string,
  isVE: boolean,
  isVEI: boolean,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

const styles = (theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    minWidth: '300px',
  },
  red: {
    color: 'red',
  },
  switch: {
    '&.MuiSwitch-root': {
      width: '40px',
      height: '20px',
      padding: '0px',
      marginLeft: '20px',
    },
    '& .MuiSwitch-switchBase': {
      'padding': 2,
      'color': 'white',
      '&.Mui-checked': {
        'transform': 'translateX(20px)',
        'color': 'white',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.link.main,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: '16px',
      height: '16px',
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      borderRadius: '20px',
      opacity: 1,
      backgroundColor: '#9f9f9f',
    },
    '& .Mui-checked': {},
  },
  vei: {
    marginTop: '24px',
  },
});

export const SuspensionVE = ({className, nameVE, nameVEI, isVE, isVEI, onChangeValue}: SuspensionVEProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <div className={classes.flex}>
        <Typography className={classes.libelle}>
          Véhicule soumis à une suspension VE <span className={classes.red}>*</span>
        </Typography>
        <Switch
          className={classes.switch}
          id={'switchVE'}
          name={nameVE}
          checked={isVE}
          onChange={onChangeValue}
        />
      </div>

      {!isVE &&
        <div className={`${classes.flex} ${classes.vei}`}>
          <Typography className={classes.libelle}>
            Véhicule économiquement irréparable <span className={classes.red}>*</span>
          </Typography>
          <Switch
            className={classes.switch}
            id={'switchVEI'}
            name={nameVEI}
            checked={isVEI}
            onChange={onChangeValue}
          />
        </div>
      }
    </div>
  )
};
