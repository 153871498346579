import React from 'react';
import {InputAdornment} from '@mui/material';
import CurrencyTextField from '../../../common/CurrencyTextField';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface TextFieldCurrencyProps {
  id: string,
  className?: string,
  value?: string,
  minValue?: string,
  maxValue?: string,
  onChange?: (event: React.ChangeEvent<{}>, value: string) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
});

export const TextFieldCurrency = ({id, className, value, minValue, maxValue, onChange}: TextFieldCurrencyProps) => {
  const classes = useEmotionStyles(styles);
  return (
    <CurrencyTextField
      id={id}
      className={`${className}`}
      variant="outlined"
      textAlign="left"
      margin='dense'
      digitGroupSeparator=""
      decimalCharacter=","
      outputFormat="string"
      value={value}
      minimumValue={minValue}
      maximumValue={maxValue}
      onChange={onChange}
      currencySymbol={''}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
        startAdornment: '',
        classes: {
          root: classes.textfield,
        },
      }}
    />
  )
};
