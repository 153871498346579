import * as React from 'react'
import {useEffect, useState} from 'react';

export interface IDragData {
  x: number
  y: number
  dx: number
  dy: number
}

export interface IReactPanZoomProps {
  children: any;
  height?: string
  width?: string
  className?: string
  enablePan?: boolean
  reset?: number
  zoom?: number
  pandx?: number
  pandy?: number
  rotation?: number
  onPan?: (x: number, y: number) => void
  setZoom: (z: number) => void
  onReset?: (dx: number, dy: number, zoom: number) => void
  onClick?: (e: React.MouseEvent<any>) => void
  style?: {}
  setIsChange: (isChange: boolean) => void
}

export const ReactPanZoom = (props: IReactPanZoomProps) => {
  let panWrapper: any;
  let panContainer: any;
  const [pandx] = useState<number>(0);
  const [pandy] = useState<number>(0);
  const [zoom] = useState<number>();
  const getInitialState = () => {
    const defaultDragData = {
      dx: pandx,
      dy: pandy,
      x: 0,
      y: 0,
    };
    return {
      comesFromDragging: false,
      dragData: defaultDragData,
      dragging: false,
      matrixData: [
        zoom!,
        0,
        0,
        zoom!,
        pandx,
        pandy, // [zoom, skew, skew, zoom, dx, dy]
      ],
      mouseDown: false,
    }
  };

  const [matrixData, setMatrixData] = useState(getInitialState());

  useEffect(() => {
    if (matrixData.matrixData[0] !== props.zoom) {
      const zoom1 = props.zoom ?? matrixData.matrixData[0];
      const zoom2 = props.zoom ?? matrixData.matrixData[3];
      const newMatrixData = [zoom1, matrixData.matrixData[1], matrixData.matrixData[2],
        zoom2, matrixData.matrixData[4], matrixData.matrixData[5]];
      setMatrixData({...matrixData,
        matrixData: newMatrixData,
      })
    }
  }, [props.zoom]);

  const onClick = (e: React.MouseEvent<EventTarget>) => {
    if (matrixData.comesFromDragging) {
      return
    }
    if (props.onClick) {
      props.onClick(e)
    }
  };

  const reset = () => {
    const matrixData1 = [1, 0, 0, 1, 0, 0];
    setMatrixData({...matrixData, matrixData: matrixData1});
    if (props.onReset) {
      props.onReset(0, 0, 1)
    }
  };

  useEffect(() => {
    reset()
  }, [props.reset]);

  const onTouchStart = (e: React.TouchEvent<EventTarget>) => {
    const {pageX, pageY} = e.touches[0];
    panStart(pageX, pageY, e)
  };

  const onTouchEnd = () => {
    onMouseUp()
  };

  const onTouchMove = (e: React.TouchEvent<EventTarget>) => {
    props.setIsChange(true);
    updateMousePosition(e.touches[0].pageX, e.touches[0].pageY)
  };

  const onMouseDown = (e: React.MouseEvent<EventTarget>) => {
    panStart(e.pageX, e.pageY, e)
  };

  const panStart = (
      pageX: number,
      pageY: number,
      event: React.MouseEvent<EventTarget> | React.TouchEvent<EventTarget>,
  ) => {
    if (!props.enablePan) {
      return
    }

    const offsetX: number = matrixData.matrixData[4];
    const offsetY: number = matrixData.matrixData[5];
    const newDragData: IDragData = {
      x: pageX,
      y: pageY,
      dx: offsetX,
      dy: offsetY,
    };
    setMatrixData({...matrixData,
      dragData: newDragData,
      mouseDown: true,
      dragging: true,
    });
    if (panWrapper) {
      panWrapper.style.cursor = 'move'
    }
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault()
  };

  const onMouseUp = () => {
    panEnd()
  };

  const panEnd = () => {
    setMatrixData({...matrixData,
      comesFromDragging: matrixData.dragging,
      dragging: false,
      mouseDown: false,
    });
    if (panWrapper) {
      panWrapper.style.cursor = ''
    }
  };

  const onMouseMove = (e: React.MouseEvent<EventTarget>) => {
    updateMousePosition(e.pageX, e.pageY)
  };

  const onWheel = (e: React.WheelEvent<EventTarget>) => {
    props.setIsChange(true);
    Math.sign(e.deltaY) < 0 ?
        props.setZoom((props.zoom ?? 0) + 0.1) : (props.zoom ?? 0) > 1 &&
        props.setZoom((props.zoom ?? 0) - 0.1)
  };

  const updateMousePosition = (pageX: number, pageY: number) => {
    if (!matrixData.mouseDown) return;
    const matrixData1 = getNewMatrixData(pageX, pageY);
    setMatrixData({...matrixData,
      dragging: true,
      matrixData: matrixData1,
    });
    props.setIsChange(true);
    if (panContainer) {
      panContainer.style.transform = `matrix(${matrixData.matrixData.toString()})`
    }
  };

  const getNewMatrixData = (x: number, y: number): number[] => {
    const dragData1 = matrixData.dragData;
    const deltaX = dragData1.x - x;
    const deltaY = dragData1.y - y;
    const x1 = dragData1.dx - deltaX;
    const y1 = dragData1.dy - deltaY;
    const res : number[] = [matrixData.matrixData[0], matrixData.matrixData[1], matrixData.matrixData[2],
      matrixData.matrixData[3], x1, y1];
    return res
  };

  return (
    <div
      id='imageViewer'
      className={`pan-container ${props.className ?? ''}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseMove={onMouseMove}
      onWheel={onWheel}
      onDrag={onMouseMove}
      onClick={onClick}
      style={{
        height: props.height,
        width: props.width,
        overflow: 'hidden',
      }}
      ref={(ref) => (panWrapper = ref)}
    >
      <div
        ref={(ref) => (ref ? (panContainer = ref) : null)}
        style={{
          transform: `matrix(${matrixData.matrixData.toString()})`,
        }}
      >
        {props.children}
      </div>
    </div>
  )
};
