import * as React from 'react'
type PanViewerProps = {
  imgUrl: string
  alt?: string
  rotation: number
  flip: boolean
  zoom: number
  setIsPhotoOnError: (onError: boolean) => void
}

const Image = ({imgUrl, alt, rotation, zoom, flip, setIsPhotoOnError}: PanViewerProps) => {
  return (
    <img
      style={{
        transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -zoom : zoom}) scaleY(${zoom})`,
        width: '100%',
        height: '100%',
        imageRendering: '-webkit-optimize-contrast',
      }}
      src={imgUrl}
      alt={alt}
      onError={() => setIsPhotoOnError(true)}
    />
  )
};

export default Image
