import React from 'react';
import {IElementCalcul} from '../../../model/ModelOM';
import {ElementCalculBloc} from '../../../../components/ElementsCalcul/ElementCalculBloc';
import {FormikValues, useFormikContext} from 'formik';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../../../common/useEmotionStyles';
import {ICheckElements} from './ElementsCalculs';

interface ModalBodyProps {
  idExpanded: number
  setIdExpanded: (id: number) => void
  errorsElementTypeVehEtHVeh: ICheckElements
  setErrorsElementTypeVehEtHVeh: (errors: ICheckElements) => void
}

/** Design */
const styles = () => ({
  error: {
    color: 'red',
    marginBottom: '16px',
  },
});

export const ModalBody = (props: ModalBodyProps) => {
  const classes = useEmotionStyles(styles);
  const {idExpanded, setIdExpanded, errorsElementTypeVehEtHVeh, setErrorsElementTypeVehEtHVeh} = props;
  const {values}: FormikValues = useFormikContext();

  return (
    <>
      {errorsElementTypeVehEtHVeh.isErrorWithElementTypeV &&
        <Typography className={classes.error}>
          Un élément de type &apos;Véhicule&apos; doit toujours être accompagné de l&apos;élément &apos;Véhicule&apos;
        </Typography>
      }
      {errorsElementTypeVehEtHVeh.isErrorWithElementTypeHV &&
        <Typography className={classes.error}>
          Un élément &apos;Hors Véhicule&apos; doit toujours être accompagné de l&apos;élément &apos;Véhicule&apos;
        </Typography>
      }

      {values.elementsCalcul.map((element: IElementCalcul) => {
        return (
          <ElementCalculBloc
            key={element.id}
            id={element.id}
            idExpanded={idExpanded}
            setIdExpanded={setIdExpanded}
            setErrorsElementTypeVehEtHVeh={setErrorsElementTypeVehEtHVeh}
          />
        )
      })}
    </>
  )
};
