export interface ItemPdfView {
  idDocument?: string,
  typeCode: string,
  ssType?: string,
  isnIdentifier: string,
};

export const itemPdfViewMapper = (params: any, data: any): ItemPdfView => {
  return {
    idDocument: data.idDocument,
    typeCode: data.docType,
    ssType: data.docSousType,
    isnIdentifier: data.identifier,
  } as ItemPdfView;
};

