import {AdresseProps} from './AdresseModel';
import {CoordonneesProps} from './CoordonneeModel';
import {CoordonneesAdresseProps} from './CoordonneeAdresseModel';
import {HoraireProps} from './HoraireModel';
import {CongesProps} from './CongeModel';
import {ActiviteProps} from './ActiviteModel';

export interface RaisonSocialeProps {
  nom: string,
  abreviation: string,
}

export interface PartenaireProps {
  id: string,
  raisonSociale: RaisonSocialeProps,
  numeroTva: string,
  numeroSiret: string,
  numeroClient: string,
  statut: string,
  adresse: AdresseProps,
  coordonnees: CoordonneesProps,
  coordonneesAdresse: CoordonneesAdresseProps,
  dateModification: string,
  distance: number,
  agreePar?: string[],
  horaires: HoraireProps,
  conges: CongesProps,
  activites: ActiviteProps[],
}

export const partenaireInitialState: PartenaireProps = {
  id: '',
  raisonSociale: {
    nom: '',
    abreviation: '',
  },
  numeroTva: '',
  numeroSiret: '',
  numeroClient: '',
  statut: '',
  adresse: {
    numeroVoie: '',
    indiceRepetition: '',
    typeVoie: '',
    ligne1: '',
    ligne2: '',
    ligne3: '',
    commune: '',
    codeCommune: '',
    codePostal: '',
    boitePostale: '',
    codeCedex: '',
    libelleCedex: '',
    pays: '',
    departement: {
      code: '',
      libelle: '',
    },
  },
  coordonnees: {
    code: '',
    email: '',
    portable: '',
    telephone: '',
    fax: '',
    website: '',
    telephoneAutorisation: false,
    emailAutorisation: false,
  },
  coordonneesAdresse: {
    lat: 0,
    lng: 0,
  },
  dateModification: '',
  distance: 0,
  agreePar: [],
  horaires: {
    lundi: [{debut: '', fin: ''}],
    mardi: [{debut: '', fin: ''}],
    mercredi: [{debut: '', fin: ''}],
    jeudi: [{debut: '', fin: ''}],
    vendredi: [{debut: '', fin: ''}],
    samedi: [{debut: '', fin: ''}],
    dimanche: [{debut: '', fin: ''}],
  },
  conges: {
    periodes: [{debut: '', fin: ''}],
    pasFermeturePrevue: false,
    fermeJamais: false,
  },
  activites: [],
};
