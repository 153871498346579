export interface ItemGetValeursCodesModel {
  valeur: string,
  libelle: string,
  libelleedition: string,
  etat: string,
  fonction?: any,
  lowercase: string,
  uppercase: string,
}

export const emptyItem: ItemGetValeursCodesModel = {
  valeur: '',
  libelle: '',
  libelleedition: '',
  etat: '',
  fonction: '',
  lowercase: '',
  uppercase: '',
};
