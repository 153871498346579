import React, {useEffect} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {ReactComponent as InProgressIcon} from '../../../../../images/icones/icn-in-progress.svg'
import {Theme} from '@emotion/react';
import {ICodeValeur, IDommage} from './Models';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {ButtonBlue} from '../../../../common/buttons/ButtonBlue';
import {ButtonBlueDelete} from '../../../../common/formsComponents/ButtonBlueDelete';
import {ItemGetValeursCodesModel} from '../../../../../model/common/ItemGetValeursCodesModel';
import InputSelectField from '../../../multiStepsForm/fields/InputSelectField';
import {elementVitreOptique} from '../../../../../constantes/constantesNormeAuto';
import {FormikValues, useFormikContext} from 'formik';

export interface DommageFormProps {
  idExpanded: number,
  setIdExpanded: (idExpanded: number) => void
  dommage: IDommage
  elementVitreList: ItemGetValeursCodesModel[],
  detailOptiqueList: ICodeValeur[],
  typeInterventionList: ICodeValeur[]
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    'width': '100%',
    'display': 'flex',
    'alignItems': 'baseline',
    'marginBottom': '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  accordion: {
    width: '90%',
    borderRadius: '8px 8px 0px 0px',
  },
  summary: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummaryExpandIcon: {
    padding: '12px 0px',
  },
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.red.main,
  },
  inProgress: {
    fill: theme.palette.primary.main,
    marginRight: '10px',
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  details: {
    display: 'block',
    padding: '16px',
  },
  textField: {
    width: '100%',
  },
  marginTop24: {
    marginTop: '24px',
  },
  boutonValider: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  delete: {
    display: 'flex',
    width: '10%',
  },
  marginAuto: {
    margin: 'auto',
  },
});

const DommageForm = (props: DommageFormProps) => {
  const classes = useEmotionStyles(styles);
  const {idExpanded, setIdExpanded, dommage, detailOptiqueList, typeInterventionList, elementVitreList} = props;
  const {values, setFieldValue, touched, setTouched, setFieldTouched, errors,
    setErrors}: FormikValues = useFormikContext();
  const isError = Boolean(errors?.dommages);
  const isErrorDommage = errors?.dommages ? Boolean(errors.dommages[dommage.id]) : false;
  const isTouched= Boolean(touched?.dommages);

  useEffect(() => {
    if (dommage.elementVitre !== elementVitreOptique && dommage.descriptionOptique) {
      setFieldValue('dommages[' + (dommage.id) + '].descriptionOptique', '')
    }
  }, [dommage.elementVitre]);

  const elementVitreListSort = [...elementVitreList].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });

  const itemsListElementVitre = elementVitreListSort.map((item: ItemGetValeursCodesModel) => {
    return (
      <MenuItem
        key={item.valeur}
        value={item.valeur}
        disabled={values.dommages.some((d: IDommage) => d.elementVitre === item.valeur)}
      >
        {item.libelleedition}
      </MenuItem>
    )
  });

  const itemsListDetailOptique = detailOptiqueList.map((item: ICodeValeur) => {
    return (<MenuItem key={item.id} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  const itemsListTypeIntervention = typeInterventionList.map((item: ICodeValeur) => {
    return (<MenuItem key={item.id} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  // Validation du dommage
  const validateDommage = () => {
    setFieldTouched('dommages[' + (dommage.id) + '].elementVitre', true);
    setFieldTouched('dommages[' + (dommage.id) + '].typeIntervention', true);
    if (dommage.elementVitre === elementVitreOptique) {
      setFieldTouched('dommages[' + (dommage.id) + '].descriptionOptique', true);
    }

    if (!isErrorDommage) {
      setFieldValue('dommages[' + (dommage.id) + '].completed', true);
      setIdExpanded(-1);
    }
  };

  // Gestion du titre dans l'accordéon
  const getLibelle = () => {
    if (dommage.elementVitre || dommage.typeIntervention || dommage.descriptionOptique) {
      return (
        <>
          {dommage.elementVitre &&
            elementVitreList.find((item) => item.valeur === dommage.elementVitre)?.libelleedition
          }
          {dommage.elementVitre && dommage.typeIntervention && <>&nbsp;-&nbsp;</>}
          {dommage.typeIntervention &&
            typeInterventionList.find((item) => item.valeur === dommage.typeIntervention)?.libelleedition
          }
          {dommage.typeIntervention && dommage.descriptionOptique && <>&nbsp;-&nbsp;</>}
          {dommage.descriptionOptique &&
            detailOptiqueList.find((item) => item.valeur === dommage.descriptionOptique)?.libelleedition
          }
        </>
      )
    } else {
      return (<span>Nouveau dommage</span>)
    }
  };

  const isDisabledValidate = () => {
    if (isError && isTouched) {
      return (errors.dommages[dommage.id]?.elementVitre && touched.dommages[dommage.id]?.elementVitre) ||
        (errors.dommages[dommage.id]?.typeIntervention && touched.dommages[dommage.id]?.typeIntervention) ||
        (dommage.elementVitre === elementVitreOptique && errors.dommages[dommage.id]?.descriptionOptique &&
          touched.dommages[dommage.id]?.descriptionOptique)
    }
    return false;
  };

  // Suppression du dommage dans values, touched et errors du context Formik
  const deleteDommage = () => {
    const dommagesCopy: IDommage[] = [...values.dommages].filter((d) => dommage.id != d.id);
    dommagesCopy.forEach((d, index) => {
      d.id = index;
    });
    setFieldValue('dommages', dommagesCopy);

    if (isTouched) {
      const touchedCopy = JSON.parse(JSON.stringify(touched));
      touchedCopy.dommages.splice(dommage.id, 1);
      setTouched(touchedCopy, false);
    }
    if (isError) {
      const errorsCopy = JSON.parse(JSON.stringify(errors));
      errorsCopy.dommages.splice(dommage.id, 1);
      setErrors(errorsCopy, false);
    }
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={idExpanded === dommage.id}
        onChange={() => setIdExpanded(idExpanded !== dommage.id ? dommage.id : -1)}
        disabled={dommage.onError}
      >
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls='panel1a-content'
          id={`panel1a-header-${dommage.id}`}
          className={classes.summary}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expandIconWrapper: classes.accordionSummaryExpandIcon,
          }}
        >
          {dommage.onError &&
            <CancelIcon className={classes.red}/>
          }
          {dommage.completed &&
            <CheckCircleIcon className={classes.green}/>
          }
          {!dommage.onError && !dommage.completed &&
            <InProgressIcon className={classes.inProgress}/>
          }

          <Typography className={classes.libelleAccordion}>{getLibelle()}</Typography>
        </AccordionSummary>

        {!dommage.onError &&
          <AccordionDetails className={classes.details}>
            <InputSelectField
              className={classes.textField}
              name={'dommages[' + (dommage.id) + '].elementVitre'}
              label={'Élément vitré'}
              mandatory={true}
              itemsList={itemsListElementVitre}
            />
            <div className={classes.marginTop24}>
              <InputSelectField
                className={classes.textField}
                name={'dommages[' + (dommage.id) + '].typeIntervention'}
                label={'Type d\'intervention'}
                mandatory={true}
                itemsList={itemsListTypeIntervention}
              />
            </div>
            {dommage.elementVitre === elementVitreOptique &&
              <div className={classes.marginTop24}>
                <InputSelectField
                  className={classes.textField}
                  name={'dommages[' + (dommage.id) + '].descriptionOptique'}
                  label={'Description optique'}
                  mandatory={true}
                  itemsList={itemsListDetailOptique}
                />
              </div>
            }
            <div className={classes.boutonValider}>
              <ButtonBlue
                id={'bouton-valider-dommage' + dommage.id}
                libelle={'Valider'}
                onClick={validateDommage}
                disabled={isDisabledValidate()}
              />
            </div>
          </AccordionDetails>
        }
      </Accordion>

      <div className={classes.delete}>
        <div className={classes.marginAuto}>
          <ButtonBlueDelete
            id={'bouton-supprimer-dommage' + dommage.id}
            onClick={deleteDommage}
          />
        </div>
      </div>
    </div>
  );
};

export default DommageForm;
