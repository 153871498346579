import React from 'react';
import {Link, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ReactComponent as CheckIcon} from '../../../../images/icones/icn-check.svg'
import {ReactComponent as WarningIcon} from '../../../../images/icones/icn-warning.svg'
import {customTheme} from '../../../../common/GlobalTheme';
import {IResponseOM} from '../../ordre-mission/model/ModelOM';
import {useNavigate} from 'react-router-dom';

const happyMan = require('../../../../images/icones/happy-man-in-red-shirt.png')
const unHappyMan = require('../../../../images/icones/man-no-connection.png')

interface ResponseApiComponentProps {
  responseOM: IResponseOM
  onClickAccesDossier: () => void
}


/** Design */
const styles = (theme: Theme) => ({
  container: {
    margin: '3% 5%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBloc: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0px',
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginLeft: '16px',
    fontSize: '24px',
    letterSpacing: '0.25px',
  },
  subTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    letterSpacing: '0.25px',
    margin: '8px 0px',
  },
  img: {
    width: '450px',
    height: '400px',
  },
  email: {
    color: customTheme.palette.link.main,
    textDecoration: 'none' as const,
  },
  text: {
    color: customTheme.palette.primary.main,
  },
});

const ResponseOMComponent = (props: ResponseApiComponentProps) => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate()
  const {responseOM, onClickAccesDossier} = props
  const {codeStatus, codeRetour} = responseOM
  const message = (codeRetour === '00' || codeRetour === '03') ? 'Le dossier a été crée avec succès !' :
    'Le dossier a été crée mais reste inactif pour le moment...'

  const onClickRetourDossiers = () => {
    navigate('/extranet/dossiers')
  }

  return (
    <div className={classes.container}>
      <ButtonLink
        id={'boutonRetour'}
        icon={<ArrowBackIcon/>}
        libelle={'Retour aux dossiers'}
        onClick={onClickRetourDossiers}
      />
      <div className={classes.body}>
        {codeStatus === 201 &&
          <>
            <img
              src={happyMan}
              alt={'logo-confirmation'}
            />
            <div className={classes.flexBloc}>
              {(codeRetour === '00' || codeRetour === '03') &&
                <CheckIcon/>
              }
              {(codeRetour === '02') &&
                <WarningIcon/>
              }
              <Typography className={classes.title}>
                {message}
              </Typography>
            </div>
            <ButtonBlue
              id={'AccesDossier'}
              libelle={'Accès au dossier'}
              onClick={onClickAccesDossier}
            />
          </>
        }
        {codeStatus > 201 &&
          <>
            <img
              src={unHappyMan}
              alt={'logo-fail'}
              className={classes.img}
            />
            <Typography className={classes.title}>
              Une erreur est survenue, veuillez contacter notre service d&apos;assistance.
            </Typography>
            <Typography className={classes.subTitle}>
              Veuillez contacter notre service Assistance.
            </Typography>
            <Link
              className={classes.email}
              href={`mailto:assistance@darva.com?subject=Erreur Sinapps Auto dépôt OM`}
            >
              assistance@darva.com
            </Link>
            <Typography className={classes.text}>
              0 820 030 031
            </Typography>
            <Typography variant={'caption'} className={classes.text}>
              L&apos;assistance DARVA est disponible du lundi au vendredi de 8h à 18h.
            </Typography>
          </>
        }
      </div>
    </div>
  );
};

export default ResponseOMComponent;
