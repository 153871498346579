import React from 'react';
import {FormikValues} from 'formik';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {Button} from '@mui/material';

interface FormNavigationProps {
    hasPrevious?: boolean
    isLastStep: boolean
    handlePrevious: (values: FormikValues) => void
}

type StylesProps = {
    hasPrevious: boolean,
}
const styles = (theme: Theme, props: StylesProps) => ({
  container: {
    display: 'flex',
    justifyContent: props.hasPrevious ? 'space-between' : 'flex-end',
    marginTop: 50,
  },
  button: {
    'backgroundColor': theme.palette.link.main,
    'color': theme.palette.white.main,
    'fontSize': '16px',
    'letterSpacing': '0px',
    'padding': '5px 20px',
    'borderRadius': '30px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
})

const FormNavigation = (props: FormNavigationProps) => {
  const {hasPrevious, isLastStep, handlePrevious} = props
  const classes = useEmotionStyles(styles, {hasPrevious: Boolean(hasPrevious)});
  return (
    <div className={classes.container}>
      {hasPrevious &&
              <Button
                id={'precedent'}
                type='button'
                variant='contained'
                className={classes.button}
                onClick={handlePrevious}>
                {'Précédent'}
              </Button>
      }
      <Button
        id={'suivant'}
        type='submit'
        variant='contained'
        className={classes.button}>
        {isLastStep ? 'Envoyer' : 'Suivant'}
      </Button>
    </div>
  );
};

export default FormNavigation;
