import React, {useState} from 'react';
import {Immatriculation} from '../../../common/vehicule/Immatriculation';
import {RefSinistre} from '../../../common/RefSinistre';
import {InputDate} from '../../../common/InputDate';
import {Hour} from '../../../common/Hour';
import {Typography} from '@mui/material';
import {postSIVForm} from '../../../../api/siv';
import {useInitialStateFormSIV} from '../initialStateFormSIV';
import {dateToDateText} from '../../../../common/utils/DateUtils';
import {ISIVFormITAccesTransparent, SIVFormModel} from '../../../../model/common/SIVFormModel';
import {Loader} from '../../../common/Loader';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {getInitialValueSIV, helperTextWithIcon} from '../../../../common/Utils';
import {useFormik} from 'formik';
import {validationSchemaIT} from './validationSchemaIT';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '0px 0px 8px 8px',
    marginBottom: '16px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  line: {
    marginTop: '24px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  textfieldSmall: {
    width: '240px',
    marginRight: '24px',
  },
  red: {
    color: 'red',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
  typography: {
    fontSize: '14px',
  },
  bottom: {
    textAlign: 'right' as const,
  },
  loader: {
    display: 'block',
    margin: '0px 60px',
  },
});

export const FormIT = ({onChangeResponse, dataAccesTransparent, isAccesTransparent}: SIVFormModel) => {
  const classes = useEmotionStyles(styles);
  const getItemMission = sessionStorage.getItem('mission');
  const mission = getItemMission ? JSON.parse(getItemMission) : '';
  const data = dataAccesTransparent as ISIVFormITAccesTransparent;
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    immatriculation: getInitialValueSIV([data?.immatriculation, mission?.infoMissionDTO?.immatriculation]),
    referenceSinistre: getInitialValueSIV([data?.referenceSinistre,
      mission?.infoMissionDTO?.referenceSinistre?.trim()]),
    date: getInitialValueSIV([data?.date, mission?.gestionDTO?.informationSinistreDTO?.dateSinistre]),
    heure: getInitialValueSIV([data?.heure, mission?.gestionDTO?.informationSinistreDTO?.heureSinistre]),
  };

  let formData = useInitialStateFormSIV();
  const onSubmit = () => {
    setIsLoading(true);
    formData = {...formData, typeOperation: 'IT', vehicule: {numeroImmatriculation: formik.values.immatriculation},
      referenceSinistre: formik.values.referenceSinistre, dateSinistre: dateToDateText(new Date(formik.values.date)),
      heureSinistre: formik.values.heure};
    postSIVForm(formData, isAccesTransparent)
        .then((response) => {
          onChangeResponse(response);
        }).catch((error) => {
          onChangeResponse(error.response)
        }).finally(() =>
          setIsLoading(false),
        )
  };

  const onFocus = (field: string) => () => {
    formik.setFieldTouched(field, false, false)
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaIT,
    onSubmit: onSubmit,
  });

  const disabled = !!(formik.touched.immatriculation && formik.errors.immatriculation) ||
    !!(formik.touched.referenceSinistre && formik.errors.referenceSinistre) ||
    !!(formik.touched.date && formik.errors.date) ||
    !!(formik.touched.heure && formik.errors.heure);

  return (
    <div>
      <div className={classes.cardContent}>
        <div className={classes.flex}>
          <Immatriculation
            className={classes.textfield}
            name={'immatriculation'}
            formulaire={'IT'}
            value={formik.values.immatriculation}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('immatriculation')}
            onError={(formik.touched.immatriculation && !!formik.errors.immatriculation) || false}
            helperText={helperTextWithIcon(formik, 'immatriculation', '', classes.helpIcon)}
          />
          <RefSinistre
            className={classes.textfield}
            name={'referenceSinistre'}
            formulaire={'IT'}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('referenceSinistre')}
            isMandatory={true}
            value={formik.values.referenceSinistre}
            onError={(formik.touched.referenceSinistre && !!formik.errors.referenceSinistre) || false}
            helperText={formik.touched.referenceSinistre && formik.errors.referenceSinistre ?
              formik.errors.referenceSinistre : ''
            }
          />
        </div>
        <div className={`${classes.flex} ${classes.line}`}>
          <InputDate
            className={classes.textfieldSmall}
            name={'date'}
            id={'date'}
            formulaire={'IT'}
            isMandatory={true}
            title={'Date du sinistre'}
            onBlur={formik.handleBlur}
            onFocus={onFocus('date')}
            onChangeValue={formik.handleChange}
            value={formik.values.date}
            onError={(formik.touched.date && !!formik.errors.date) || false}
            helperText={formik.touched.date && formik.errors.date ? formik.errors.date : ''}
          />
          <Hour
            className={classes.textfieldSmall}
            name={'heure'}
            id={'heure'}
            formulaire={'IT'}
            isMandatory={true}
            title={'Heure du sinistre'}
            value={formik.values.heure}
            onChangeValue={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={onFocus('heure')}
            onError={(formik.touched.heure && !!formik.errors.heure) || false}
            helperText={formik.touched.heure && formik.errors.heure ? formik.errors.heure : ''}
          />
        </div>
      </div>

      <Typography className={classes.typography}>
        Les champs marqués d&apos;un astérisque <span className={classes.red}>*</span> sont obligatoires.
      </Typography>

      <div className={classes.bottom}>
        {isLoading &&
          <Loader className={classes.loader} size={30}/>
        }
        {!isLoading &&
          <ButtonBlueSend id={'boutonEnvoyerIT'} disabled={disabled} onClick={formik.submitForm}/>
        }
      </div>
    </div>
  )
};
