export const permittedRolesDossiers: string[] =
  ['P092', 'P09X', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P495', 'P496', 'P497', 'P498', 'P499', 'P49B',
    'P49C', 'P49D', 'P49E', 'P49F', 'P49G', 'P49H', 'P49I', 'P49J', 'P49L', 'P49N', 'P49R', 'P49U', 'P49V', 'P49W',
    'P49X', 'P49Y', 'P49Z', 'P500', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSCB', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH',
    'PSFE', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5', 'PQTZ', 'PQTZ',
  ];

export const permittedRolesIndicateurs: string[] =
  ['P092', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P497', 'P49B', 'P49C', 'P49D', 'P49F', 'P49G', 'P49H',
    'P49J', 'P49L', 'P49V', 'P49Y', 'P500', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSCB', 'PSCD', 'PSCE', 'PSCF', 'PSCG',
    'PSCH', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5', 'PQTZ',
  ];

export const permittedRolesDetailsDossierConsultations: string[] =
  ['P092', 'P09X', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P495', 'P496', 'P497', 'P498', 'P499', 'P49B',
    'P49C', 'P49D', 'P49E', 'P49F', 'P49G', 'P49H', 'P49I', 'P49J', 'P49L', 'P49N', 'P49R', 'P49U', 'P49V', 'P49W',
    'P49X', 'P49Y', 'P49Z', 'P500', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSCB', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH',
    'PSFE', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5', 'PQTZ',
  ];

export const permittedRolesDetailsDossierModifications: string[] =
  ['P491', 'P493', 'P494', 'P495', 'P497', 'P49B', 'P49C', 'P49D', 'P49F', 'P49G', 'P49H', 'P49J', 'P49V', 'PSCE',
    'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesHistoriqueDetaille: string[] =
  ['P09X', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P495', 'P496', 'P497', 'P498', 'P49B', 'P49C', 'P49D',
    'P49E', 'P49F', 'P49G', 'P49H', 'P49I', 'P49J', 'P49L', 'P49R', 'P49U', 'P49V', 'P49Y', 'P500', 'PSC1', 'PSC2',
    'PSC3', 'PSC4', 'PSCB', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH', 'PSFE', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
    'PQTZ',
  ];

export const permittedRolesPiecesJointes: string[] =
  ['P500', 'PQTZ'];

export const permittedRolesRAD: string[] =
    ['P168', 'P490', 'P491', 'P492', 'P493', 'P497', 'P49B', 'P49C', 'P49D', 'P49F', 'P49G', 'P49H', 'P49J', 'P49R',
      'P49V', 'P49Y', 'P500', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH', 'PSS1', 'PSS2',
      'PSS3', 'PSS4', 'PSS5',
    ];

/*
* Formulaires
*/
export const permittedRolesSIVConsultations: string[] =
  ['P49O', 'PSC0', 'PSC1', 'PSC3', 'PSC4', 'PSC5', 'PSC7', 'PSC9', 'PSCA', 'PSCB', 'PSCD', 'PSCE', 'PSCF', 'PSCG',
    'PSCH', 'PSI9', 'PSS1', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesSIVDeclarations: string[] =
  ['P49O', 'PSC2', 'PSC4', 'PSC7', 'PSCA', 'PSCB', 'PSCC', 'PSCD', 'PSCF', 'PSCG', 'PSI9', 'PSS2', 'PSS4', 'PSS5',
  ];

export const permittedRolesFDV: string[] =
  [];

export const permittedRolesRDV: string[] =
    ['PRDV'];

export const permittedRolesFormPECBG: string[] =
  ['P492'];


/*
* Portail
*/
export const permittedRolesCarteExtranet: string[] =
  ['P092', 'P09X', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P495', 'P496', 'P497', 'P498', 'P499', 'P49B',
    'P49C', 'P49D', 'P49E', 'P49F', 'P49G', 'P49H', 'P49I', 'P49J', 'P49L', 'P49N', 'P49O', 'P49R', 'P49U', 'P49V',
    'P49W', 'P49X', 'P49Y', 'P49Z', 'P500', 'PSC0', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSC5', 'PSC7', 'PSC9', 'PSCA',
    'PSCB', 'PSCC', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH', 'PSFE', 'PSI9', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesCarteAutoweb: string[] =
  ['P092', 'P09X', 'P168', 'P490', 'P491', 'P492', 'P493', 'P494', 'P495', 'P496', 'P497', 'P498', 'P499', 'P49B',
    'P49C', 'P49D', 'P49E', 'P49F', 'P49G', 'P49H', 'P49I', 'P49J', 'P49K', 'P49L', 'P49M', 'P49N', 'P49O', 'P49Q',
    'P49R', 'P49U', 'P49V', 'P49W', 'P49X', 'P49Y', 'P49Z', 'P500', 'PNS1', 'PNS2', 'PSC0', 'PSC1', 'PSC2', 'PSC3',
    'PSC4', 'PSC5', 'PSC7', 'PSC8', 'PSC9', 'PSCA', 'PSCB', 'PSCC', 'PSCD', 'PSCE', 'PSCF', 'PSCG', 'PSCH', 'PSFE',
    'PSI9', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesCarteAtwDecisionnel: string[] =
  ['P544'];

export const permittedRolesCarteAtwDecisionnelMaif: string[] =
  ['P545'];

export const permittedRolesCarteAtwDecisionnelMatmut: string[] =
  ['P547'];

export const permittedRolesCarteAtwDecisionnelSferen: string[] =
  ['P543'];

export const permittedRolesCarteAtwAdministration: string[] =
  ['P107', 'P108', 'P109'];

export const permittedRolesCarteAtwRecycleurs: string[] =
  ['PRE1', 'PRE2', 'PRE3'];

export const permittedRolesCarteSinappsDecisions: string[] =
  ['P541'];

export const permittedRolesCarteSinappsOmega: string[] =
  ['PEGA'];

export const permittedRolesCarteIdigo: string[] =
  ['P830', 'P833'];


/*
* Menu
*/
export const permittedRolesMenuSIV: string[] =
  ['P49O', 'PSC0', 'PSC1', 'PSC2', 'PSC3', 'PSC4', 'PSC5', 'PSC7', 'PSC9', 'PSCA', 'PSCB', 'PSCC', 'PSCD', 'PSCE',
    'PSCF', 'PSCG', 'PSCH', 'PSI9', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesMenuFDV: string[] =
  [];

export const permittedRolesMenuRDV: string[] =
  [];

export const permittedRolesMenuFormOM: string[] =
  ['P491', 'P493', 'P49B', 'P49D', 'PSS1', 'PSS2', 'PSS3', 'PSS4', 'PSS5',
  ];

export const permittedRolesSupport: string[] =
    ['P49Y'];
