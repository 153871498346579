import React, {useContext, useEffect, useState} from 'react';
import {FormControlLabel, IconButton, MenuItem, Tooltip, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {ButtonLink} from '../../../common/buttons/ButtonLink';
import {ButtonBlueSend} from '../../../common/formsComponents/ButtonBlueSend';
import {Attachments, FilesDragAndDrop} from '../../components/Attachment/FilesDragAndDrop';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {TextFieldDate} from '../../../common/formsComponents/TextFieldDate';
import HelpIcon from '@mui/icons-material/Help';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import InputMask from 'react-input-mask-3.0';
import BlueSwitch from '../../../common/BlueSwitch';
import moment from 'moment';
import {postIFRForm} from '../../../../api/message';
import {getValeursCodes} from '../../../../api/norme';
import {UserContext} from '../../../../store/UserContext';
import {useMissionContext} from '../../../../store/MissionContext';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {Loader} from '../../../common/Loader';
import MotifsSollicitation from './MotifsSollicitation';
import {FormModel} from '../../../../model/forms/FormModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

type StylesProps = { showPjForm: boolean, isLoading: boolean }
const styles = (theme: Theme, props: StylesProps) => ({
  form: {
    height: '100vh',
    width: '40%',
    position: 'absolute' as const,
    top: '0%',
    right: '0%',
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 10px 20px ' + theme.palette.boxShadow.light1,
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  content: {
    height: props.showPjForm ? '100vh' : '90vh',
    overflowY: 'auto' as const,
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '10px',
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  body: {
    padding: '20px 20px 10px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  fullWidth: {
    width: '100%',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  motifSollicitation: {
    color: theme.palette.text.primary,
  },
  line1: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '20px',
    marginTop: '10px',
  },
  line2: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '20px',
    marginTop: '20px',
  },
  duree: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '10px',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px',
    color: theme.palette.text.primary,
  },
  attachments: {
    padding: '10px 0px 0px 20px',
    height: 'calc(100vh - 80px)', // 100vh - (hauteur du header + paddingTop)
  },
  divResponse: {
    display: 'flex',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  buttonOK: {
    marginTop: '15px',
  },
  footer: {
    height: '10vh',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: props.isLoading ? 'flex-end': 'space-between',
    padding: '0px 20px',
    margin: 'auto 0px', // pour centrer verticalement
    width: '100%',
  },
  loader: {
    display: 'block',
    margin: '0px 20px 0px 0px',
  },
});

export const IFRForm = (props: FormModel) => {
  const {destinataire, sollicitaion} = props;
  const {user} = useContext(UserContext);
  const {mission} = useMissionContext();
  const nomCompteAssistance = sessionStorage.getItem('nomCompteAssistance');
  const [responseReturn, setResponseReturn] = useState(0);
  const [showPjForm, setShowPjForm] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [libelleSuivi, setLibelleSuivi] = useState('');
  const [onErrorLibelleSuivi, setOnErrorLibelleSuivi] = useState(false);
  const [infosComplementaires, setInfosComplementaires] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [onErrorDateDebutEtDuree, setOnErrorDateDebutEtDuree] = useState(false);
  const [dateFin, setDateFin] = useState('');
  const [onErrorDateFinWithDateDebut, setOnErrorDateFinWithDateDebut] = useState(false);
  const [onErrorDateFinEtDuree, setOnErrorDateFinEtDuree] = useState(false);
  const [duree, setDuree] = useState('');
  const [onErrorDuree, setOnErrorDuree] = useState(false);
  const [dateMiseADispo, setDateMiseADispo] = useState('');
  const [dateRemise, setDateRemise] = useState('');
  const [onErrorDateRemise, setOnErrorDateRemise] = useState(false);
  const [retraitVehicule, setRetraitVehicule] = useState(false);
  const [activationSuivi, setActivationSuivi] = useState(false);
  const [message, setMessage] = useState('');
  const [etapesReparations, setEtapesReparations] = useState<ItemGetValeursCodesModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useEmotionStyles(styles, {showPjForm, isLoading});

  const isResponseSollicitation = !!sollicitaion;

  useEffect(() => {
    getValeursCodes('AR2', '98720101').then((result) => setEtapesReparations(result));
  }, []);

  const etapesReparationsSort = [...etapesReparations].sort((a, b) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListLibelleSuivi = etapesReparationsSort.map((etape) => {
    return (
      <MenuItem key={etape.valeur} value={etape.valeur}>{etape.libelleedition}</MenuItem>
    )
  });

  const initialStatePj = {
    attachments: [],
    isValid: true,
  };
  const [piecesJointesList, setPiecesJointesList] = useState<Attachments>(initialStatePj);
  const libelleLink = (!piecesJointesList || piecesJointesList.attachments.length === 0) ?
    'Ajouter des pièces jointes' :
    'Gérer les pièces jointes (' + piecesJointesList.attachments.length + ')';

  const onUpload = (attachments: Attachments) => {
    setShowPjForm(!showPjForm);
    setPiecesJointesList(attachments)
  };

  const onCloseAttachmentForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setShowPjForm(!showPjForm)
    }
  };

  const handleChangeLibelleSuivi = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLibelleSuivi(event.target.value);
    if (event.target.value === '') {
      setIsDisabledButton(true);
      setOnErrorLibelleSuivi(true);
    } else {
      setIsDisabledButton(false);
      setOnErrorLibelleSuivi(false);
    }
  };

  const handleChangeInfosComplementaires = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfosComplementaires(event.target.value);
  };

  const handleChangeDateDebut = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateDebut(event.target.value);
    if (dateFin && event.target.value > dateFin) {
      setOnErrorDateFinWithDateDebut(true);
      setIsDisabledButton(true);
    } else {
      setOnErrorDateFinWithDateDebut(false);
      const nbJours = dateFin && event.target.value !== '' &&
        moment(dateFin).diff(moment(event.target.value), 'days') + 1;
      if (nbJours) {
        if (nbJours <= 99) {
          setDuree(String(nbJours));
          setOnErrorDateFinEtDuree(false);
          setOnErrorDateDebutEtDuree(false);
          setIsDisabledButton(false);
        } else {
          setDuree('');
          !onErrorDateFinEtDuree && setOnErrorDateDebutEtDuree(true);
          setIsDisabledButton(true);
        }
      } else {
        setDuree('');
        setOnErrorDateFinEtDuree(false);
        setOnErrorDateDebutEtDuree(false);
        setIsDisabledButton(false);
      }
    }
  };

  const handleChangeDateFin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateFin(event.target.value);
    if (dateDebut && event.target.value < dateDebut) {
      setOnErrorDateFinWithDateDebut(true);
      setIsDisabledButton(true);
    } else {
      setOnErrorDateFinWithDateDebut(false);
      const nbJours = dateDebut && event.target.value !== '' &&
        moment(event.target.value).diff(moment(dateDebut), 'days') + 1;
      if (nbJours) {
        if (nbJours <= 99) {
          setDuree(String(nbJours));
          setOnErrorDateFinEtDuree(false);
          setOnErrorDateDebutEtDuree(false);
          setIsDisabledButton(false);
        } else {
          setDuree('');
          !onErrorDateDebutEtDuree && setOnErrorDateFinEtDuree(true);
          setIsDisabledButton(true);
        }
      } else {
        setDuree('');
        setOnErrorDateFinEtDuree(false);
        setOnErrorDateDebutEtDuree(false);
        setIsDisabledButton(false);
      }
    }
  };

  const handleChangeDuree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnErrorDuree(false);
    setIsDisabledButton(false);
    setDuree(event.target.value);
  };

  const handleChangeDateMiseADispo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateMiseADispo(event.target.value);
    if (dateRemise && event.target.value !== '' && event.target.value > dateRemise) {
      setOnErrorDateRemise(true);
      setIsDisabledButton(true);
    } else {
      setOnErrorDateRemise(false);
      setIsDisabledButton(false);
    }
  };

  const handleChangeDateRemise = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRemise(event.target.value);
    if (dateMiseADispo && event.target.value !== '' && event.target.value < dateMiseADispo) {
      setOnErrorDateRemise(true);
      setIsDisabledButton(true);
    } else {
      setOnErrorDateRemise(false);
      setIsDisabledButton(false);
    }
  };

  const handleChangeRetraitVehicule = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetraitVehicule(event.target.checked);
  };

  const handleChangeActivationSuivi = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivationSuivi(event.target.checked);
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const buildPayLoad = () => {
    const payLoad = new FormData();
    const piecesJointes = piecesJointesList.attachments.map((attachment, index) => {
      payLoad.append(`file-${index}`, attachment.file);
      return {
        id: `file-${index}`,
        reference: attachment.libelle,
        type: attachment.type,
        nature: attachment.nature,
        version: attachment.version,
      };
    });

    const formData = {
      codeAbonneEmeteur: user.codeAbo,
      codeAbonneDestinataire: destinataire?.codeAbonne,
      codeGTA: mission?.codeGTA,
      codeService: user.service,
      numClient: user.numClient,
      scenario: mission?.scenario,
      numeroAssure: mission?.societaireDTO?.numero,
      referenceSinistre: mission?.infoMissionDTO?.referenceSinistre,
      numMission: mission?.infoMissionDTO?.numeroMission,
      dateEffetPolice: mission?.infoMissionDTO?.dateEffetPolice,
      codeGestionnaireSinistre: mission?.infoMissionDTO?.codeGestionnaireSinistre,
      nomGestionnaireSinistre: mission?.infoMissionDTO?.nomGestionnaireSinistre,
      coordonneesCommunicationGestionnaire: mission?.infoMissionDTO?.coordonneesCommunicationGestionnaire,
      identifiant: sollicitaion?.idDoc,
      listSuiviRep: [{
        etapeReparation: libelleSuivi,
        infosCompl: infosComplementaires,
        dateDebutTrvxPrev: dateDebut,
        dateFinTrvxPrev: dateFin,
        dureeTrvx: duree,
        dateMiseADispVeh: dateMiseADispo,
        dateRemiseVeh: dateRemise,
        retraitParAssure: retraitVehicule ? 'O' : 'N',
        activSuiviAssure: activationSuivi ? 'O' : 'N',
      }],
      commentaire: message,
      userCompte: user.userCompte,
      nomCompteAssistance: nomCompteAssistance ?? '',
    };

    payLoad.append('metadata',
        new Blob([JSON.stringify({...formData, piecesJointes: piecesJointes})],
            {type: 'application/json'}));
    return payLoad;
  };

  const handleSubmit = () => {
    const isLibelleError = !libelleSuivi;
    const isDureeError = duree !== '' && Number(duree) < 1;
    setIsDisabledButton(isLibelleError || isDureeError);
    if (!isLibelleError && !isDureeError) {
      setIsLoading(true);
      const payload = buildPayLoad();
      postIFRForm(payload)
          .then((response) => {
            setResponseReturn(response.status);
          })
          .catch((error) => {
            if (error.response) {
              setResponseReturn(error.response.status);
            } else {
              setResponseReturn(-1);
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
    } else {
      isLibelleError && setOnErrorLibelleSuivi(true);
      isDureeError && setOnErrorDuree(true);
    }
  };

  return (
    <div id={'popup-ifr'} className={classes.form}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography id={'title-popup-ifr'} className={classes.titre}>
            {isResponseSollicitation ? 'Répondre à la sollicitation' : 'Ajouter un suivi de réparation'}
          </Typography>
          <Tooltip disableInteractive title='Quitter' PopperProps={{disablePortal: true}}>
            <IconButton component='span' style={{padding: '0px'}}
              onClick={responseReturn <= 0 ? props.onClose : props.onCloseAndRefresh}>
              <CancelIcon style={{marginRight: '0px', color: 'white'}}/>
            </IconButton>
          </Tooltip>
        </div>
        {(responseReturn <= 0) && !showPjForm &&
          <div className={classes.body}>
            {isResponseSollicitation &&
              <>
                <MotifsSollicitation infoSollicitation={sollicitaion?.infoSollicitation}/>
              </>
            }
            <Typography className={classes.libelle} style={{marginTop: isResponseSollicitation ? '10px' : '0px'}}>
              Libellé du suivi <span className={classes.red}>*</span>
            </Typography>
            <TextFieldSelect
              id={'libelle'}
              name={'libelle'}
              marginDense={true}
              fullWidth={true}
              value={libelleSuivi}
              itemsList={itemsListLibelleSuivi}
              onChange={handleChangeLibelleSuivi}
              onError={onErrorLibelleSuivi}
            />
            {onErrorLibelleSuivi &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Veuillez sélectionner une valeur
              </Typography>
            }
            <Typography className={classes.libelle} style={{marginTop: '10px'}}>
              Informations complémentaires
            </Typography>
            <TextFieldMultiline id={'infosComplementaires'} marginDense={true} value={infosComplementaires}
              maxLength={200} rows={4} onChange={handleChangeInfosComplementaires}
              helperText={infosComplementaires.length + '/200'}
            />

            <Typography variant={'h3'} style={{marginTop: '5px'}}>Dates prévisionnelles des travaux</Typography>
            <div className={classes.line1}>
              <div className={classes.fullWidth}>
                <Typography className={classes.libelle}>Date de début</Typography>
                <TextFieldDate id={'dateDebut'} marginDense={true} value={dateDebut} onChange={handleChangeDateDebut}
                  onError={onErrorDateDebutEtDuree}/>
                {onErrorDateDebutEtDuree &&
                  <Typography className={`${classes.red} ${classes.error}`}>
                    La durée des travaux ne peut pas excéder 99 jours
                  </Typography>
                }
              </div>
              <div className={classes.fullWidth}>
                <Typography className={classes.libelle}>Date de fin</Typography>
                <TextFieldDate id={'dateFin'} marginDense={true} value={dateFin} onChange={handleChangeDateFin}
                  onError={onErrorDateFinWithDateDebut || onErrorDateFinEtDuree}
                />
                {onErrorDateFinWithDateDebut &&
                  <Typography className={`${classes.red} ${classes.error}`}>
                    Veuillez saisir une date supérieure ou égale à la date de début
                  </Typography>
                }
                {onErrorDateFinEtDuree &&
                  <Typography className={`${classes.red} ${classes.error}`}>
                    La durée des travaux ne peut pas excéder 99 jours
                  </Typography>
                }
              </div>
              <div className={classes.fullWidth}>
                <Typography className={`${classes.libelle} ${classes.duree}`}>
                  Durée
                  <Tooltip disableInteractive className={classes.tooltip}
                    placement="top" title={'Durée de 1 à 99 jours'}
                    PopperProps={{disablePortal: true}}>
                    <HelpIcon/>
                  </Tooltip>
                </Typography>
                <InputMask mask="99" maskPlaceholder={null} value={duree} onChange={handleChangeDuree}>
                  <TextFieldSearch id={'duree'} endAdornment={'jour(s)'} onError={onErrorDuree}/>
                </InputMask>
                {onErrorDuree &&
                  <Typography className={`${classes.red} ${classes.error}`}>
                    Veuillez saisir une valeur supérieure à 0
                  </Typography>
                }
              </div>
            </div>
            <div className={classes.line2}>
              <div className={classes.fullWidth}>
                <Typography className={classes.libelle}>Date de mise à disposition du véhicule</Typography>
                <TextFieldDate id={'dateMiseADispo'} marginDense={true} value={dateMiseADispo}
                  onChange={handleChangeDateMiseADispo}
                />
              </div>
              <div className={classes.fullWidth}>
                <Typography className={classes.libelle}>Date de remise du véhicule</Typography>
                <TextFieldDate id={'dateRemise'} marginDense={true} value={dateRemise}
                  onChange={handleChangeDateRemise} onError={onErrorDateRemise}
                />
                {onErrorDateRemise &&
                  <Typography className={`${classes.red} ${classes.error}`}>
                    Veuillez saisir une date supérieure ou égale à la date de mise à disposition du véhicule
                  </Typography>
                }
              </div>
            </div>

            <Typography variant={'h3'} style={{marginTop: '25px'}}>Autres informations</Typography>
            <div className={classes.switchContainer}>
              <FormControlLabel
                className={classes.formControlLabel}
                value={retraitVehicule}
                label="Retrait du véhicule par l'assuré"
                labelPlacement="start"
                control={
                  <BlueSwitch
                    id={'switch-retrait-vehicule'}
                    name="retraitVehicule"
                    checked={retraitVehicule}
                    onChange={handleChangeRetraitVehicule}
                  />
                }
              />
              <FormControlLabel
                className={classes.formControlLabel}
                value={activationSuivi}
                label="Activation du suivi assuré"
                labelPlacement="start"
                control={
                  <BlueSwitch
                    id={'switch-activation-suivi'}
                    name="activationSuivi"
                    checked={activationSuivi}
                    onChange={handleChangeActivationSuivi}
                  />
                }
              />
            </div>

            <Typography className={classes.libelle} style={{marginTop: '20px'}}>Votre message</Typography>
            <TextFieldMultiline id={'message'} marginDense={true} value={message} rows={4}
              onChange={handleChangeMessage}
            />
            {responseReturn === -1 &&
              <Typography className={`${classes.red} ${classes.error}`}>
                Échec de l&apos;envoi du message. Le contenu de votre formulaire n&apos;est pas valide.
              </Typography>
            }
          </div>
        }
        {responseReturn <= 0 && showPjForm &&
          <div className={classes.attachments}>
            <FilesDragAndDrop
              attachments={piecesJointesList}
              onSave={onUpload}
              onCloseAttachmentForms={onCloseAttachmentForms}
            />
          </div>
        }
        {responseReturn === 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Votre message a bien été envoyé
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onCloseAndRefresh}/>
          </div>
        }
        {responseReturn > 0 && responseReturn !== 201 &&
          <div className={classes.divResponse}>
            <Typography variant='body1' component='div' className={classes.titre}>
              Une erreur s&apos;est produite. Merci de réessayer ultérieurement.
            </Typography>
            <ButtonBlue id={'boutonOk'} className={classes.buttonOK} libelle={'OK'} onClick={props.onClose}/>
          </div>
        }
      </div>
      {responseReturn <= 0 && !showPjForm &&
        <div className={classes.footer}>
          <div className={classes.buttons}>
            {isLoading ? <Loader className={classes.loader} size={30}/> :
              <>
                <ButtonLink
                  id={'boutonAjoutPJ'}
                  libelle={libelleLink}
                  isLibelleUpperCase={true}
                  isLibelleBold={true}
                  onClick={() => setShowPjForm(!showPjForm)}
                />
                <ButtonBlueSend id={'boutonEnvoyer'} onClick={handleSubmit} disabled={isDisabledButton}/>
              </>
            }
          </div>
        </div>
      }
    </div>
  )
};
