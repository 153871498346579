export interface DebutFinProps {
  debut: string,
  fin: string,
}

export interface HoraireProps {
  lundi: DebutFinProps[],
  mardi: DebutFinProps[],
  mercredi: DebutFinProps[],
  jeudi: DebutFinProps[],
  vendredi: DebutFinProps[],
  samedi: DebutFinProps[],
  dimanche: DebutFinProps[],
}

export const emptyHoraire = {
  lundi: [],
  mardi: [],
  mercredi: [],
  jeudi: [],
  vendredi: [],
  samedi: [],
  dimanche: [],
};
