import React from 'react';
import {EdiProps} from './VueEdi';
import {ReponseCommonSIV} from '../../vueEdiSIV/reponseSIV/ReponseCommonSIV';
import {RequeteCommonSIV} from '../../vueEdiSIV/requeteSIV/RequeteCommonSIV';

interface VueEdiSIVProps {
  content: EdiProps,
  search: any,
}

export const VueEdiSIV = (props: VueEdiSIVProps) => {
  const {content, search} = props;

  switch (search.type) {
    case 'CSIT00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'IT'}
      />;
    case 'ISIT00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'IT'}
      />;
    case 'CSCT00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'CT'}
      />;
    case 'ISCT00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'CT'}
      />;
    case 'CSSA00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'SA'}
      />;
    case 'ISSA00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'SA'}
      />;
    case 'ODDC00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'DC'}
      />;
    case 'AQDC00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'DC'}
      />;
    case 'ODIR00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'IR'}
      />;
    case 'AQIR00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'IR'}
      />;
    case 'ODDA00':
      return <RequeteCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'DA'}
      />;
    case 'AQDA00':
      return <ReponseCommonSIV sIVReqRepModel={{content: content, from: 'historique'}}
        formulaire={'DA'}
      />;
    default:
      return null
  }
};
