export const getMessageErrorByStatusCode = (statusCode: number, entity: string): string => {
  switch (statusCode) {
    case 401:
      return 'Le jeton d\'authentification n\'est pas valide'
    case 404:
      return entity === 'user' ? 'L\'utilisateur recherché est introuvable' :
                'La pièce jointe recherchée est introuvable'
    case 500:
      return 'Une erreur est survenue, veuillez réessayer plus tard'
    default:
      return ''
  }
}
