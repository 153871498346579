import React from 'react';
import {ICardsTabProps} from './VignettesComponent';
import {ReactComponent as LogoSinappsAutoExtranet} from '../../images/logo/logo-sinapps-auto-extranet.svg';
import {ReactComponent as LogoIdigo} from '../../images/logo/logo-idigo.svg';
import {ReactComponent as LogoSinappsAutoDecisions} from '../../images/logo/logo-sinapps-auto-decisions.svg';
import {ReactComponent as LogoAtwRecycleurs} from '../../images/logo/logo-autow-recycleurs.svg';
import {ReactComponent as LogoAtwAdministration} from '../../images/logo/logo-autow-administration.svg';
import {ReactComponent as LogoAtwDecisionnel} from '../../images/logo/logo-autow-decisionnel.svg';
import {ReactComponent as LogoSinappsAutoOmega} from '../../images/logo/logo-sinapps-auto-omega.svg';
import {ReactComponent as LogoAutoweb} from '../../images/logo/logo-autoweb.svg';

export const vignetteSinappsAutoExtranet = (className: string): ICardsTabProps => {
  return {
    name: 'Extranet',
    icon: <LogoSinappsAutoExtranet className={className}/>,
    subtitle: 'Votre nouvel outil de gestion sinistre',
    path: '/extranet/dossiers',
    openInNewTab: false,
  }
};

export const vignetteAutoweb = (className: string): ICardsTabProps => {
  return {
    name: 'Autoweb',
    icon: <LogoAutoweb className={className}/>,
    subtitle: 'Tableau de bord de vos dossiers auto',
    path: window.extranet.globalConfig.autowebUrl,
    openInNewTab: true,
  }
};

export const vignetteSinappsAutoOmega = (className: string): ICardsTabProps => {
  return {
    name: 'Omega',
    icon: <LogoSinappsAutoOmega className={className}/>,
    subtitle: 'Votre outil d\'Organisation et de Maîtrise des Episodes de Grande Ampleur',
    path: window.extranet.globalConfig.omegaUrl,
    openInNewTab: true,
  }
};

export const vignetteDecisionnel = (className: string): ICardsTabProps => {
  return {
    name: 'Decisionnel',
    icon: <LogoAtwDecisionnel className={className}/>,
    subtitle: 'Espace décisionnel',
    path: window.extranet.globalConfig.decisionnelUrl,
    openInNewTab: true,
  }
};

export const vignetteDecisionnelMaif = (className: string): ICardsTabProps => {
  return {
    name: 'DecisionnelMaif',
    icon: <LogoAtwDecisionnel className={className}/>,
    subtitle: 'Espace décisionnel',
    path: window.extranet.globalConfig.decisionnelMaifUrl,
    openInNewTab: true,
  }
};

export const vignetteDecisionnelMatmut = (className: string): ICardsTabProps => {
  return {
    name: 'DecisionnelMatmut',
    icon: <LogoAtwDecisionnel className={className}/>,
    subtitle: 'Espace décisionnel',
    path: window.extranet.globalConfig.decisionnelMatmutUrl,
    openInNewTab: true,
  }
};

export const vignetteDecisionnelSferen = (className: string): ICardsTabProps => {
  return {
    name: 'DecisionnelSferen',
    icon: <LogoAtwDecisionnel className={className}/>,
    subtitle: 'Espace décisionnel',
    path: window.extranet.globalConfig.decisionnelSferenUrl,
    openInNewTab: true,
  }
};

export const vignetteAdministration = (className: string, userRoles: string[]): ICardsTabProps => {
  const subtitle = () => {
    if (userRoles.includes('P107')) {
      return 'Administration du réseau'
    }
    return userRoles.includes('P108') ? 'Itinérant du réseau' : 'Membre du réseau'
  };
  return {
    name: 'Administration',
    icon: <LogoAtwAdministration className={className}/>,
    subtitle: `${subtitle()}`,
    path: window.extranet.globalConfig.administrationUrl,
    openInNewTab: true,
  }
};

export const vignetteRecycleurs = (className: string, userRoles: string[]): ICardsTabProps => {
  const subtitle = () => {
    if (userRoles.includes('PRE1')) {
      return 'Gestion conventions , paramétrage, livre de police'
    }
    return userRoles.includes('PRE2') ? 'Gestion conventions, livre de police' : 'Gestion conventions'
  };
  return {
    name: 'Recycleurs',
    icon: <LogoAtwRecycleurs className={className}/>,
    subtitle: `${subtitle()}`,
    path: window.extranet.globalConfig.recycleursUrl,
    openInNewTab: true,
  }
};

export const vignetteSinappsAutoDecisions = (className: string): ICardsTabProps => {
  return {
    name: 'Decisions',
    icon: <LogoSinappsAutoDecisions className={className}/>,
    subtitle: 'Le pilotage par la data',
    path: window.extranet.globalConfig.decisionsUrl,
    openInNewTab: true,
  }
};

export const vignetteIdigo = (className: string): ICardsTabProps => {
  return {
    name: 'Idigo',
    icon: <LogoIdigo className={className}/>,
    subtitle: 'Le référentiel des prestataires de la gestion de sinistres',
    path: window.extranet.globalConfig.idigoUrl,
    openInNewTab: true,
  }
};
