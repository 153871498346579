import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {TextFieldSearch} from '../../../common/formsComponents/TextFieldSearch';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {TextFieldMultiline} from '../../../common/formsComponents/TextFiledMultiline';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {ButtonBlueDelete} from '../../../common/formsComponents/ButtonBlueDelete';
import {IAttachment} from '../initialStateFormFDV';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {ReactComponent as InProgressIcon} from '../../../../images/icones/icn-in-progress.svg'
import {customTheme} from '../../../../common/GlobalTheme';
import {ItemGetValeursCodesModel} from '../../../../model/common/ItemGetValeursCodesModel';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

export interface IAttachmentProps {
    expanded: boolean,
    attachment: IAttachment,
    codesValeursNaturePieceJointe: ItemGetValeursCodesModel[],
    onChangePanel: (panel: string) => void,
    onSave: (attachment: IAttachment) => void,
    onChangeAttachment: (attachment: IAttachment) => void,
    onDelete: (attachment: IAttachment) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
  },
  summary: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  summaryContent: {
    'display': 'flex',
    'alignItems': 'center',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  libelleAccordion: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
  },
  fileOnError: {
    color: '#F0283F',
    fontSize: '14px',
  },
  textField: {
    width: '100%',
  },
});

const Attachment = (props: IAttachmentProps) => {
  const classes = useEmotionStyles(styles);
  const {
    expanded, attachment, codesValeursNaturePieceJointe,
    onSave, onDelete, onChangePanel, onChangeAttachment,
  } = props;
  const [formData, setFormData] = useState<IAttachment>(attachment);
  const [errorFields, setErrorFields] = useState(
      {natureError: false, referenceError: false, descriptifError: false},
  );

  const codesValeursNaturePieceJointeSort = [...codesValeursNaturePieceJointe].sort((a: ItemGetValeursCodesModel,
      b: ItemGetValeursCodesModel) => {
    if (a.libelleedition > b.libelleedition) {
      return 1
    } else {
      return -1
    }
  });
  const itemsListNaturePieceJointe = codesValeursNaturePieceJointeSort.map((item: ItemGetValeursCodesModel) => {
    return (<MenuItem key={item.valeur} value={item.valeur}>{item.libelleedition}</MenuItem>)
  });

  useEffect(() => {
    setFormData({...attachment})
  }, [attachment]);

  const handleChange = (input: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (input === 'nature') setErrorFields({...errorFields, natureError: false, descriptifError: false});
      else if (input === 'reference') setErrorFields({...errorFields, referenceError: false});
      else setErrorFields({...errorFields, descriptifError: false});

      const attachmentApdated = {...formData, completed: false, [input]: event.target.value};
      setFormData(attachmentApdated);
      onChangeAttachment(attachmentApdated)
    };

  const handleSubmit = async () => {
    setErrorFields({
      natureError: !formData.nature,
      referenceError: !formData.reference,
      descriptifError: formData.nature === '99' && !formData.descriptif,
    });
    if (formData.nature && formData.reference && (formData.nature === '99' ? formData.descriptif : true)) {
      onSave(formData)
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <Accordion style={{width: '90%', borderRadius: '8px 8px 0px 0px'}}
          expanded={attachment.onError ? false : expanded}
          onChange={() => onChangePanel(`panel-${attachment.id}`)}
          disabled={attachment.onError}
        >
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className={classes.summary}
            classes={{root: classes.summaryRoot, content: classes.summaryContent}}
          >
            {attachment.onError && <CancelIcon style={{color: '#F0283F'}}/>}
            {attachment.completed && <CheckCircleIcon style={{color: '#1EA851'}}/>}
            {!attachment.completed && attachment.onError &&
              <InProgressIcon style={{fill: customTheme.palette.primary.main, marginRight: '10px'}}/>
            }
            <Typography className={classes.libelleAccordion}>{attachment.file?.name}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{display: 'block', padding: '16px'}}>
            <div>
              <Typography className={classes.libelle}>
                Nom du justificatif <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSearch id={'reference'} className={classes.textField} value={formData.reference}
                maxLength={50} onChange={handleChange('reference')} onError={errorFields.referenceError}
              />
              {errorFields.referenceError &&
                <Typography className={`${classes.red} ${classes.error}`}>
                  Veuillez saisir une valeur
                </Typography>
              }
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>
                Nature du justificatif <span className={classes.red}>*</span>
              </Typography>
              <TextFieldSelect
                className={classes.textField}
                id={'nature'}
                name={'nature'}
                marginDense={true}
                value={formData.nature}
                itemsList={itemsListNaturePieceJointe}
                onChange={handleChange('nature')}
                onError={errorFields.natureError}
              />
              {errorFields.natureError &&
                <Typography className={`${classes.red} ${classes.error}`}>
                  Veuillez sélectionner une valeur
                </Typography>
              }
            </div>
            <div style={{marginTop: '10px'}}>
              <Typography className={classes.libelle}>
                Descriptif du justificatif
                {formData.nature === '99' && <span className={classes.red}> *</span>}
              </Typography>
              <TextFieldMultiline id={'textFieldDescriptif'} maxLength={80} rows={2} marginDense={true}
                value={formData.descriptif} onError={errorFields.descriptifError}
                onChange={handleChange('descriptif')} helperText={formData.descriptif.length + '/80'}
              />
              {errorFields.descriptifError &&
                <Typography className={`${classes.red} ${classes.error}`}>
                  Veuillez saisir une valeur
                </Typography>
              }
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '16px'}}>
              <ButtonBlue id={'boutonValider' + attachment.id} libelle={'Valider'} onClick={handleSubmit}
                disabled={errorFields.natureError || errorFields.referenceError || errorFields.descriptifError}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <div style={{display: 'flex', width: '10%'}}>
          <div style={{margin: 'auto'}}>
            <ButtonBlueDelete id={'boutonSupprimer' + attachment.id} onClick={() => onDelete(attachment)}/>
          </div>
        </div>
      </div>
      {attachment.onError && attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : format de fichier non autorisé</Typography>
      }
      {attachment.onError && !attachment.onTypeError &&
        <Typography className={classes.fileOnError}>Erreur : fichier trop volumineux</Typography>
      }
    </div>
  );
};

export default Attachment;
