import React, {useEffect} from 'react'
import unescape from 'lodash/unescape';
import {getLibelleValeur, getValueDateElement, getValueDateHeureElement, getValueElement} from '../Utils';
import {CardContainer} from '../CardContainer';
import {Entete} from './Entete';

interface RequeteIRProps {
  refSinistre: string,
  data: any,
}

const libellesValeursVehicule = (data: any) => {
  return ([{
    libellesValeurs: [
      [
        getLibelleValeur(`N° d'immatriculation :`, getValueElement(data, 'ns1:', 'numero_immatriculation')),
        getLibelleValeur(`N° de formule du certificat d'immatriculation :`,
            getValueElement(data, 'ns1:', 'numero_formule')),
      ],
      [
        getLibelleValeur(`Date du certificat d'immatriculation :`, getValueDateElement(data, 'ns1:', 'date_CI')),
      ],
    ],
  }])
};

export const RequeteIR = (props: RequeteIRProps) => {
  const {refSinistre, data} = props

  useEffect(() => {
    document.title = 'Requête RC';
  }, []);

  const valeurPersonneMoralePhysique = () => {
    if (data.getElementsByTagName('ns1:identification_pers_morale').length !== 0) {
      return unescape(data.getElementsByTagName('ns1:identification_pers_morale')[0].value)
    } else {
      return (data.getElementsByTagName('ns1:identification_pers_phy').length !== 0 ?
          data.getElementsByTagName('ns1:identification_pers_phy')[0].value : '')
    }
  }

  return (<React.Fragment>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <Entete data={data} prefix={'ns1:'} formulaire={'IR'}
        refSinistre={refSinistre}
      />
      <CardContainer titreContainer={'Titulaire (actuel) : Assuré'}
        content={
          [{libellesValeurs: [
            [
              getLibelleValeur(data.getElementsByTagName('ns1:identification_pers_morale').length !== 0 ?
                  'Identification de la personne morale :\xa0' : 'Identification de la personne physique :\xa0',
              valeurPersonneMoralePhysique()),
            ]]}]
        }/>
    </div>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <CardContainer titreContainer={'Véhicule'}
        content={libellesValeursVehicule(data)}/>
      <CardContainer titreContainer={`Information sur la date et l'heure du refus`}
        content={
          [{libellesValeurs: [
            [
              getLibelleValeur('Date et heure du refus :',
                  getValueDateHeureElement(data, 'ns1:', 'date_heure_declaration_refus')),
            ]]}]
        }/>
    </div>
  </React.Fragment>)
};
