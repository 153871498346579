import React, {ChangeEvent, useState} from 'react';
import {Autocomplete, FormControl, TextField, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import {UserContext} from '../../../../store/UserContext';
import {getLocalites} from '../../../../api/infogeo';
import {ILocalite} from '../../ordre-mission/model/ModelOM';

export interface LocalityAutocompleteProps extends FieldConfig{
    id: string,
    label: string,
    isMandatory: boolean,
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});


const LocaliteAutocompleteField = ({label, isMandatory, id, ...props}: LocalityAutocompleteProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const [field, meta, helper] = useField<ILocalite>(props as FieldHookConfig<ILocalite>);

  const [localites, setLocalites] = useState<ILocalite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearchLocality = (event: ChangeEvent<{}>, value: string) => {
    if (value.trim().length > 2) {
      setIsLoading(true);
      getLocalites(value, user.numClient).then((response) => {
        setLocalites(response);
        setIsLoading(false)
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setLocalites([])
    }
  };

  const onSelectLocalite = (event: ChangeEvent<{}>, value: any) => {
    value ? helper.setValue(value) :
        helper.setValue({
          codePostal: '',
          commune: '',
        })
  };


  const renderInputAutoCompletion = (params: any) => {
    return <FormControl fullWidth>
      <TextField
        {...params}
        name={field.name}
        margin='dense'
        size='small'
        fullWidth
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.textfield,
          },
        }}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </FormControl>
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.libelle}>
        {label} {isMandatory && <span style={{color: 'red'}}>*</span>}
      </Typography>
      <Autocomplete
        id={id}
        autoHighlight
        loading={isLoading}
        loadingText='Chargement...'
        popupIcon={''}
        value={field.value}
        options={localites}
        getOptionLabel={(loc: ILocalite) => [loc.codePostal, loc.commune].filter(Boolean).join(' - ')}
        onChange={onSelectLocalite}
        onBlur={field.onBlur}
        onInputChange={handleSearchLocality}
        renderInput={renderInputAutoCompletion}
        filterOptions={(options) => options}
        noOptionsText={'Aucun résultat trouvé'}
      />
    </div>
  );
};

export default LocaliteAutocompleteField;
