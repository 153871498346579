import React from 'react'
import {Typography} from '@mui/material';
import {TextFieldSearch} from './formsComponents/TextFieldSearch';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface NumMissionProps {
  className?: string,
  name: string,
  formulaire: string,
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onError: boolean,
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  value: string,
  helperText?: React.JSX.Element | string,
}

const styles = (theme: Theme) => ({
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
});

export const NumMission = ({className, name, formulaire, onChangeValue, onError, helperText, onFocus,
  onBlur, value}: NumMissionProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <div className={className}>
      <Typography className={classes.libelle}>
        N° de mission
      </Typography>

      <TextFieldSearch
        id={'numMission' + formulaire}
        name={name}
        fullWidth={true}
        maxLength={10}
        value={value}
        onError={onError}
        helperText={helperText}
        onChange={onChangeValue}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
};
