import React from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../common/useEmotionStyles';

interface MessageBubbleProps {
  indexBubble?: number,
  itemSelected?: boolean,
  titleBold: string,
  subtitle: string,
  contentBubble: React.ReactElement,
  dateHeure?: string,
  typeBubble: string,
  isBubbleCommentaire?: boolean,
}

const styles = () => ({
  container: {
    position: 'relative' as const,
    maxWidth: '750px',
    marginBottom: '40px',
  },
  containerSent: {
    marginLeft: 'auto',
  },
  enTete: {
    display: 'flex',
    columnGap: '20px',
    marginBottom: '5px',
    alignItems: 'baseline',
  },
  titleBold: {
    fontWeight: 'bold',
  },
  bubble: {
    filter: 'drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15))',
    borderRadius: '8px',
    padding: '15px',
  },
  bubbleSent: {
    backgroundColor: '#EAEBF4',
  },
  bubbleReceive: {
    backgroundColor: '#F4F4F4',
  },
  bubbleCommentaireReceived: {
    'borderRadius': '8px 8px 8px 0px',
    '&:before': {
      content: '""',
      position: 'absolute' as const,
      height: '0',
      width: '0',
      left: '0px',
      bottom: '-20px',
      borderTop: '20px solid #F4F4F4',
      borderRight: '35px solid transparent',
    },
  },
  bubbleCommentaireSent: {
    'borderRadius': '8px 8px 0px 8px',
    '&:before': {
      content: '""',
      position: 'absolute' as const,
      height: '0',
      width: '0',
      right: '0px',
      bottom: '-20px',
      borderTop: '20px solid #EAEBF4',
      borderLeft: '35px solid transparent',
    },
  },
  divDateHeure: {
    textAlign: 'right' as const,
    margin: '5px 0px 0px 0px',
  },
  decalage30: {
    margin: '5px 30px 0px 0px',
  },
  text: {
    fontSize: '14px',
    color: '#505050',
  },
});

export const MessageBubble = (props: MessageBubbleProps) => {
  const classes = useEmotionStyles(styles);
  const {indexBubble, itemSelected, titleBold, subtitle, contentBubble, dateHeure, typeBubble,
    isBubbleCommentaire} = props;

  const classNameBubbleCommentaire = () => {
    const className = `${classes.bubble} ${typeBubble === 'envoye' ? classes.bubbleSent : classes.bubbleReceive}`;
    if (isBubbleCommentaire) {
      if (typeBubble === 'envoye') {
        return className.concat(` ${classes.bubbleCommentaireSent}`)
      }
      return className.concat(` ${classes.bubbleCommentaireReceived}`);
    }
    return className
  };

  return (
    <div
      id={`messageBubble-${indexBubble}`}
      className={`${classes.container} ${typeBubble === 'envoye' ? classes.containerSent : ''}`}
    >
      <div className={classes.enTete}>
        <Typography id={`bubbleTitle-${indexBubble}`} className={classes.titleBold}>{titleBold}</Typography>
        <Typography id={`bubbleReceiver-${indexBubble}`} className={classes.text}>{subtitle}</Typography>
      </div>
      <div
        className={classNameBubbleCommentaire()}
        style={{filter: itemSelected ? 'drop-shadow(-1px -1px 2px #241E46) drop-shadow(1px 2px 2px #241E46)' : ''}}
      >
        {contentBubble}
      </div>
      <div
        className={`${classes.divDateHeure}
          ${isBubbleCommentaire && typeBubble === 'envoye' ? classes.decalage30 : ''}
        `}
      >
        <Typography id={`bubbleDateHeure-${indexBubble}`} className={classes.text}>{dateHeure}</Typography>
      </div>
    </div>
  )
};
