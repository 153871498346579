import {Dispatch} from 'react'

import {MissionAction, MissionDataState} from '../reducers/MissionReducer'
import {DetailsModel} from '../model/detailsDossier/DetailsModel'

export const getMissionActions = (state: MissionDataState, dispatch: Dispatch<MissionAction>) => ({
  setMission: (mission : DetailsModel ) => {
    dispatch( {type: 'SET_MISSION', mission: mission} )
  },
  setErreur: (error : string ) => {
    dispatch( {type: 'SET_ERREUR', error: error} )
  },
})

/**
 * This hack allow us to get the list of the methods names without maintaining a enum
 */
// get an "instance" of the function
// eslint-disable-next-line
// @ts-ignore
const getMissionActionsDummy = (false as true) && getMissionActions(undefined, undefined)
export type MissionActions = typeof getMissionActionsDummy; // create a type from that instance


