import React from 'react';
import {FieldConfig, FieldHookConfig, useField} from 'formik';
import InputMask from 'react-input-mask-3.0';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {TextField, Typography} from '@mui/material';
import {getIdFromName} from '../../utils/Utils';

interface InputPhoneFieldProps extends FieldConfig {
    label: string
    mandatory?: boolean
    disabled?: boolean
    isPortable?: boolean
}

const styles = (theme: Theme) => ({
  container: {
    width: '360px',
    marginRight: '24px',
  },
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  red: {
    color: 'red',
  },
})
const InputPhoneField = ({label, ...props}: InputPhoneFieldProps) => {
  const classes = useEmotionStyles(styles);
  const [field, meta, helper] = useField<string>(props as FieldHookConfig<string>);
  const {mandatory, isPortable} = props

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replaceAll(' ', '')
    let regEx
    if (isPortable) {
      regEx = value && value.length > 1 ? /^0[6-7]/ : /^0/
    } else {
      regEx = value && value.length > 1 ? /^0[1-9]/ : /^0/
    }
    if (regEx.test(value)) {
      helper.setValue(value)
    } else {
      helper.setValue(value.startsWith('0') ? value.substring(0, 1) : '')
    }
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.libelle}>
        {label} {mandatory && <span className={classes.red}>*</span>}
      </Typography>
      <InputMask
        mask='99 99 99 99 99'
        maskPlaceholder={null}
        onChange={onChangePhone}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
      >
        <TextField
          id={getIdFromName(field.name)}
          fullWidth
          margin='dense'
          size='small'
          type={'search'}
          InputProps={{
            classes: {
              root: classes.textfield,
            },
          }}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      </InputMask>
    </div>
  );
};

export default InputPhoneField;
