import React from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Typography} from '@mui/material';
import {IManagementCenter} from '../../model/ModelPECBG';

export interface ManagementCenterProps {
  centreGestion: IManagementCenter,
}

const styles = (theme: Theme) => ({
  title: {
    marginBottom: '8px',
  },
  card: {
    backgroundColor: theme.palette.secondary.light,
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    padding: '16px',
    borderRadius: '8px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '16px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
  },
  gridElement: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    marginBottom: '16px',
  },
  addressElement: {
    marginBottom: '8px',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
});

const ManagementCenter = (props: ManagementCenterProps) => {
  const {centreGestion} = props;
  const classes = useEmotionStyles(styles);
  return (
    <div>
      <Typography variant={'h6'} className={classes.title}>
        Centre de gestion
      </Typography>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <div className={classes.grid}>
            <div>
              <div className={classes.gridElement}>
                <span className={classes.libelle}>Adresse : </span>
                <div>
                  <div className={centreGestion.adresse1 &&
                  (centreGestion.adresse2 || centreGestion.adresse3) ? classes.addressElement : ''}>
                    {centreGestion.adresse1}
                  </div>
                  <div className={centreGestion.adresse3 &&
                  (centreGestion.adresse2 || centreGestion.adresse1) ? classes.addressElement : ''}>
                    {centreGestion.adresse2}
                  </div>
                  <div>{centreGestion.adresse3}</div>
                </div>
              </div>
              <div>
                <span className={classes.libelle}>Téléphone : </span>
                <span>{centreGestion.telephone}</span>
              </div>
            </div>
            <div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Code postal : </span>
                <span>{centreGestion.codePostal}</span>
              </div>
              <div className={classes.marginBottom16}>
                <span className={classes.libelle}>Localité : </span>
                <span>{centreGestion.localite}</span>
              </div>
              <div>
                <span className={classes.libelle}>Pays : </span>
                <span>{centreGestion.pays}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementCenter;
