export const formulairesSIV = [
  {
    code: 'IT',
    libelle: 'Identification du titulaire du véhicule',
  },
  {
    code: 'SA',
    libelle: 'Situation administrative',
  },
  {
    code: 'CT',
    libelle: 'Caractéristiques techniques',
  },
  {
    code: 'DA',
    libelle: 'Achat du véhicule à l\'assuré',
  },
  {
    code: 'DC',
    libelle: 'Cession du véhicule à un professionnel',
  },
  {
    code: 'IR',
    libelle: 'Inscription de refus de cession du véhicule',
  },
];
