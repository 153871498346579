import React, {ForwardedRef, useEffect, useState} from 'react';
import {Siren} from './Siren';
import {Siret} from './Siret';
import {IconButton, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
import {getCorporation} from '../../../api/infogeo';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import {UserContext} from '../../../store/UserContext';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {helperTextWithIcon} from '../../../common/Utils';

export interface InfoGeoSiege {
  raisonSociale: string,
  activite: string,
  nic: string,
}

export interface InfoGeoAdresse {
  complementAdresse: string,
  numeroVoie: string,
  indiceRepetition: string,
  typeVoie: string,
  libelleVoie: string,
  codePostal: string,
  libelleCommune: string,
  distributionSpeciale: string,
  codeCommune: string,
  codeCedex: string,
  libelleCedex: string,
}

export interface InfoGeoEtablissement {
  siren: string,
  siret: string,
  nom: string,
  activite: string,
  etablissementSiege: string,
  nic: string,
  etatAdministratifEt: string,
  score: number,
  siege: InfoGeoSiege,
  adresse: InfoGeoAdresse,
}

export interface Row {
  id: number,
  raisonSociale: string,
  siret: string,
  siren: string,
  codePostal: string,
  localite: string,
  adresse: string,
  numeroVoie: string,
  typeVoie: string,
  libelleVoie: string,
  indiceRepetition: string,
  complementAdresse: string,
}

export interface SearchPersonneMoraleProps {
  siren: string,
  siret: string,
  onClose: () => void,
  onSelect: (row: Row) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    position: 'absolute' as const,
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    boxShadow: '0px 10px 20px #6469A61A',
    backgroundColor: 'white',
  },
  form: {
    padding: '24px',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    display: 'flex',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '16px',
  },
  textfield: {
    width: '360px',
    marginRight: '24px',
  },
  table: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'none',
    },
  },
  loupe: {
    marginTop: '24px',
  },
  searchIcon: {
    color: theme.palette.link.main,
    marginRight: '0px',
  },
  disableSearch: {
    color: theme.palette.secondary.dark2,
    marginRight: '0px',
  },
  helpIcon: {
    width: '15px',
    height: 'auto',
  },
});

const validationSchema = yup.object({
  siren: yup.string()
      .min(9, 'Veuillez saisir une valeur au format attendu, voir'),
  siret: yup.string()
      .min(14, 'Veuillez saisir une valeur au format attendu, voir'),
});

const SearchPersonneMorale = React.forwardRef((props: SearchPersonneMoraleProps, ref: ForwardedRef<any>) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const {onClose, siren, siret, onSelect} = props;
  const [corporationList, setCorporationList] = useState<InfoGeoEtablissement[]>([]);
  const [rows, setRows] = useState<Row[]>([]);
  const [showResult, setShowResult] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (siren || siret) {
      goSearch();
    }
  }, []);

  useEffect(()=> {
    setRows(createRows)
  }, [corporationList]);

  useEffect(()=> {
    if (selectionModel.length > 0) {
      const row = rows[selectionModel[0] as number];
      onSelect(row);
      onClose()
    }
  }, [selectionModel]);

  const goSearch = () => {
    const {siren, siret, numClient} = formik.values;
    getCorporation(siren, siret, numClient)
        .then((response) => {
          if (response.status === 200) {
            setCorporationList(response.data)
          }
        })
        .catch(() => {
          setCorporationList([])
        })
        .finally(() => {
          setShowResult(true)
        })
  };

  const initialValues = {
    siren: siren,
    siret: siret,
    numClient: user.numClient,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: goSearch,
  });

  const onFocus = (field: string) => () => {
    formik.setFieldTouched(field, false, false)
  };

  const disableSearch = !!(formik.touched.siren && formik.errors.siren) ||
    !!(formik.touched.siret && formik.errors.siret);

  const createRows = () => {
    return corporationList.map((infoGeoEtablissement, index) => {
      const {siret, siren, siege: {raisonSociale}, adresse: {numeroVoie, typeVoie, libelleVoie, codePostal,
        libelleCommune, complementAdresse, indiceRepetition}} = infoGeoEtablissement;
      const adresse = numeroVoie ? numeroVoie?.concat(' ', typeVoie?.concat(' ', libelleVoie)) :
          typeVoie?.concat(' ', libelleVoie);
      return {id: index, raisonSociale, siret, siren, codePostal, localite: libelleCommune, adresse,
        numeroVoie: numeroVoie, typeVoie: typeVoie, libelleVoie: libelleVoie, complementAdresse: complementAdresse,
        indiceRepetition: indiceRepetition} as Row
    })
  };

  const handleChangeTable = (selection: GridRowSelectionModel) => {
    if (selection.length > 1) {
      const selectionSet = new Set(selectionModel);
      const result = selection.filter( (s) => !selectionSet.has(s));
      setSelectionModel(result);
    } else {
      setSelectionModel(selection);
    }
  };

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID'},
    {field: 'raisonSociale', headerName: 'Raison sociale', sortable: false, minWidth: 150, flex: 1.5, align: 'center',
      headerAlign: 'center'},
    {field: 'siret', headerName: 'Siret', headerAlign: 'center', sortable: false, width: 140,
      align: 'center'},
    {field: 'siren', headerName: 'Siren', headerAlign: 'center', sortable: false, width: 130,
      align: 'center'},
    {field: 'codePostal', headerName: 'Code postal', headerAlign: 'center', sortable: false, width: 120,
      align: 'center'},
    {field: 'localite', headerName: 'Localité', headerAlign: 'center', sortable: false, width: 140,
      align: 'center'},
    {field: 'adresse', headerName: 'Adresse', headerAlign: 'center', sortable: false, minWidth: 150, flex: 1, align:
          'center'},
  ];

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.form}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography style={{fontSize: 'larger'}}>Recherche d’établissement</Typography>
          <IconButton component='span' style={{padding: '0px'}} onClick={onClose}>
            <CancelIcon style={{marginRight: '0px', color: 'grey'}}/>
          </IconButton>
        </div>
        <fieldset style={{marginBottom: '16px'}}>
          <legend>
            <Typography className={classes.libelle}>Vos critères de recherche</Typography>
          </legend>

          <div className={classes.search}>
            <div className={classes.textfield}>
              <Siren
                id={'searchSiren'}
                name={'siren'}
                formulaire={'DC'}
                isMandatory={false}
                value={formik.values.siren}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siren')}
                onError={(formik.touched.siren && !!formik.errors.siren) || false}
                helperText={helperTextWithIcon(formik, 'siren', '', classes.helpIcon)}
              />
            </div>
            <div className={classes.textfield}>
              <Siret
                id={'searchSiret'}
                name={'siret'}
                formulaire={'DC'}
                isMandatory={false}
                value={formik.values.siret}
                onChangeValue={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={onFocus('siret')}
                onError={(formik.touched.siret && !!formik.errors.siret) || false}
                helperText={helperTextWithIcon(formik, 'siret', '', classes.helpIcon)}
              />
            </div>

            <div className={classes.loupe}>
              <IconButton
                id={'search'}
                aria-label="search"
                onClick={formik.submitForm}
                disabled={disableSearch}
              >
                <SearchIcon className={disableSearch ? classes.disableSearch : classes.searchIcon}/>
              </IconButton>
            </div>
          </div>
        </fieldset>
        {showResult && corporationList.length > 0 &&
          <div style={{minWidth: '1200px'}}>
            <div style={{width: '100%', display: 'flex', flexGrow: 1}}>
              <DataGrid
                className={classes.table}
                rows={rows}
                columns={columns}
                columnVisibilityModel={{id: false}}
                checkboxSelection
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => handleChangeTable(newSelectionModel)}
                disableColumnMenu={true}
                hideFooter={true}
                autoHeight
                density={'compact'}
              />
            </div>
          </div>
        }

        {showResult && corporationList.length === 0 &&
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <em>Aucun résultat trouvé</em>
          </div>
        }
      </div>
    </div>
  );
});
SearchPersonneMorale.displayName = 'SearchPersonneMorale';

export default SearchPersonneMorale;
