import React, {ReactElement} from 'react';
import {Typography} from '@mui/material';
import useEmotionStyles from '../../../../common/useEmotionStyles';

export const styles = () => ({
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0px 10px 20px #6469a61a',
  },
  sousTitre: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    flexGrow: 1,
    flexBasis: '0px',
  },
  sousTitre2: {
    fontWeight: 'bold',
    color: '#241E46',
    fontSize: '14px',
    marginBottom: '10px',
  },
  cardContent: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  flex: {
    display: 'flex',
  },
  row: {
    columnGap: '5px',
    marginBottom: '15px',
  },
  lastrow: {
    columnGap: '5px',
  },
  divLibelleVal: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  libelleHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  valeurHeader: {
    paddingLeft: '5px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  libelleElement: {
    color: '#7c7c7c',
    fontSize: '14px',
    minWidth: 'max-content',
  },
  valeurElement: {
    paddingLeft: '5px',
    color: '#241E46',
    fontWeight: 'bold',
    fontSize: '14px',
  },
});

export interface CardContainerProps {
  titreContainer: string | undefined,
  content: CardContentProps[]
  isEntete?: boolean
  specificiteDADC?: ReactElement
}

export interface CardContentProps {
  titre?: string
  libellesValeurs: LibelleValeur[][]
}

export interface LibelleValeur {
  libelle: string,
  valeur: string,
}

export const CardContainer = (props: CardContainerProps) => {
  const classes = useEmotionStyles(styles);

  const renderSousTitre = (content: CardContentProps, index: number) => {
    return (
        content.titre ? <Typography className={classes.sousTitre2} style={index !== 0 ? {marginTop: '20px'} : {}}>
          {content.titre}
        </Typography> : <></>
    )
  }

  const renderLibelleValeur = (libelleValeur: LibelleValeur, classNameDiv: string,
      keyDiv: string, isHeader: boolean) => {
    return (
      <div key={keyDiv}
        className={classNameDiv}>
        <Typography
          className={
            isHeader ? classes.libelleHeader : classes.libelleElement
          }
        >
          {libelleValeur.libelle}
        </Typography>
        <Typography
          className={
            isHeader ? classes.valeurHeader : classes.valeurElement
          }
        >
          {libelleValeur.valeur}
        </Typography>
      </div>
    )
  }

  return (<div style={{flexGrow: 1, flexBasis: '20px', display: 'flex', flexDirection: 'column'}}>
    <div className={classes.flex} style={{flexGrow: 1, flexDirection: 'column'}}>
      {props.titreContainer && <div className={classes.flex}>
        <Typography className={classes.sousTitre}>{props.titreContainer}</Typography>
      </div>}
      <div className={classes.cardContainer} style={
        props.content.length > 1 ?
            {flexGrow: 1, flexBasis: '0px', flexDirection: 'column', display: 'flex'} :
            {flexGrow: 1, flexBasis: '0px', display: 'flex'}
      }>
        {props.content.length > 0 && props.content.map((content, indexA) => {
          const keyDivA = `${indexA}`
          const lastContent = props.content.length == (indexA +1)
          return (<React.Fragment key={keyDivA}>
            {renderSousTitre(content, indexA)}
            <div className={classes.cardContent} style={
              (props.content.length > 1 && lastContent) || (props.content.length == 1) ? {flexGrow: 1} : {}
            }>
              <div style={{padding: '12px'}}>
                {content.libellesValeurs.length > 0 && content.libellesValeurs.map((libellesValeurs, indexB) => {
                  const keyDivB = `${indexB}`
                  const lastRow = content.libellesValeurs.length == (indexB +1)
                  return (
                    <div className={lastRow ? `${classes.flex} ${classes.lastrow}` : `${classes.flex} ${classes.row}`}
                      key={keyDivB}
                      style={(libellesValeurs.length > 2) ? {justifyContent: 'space-between'} : {}}>
                      {libellesValeurs.length > 0 && libellesValeurs.map((libelleValeur, indexC) => {
                        const keyDivC = `${indexC}`
                        const classNameDiv = (libellesValeurs.length > 2) ?
                            `${classes.flex}` : `${classes.flex} ${classes.divLibelleVal}`
                        return renderLibelleValeur(libelleValeur, classNameDiv, keyDivC,
                            (!!props.isEntete && indexB == 0 && indexA ==0))
                      })}
                    </div>
                  )
                })}
                {props.specificiteDADC}
              </div>
            </div>
          </React.Fragment>)
        })}
      </div>
    </div>
  </div>)
}
