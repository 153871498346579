import React from 'react';
import {Autocomplete, Chip, TextField} from '@mui/material';
import {ItemGetValeursCodesModel} from '../../../model/common/ItemGetValeursCodesModel';
import {ReactComponent as CrossIcon} from '../../../images/icones/icn-cross.svg';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

export interface AutoCompleteMarqueProps {
  id: string,
  disabled: boolean,
  placeholder?: string,
  codesValeursMarque: ItemGetValeursCodesModel[] | Partial<ItemGetValeursCodesModel>[],
  optionsList: ItemGetValeursCodesModel[],
  fullWidth?: boolean,
  onChangeMarque: (event: React.ChangeEvent<{}>, value: any) => void
}

const styles = (theme: Theme) => ({
  textfield: {
    backgroundColor: theme.palette.white.main,
  },
  chip: {
    backgroundColor: 'transparent',
    border: '1px solid ' + theme.palette.secondary.dark1,
  },
  deleteIcon: {
    fill: theme.palette.link.main,
    width: '18px',
    height: '18px',
  },
});

const AutoCompleteMultipleMarque = (props: AutoCompleteMarqueProps) => {
  const {id, disabled, placeholder, codesValeursMarque, optionsList, fullWidth, onChangeMarque} = props;
  const classes = useEmotionStyles(styles);

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        id='marque'
        name='marque'
        fullWidth={fullWidth}
        margin="dense"
        size="small"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.textfield,
          },
        }}
        placeholder={placeholder}
      />
    )
  };

  return (
    <Autocomplete
      id={id}
      autoHighlight
      multiple
      disabled={disabled}
      size="small"
      options={codesValeursMarque}
      getOptionLabel={
        (item: ItemGetValeursCodesModel | Partial<ItemGetValeursCodesModel>) => item.libelleedition?.trim() ?? ''
      }
      getOptionDisabled={(options) => optionsList.length >= 2}
      value={optionsList}
      onChange={onChangeMarque}
      noOptionsText={'Aucun résultat trouvé'}
      renderInput={renderInput}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({index})}
            key={option.valeur}
            className={classes.chip}
            variant='outlined'
            size={'small'}
            label={(option.libelleedition && option.libelleedition.length <= 10) ?
                option.libelleedition : option.libelleedition?.slice(0, 10) + '...'
            }
            deleteIcon={<CrossIcon className={classes.deleteIcon}/>}
          />
        ))
      }
    />
  );
};

export default AutoCompleteMultipleMarque;
