export const CODE_ASSURE = '01'
export const CODE_ASSUREUR = '04'
export const CODE_REPARATEUR = '05'
export const CODE_EXPERT = '06'
export const CODE_DEPANNEUR = '10'
export const CODE_REMORQUEUR = '11'
export const CODE_LOUEUR = '12'
export const CODE_STOCKEUR = '14'
export const CODE_INTERMEDIAIRE = '19'
export const CODE_EVALUATEUR = '20'
export const CODE_REPAIR_MANAGER = '25'
export const CODE_DEBOSSELEUR = '35'
export const CODE_RECYCLEUR = '36'
export const CODE_EVALUATEUR_IA = '38'
