import {ButtonBlue} from '../common/buttons/ButtonBlue';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Link, useLocation} from 'react-router-dom';
import {Vignette} from './Vignette';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Typography} from '@mui/material';
import React, {ReactElement, useState} from 'react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {UserContext} from '../../store/UserContext';

interface PathProps {
  pathname: string,
  state: {
    from: string,
  },
}

export interface ICardsTabProps {
  name: string,
  icon: ReactElement,
  subtitle: string,
  path: string | PathProps,
  openInNewTab: boolean,
}

interface IProps {
  cardsTab: ICardsTabProps[]
}

/** Design */
const styles = (theme: Theme) => ({
  containerCards: {
    display: 'flex',
    alignItems: 'center',
  },
  containerButton: {
    width: '120px',
    textAlign: 'center' as const,
    ['@media (max-width:1750px)']: {
      width: '80px',
    },
    ['@media (max-width:1550px)']: {
      width: '60px',
    },
    ['@media (max-width:1280px)']: {
      width: '100px',
    },
  },
  arrowButton: {
    padding: '0px',
    minWidth: '0px',
    width: '40px',
    height: '40px',
  },
  arrowIcon: {
    margin: '0px',
    width: '30px',
    height: '30px',
  },
  containerVignettes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: '20px',
    gridRowGap: '40px',
    ['@media (max-width:1280px)']: {
      gridColumnGap: '10px',
    },
    ['@media (max-width:900px)']: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    ['@media (max-width:600px)']: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  link: {
    textDecoration: 'none',
    width: 'fit-content',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
  },
  dotsIcon: {
    margin: '0px 5px',
    color: theme.palette.primary.main,
  },
  noAccess: {
    margin: '50px 0px 0px 120px',
  },
});

const LinkComponent = (props: {card: ICardsTabProps}) => {
  const {card} = props;
  const classes = useEmotionStyles(styles);
  const location = useLocation();

  return (
    <Link
      id={'linkCard' + card.name}
      target={card.openInNewTab ? '_blank' : ''}
      className={classes.link}
      to={card.path}
      onClick={() => sessionStorage.setItem('fromPath', location.pathname)}
    >
      <Vignette icon={card.icon} subtitle={card.subtitle}/>
    </Link>
  )
};

export const VignettesComponent = (props: IProps) => {
  const {cardsTab} = props;
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const isAssistance = user.isAssistance;
  const [activePage, setActivePage] = useState(1);
  const [indexFirstCard, setIndexFirstCard] = useState(0);
  const [indexLastCard, setIndexLastCard] = useState(7);

  const nbPages = Array.from({length: Math.ceil(cardsTab.length / 8)}, (_, i) => i + 1);

  const goBack = () => {
    setActivePage((prevState) => prevState - 1);
    setIndexFirstCard((prevState) => prevState - 8);
    setIndexLastCard((prevState) => prevState - 8)
  };

  const goNext = () => {
    setActivePage((prevState) => prevState + 1);
    setIndexFirstCard((prevState) => prevState + 8);
    setIndexLastCard((prevState) => prevState + 8)
  };

  return (
    <>
      {!cardsTab.length && isAssistance && user.userCompte &&
        <Typography className={classes.noAccess}>
          Ce compte ne vous permet pas d&apos;accéder à la plateforme Sinapps AUTO
        </Typography>
      }

      {!!cardsTab.length &&
        <>
          <div className={classes.containerCards}>
            <div className={classes.containerButton}>
              {cardsTab.length > 8 && activePage > 1 &&
                <ButtonBlue
                  id={'boutonBack'}
                  className={classes.arrowButton}
                  icon={<KeyboardArrowLeftIcon className={classes.arrowIcon}/>}
                  onClick={goBack}
                />
              }
            </div>

            <div className={classes.containerVignettes}>
              {cardsTab.slice(indexFirstCard, indexLastCard+1).map((card) => {
                return (
                  <LinkComponent key={card.name} card={card}/>
                )
              })}
            </div>

            <div className={classes.containerButton}>
              {cardsTab.length > 8 && activePage < nbPages.length &&
                <ButtonBlue
                  id={'boutonNext'}
                  className={classes.arrowButton}
                  icon={<KeyboardArrowRightIcon className={classes.arrowIcon}/>}
                  onClick={goNext}
                />
              }
            </div>
          </div>

          {cardsTab.length > 8 && nbPages.length > 1 &&
            <div className={classes.pagination}>
              {nbPages.map((page) => {
                return page === activePage ?
                <FiberManualRecordIcon key={page} className={classes.dotsIcon}/> :
                <FiberManualRecordOutlinedIcon key={page} className={classes.dotsIcon}/>
              })}
            </div>
          }
        </>
      }
    </>
  )
};
