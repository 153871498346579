import React from 'react';
import {
  InfosRendezVousModel,
  PartenaireInfosRdvProps,
} from '../../../../model/detailsDossier/RendezVousModel';
import {Typography} from '@mui/material';
import moment from 'moment';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

interface RdvCardProps {
  rdv: InfosRendezVousModel,
  partenaire?: PartenaireInfosRdvProps,
  nextRdv?: InfosRendezVousModel,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    marginBottom: '25px',
  },
  bloc: {
    display: 'flex',
    columnGap: '5px',
    marginBottom: '15px',
  },
  colonneGauche: {
    flexGrow: 1,
    flexBasis: '0px',
  },
  textJour: {
    fontWeight: 500,
    fontSize: '24px',
  },
  textBleu: {
    color: theme.palette.link.main,
  },
  textGris: {
    color: theme.palette.secondary.dark,
  },
  colonneDroite: {
    flexGrow: 3,
    flexBasis: '0px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-end',
  },
  repere: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  dot: {
    height: '9px',
    width: '9px',
    backgroundColor: theme.palette.link.main,
    borderRadius: '10px',
  },
  line: {
    borderTop: '2px dotted ' + theme.palette.link.main,
    borderBottom: 'none',
    color: theme.palette.link.main,
    width: '100%',
  },
  greyCard: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    padding: '5px 10px',
    width: '85%',
  },
  nomPartenaire: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  heure: {
    fontSize: '14px',
    marginTop: '5px',
  },
});

export const RdvCard = (props: RdvCardProps) => {
  const {rdv, partenaire, nextRdv} = props;
  const classes = useEmotionStyles(styles);

  const jour = moment(rdv.dateDebut).format('DD');
  const jourLibelle = moment(rdv.dateDebut).format('ddd');
  const heureDebut = moment(rdv.dateDebut).format('HH:mm');
  const heureFin = moment(rdv.dateFin).format('HH:mm');
  const jourBleu = nextRdv?.id === rdv.id;

  return (
    <div className={classes.container}>
      <div className={classes.bloc}>
        <div className={classes.colonneGauche}>
          <Typography className={`${classes.textJour} ${jourBleu ? classes.textBleu : classes.textGris}`}>
            {jour}
          </Typography>
          <Typography className={`${classes.textJour} ${jourBleu ? classes.textBleu : classes.textGris}`}>
            {jourLibelle}
          </Typography>
        </div>
        <div className={classes.colonneDroite}>
          {jourBleu &&
            <div className={classes.repere}>
              <div className={classes.dot}/>
              <hr className={classes.line}/>
            </div>
          }
          <div className={classes.greyCard}>
            <Typography className={classes.nomPartenaire}>{partenaire?.raisonSociale}</Typography>
            <Typography className={classes.heure}>{heureDebut} - {heureFin}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
};
