import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import useUser from '../../../hook/useUser';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import {UserContextType, userInitialState} from '../../../store/UserContext';
import {Loader} from '../../common/Loader';
import {getMessageErrorByStatusCode} from '../utils/utils';
import {GenericError} from '../../common/errorsComponents/GenericError';
import {useNavigate} from 'react-router-dom';
import {mapperEDI} from '../../detailsDossier/components/historiqueComponents/Utils';
import {getMessageById} from '../../../api/dossier';
import {AxiosError} from 'axios';
const base64 = require('base-64');

export interface ParamsEdiType {
  tokenEdi: string
}

const Edi = () => {
  const searchParams = useParams<keyof ParamsEdiType>() as ParamsEdiType;
  const search = JSON.parse(base64.decode(searchParams.tokenEdi));
  const navigate = useNavigate();
  Cookies.set('token', search.accessToken);

  const [dataUser, setDataUser] = useState<UserContextType>(
      {...userInitialState,
        isTransparent: true,
        isAssistance: false,
      });
  const [messageError, setMessageError] = useState<string>('');

  const decodedToken = jwtDecode<any>(search.accessToken);
  const userCompte = decodedToken['preferred_username'];

  const {data, error, loading} = useUser({
    userCompte: userCompte,
    isAssistance: false,
    isTransparent: true,
    codeService: search.codeService,
  });
  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      setDataUser(user);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const statusCode= error.response ? error.response.status : 0;
      const messageErrorByStatusCode = getMessageErrorByStatusCode(statusCode, 'user');
      setMessageError(messageErrorByStatusCode)
    }
  }, [error])

  useEffect(()=> {
    if (dataUser.codeAbo) {
      const urlParams = {
        numClient: dataUser.numClient,
        codeService: search.codeService,
        referenceSinistre: search.referenceSinistre?.trim(),
        numeroMission: search.numeroMission?.trim(),
        idMessage: search.idDocument,
        codeGta: dataUser.codeGTA,
      }

      getMessageById(urlParams)
          .then((response) => {
            const tokenEdi = mapperEDI(search, dataUser, response.data);
            const token = base64.encode(JSON.stringify(tokenEdi))
            navigate('/extranet/liens-transparents/mission/vue-edi/' + token)
          }).catch((error: AxiosError) => {
            if (error.response) {
              setMessageError(getMessageErrorByStatusCode(error.response.status, 'Edi'));
            }
          })
    }
  }, [dataUser])

  return (
    <>
      {loading &&
        <Loader/>
      }
      {!!messageError &&
        <GenericError errorMessage={messageError}/>
      }
    </>
  )
};

export default Edi;
