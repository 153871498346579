import React, {useEffect, useState} from 'react';
import {getDocumentEdi} from '../../../../../api/dossier';
import {useParams} from 'react-router'
import {Loader} from '../../../../common/Loader';
import {GenericError} from '../../../../common/errorsComponents/GenericError';
import {VueEdiSIV} from './VueEdiSIV';
import {VueEdiClassique} from './VueEdiClassique';

const base64 = require('base-64');

export interface EdiProps {
  infos: {
    actions: [],
    anomalies: [],
    codeGta: string,
    dateEnvoi: string,
    dateReception: string,
    destinataire: string,
    emetteur: string,
    etat: string,
    numMission: string,
    origine: string,
    refSinistre: string,
    ssType: string,
    statut: string,
    typeDoc: string,
  },
  contenuMessage: ContenuMessageModel[] | string,
  version?: string,
  type?: string,
}

export interface ContenuMessageModel {
  libelle: string,
  type: string,
  contenu: []
}

export interface ParamsEdiType {
  tokenEdi: string
}

interface IProps {
  isAccesTransparent?: boolean
}

export const VueEdi = (props: IProps) => {
  const {isAccesTransparent} = props;
  const params = useParams<keyof ParamsEdiType>() as ParamsEdiType;
  const [notFound, setNotFound] = useState(false);
  const search = JSON.parse(base64.decode(params.tokenEdi));
  const [edi, setEDI] = React.useState<EdiProps>();

  useEffect(() => {
    document.title = 'Affichage EDI';
    getDocumentEdi(
        search.idDocument,
        search.search,
        search.type,
        search.ssType,
        search.isnIdentifier,
        isAccesTransparent || false,
    )
        .then((response) => {
          setEDI(response)
        })
        .catch((error) => {
          setNotFound(true);
          return error
        })
  }, []);

  if (notFound) {
    return <GenericError errorMessage={'Document inexistant !'}/>
  }

  if (!edi) {
    return <Loader/>
  }

  return (
    edi.type && edi.type === 'SIV' ?
      <VueEdiSIV content={edi} search={search}/> :
      <VueEdiClassique edi={edi}/>
  )
};
