import React, {useEffect, useState, useMemo} from 'react';
import {useParams} from 'react-router';
import useUser from '../../../hook/useUser';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import {UserContext, UserContextType, userInitialState} from '../../../store/UserContext';
import {MissionContextProvider} from '../../../store/MissionContext';
import {DetailsDossier, ParamsMissionType} from '../../detailsDossier/DetailsDossier';
import {Loader} from '../../common/Loader';
import {AppBarHeader} from '../../app-bar/AppBarHeader';
import ConfigEnvironnement from '../../../ConfigEnvironnement';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {getMessageErrorByStatusCode} from '../utils/utils';
const base64 = require('base-64');

/** Design */
const styles = () => ({
  error: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
  },
})

const Mission = () => {
  const classes = useEmotionStyles(styles);
  const searchParams = useParams<keyof ParamsMissionType>();
  const search = JSON.parse(base64.decode(searchParams.token));
  Cookies.set('token', search.accessToken);
  const decodedToken = jwtDecode<any>(search.accessToken);

  const [dataUser, setDataUser] = useState<UserContextType>(
      {...userInitialState,
        isTransparent: true,
        isAssistance: false,
      });
  const [messageError, setMessageError] = useState<string>('');


  const {data, error, loading} = useUser({
    userCompte: decodedToken['preferred_username'],
    codeService: search.codeService,
    isAssistance: false,
    isTransparent: true,
    noClient: decodedToken['nocli'],
    rolesSSO: decodedToken['realm_access'] ? decodedToken['realm_access'].roles : [],
  });

  useEffect(() => {
    if (data.codeAbo) {
      const user: UserContextType = data;
      sessionStorage.setItem('user', JSON.stringify(user));
      setDataUser(user);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const statusCode= error.response ? error.response.status : 0;
      const messageErrorByStatusCode = getMessageErrorByStatusCode(statusCode, 'user');
      setMessageError(messageErrorByStatusCode)
    }
  }, [error])

  const conf = new ConfigEnvironnement('', '', '',
      '', '', '', '');

  const valueUserContextProvider = useMemo(() => ({user: dataUser, setDataUser}), [dataUser]);

  return (
    <>
      {
        loading && <Loader/>
      }
      {
        !!messageError && <p className={classes.error}>
          {messageError}
        </p>
      }
      {
        !loading && dataUser.codeAbo &&
              <UserContext.Provider value={valueUserContextProvider}>
                <MissionContextProvider>
                  <AppBarHeader configEnvironnement={conf} setUser={(user) => console.log(user)} isTransparent={true}/>
                  <DetailsDossier/>
                </MissionContextProvider>
              </UserContext.Provider>
      }
    </>
  )
};

export default Mission;
