import React, {ReactNode} from 'react';
import {IconButton, Typography} from '@mui/material';
import {ReactComponent as CloseIcon} from '../../../images/icones/icn-close-notifications.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

interface IProps {
  children: ReactNode
  theme: 'Success' | 'Error' | 'Warning'
  onClose: () => void
}

const styles = (theme: Theme) => ({
  card: {
    width: 'fit-content',
    boxShadow: 'none',
    margin: '0 auto 16px',
    padding: '8px',
    border: '2px solid',
    borderRadius: '12px',
  },
  header: {
    display: 'flex',
    justifyContent: 'end',
  },
  iconButton: {
    padding: '3px',
  },
  icon: {
    margin: '0px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px 24px',
    columnGap: '8px',
  },
  notificationSuccess: {
    color: theme.palette.green.main,
    backgroundColor: theme.palette.green.light1,
  },
  notificationError: {
    color: theme.palette.red.main,
    backgroundColor: theme.palette.red.light1,
  },
  notificationWarning: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.red.light1,
  },
});

const Notification = (props: IProps) => {
  const classes = useEmotionStyles(styles);
  const {children, theme, onClose} = props;
  const themeClasses: Record<'Error' | 'Warning' | 'Success', string> = {
    Error: classes.notificationError,
    Warning: classes.notificationWarning,
    Success: classes.notificationSuccess,
  };
  const classByType = themeClasses[theme];

  return (
    <div className={`${classes.card} ${classByType}`}>
      <div className={classes.header}>
        <IconButton
          id={'bouton-fermer'}
          className={classes.iconButton}
          component='span'
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>
      </div>

      <div className={classes.content}>
        {theme === 'Success' ? <CheckCircleIcon/> : <WarningIcon/>}

        <Typography>{children}</Typography>
      </div>
    </div>
  );
};

export default Notification;
