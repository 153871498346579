import * as React from 'react'
import {Typography} from '@mui/material'
import {customTheme} from '../../common/GlobalTheme';
import useEmotionStyles from '../../common/useEmotionStyles';


interface StyledTypographyProps {
    id?: string,
    title?: string,
    text?: string
}

const styles = () => ({
  bloc: {
    marginTop: '12px',
  },
})

export const StyledTypography = (props: StyledTypographyProps) => {
  const classes = useEmotionStyles(styles)

  return (
    <div className={classes.bloc}>
      <Typography id={'title-' + props.id} style={customTheme.styledTypography.libelle}>{props.title}</Typography>
      {props.text && props.text !== '' ?
        <Typography id={props.id} style={customTheme.styledTypography.donneeGrise16}>{props.text}</Typography> :
        <br/>
      }
    </div>
  )
}
