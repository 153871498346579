import React from 'react';
import {Button, Card, CardContent, Typography} from '@mui/material';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';

export interface IFormsList {
  OM: boolean,
  PECBG: boolean,
}

interface MenuFormsProps {
  onChangeMenu: (menu: string) => void,
  activeMenu: string,
  formsList: IFormsList,
}

const styles = (theme: Theme) => ({
  btnMenu: {
    width: '100%',
    justifyContent: 'left',
    textAlign: 'left' as const,
    marginBottom: '8px',
  },
  activeMenu: {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.white.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
});

export const MenuForms = (props: MenuFormsProps) => {
  const {onChangeMenu, activeMenu, formsList} = props;
  const classes = useEmotionStyles(styles);

  return (
    <Card>
      <CardContent>
        <div>
          <Typography variant={'h3'}>Formulaires</Typography>
          {formsList.OM &&
            <Button
              id={'boutonOM'}
              className={`${classes.btnMenu} ${activeMenu === 'OM' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('OM')}
            >
              <Typography>Ordre de mission</Typography>
            </Button>
          }
          {formsList.PECBG &&
            <Button
              id={'boutonPECBG'}
              className={`${classes.btnMenu} ${activeMenu === 'PECBG' ? classes.activeMenu : ''}`}
              onClick={() => onChangeMenu('PECBG')}
            >
              <Typography>Consultation PEC BG</Typography>
            </Button>
          }
        </div>
      </CardContent>
    </Card>
  )
};
