import React from 'react';
import {TextField} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface TextFieldDateProps {
  id: string,
  className?: string,
  marginDense?: boolean,
  value: string,
  onError?: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: React.KeyboardEvent) => void,
}

const styles = (theme: Theme) => ({
  textfield: {
    borderRadius: '4px',
    width: '100%',
  },
  inputDate: {
    'backgroundColor': theme.palette.white.main,
    '&:before': {
      border: 'none',
    },
  },
});

export const TextFieldDate = ({id, className, marginDense, value, onError,
  onChange, onKeyDown}: TextFieldDateProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <TextField
      id={id}
      label=""
      type="date"
      variant="outlined"
      margin={marginDense ? 'dense' : 'none'}
      size={marginDense ? 'small' : undefined}
      placeholder="Saisir une date"
      className={`${className} ${classes.textfield}`}
      value={value}
      InputProps={{
        classes: {
          root: classes.inputDate,
        },
      }}
      inputProps={{
        min: '1980-01-01',
        max: '2099-05-31',
      }}
      error={onError}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
};
