import React from 'react'
import {Card, CardContent, Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

interface VignetteProps {
  icon: React.JSX.Element,
  subtitle: string,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    'width': '255px',
    'height': '170px',
    'border': '0.5px solid ' + theme.palette.primary.main,
    'borderRadius': '16px',
    'boxShadow': '3px 3px 6px ' + theme.palette.boxShadow.main,
    'display': 'flex',
    'flexDirection': 'column' as const,
    'justifyContent': 'space-between',
    '&:hover #divHover': {
      backgroundColor: theme.palette.primary.main,
    },
    ['@media (max-width:1550px)']: {
      width: '240px',
      height: '160px',
    },
    ['@media (max-width:1280px)']: {
      width: '180px',
      height: '120px',
    },
  },
  cardContent: {
    padding: '16px 16px 0px',
  },
  logo: {
    height: '80px',
    textAlign: 'center' as const,
    ['@media (max-width:1280px)']: {
      height: '60px',
    },
  },
  subtitle: {
    fontSize: '12px',
    textAlign: 'center' as const,
    ['@media (max-width:1280px)']: {
      fontSize: '10px',
    },
  },
  divHover: {
    height: '15px',
  },
});

export const Vignette = ({icon, subtitle}: VignetteProps) => {
  const classes = useEmotionStyles(styles);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.logo}>{icon}</div>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </CardContent>
      <div id='divHover' className={classes.divHover}>&nbsp;</div>
    </Card>
  )
};
