import React from 'react';
import {CardContainer} from '../CardContainer';
import {getLibelleValeur, getValueElement} from '../Utils';

interface CaracteristiquesTechProps {
  data: any,
  prefix: string
}

export const CaracteristiquesTech = (props: CaracteristiquesTechProps) => {
  const {data, prefix} = props

  const libellesValeursCT = () => {
    return (
      [
        [
          getLibelleValeur('Catégorie du véhicule (CE)(J) :', getValueElement(data, prefix, 'categorie')),
          getLibelleValeur(`Type de carburant ou source d'énergie (P.3) :`, getValueElement(data, prefix, 'energie')),
        ],
        [
          getLibelleValeur('Carrosserie CE (J.2) :', getValueElement(data, prefix, 'carrosserie_ce')),
          getLibelleValeur('Carrosserie nationale (J.3) :', getValueElement(data, prefix, 'carrosserie')),
        ],
        [
          getLibelleValeur('Puissance administrative nationale (P.6) :',
              getValueElement(data, prefix, 'puissance_admin_nat')),
          getLibelleValeur('Vitesse du moteur (U.2) :', getValueElement(data, prefix, 'regime_moteur')),
        ],
        [
          getLibelleValeur('Places assises (S.1) :', getValueElement(data, prefix, 'places_assises')),
          getLibelleValeur('Places debout (S.2) :', getValueElement(data, prefix, 'places_debout')),
        ],
      ]
    )
  };

  return (
    <CardContainer titreContainer={'Caractéristiques techniques'} content={[{libellesValeurs: libellesValeursCT()}]}
    />
  )
};
