import React, {useEffect, useState} from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';
import BlocSearch from './BlocSearch';
import {getListRdvByRefSinistre} from '../../api/dossier';
import BlocRdv from './BlocRDV';
import {Loader} from '../common/Loader';
import {ButtonHome} from '../common/buttons/ButtonHome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ButtonLink} from '../common/buttons/ButtonLink';
import {useNavigate} from 'react-router';
import {RendezVousModel} from '../../model/rechercherRendezVous/SearchRdvModel';
import {UserContext} from '../../store/UserContext';
import {useMissionContext} from '../../store/MissionContext';
import useHasRole from '../../hook/useHasRole';
import {permittedRolesMenuRDV} from '../../constantes/roles/Roles';
import AccessDenied from '../common/errorsComponents/AccessDenied';
import useEmotionStyles from '../../common/useEmotionStyles';
import {isAssureurOrExpertOrCarrossier} from '../../common/utils/UserTypeUtils';

const styles = () => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '35px',
    opacity: '1',
  },
  back: {
    textAlign: 'left' as const,
    height: '40px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  loader: {
    margin: '0px 20px 0px 0px',
  },
});

const RechercherRdv = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const navigate = useNavigate();
  const hasRoleConsultationRDV = useHasRole(permittedRolesMenuRDV) && isAssureurOrExpertOrCarrossier(user.type);
  const [referenceSinistre, setReferenceSinistre] = useState('');
  const {mission} = useMissionContext();
  const refSin = mission?.infoMissionDTO?.referenceSinistre?.trim();
  const [listRendezVous, setListRendezVous] = useState<RendezVousModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const searchRdv = sessionStorage.getItem('searchRdv') && JSON.parse(sessionStorage.getItem('searchRdv') ?? '');
  const fromPath = sessionStorage.getItem('fromPath');

  useEffect(() => {
    const refToSearch = refSin ?? searchRdv?.referenceSinistre;
    if (refToSearch) {
      handleSearch(refToSearch)
    }
  }, []);

  const handleSearch = (refSinistre: string) => {
    const codeGta = mission?.codeGTA?.trim() ?? user.codeGTA;
    setIsLoading(true);
    setReferenceSinistre(refSinistre);
    const searchRdv = {referenceSinistre: refSinistre};
    sessionStorage.setItem('searchRdv', JSON.stringify(searchRdv));
    const {numClient, userCompte} = user;
    getListRdvByRefSinistre(refSinistre, codeGta, numClient, userCompte).then((response) => {
      if (response.status === 200) {
        setListRendezVous(response.data);
      } else {
        setListRendezVous([]);
      }
      setIsLoading(false)
    }).catch((e) => {
      console.error(e);
      setIsLoading(false)
    })
  };

  return (
    <>
      {hasRoleConsultationRDV && <div className={classes.root}>
        <Grid container justifyContent="center" spacing={1}>
          {fromPath !== '/extranet/portail-sinapps-auto' &&
            <Grid item style={{width: '12%', paddingRight: '20px'}}>
              <div className={classes.back}>
                {fromPath?.includes('extranet/dossiers/mission/') ?
                  <ButtonLink id={'retour'} icon={<ArrowBackIcon/>} libelle={'Retour'}
                    onClick={() => navigate(fromPath)}/> :
                  <ButtonHome/>
                }
              </div>
            </Grid>
          }
          <Grid item style={{width: '80%'}}>
            <Typography variant={'h1'}>Prise de rendez-vous réparateur</Typography>
            <Card style={{margin: '15px 0px'}}>
              <CardContent>
                <BlocSearch onSearch={handleSearch} listRdv={listRendezVous}/>
                {isLoading &&
                  <div className={classes.flex}>
                    <Loader className={classes.loader} size={30}/>
                  </div>
                }
                {!isLoading && listRendezVous && listRendezVous.length > 0 &&
                  <div>
                    <Typography variant={'h3'} style={{margin: '24px 0px 12px'}}>
                      Liste de rendez-vous ({listRendezVous.length})
                    </Typography>
                    <Grid container justifyContent="space-between" spacing={2}>
                      {listRendezVous.map((rdv, index) => {
                        const keyGrid = `${index}`
                        return <Grid item key={keyGrid} xs={12} md={6}>
                          <BlocRdv rdv={rdv} referenceSinistre={referenceSinistre}/>
                        </Grid>
                      })}
                    </Grid>
                  </div>
                }
                {!isLoading && listRendezVous && listRendezVous.length === 0 &&
                  <div className={classes.flex}>
                    <Typography>
                      Aucun rendez-vous n&apos;existe pour cette référence sinistre
                    </Typography>
                  </div>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      }
      {!hasRoleConsultationRDV &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder à la recherche de rendez-vous'}
          isReturnButton={true}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }
    </>
  );
};

export default RechercherRdv;
