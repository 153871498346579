import {Button, Typography} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import {ContentGR, ContentGRLIST, ContentSE, ContentSELIST} from './Utils';
import React, {useState} from 'react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ContenuMessageModel, EdiProps} from './VueEdi';

interface VueEdiClassiqueProps {
  edi: EdiProps,
}

const styles = (theme: Theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: '5px',
  },
  span: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
  },
  libelle: {
    fontSize: '11px',
    fontWeight: 'bold',
    minWidth: 'max-content',
  },
  donnee: {
    fontSize: '11px',
  },
  divButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
  },
  printIcon: {
    cursor: 'pointer',
    color: theme.palette.link.main,
  },
});

export const VueEdiClassique = (props: VueEdiClassiqueProps) => {
  const {edi} = props;
  const classes = useEmotionStyles(styles);
  const [openAccordion, setOpenAccordion] = useState<boolean>(true);

  const handleChange = () => {
    setOpenAccordion(!openAccordion);
  };

  return (
    <div>
      <div className={classes.header}>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Type de document :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.typeDoc}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Sous-type de document :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.ssType}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Etat :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.etat}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Emetteur :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.emetteur}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Référence sinistre :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.refSinistre}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Statut :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.statut}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Destinataire :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.destinataire}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Numéro OM :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.numMission}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Code GTA :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.codeGta}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Date de réception :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.dateReception}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Origine :&nbsp;</Typography>
          <Typography className={classes.donnee}>{edi?.infos?.origine}</Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Anomalie(s) :&nbsp;</Typography>
          <Typography className={classes.donnee}>
            {edi?.infos?.anomalies?.length !== 0 ?
              edi?.infos?.anomalies.map((anomalie: any, index) => {
                return anomalie.libelle + (index + 1 !== edi.infos.anomalies.length ? '; ' : '')
              }) :
              'Aucune'
            }
          </Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Date(s) d&apos;émission :&nbsp;</Typography>
          <Typography className={classes.donnee}>
            {edi?.infos?.dateEnvoi ? edi.infos.dateEnvoi : 'Aucune'}
          </Typography>
        </span>
        <span className={classes.span}>
          <Typography className={classes.libelle}>Action(s) :&nbsp;</Typography>
          <Typography className={classes.donnee}>
            {edi?.infos?.actions?.length !== 0 ? edi?.infos?.actions.map((action: any, index) => {
              return action.libelle + (index + 1 !== edi.infos.actions.length ? '; ' : '')
            }) : 'Aucune'}
          </Typography>
        </span>
      </div>

      <div className={classes.divButtons}>
        <Button onClick={handleChange}>
          {edi?.infos?.typeDoc}
          {openAccordion ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </Button>
        <PrintIcon className={classes.printIcon} onClick={() => window.print()}/>
        <ButtonLink id={'boutonFermer'} libelle={'Fermer'} onClick={() => window.close()}/>
      </div>

      {(edi?.contenuMessage as ContenuMessageModel[]).map((value) => {
        if (value.type.startsWith('SE') && value.type.substring(9) !== 'LIST') {
          return (
            <ContentSE
              key={value.type}
              type={value.type}
              libelle={value.libelle}
              contenu={value.contenu}
              openAccordion={openAccordion}
            />
          )
        } else if (value.type.startsWith('SE') && value.type.substring(9) === 'LIST') {
          return (
            <ContentSELIST
              key={value.type}
              type={value.type}
              contenu={value.contenu}
              openAccordion={openAccordion}
            />
          )
        } else if (value.type.startsWith('GR') && value.type.substring(8) !== 'LIST') {
          return (
            <ContentGR
              key={value.type}
              type={value.type}
              libelle={value.libelle}
              contenu={value.contenu}
              openAccordion={openAccordion}
            />
          )
        } else if (value.type.startsWith('GR') && value.type.substring(8) === 'LIST') {
          return (
            <ContentGRLIST
              key={value.type}
              type={value.type}
              contenu={value.contenu}
              openAccordion={openAccordion}
            />
          );
        } else {
          return (
            <div key={value.type}>Donnée inconnue</div>
          )
        }
      })}
    </div>
  )
};
