import React from 'react';
import DossierBloc from './DossierBloc';
import GestionnaireBloc from './GestionnaireBloc';
import IntermediaireBloc from './IntermediaireBloc';

const Identification = () => {
  return (
    <div style={{marginBottom: '16px'}}>
      <DossierBloc/>
      <GestionnaireBloc/>
      <IntermediaireBloc/>
    </div>
  );
};

export default Identification;
