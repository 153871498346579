import {Modal, Slide, Typography} from '@mui/material';
import React, {useState} from 'react';
import useEmotionStyles from '../../../../../../common/useEmotionStyles';
import {Theme} from '@emotion/react';
import {ButtonLink} from '../../../../../common/buttons/ButtonLink';
import {ReactComponent as IconAdd} from '../../../../../../images/icones/icn-add.svg';
import {ReactComponent as IconEye} from '../../../../../../images/icones/icn-eye.svg';
import SlideForm from '../../../../components/slide/SlideForm';
import {ModalFooter} from './ModalFooter';
import {ModalBody} from './ModalBody';
import {FormikValues, useFormikContext} from 'formik';
import {elementCalculInitialState} from '../../../model/ConstantsOM';
import {IElementCalcul} from '../../../model/ModelOM';
import {elementsCalculTypeHV, elementsCalculTypeV} from '../../../../../../constantes/constantesNormeAuto';

export interface ICheckElements {
  isErrorWithElementTypeV: boolean
  isErrorWithElementTypeHV: boolean
}

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  title: {
    marginBottom: '0px',
  },
  red: {
    color: 'red',
  },
  icnBlue: {
    fill: theme.palette.link.main,
    marginRight: '4px',
  },
  error: {
    fontSize: '12px',
    color: theme.palette.red.main,
  },
});

// Nombre maximum d'éléments de calcul autorisé
const ELEMENTS_CALCUL_NB_MAX = 99;

// Liste des codes de la donnée "Règlement direct consenti par l'assurance" qui rendent obligatoire la saisie
// d'au moins un élément de calcul
export const CODES_REGLEMENT_DIRECT = ['1', '3', '4'];

// Retourne un objet errors qui contient 2 booléens pour déterminer si il y a une erreur avec un élément
// de type Véhicule ou Hors Véhicule
export const checkErrorsElementTypeVehEtHVeh = (values: FormikValues): ICheckElements => {
  const errors = {
    isErrorWithElementTypeV: false,
    isErrorWithElementTypeHV: false,
  };
  let isElementVehicule = false;
  let isElementTypeV = false;
  let isElementTypeHV = false;
  (values.elementsCalcul ?? values).forEach((element: IElementCalcul) => {
    if (elementsCalculTypeV.includes(element.elementPriseEnCharge)) {
      isElementTypeV = true;
    }
    if (elementsCalculTypeHV.includes(element.elementPriseEnCharge)) {
      isElementTypeHV = true;
    }
    if (element.elementPriseEnCharge === '2') {
      isElementVehicule = true;
    }
  });

  if (isElementTypeV && !isElementVehicule) {
    errors.isErrorWithElementTypeV = true
  }
  if (isElementTypeHV && !isElementVehicule) {
    errors.isErrorWithElementTypeHV = true
  }

  return errors
};

export const ElementsCalculs = () => {
  const classes = useEmotionStyles(styles);
  const {values, setFieldValue, errors, setErrors, touched, setTouched, submitCount}: FormikValues = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const [idExpanded, setIdExpanded] = useState<number>(0);
  const [elementsCalculTmp, setElementsCalculTmp] = useState([...values.elementsCalcul]);
  const [errorsElementTypeVehEtHVeh, setErrorsElementTypeVehEtHVeh] = useState<ICheckElements>({
    isErrorWithElementTypeV: false, isErrorWithElementTypeHV: false,
  });
  const [submitCountPreview] = useState<number>(submitCount);

  const onCloseElementsCalculForms = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setFieldValue('elementsCalcul', elementsCalculTmp);
      if (touched?.elementsCalcul) {
        const touchedCopy = JSON.parse(JSON.stringify(touched));
        touchedCopy.elementsCalcul = [];
        setTouched(touchedCopy, false);
      }
      if (errors?.elementsCalcul) {
        const errorsCopy = JSON.parse(JSON.stringify(errors));
        errorsCopy.elementsCalcul = [];
        setErrors(errorsCopy, false);
      }
      setShowModal(!showModal);
    }
  };

  const onSaveElementsCalcul = () => {
    const errorsElement = checkErrorsElementTypeVehEtHVeh(values);
    if (errorsElement.isErrorWithElementTypeV || errorsElement.isErrorWithElementTypeHV) {
      setErrorsElementTypeVehEtHVeh(errorsElement);
    } else {
      setElementsCalculTmp(values.elementsCalcul);
      setShowModal(false);
      setIdExpanded(-1);
    }
  };

  const newElementCalcul = {...elementCalculInitialState, id: values.elementsCalcul.length};
  const addElementCalcul = () => {
    if (values.elementsCalcul.length >= ELEMENTS_CALCUL_NB_MAX) {
      window.confirm('Vous avez atteint le nombre maximum d\'éléments de calcul');
    } else {
      setFieldValue('elementsCalcul', [...values.elementsCalcul, newElementCalcul]);
      setIdExpanded(newElementCalcul.id)
    }
  };

  const getButtonTitle = () => {
    if (values.elementsCalcul.length > 0) {
      return 'Gérer les éléments de calcul (' + values.elementsCalcul.length + ')'
    } else {
      return 'Ajouter des éléments de calcul'
    }
  };

  const getButtonIcon = () => {
    if (values.elementsCalcul.length > 0) {
      return <IconEye className={classes.icnBlue}/>
    } else {
      return <IconAdd className={classes.icnBlue}/>
    }
  };

  const onClickOpenModal = () => {
    if (values.elementsCalcul.length === 0) {
      const newElementCalcul = {...elementCalculInitialState};
      setFieldValue('elementsCalcul', [...values.elementsCalcul, newElementCalcul]);
      setIdExpanded(0);
    }
    setShowModal(!showModal)
  };

  return (
    <div className={classes.cardContent}>
      <div className={classes.flex}>
        <Typography variant={'h3'} className={classes.title}>
          Éléments de calcul
          {CODES_REGLEMENT_DIRECT.includes(values.garantieResponsabilite.reglement) &&
            <span className={classes.red}>&nbsp;*</span>
          }
        </Typography>
        <ButtonLink
          id={'ajoutElementsCalcul'}
          libelle={getButtonTitle()}
          isLibelleUpperCase={true}
          isLibelleBold={true}
          icon={getButtonIcon()}
          onClick={onClickOpenModal}
        />
      </div>

      <div>
        {submitCountPreview < submitCount && errors?.elementsCalcul && typeof errors.elementsCalcul === 'string' &&
          <Typography className={classes.error}>{errors.elementsCalcul}</Typography>
        }
      </div>

      <Modal
        open={showModal}
        aria-labelledby="modal-elements-calcul-OM"
        aria-describedby="modal-formulaire-envoie-messageOM"
      >
        <Slide in={showModal} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          <div>
            <SlideForm
              title={'Éléments de calcul'}
              onClose={onCloseElementsCalculForms}
              childrenBody={
                <ModalBody
                  idExpanded={idExpanded}
                  setIdExpanded={setIdExpanded}
                  errorsElementTypeVehEtHVeh={errorsElementTypeVehEtHVeh}
                  setErrorsElementTypeVehEtHVeh={setErrorsElementTypeVehEtHVeh}
                />
              }
              childrenFooter={
                <ModalFooter
                  addElementCalcul={addElementCalcul}
                  onSave={onSaveElementsCalcul}
                />
              }
            />
          </div>
        </Slide>
      </Modal>
    </div>
  )
};
