import React, {useEffect, useState} from 'react';
import {Loader} from '../common/Loader';
import {Button, Grid, IconButton, MenuItem, Modal, Slide, Tooltip, Typography} from '@mui/material';
import {Acteurs} from './components/acteurs/Acteurs';
import {Assure} from './components/Assure';
import {AlbumPhotos} from './components/photosComponents/AlbumPhotos';
import {Details} from './components/Details';
import {useMissionContext} from '../../store/MissionContext';
import {getDetailMission, getMissions} from '../../api/dossier';
import {useParams} from 'react-router';
import {ButtonHome} from '../common/buttons/ButtonHome';
import {InfosDossier} from './components/infosDossierComponents/InfosDossier';
import {InfosDossierSimple} from './components/infosDossierComponents/InfosDossierSimple';
import {InfosDossierPrint} from './components/infosDossierComponents/InfosDossierPrint';
import PrintIcon from '@mui/icons-material/Print';
import {InfosDossierArchive} from './components/infosDossierComponents/InfosDossierArchive';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {ModalDossier} from './components/ModalDossier';
import {RendezVous} from './components/rendezVousComponents/RendezVous';
import {InfosRendezVousModel} from '../../model/detailsDossier/RendezVousModel';
import {UserContext} from '../../store/UserContext';
import useHasRole from '../../hook/useHasRole';
import {permittedRolesDetailsDossierConsultations, permittedRolesMenuRDV} from '../../constantes/roles/Roles';
import AccessDenied from '../common/errorsComponents/AccessDenied';
import {customTheme} from '../../common/GlobalTheme';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';
import {isAssureurOrExpertOrCarrossier} from '../../common/utils/UserTypeUtils';
import {GenericError} from '../common/errorsComponents/GenericError';
import {TextFieldSelect} from '../common/formsComponents/TextFieldSelect';
import {TokenModel} from '../../model/common/TokenModel';
import {useNavigate, useLocation} from 'react-router-dom';
import {SearchModel} from '../../model/common/SearchModel';
import {CardModel} from '../../model/rechercheDossier/CardModel';

const base64 = require('base-64');

const styles = (theme: Theme) => ({
  smallScreen: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  iconButtonPrint: {
    ['@media print']: {
      display: 'none',
    },
  },
  infoPrint: {
    display: 'none',
    ['@media print']: {
      display: 'block !important',
    },
  },
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '35px',
    opacity: '1',
  },
  colonneGauche: {
    width: '17%',
    marginBottom: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '17%',
      paddingRight: '10px',
    },
    [theme.breakpoints.down('md')]: {
      width: '12%',
    },
    ['@media print']: {
      display: 'none',
    },
  },
  colonneCentre: {
    width: '60%',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    ['@media print']: {
      width: '100% !important',
      marginTop: '-70px ! important',
    },
  },
  colonneDroite: {
    width: '17%',
    marginBottom: '20px',
    paddingLeft: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '17%',
      paddingLeft: '10px',
    },
    ['@media print']: {
      display: 'none',
    },
  },
  center: {
    textAlign: 'left' as const,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  missionCell: {
    justifySelf: 'end',
    width: '57%',
    marginTop: '-8px',
    display: 'grid',
    gridTemplateColumns: '70px 1fr',
    gridGap: '10px',
    marginBottom: '-5px',
  },
  select: {
    width: '100%',
  },
  libelle: {
    color: theme.palette.secondary.dark2,
    justifySelf: 'end',
    alignSelf: 'center',
  },
  card15: {
    marginTop: '15px',
  },
  card25: {
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '15px',
    },
  },
  maincard: {
    marginTop: '15px',
  },
  back: {
    textAlign: 'left' as const,
    height: '40px',
  },
  titlePrint: {
    display: 'none',
    ['@media print']: {
      display: 'inline !important',
      fontSize: '18px !important',
      marginBottom: '10px !important',
    },
  },
  title: {
    ['@media print']: {
      display: 'none !important',
    },
  },
  printIcon: {
    marginRight: '0px',
    color: theme.palette.link.main,
  },
  commentaire: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    marginLeft: '30px',
  },
  btnMenuSmallScreen: {
    'position': 'fixed' as const,
    'top': '160px',
    'padding': '0px',
    'justifyContent': 'flex-start',
    '&:hover': {
      backgroundColor: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    ['@media print']: {
      display: 'none',
    },
  },
  menuSmallScreen: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    height: '100px',
    borderRadius: '0px 8px 8px 0px',
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    padding: '5px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notification: {
    position: 'fixed' as const,
    bottom: '0px',
    right: '0px',
  },
});

export interface ParamsMissionType {
    token: string
}

export const DetailsDossier: React.FC = () => {
  const classes = useEmotionStyles(styles);
  const params = useParams<keyof ParamsMissionType>() as ParamsMissionType;

  const {mission, actions} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const hasRoleDossierConsultations = useHasRole(permittedRolesDetailsDossierConsultations);
  const hasRoleRdvConsultation = useHasRole(permittedRolesMenuRDV) && isAssureurOrExpertOrCarrossier(user.type);
  const navigate = useNavigate();
  const location = useLocation()
  let indicateurArchivage = mission?.indicateurArchivage;
  let refSinistre = mission?.infoMissionDTO?.referenceSinistre;
  let isDossierSimple = mission?.dossierSimple;
  const [idigoRendezVous, setIdigoRendezVous] = useState<InfosRendezVousModel[]>();
  const [isPendingMission, setIsPendingMission] = React.useState(true);
  const [isPendingValue, setIsPendingValue] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [isOpenMenuDossier, setIsOpenMenuDossier] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedMission, setSelectedMission] = useState('');
  const [missionsList, setMissionsList] = useState<any[]>([]);
  const [isChangingMission, setChangingMission] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialSearch = {
    numClient: '',
    referenceSinistre: '',
  }
  const [search, setSearch] = useState(initialSearch);

  const urlErrorComponent = '/extranet/error';

  const viewType = (() => {
    if (indicateurArchivage === 'O' || indicateurArchivage === 'A') return 'archive';
    if (isDossierSimple) return 'simple';
    return 'normal';
  })();

  const listContains = (list: CardModel[], numMission: string) => {
    return list.some((item) => item.numMission?.trim() === numMission);
  }

  useEffect(() => {
    try {
      const decodedToken = JSON.parse(base64.decode(params.token));
      if (decodedToken) {
        setSearch(decodedToken);
        const searchMissions: SearchModel = {
          numClient: user.numClient,
          userCompte: user.userCompte,
          codeAbo: user.codeAbo,
          codeService: user.service,
          nbMaxElement: '12',
          referenceSinistre: decodedToken.referenceSinistre,
        }
        getMissions(searchMissions).then((result) => {
          if (result?.list && result?.list.length > 0) {
            const missions: any[] = [];
            let otherMissions = false;
            result.list.forEach((item) => {
              if (item.numMission && item.numMission.length > 0) {
                const element = {
                  value: item.numMission?.trim(),
                  label: item.numMission?.trim(),
                }
                missions.push(element);
              } else {
                otherMissions = true;
              }
            });
            if (otherMissions) {
              missions.push({
                value: 'Toutes',
                label: 'Toutes',
              });
            }
            setMissionsList(missions);
            const numMission = decodedToken?.numeroMission?.trim();
            setSelectedMission(numMission === '' ? 'Toutes' : numMission)
            if (user.isTransparent && numMission && !listContains(result.list, numMission)) {
              refNotFound();
            }
          } else if (user.isTransparent) {
            refNotFound();
          }
        })
        detailMission(decodedToken);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    }
  }, []);

  const refNotFound = () => {
    setIsPendingMission(false);
    setError(true);
    setErrorMessage('Le dossier recherché est introuvable');
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // Test si le user connecté est bien le user de la requete
    if (search.numClient !== '' && user.numClient !== search.numClient) {
      navigate(urlErrorComponent)
    }
  }, []);

  const detailMission = (payLoad: TokenModel) => {
    const urlParams: TokenModel = !user.isTransparent ? payLoad : {
      ...payLoad,
      codeGTA: user.codeGTA,
      codeAbonne: user.codeAbo,
      userCompte: user.userCompte,
    };
    getDetailMission(urlParams, user.isTransparent).then((response) => {
      if (response === null) {
        navigate(urlErrorComponent)
      }
      actions.setMission(response);
      setIdigoRendezVous(response.rendezVousDTO);
      sessionStorage.setItem('listRdv', JSON.stringify(response.rendezVousDTO));
      sessionStorage.setItem('mission', JSON.stringify(response));
      setIsPendingMission(false);
      setChangingMission(false);
    }).catch((error) => {
      console.error(error);
    })
  }

  const checkPendingMission = () => {
    if (!isPendingMission) {
      const condition = indicateurArchivage === 'O' || indicateurArchivage === 'A' || isDossierSimple;
      if (condition) {
        setValue(1);
      } else {
        const tabStorage = sessionStorage.getItem('tab');
        tabStorage ? setValue(parseInt(tabStorage)) : setValue(0);
      }
      setIsPendingValue(false)
    }
  }

  useEffect(() => {
    checkPendingMission();
  });

  const onClickPrint = () => {
    setIsPrint(true)
  };

  const handleWindowSizeChange = () => {
    /* Permet de fermer la modal du menu Dossier si l'utilisateur dézoome jusqu'à atteindre la vue "normale" */
    if (window.innerWidth >= customTheme.breakpoints.values.md) {
      setIsOpenMenuDossier(false);
    }
  };

  const slideContentMenuDossier = (
    <div>
      <ModalDossier onClose={() => setIsOpenMenuDossier(!isOpenMenuDossier)}/>
    </div>
  );

  const itemsMissionsList = missionsList.map((item) => {
    return (
      <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
    )
  });

  const onChangeSelectedMission = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedMission !== value) {
      setChangingMission(true);
      setSelectedMission(value);
      sessionStorage.setItem('fromPath', location.pathname)
      const tokenMission: TokenModel = {
        referenceSinistre: search.referenceSinistre,
        numeroMission: value !== 'Toutes' ? value : '',
        codeGTA: mission?.codeGTA ?? user.codeGTA,
        numClient: user.numClient,
        userCompte: user.userCompte,
        codeAbonne: user.codeAbo,
        codeService: user.service,
        immatriculation: mission?.infoMissionDTO?.immatriculation,
        isDossierComplet: false,
      }
      if (!user.isTransparent) {
        const token = base64.encode(JSON.stringify(tokenMission));
        navigate(`/extranet/dossiers/mission/`+ token);
      }
      indicateurArchivage = mission?.indicateurArchivage;
      refSinistre = mission?.infoMissionDTO?.referenceSinistre;
      isDossierSimple = mission?.dossierSimple;
      checkPendingMission();
      detailMission(tokenMission);
    }
  };
  const isShowMission = missionsList.length === 1 && selectedMission !== 'Toutes';

  const detailsComponents = () => {
    return <div className={classes.root}>
      <Button className={classes.btnMenuSmallScreen} disableRipple
        onClick={() => setIsOpenMenuDossier(!isOpenMenuDossier)}>
        <div className={classes.menuSmallScreen}>
          <ArrowForwardIosIcon style={{marginRight: '0px', marginBottom: '5px'}}/>
          <Typography style={{fontSize: '12px'}}>Dossier</Typography>
        </div>
      </Button>
      <Modal open={isOpenMenuDossier}>
        <Slide in={isOpenMenuDossier} direction={'right'} timeout={800} mountOnEnter unmountOnExit>
          {slideContentMenuDossier}
        </Slide>
      </Modal>
      <Grid container justifyContent="center">
        <Grid item className={classes.colonneGauche}>
          <div>
            <div className={classes.back}>
              {!user.isTransparent &&
                  <ButtonHome/>
              }
            </div>

            <div className={`${classes.card15} ${classes.smallScreen}`}>
              {viewType === 'normal' && <InfosDossier showMission={isShowMission}/>}
              {viewType === 'simple' && <InfosDossierSimple/>}
              {viewType === 'archive' && <InfosDossierArchive showMission={isShowMission}/>}
            </div>
            <div className={`${classes.card25} ${classes.smallScreen}`}>
              {viewType === 'normal' && <Assure/>}
            </div>
          </div>
        </Grid>
        <Grid item className={classes.colonneCentre}>
          <div className={classes.center}>
            <div className={classes.grid}>
              <div style={{
                display: 'flex', alignItems: indicateurArchivage === 'O' || indicateurArchivage === 'A' ?
                    'baseline' : 'end',
              }}>
                <Typography variant={'h1'} className={classes.title}>Détails du dossier</Typography>
                <Typography variant={'h1'} className={classes.titlePrint}>Détails du dossier {refSinistre}</Typography>
                {viewType === 'normal' &&
                  <Tooltip disableInteractive title='Imprimer une synthèse du dossier' placement="right">
                    <IconButton className={classes.iconButtonPrint} component='button'
                      style={{padding: '5px', marginLeft: '10px'}} onClick={onClickPrint}>
                      <PrintIcon className={classes.printIcon}/>
                    </IconButton>
                  </Tooltip>
                }
                <Typography className={classes.commentaire}>
                  {indicateurArchivage === 'O' && 'Dossier archivé'}
                  {indicateurArchivage === 'A' && 'En cours de désarchivage'}
                </Typography>
              </div>
              {missionsList && missionsList.length > 1 &&
                <div className={classes.missionCell}>
                  <Typography className={classes.libelle}>Missions</Typography>
                  <TextFieldSelect
                    id={'selectMission'}
                    className={classes.select}
                    name={'selectMission'}
                    value={selectedMission}
                    itemsList={itemsMissionsList}
                    disabled={false}
                    onChange={onChangeSelectedMission}
                    marginDense={true}
                  />
                </div>
              }
            </div>
            <div className={classes.maincard}>
              <div className={classes.infoPrint}>
                <InfosDossierPrint/>
              </div>
              <Details valueInit={value} isPrint={isPrint} onChangeIsPrint={() => setIsPrint(false)}/>
            </div>
          </div>
        </Grid>
        <Grid item className={`${classes.colonneDroite} ${classes.smallScreen}`}>
          <div>
            <div style={{height: '40px'}}>
              &nbsp;
            </div>
            <div className={classes.card15}>
              <Acteurs/>
            </div>
            <div className={classes.card25}>
              {viewType === 'normal' ? <AlbumPhotos/> : ''}
            </div>
            {hasRoleRdvConsultation && idigoRendezVous && idigoRendezVous.length > 0 &&
            <div className={classes.card25}>
              <RendezVous idigoRendezVous={idigoRendezVous} refSinistre={refSinistre}/>
            </div>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  };

  return <>
    {hasRoleDossierConsultations && (isPendingMission || isPendingValue) && search.numClient !== '' && !error &&
      <Loader/>
    }
    {isChangingMission &&
        <Loader/>
    }
    {hasRoleDossierConsultations && !isPendingMission && !isPendingValue && !isChangingMission && !error &&
        <>
          {detailsComponents()}
        </>
    }
    {!hasRoleDossierConsultations &&
      <AccessDenied
        message={'Ce compte ne vous permet pas de consulter le détail du dossier'}
        isReturnButton={true}
        buttonLabel={'Retour'}
        onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
      />
    }
    {(!isPendingMission && search.numClient === '') || error &&
        <GenericError errorMessage={errorMessage}/>
    }
  </>
};
