import React, {useState} from 'react';
import {RetourDossier} from '../../common/buttons/RetourDossier';
import {Button, Grid, Typography} from '@mui/material';
import {MenuFormsSIV} from './MenuFormsSIV';
import {ContentFormsSIV} from './ContentFormsSIV';
import {useNavigate, useLocation} from 'react-router-dom';
import {ButtonHome} from '../../common/buttons/ButtonHome';
import {useParams} from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useHasRole from '../../../hook/useHasRole';
import {permittedRolesSIVConsultations, permittedRolesSIVDeclarations} from '../../../constantes/roles/Roles';
import {AxiosResponse} from 'axios';
import AccessDenied from '../../common/errorsComponents/AccessDenied';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';
import {UserContext} from '../../../store/UserContext';
import {getLibelleSIVByCode} from '../../../common/Utils';
import {ReponseCommonSIV} from '../../detailsDossier/components/vueEdiSIV/reponseSIV/ReponseCommonSIV';

interface ParamsSivType {
  tokenSiv?: string,
  urlParams?: string,
}

interface ResponseOKProps {
  menu: string,
  response: AxiosResponse,
  fromPath?: string,
  token?: string
}

interface ResponseKOProps {
  fromPath?: string,
  token?: string
}

/** Design */
const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    border: 'none',
    marginTop: '32px',
    opacity: '1',
  },
  center: {
    textAlign: 'left' as const,
  },
  card15: {
    marginTop: '16px',
  },
  maincard: {
    marginTop: '16px',
  },
  back: {
    textAlign: 'left' as const,
    height: '40px',
  },
  header: {
    display: 'inline-block',
  },
  buttonReturn: {
    textAlign: 'left' as const,
    padding: '5px',
    fontSize: '16px',
    letterSpacing: '0px',
    color: theme.palette.link.main,
    border: 'none',
    textTransform: 'initial' as const,
  },
});

const base64 = require('base-64');

const getTabMenu = (hasRoleSIVConsultations: boolean, hasRoleSIVDeclarations: boolean) => {
  let menuTab = '';
  const fromSessionStorage = sessionStorage.getItem('tabSiv');
  if (fromSessionStorage) {
    menuTab = fromSessionStorage;
  } else if (hasRoleSIVConsultations) {
    menuTab = 'IT';
  } else if (hasRoleSIVDeclarations) {
    menuTab = 'DA';
  }
  return menuTab;
};

const ReponseOK = (props: ResponseOKProps) => {
  const {menu, response, fromPath, token} = props;
  return <ReponseCommonSIV
    sIVReqRepModel={{content: response.data, from: 'formulaire', fromPath: fromPath, token: token}}
    formulaire={menu}
  />;
};

const ResponseKO = (props: ResponseKOProps) => {
  const {fromPath, token} = props;
  const classes = useEmotionStyles(styles);
  return <div className={classes.root}>
    <Grid container justifyContent="center" spacing={1}>
      <Grid item style={{width: '22%', paddingRight: '20px'}}>
        <div className={classes.back}>
          {fromPath === '/extranet/portail-sinapps-auto' &&
            <Button
              className={classes.buttonReturn}
              variant="outlined"
              onClick={() => window.location.reload()}>
              <ArrowBackIcon/>Retour
            </Button>
          }
          {(fromPath === '/extranet/dossiers' || location.pathname === '/extranet/dossiers/formulaires-siv') ?
            <ButtonHome/> :
            <RetourDossier isLink={true} token={token}/>
          }
        </div>
      </Grid>
      <Grid item style={{width: '70%'}}>
        <Typography variant={'h1'}>Erreur lors du traitement de la demande</Typography>
        <Typography style={{marginTop: '15px'}}>
          Une erreur est survenue, merci de réessayer ultérieurement.
        </Typography>
      </Grid>
    </Grid>
  </div>
};

export const SIVForm = () => {
  const classes = useEmotionStyles(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<keyof ParamsSivType>() as ParamsSivType;
  const {user} = React.useContext(UserContext);
  const isLienTransparent = user.isTransparent;
  const tokenSiv = !isLienTransparent ? params.tokenSiv : '';
  const tokenAccesTransparent = isLienTransparent ? params.urlParams : '';
  const tokenAccesTransparentParsed = tokenAccesTransparent ? JSON.parse(base64.decode(tokenAccesTransparent)) : '';
  const hasRoleSIVConsultations = useHasRole(permittedRolesSIVConsultations);
  const hasRoleSIVDeclarations = useHasRole(permittedRolesSIVDeclarations);
  const hasAccess = () => {
    if (isLienTransparent) {
      if (tokenAccesTransparentParsed.form === 'IT' || tokenAccesTransparentParsed.form === 'SA' ||
        tokenAccesTransparentParsed.form === 'CT') {
        return hasRoleSIVConsultations
      }
      return hasRoleSIVDeclarations
    } else {
      return hasRoleSIVConsultations || hasRoleSIVDeclarations
    }
  };
  const tabSivInitialState = getTabMenu(hasRoleSIVConsultations, hasRoleSIVDeclarations);
  const [menu, setMenu] = useState<string>(isLienTransparent ? tokenAccesTransparentParsed.form : tabSivInitialState);
  const [response, setResponse] = useState<AxiosResponse>();

  const fromPath = sessionStorage.getItem('fromPath') ?? undefined;

  const onChangeMenu = (menu: string) => {
    setMenu(menu)
  };

  const onChangeResponse = (resp: AxiosResponse) => {
    setResponse(resp)
  };

  const titleSIV = () => {
    switch (tokenAccesTransparentParsed.form) {
      case 'CT':
      case 'IT':
      case 'SA':
        return 'Consultation';
      case 'DA':
      case 'DC':
      case 'IR':
        return 'Déclaration';
      default:
        return '';
    }
  };

  return (
    <div>
      {hasAccess() && !response &&
        <div className={classes.root}>
          <Grid container justifyContent="center" spacing={1}>
            {!isLienTransparent &&
              <Grid item style={{width: '22%', paddingRight: '20px'}}>
                <div>
                  <div className={classes.back}>
                    {fromPath !== '/extranet/portail-sinapps-auto' &&
                      (fromPath === '/extranet/dossiers' || location.pathname === '/extranet/dossiers/formulaires-siv' ?
                        <ButtonHome/> :
                        <RetourDossier isLink={true} token={tokenSiv}/>)
                    }
                  </div>
                  <div className={classes.card15}>
                    <MenuFormsSIV
                      onChangeMenu={onChangeMenu}
                      activeMenu={menu}
                      hasRoleSIVConsultations={hasRoleSIVConsultations}
                      hasRoleSIVDeclarations={hasRoleSIVDeclarations}
                    />
                  </div>
                </div>
              </Grid>
            }
            <Grid item style={{width: '70%'}}>
              <div className={classes.center}>
                <div className={classes.header}>
                  <Typography variant={'h1'}>
                    {isLienTransparent && titleSIV() +
                      ' SIV - ' + getLibelleSIVByCode(tokenAccesTransparentParsed.form)}
                    {!isLienTransparent && 'Gestion SIV'}
                  </Typography>
                </div>
                <div className={classes.maincard}>
                  <ContentFormsSIV
                    menu={menu}
                    onChangeResponse={onChangeResponse}
                    dataAccesTransparent={tokenAccesTransparentParsed}
                    isTransparent={user.isTransparent}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
      {hasAccess() && response && response.status === 200 &&
        <ReponseOK
          menu={menu}
          response={response}
          fromPath={fromPath}
          token={tokenSiv}
        />
      }
      {hasAccess() && response && response.status !== 200 &&
        <ResponseKO
          fromPath={fromPath}
          token={tokenSiv}
        />
      }
      {!hasAccess() &&
        <AccessDenied
          message={'Ce compte ne vous permet pas d\'accéder à la gestion SIV'}
          isReturnButton={!isLienTransparent}
          buttonLabel={'Retour'}
          onClickButton={() => navigate('/extranet/portail-sinapps-auto')}
        />
      }
    </div>
  )
};
