import React, {useEffect} from 'react'
import {getValueElement} from '../Utils';
import {Entete} from './Entete';

interface RequeteITProps {
  data: any,
}

export const RequeteIT = (props: RequeteITProps) => {
  const {data} = props

  useEffect(() => {
    document.title = 'Requête IT';
  }, []);

  return (
    <div style={{columnGap: '30px', width: '60%', margin: 'auto'}}>
      <Entete data={data} prefix={'ns1:'} formulaire={'IT'}
        withoutTitreContainer={true}
        refSinistre={getValueElement(data, 'ns1:', 'numero_sinistre')}
      />
    </div>
  )
};
