import AxiosService from '../services/AxiosService';
import {PartenaireProps} from '../model/priseRDV/PartenaireModel';

const baseUrl = window.extranet.globalConfig.apiUrl + '/api';
const axiosInstance = AxiosService.getAxiosClient();

interface DisponibilitesPartenaireProps {
  idPartenaire: string,
  idActivite: string,
  numeroSiret: string,
  numeroClientPartenaire: string
  numClient: string,
  userCompte: string,
  dateDebutDispo: string,
  dateFinDispo: string,
  userSiret: string,
}
/**
 * Retourne les disponibilites d'un partenaire passé en paramètre et les passages des experts
 *
 * @param {DisponibilitesPartenaireProps} props
 * - {String} idPartenaire    - Id du partenaire
 * - {String} idActivite      - Id de l'activité
 * - {String} numeroSiret     - Numéro Siret du partenaire
 * - {String} numeroClientPartenaire   - Numéro Client du partenaire
 * - {String} numClient       - Numéro client du user connecté
 * - {String} userCompte      - User connecté
 * - {String} dateDebutDispo  - Date Début du e disponibilités
 * - {String} dateFinDispo    - Date Fin du e disponibilités
 * - {String} userSiret       - Numéro Siret de l'utilisateur connecté
 * - {Promise} response
 * @example
 *     getDisponibilitesPartenaire({6094bbe33485aa55cbd874ca, 82145663900011, 46180,
 *     2022-09-26T14:30:00.000Z, 2022-10-02T21:59:59.999Z, 78145251100814})
 */
export async function getDisponibilitesPartenaire(props: DisponibilitesPartenaireProps) {
  const {idPartenaire, idActivite, numeroSiret, numeroClientPartenaire, numClient, userCompte,
    dateDebutDispo, dateFinDispo, userSiret} = props
  return axiosInstance(`${baseUrl}/getDisponibilitesPartenaire`,
      {
        params: {
          idPartenaire: idPartenaire,
          idActivite: idActivite,
          numeroSiret: numeroSiret,
          numeroClientPartenaire: numeroClientPartenaire,
          activiteType: 'AtelierReparation',
          dateDebutDispo: dateDebutDispo,
          dateFinDispo: dateFinDispo,
          userSiret: userSiret,
          numClient: numClient,
          userCompte: userCompte,
        },
      },
  );
}

interface ListeRDVProps {
  lat: number,
  lng: number,
  rayon: number,
  activiteSousType: string
  nbrDisposTotal: string,
  nonAgrees: boolean,
  agreePar: string,
  tri: string,
  numClient: string,
  userCompte: string,
  servicesProposes: string
}
/**
 * Retourne une liste de réparateurs disponibles en fonction des paramètres passés
 * @param {ListeRDVProps} props
 * - {Number} lat - Latitude
 * - {Number} lng - Longitude
 * - {Number} rayon - Rayon de recherche
 * - {String} activiteSousType - Sous-type d'activité du réparateur
 * - {String} servicesProposes - Service proposé par le réparateur
 * - {String} nbrDisposTotal - Nombre de dispos total retourné
 * - {Boolean} nonAgrees - Récupération ou non des partenaires non agréés
 * - {String} agreePar - Numéro SIRET de l'assureur connecté
 * - {String} tri - Critère de tri de la recherche
 * - {String} numClient - Numéro Client
 * - {String} userCompte - User connecté
 * @return {Promise} response
 * @example
 *     getListeRDV({46.2353635, -0.40775, 50, 4R, 10, 34513312800057})
 */
export async function getListeRDV(props: ListeRDVProps) {
  const {lat, lng, rayon, activiteSousType, nbrDisposTotal, nonAgrees,
    agreePar, tri, numClient, userCompte, servicesProposes} = props
  const service = servicesProposes !== '' ? {servicesProposes: servicesProposes} : '';
  const params = {...service};

  return axiosInstance(`${baseUrl}/getListeRDV`,
      {
        params: {
          lat: lat,
          lng: lng,
          rayon: rayon,
          activiteType: 'AtelierReparation',
          activiteSousType: activiteSousType,
          nbrDispo: nbrDisposTotal,
          nbrDispoParPartenaire: '1',
          agreePar: agreePar,
          nonAgrees: nonAgrees,
          tri: tri,
          numClient: numClient,
          userCompte: userCompte,
          ...params,
        },
      },
  );
}


/**
 * Retourne le détail rendez-vous
 *
 * @param {String} idRdv - id rendez-vous
 * @param {String} idPartenaire - id partenaire
 * @param {String} numClient - Numéro Client
 * @param {String} userCompte - User connecté
 * @return {Promise} response
 * @example
 *     getDetailRendezVous('63039d9f0eef4230989358f9', '5a0558ecaf0000b21890616b')
 */
export async function getDetailRendezVous(idRdv: string, idPartenaire: string, numClient: string, userCompte: string) {
  const response = await axiosInstance(`${baseUrl}/getDetailRendezVous`,
      {
        params: {
          idRdv: idRdv,
          idPartenaire: idPartenaire,
          numClient: numClient,
          userCompte: userCompte,
        },
      },
  );
  return response.data
}

/**
 * Retourne la liste des valeurs que peut prendre le paramètre Idigo passé en paramètre
 *
 * @param {String} param - Nom du paramètre
 * @return {Promise} response data
 *
 * @example
 *     getIdigoParamValues('SousActivites')
 */
export async function getIdigoParamValues(param: string) {
  const response = await axiosInstance(`${baseUrl}/getIdigoParamValues/${param}`);
  return response.data
}


/**
 * Envoie une création de rendez-vous (Prise de rdv)
 *
 * @param {Object} params - Objet contenant les paramètres du rendez-vous
 * @param {String} params.referenceSinistre - Référence du sinistre
 * @param {String} params.idPartenaire - Id du partenaire
 * @param {String} params.idActivite - Id de l'activité
 * @param {String} params.numeroSiret - Numéro siret du partenaire
 * @param {String} params.numeroAgrement - Numéro d'agrément du partenaire
 * @param {String} params.codeAbonne - Code abonné présent dans l'activité
 * @param {String} params.numClient - Numéro client du user connecté
 * @param {String} params.nomClient - Nom du user connecté (assureur)
 * @param {String} params.userCompte - User connecté
 * @param {String} params.activiteType - Type de l'activité choisie
 * @param {String} params.activiteSousType - Sous-type de l'activité choisie
 * @param {String} params.serviceCode - Code du service choisie
 * @param {String} params.codeGTA - Code GTA du user connecté
 * @param {Object} data - Objet contenant les données du rendez-vous
 * @return {Promise}
 * @example
 *     postRendezVous(params, data)
 */
export function postRendezVous(params: any, data: any) {
  return axiosInstance.post(
      `${baseUrl}/createRendezvous`,
      `${JSON.stringify(data)}`,
      {
        params: {
          ...params,
        },
      },
  )
}

interface UpdateRendezvousProps {
  idPartenaire: string,
  idRdv: string,
  idActivite: string,
  numClient: string
  userCompte: string,
  codeGTA: string,
  serviceCode: string,
  data: any,
  sendNotif: boolean,
  infosPartenaire?: PartenaireProps,
  nomClient?: string,
}
/**
 * Update les informations d'un rdv
 *
 * @param {UpdateRendezvousProps} props
 * - {String} idPartenaire - id du partenaire
 * - {String} idRdv - id du rendez-vous
 * - {String} idActivite - Id de l'activité
 * - {String} numClient - Numéro du client connecté
 * - {String} userCompte - User connecté
 * - {String} codeGTA - Code GTA de l'assureur
 * - {String} serviceCode - Code du service
 * - {Object} data - Objet contenant les données à mettre à jour
 * - {Boolean} sendNotif - Envoi ou non d'une notification
 * - {PartenaireProps} infosPartenaire - infos du partenaire
 * - {String} nomClient - Nom du client connecté
 *
 * @return {Promise} response
 */
export async function updateRendezvous(props: UpdateRendezvousProps) {
  const {idPartenaire, idRdv, idActivite, numClient, userCompte, codeGTA,
    serviceCode, data, sendNotif, infosPartenaire, nomClient} = props
  return await axiosInstance.patch(
      `${baseUrl}/updateRendezvous`,
      `${JSON.stringify(data)}`,
      {
        params: {
          idPartenaire: idPartenaire,
          idRendezVous: idRdv,
          idActivite: idActivite,
          numClient: numClient,
          userCompte: userCompte,
          codeGTA: codeGTA,
          serviceCode: serviceCode,
          adressePartenaire: JSON.stringify(infosPartenaire?.adresse),
          coordonneesPartenaire: JSON.stringify(infosPartenaire?.coordonnees),
          raisonSocialePartenaire: JSON.stringify(infosPartenaire?.raisonSociale),
          sendNotif: sendNotif,
          nomClient: nomClient,
        },
      },
  );
}

interface RefusRdvProps {
  idRdv: string,
  idPartenaire: string,
  message: string,
  mailAss: string
  telAss: string,
  mailRep: string,
  mailAuth: boolean,
  telAuth: boolean,
  codeGTA: string,
  numClient: string,
  nomClient: string,
  userCompte: string,
  serviceCode: string,
  referenceSinistre: string,
  idActivite: string
}
/**
 * Update le statut du rendez-vous à Refuse et notifie l'assuré / réparateur par mail du refus du rendez-vous
 * liste des statuts : (EnAttente, Refuse, Accepte, Realiser, NonRealiser)
 *
 * @param {RefusRdvProps} props
 * - {String} idRdv - id rendez-vous
 * - {String} idPartenaire - id partenaire
 * - {String} message - message de notification à l'assuré / réparateur
 * - {String} mailAss - email de l'assuré
 * - {String} telAss - téléphone de l'assuré
 * - {String} mailRep - email du réparateur
 * - {String} codeGTA - Code GTA du user connecté
 * - {Boolean} mailAuth - autorisation envoi email
 * - {Boolean} telAuth - autorisation envoi sms
 * - {String} numClient - Numéro Client
 * - {String} nomClient - Nom Client
 * - {String} userCompte - User connecté
 * - {String} serviceCode - Code du service
 * - {String} referenceSinistre - Référence sinistre
 * - {String} idActivite - Id de l'activité
 * @return {Promise} response
 */
export async function refusRdv(props: RefusRdvProps) {
  const {idRdv, idPartenaire, message, mailAss, telAss, mailRep,
    mailAuth, telAuth, codeGTA, numClient, nomClient,
    userCompte, serviceCode, referenceSinistre, idActivite} = props
  const activite = idActivite !== '' ? {idActivite: idActivite} : '';
  const params = {...activite};
  return axiosInstance(`${baseUrl}/refusRdv`,
      {
        params: {
          idRdv: idRdv,
          idPartenaire: idPartenaire,
          message: message,
          mailAssure: mailAss,
          telAssure: telAss,
          mailReparateur: mailRep,
          emailAutorisation: mailAuth,
          telAutorisation: telAuth,
          codeGTA: codeGTA,
          numClient: numClient,
          nomClient: nomClient,
          userCompte: userCompte,
          serviceCode: serviceCode,
          referenceSinistre: referenceSinistre,
          ...params,
        },
      },
  );
}
