import React, {useEffect, useState} from 'react';
import {Attachment} from './FilesDragAndDrop'
import AttachmentForm from './AttachmentForm';
import {getValeursCodes} from '../../../../api/norme';

export interface AttachmentsProps {
  attachments: Attachment[]
  onSaveAttachments: (attachments: Attachment[]) => void,
  onDeleteAttachment: (attachments: Attachment[]) => void,
}

const AttachmentsForms = (props: AttachmentsProps) => {
  const {attachments, onSaveAttachments, onDeleteAttachment} = props;
  const [codesValeursNaturePieceJointe, setCodesValeursNaturePieceJointe] = useState([]);
  const [codesValeursTypePieceJointe, setCodesValeursTypePieceJointe] = useState([]);
  const [expanded, setExpanded] = useState<string>('panel-0');
  const [attachmentsList, setAttachmentsList] = useState<Attachment[]>(attachments);

  useEffect(() => {
    getValeursCodes('AR2', '93900101').then((result) => setCodesValeursNaturePieceJointe(result));
    getValeursCodes('AR2', '93910101').then((result) => setCodesValeursTypePieceJointe(result))
  }, []);

  useEffect(() => {
    setAttachmentsList(attachments);
    const panel = `panel-${findFirstAttachmentNotCompleted(attachments)?.id}`;
    setExpanded(panel)
  }, [attachments]);

  const handleChangePanel = (panel: string) => {
    setExpanded(expanded !== panel ? panel : '')
  };

  const findFirstAttachmentNotCompleted = (listAttachments: Attachment[]) => {
    return listAttachments.find((attachment) => !attachment.completed)
  };

  const onSaveForm = (pj: Attachment) => {
    pj.completed = true;
    const attachmentsTemporary: Attachment[] = [...attachmentsList];
    attachmentsTemporary.splice(pj.id, 1, pj);
    setAttachmentsList(attachmentsTemporary);
    onSaveAttachments([...attachmentsTemporary]);
    const panel = `panel-${findFirstAttachmentNotCompleted([...attachmentsTemporary])?.id}`;
    setExpanded(panel)
  };

  const handleDeleteAttachment = (currentAttachment: Attachment) => {
    const attachmentsCopy: Attachment[] = [...attachmentsList].filter((attachment) =>
      attachment.id != currentAttachment.id,
    );
    attachmentsCopy.forEach((attachment, index) => {
      attachment.id = index
    });
    setAttachmentsList([...attachmentsCopy]);
    onDeleteAttachment([...attachmentsCopy])
  };

  return (
    <div>
      {attachmentsList.map((attachment: Attachment) => {
        return (
          <div key={attachment.id} style={{marginTop: '16px'}} id={'panel-' + attachment.id}>
            <AttachmentForm
              expanded={expanded === `panel-${attachment.id}`}
              attachment={attachment}
              codesValeursNaturePieceJointe={codesValeursNaturePieceJointe}
              codesValeursTypePieceJointe={codesValeursTypePieceJointe}
              onChangePanel={(panel) => handleChangePanel(panel)}
              onSave={onSaveForm}
              onDelete={handleDeleteAttachment}
            />
          </div>
        )
      })}
    </div>
  );
};

export default AttachmentsForms;
