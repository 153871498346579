import React, {useEffect, useState} from 'react';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {Modal, Slide, Typography} from '@mui/material';
import {FormikValues, useFormikContext} from 'formik';
import {ButtonLink} from '../../../../common/buttons/ButtonLink';
import SlideForm from '../../../components/slide/SlideForm';
import {ReactComponent as IconEye} from '../../../../../images/icones/icn-eye.svg';
import {ReactComponent as IconAdd} from '../../../../../images/icones/icn-add.svg';
import DommagesForms from '../../components/inputDommagesField/DommagesForms';
import {DommagesFooter} from '../../components/inputDommagesField/DommagesFooter';
import {dommageInitialState, lustrageCases} from '../../model/ConstantsPECBG';
import {IDommage} from '../../components/inputDommagesField/Models';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 252px',
  },
  red: {
    color: 'red',
  },
  error: {
    fontSize: '12px',
    color: theme.palette.red.main,
  },
  icnBlue: {
    fill: theme.palette.link.main,
    marginRight: '4px',
  },
  bold: {
    fontWeight: 'bold',
  },
});

const DommagesBloc = () => {
  const classes = useEmotionStyles(styles);
  const {values, errors, setFieldValue, touched, setTouched, setErrors, submitCount}: FormikValues = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const [dommagesTmp, setDommagesTmp] = useState([...values.dommages]);
  const [idExpanded, setIdExpanded] = useState<number>(0);
  const [submitCountPreview] = useState<number>(submitCount);
  const [erreurLustrage, setErreurLustrage] = useState(false);
  const [erreurDommages, setErreurDommages] = useState(false);
  const nbDommages = values.dommages.length;

  useEffect(() => {
    if (!lustrageCases.includes(values.infosVehicule.assureurGTA)) {
      if (values.dommages.filter((dommage: IDommage) => !dommage.completed).length > 0) {
        setErreurLustrage(true)
      }
    } else {
      setErreurLustrage(false);
      if (values.dommages.filter((dommage: IDommage) => !dommage.completed).length > 0) {
        setErreurDommages(true)
      } else {
        setErreurDommages(false)
      }
    }
  }, [values.infosVehicule.assureurGTA]);

  useEffect(() => {
    if (values.dommages.filter((dommage: IDommage) => !dommage.completed).length === 0) {
      setErreurLustrage(false);
      setErreurDommages(false);
    }
  }, [values.dommages]);

  // Gestion de l'icone du bouton
  const getButtonIcon = () => {
    if (values.dommages.length > 0) {
      return <IconEye className={classes.icnBlue}/>
    } else {
      return <IconAdd className={classes.icnBlue}/>
    }
  };
  // Gestion du libellé du bouton
  const libelleLink = nbDommages > 0 ? 'Gérer les dommages (' + nbDommages + ')' : 'Ajouter des dommages';

  // Ouverture de la modal
  const onClickOpenModal = () => {
    if (values.dommages.length === 0) {
      const newDommage = {...dommageInitialState};
      setFieldValue('dommages', [newDommage]);
      setIdExpanded(0);
    }
    setShowModal(!showModal)
  };

  // Fermeture de la modal
  const onCloseModal = () => {
    const result = window.confirm('Si vous n\'avez pas enregistré vos modifications, elles seront perdues.\n' + '\n' +
      'Souhaitez-vous continuer ?');
    if (result) {
      setFieldValue('dommages', dommagesTmp);

      if (touched?.dommages) {
        const touchedCopy = JSON.parse(JSON.stringify(touched));
        touchedCopy.dommages = [];
        setTouched(touchedCopy, false);
      }
      if (errors?.dommages) {
        const errorsCopy = JSON.parse(JSON.stringify(errors));
        errorsCopy.dommages = [];
        setErrors(errorsCopy, false);
      }

      setShowModal(!showModal)
    }
  };

  // Ajout d'un dommage
  const newDommage = {...dommageInitialState, id: values.dommages.length};
  const addDommage = () => {
    setFieldValue('dommages', [...values.dommages, newDommage]);
    setIdExpanded(newDommage.id)
  };

  // Enregistrement des dommages
  const onSaveDommages = () => {
    setDommagesTmp(values.dommages);
    setShowModal(false);
    setIdExpanded(-1);
  };

  // Body de la modal
  const childrenBody = (
    <DommagesForms
      idExpanded={idExpanded}
      setIdExpanded={setIdExpanded}
      onCloseModal={onCloseModal}
    />
  );
  // Footer de la modal
  const childrenFooter = (
    <DommagesFooter
      addDommage={addDommage}
      onSaveAll={onSaveDommages}
    />
  );

  return (
    <>
      <div className={classes.cardContent}>
        <div className={classes.grid}>
          <Typography variant={'h3'}>Description des dommages <span className={classes.red}>*</span></Typography>
          <ButtonLink
            id={'bouton-gerer-dommage'}
            libelle={libelleLink}
            isLibelleUpperCase={true}
            isLibelleBold={true}
            icon={getButtonIcon()}
            onClick={onClickOpenModal}
          />
        </div>
        {submitCountPreview < submitCount && errors?.dommages && typeof errors.dommages === 'string' &&
          <Typography id={'erreur-dommage'} className={classes.error}>
            {errors.dommages}
          </Typography>
        }
        {erreurLustrage &&
          <Typography className={classes.error}>
            Le type d&apos;intervention <span className={classes.bold}>&#171;lustrage&#187;</span> n&apos;est
            pas disponible pour l&apos;assureur choisi
          </Typography>
        }
        {erreurDommages && !showModal &&
          <Typography className={classes.error}>
            Il y a une erreur sur les dommages saisis
          </Typography>
        }
      </div>

      <Modal
        open={showModal}
        aria-labelledby="modal-dommages-PECBG"
        aria-describedby="modal-formulaire-envoie-messagePECBG"
      >
        <Slide in={showModal} direction={'left'} timeout={800} mountOnEnter unmountOnExit>
          <div>
            <SlideForm
              title={'Dommages'}
              onClose={onCloseModal}
              childrenBody={childrenBody}
              childrenFooter={childrenFooter}
            />
          </div>
        </Slide>
      </Modal>
    </>
  );
};

export default DommagesBloc;
