import React, {useMemo, useReducer} from 'react';
import {SearchDataState, searchReducer} from '../reducers/SearchReducer'
import {SearchActions, getSearchActions} from '../action/search.action'

interface SearchContextProviderProps {
  children: React.ReactNode,
}

const initialState: SearchDataState = {
  dispatch: () => undefined,
  actions: {} as SearchActions,
};

const SearchContext = React.createContext(initialState);

export const SearchContextProvider = (props : SearchContextProviderProps) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const actions = getSearchActions(state, dispatch);
  const valueSearchContextProvider = useMemo(() => ({...state, dispatch, actions}), [dispatch, actions]);

  return (
    <SearchContext.Provider value={valueSearchContextProvider}>
      {props.children}
    </SearchContext.Provider>
  )
};

export const useSearchContext = () => React.useContext(SearchContext);
