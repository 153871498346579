import React, {useEffect, useState} from 'react';
import {AppBar, Box, Button, IconButton, MenuItem, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import {ReactComponent as SortDownIcon} from '../../../../images/icones/icn-sort-down.svg'
import {ReactComponent as SortUpIcon} from '../../../../images/icones/icn-sort-up.svg'
import RefreshIcon from '@mui/icons-material/Refresh';
import {useMissionContext} from '../../../../store/MissionContext';
import {PanelProps} from '../Details';
import {ItemHistoriqueModel} from '../../../../model/detailsDossier/ItemHistoriqueModel';
import moment from 'moment';
import {SimplifiedBlocHistorique} from './SimplifiedBlocHistorique';
import {HistoriqueProps} from './Historique';
import {UserContext} from '../../../../store/UserContext';
import {ActeurModel} from '../../../../model/detailsDossier/ActeurModel';
import {TextFieldSelect} from '../../../common/formsComponents/TextFieldSelect';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';

/** Design */
const styles = (theme: Theme) => ({
  appbar: {
    flexGrow: 1,
    boxShadow: 'none',
    marginTop: '20px',
  },
  tabs: {
    padding: '0rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    fontSize: '1rem',
  },
  tabPanel: {
    padding: '0px',
    marginTop: '15px',
  },
  main: {
    lineHeight: '55px',
  },
  containerPrincipal: {
    border: '1px solid ' + theme.palette.secondary.dark1,
    borderRadius: '8px',
    opacity: '1',
    padding: '15px',
    margin: '30px 0px',
  },
  filtres: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  search: {
    paddingBottom: '10px',
    color: theme.palette.secondary.dark2,
  },
  textField: {
    width: 300,
    marginRight: theme.spacing(1),
  },
  noMessage: {
    textAlign: 'center' as const,
  },
  iconRoot: {
    margin: '0px',
  },
  buttonDate: {
    padding: '0px 30px 0px 15px',
  },
  tab: {
    '&.MuiTab-root': {
      'textTransform': 'none' as const,
      'textAlign': 'left' as const,
      'fontWeight': 'bold',
      'fontSize': '16px',
      'letterSpacing': '0px',
      'opacity': '1',
      'minHeight': '48px',
      'color': theme.palette.link.main,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: 'inherit',
      },
      '&:disabled': {
        color: theme.palette.disabled.main,
      },
    },
    '&.Mui-selected': {},
  },
});

const NoMessage = (props: {messagesList: ItemHistoriqueModel[]}) => {
  const classes = useEmotionStyles(styles);
  const {messagesList} = props;
  return messagesList?.length === 0 ?
      <div className={classes.noMessage}><Typography>Aucun résultat</Typography></div> : <></>
};

const Content = (props: {messagesList: ItemHistoriqueModel[],
  onClickPj: (tabPanelValue: number, idPJ: string) => void,
  onClickComment: ((tabPanelValue: number, date: string) => void) | undefined}) => {
  const {messagesList, onClickPj, onClickComment} = props;
  return (
    <React.Fragment>
      {messagesList?.map( (item: ItemHistoriqueModel, index: number) => {
        const keySimplifiedBlocHistorique = `${index}`
        return (
          <SimplifiedBlocHistorique
            key={keySimplifiedBlocHistorique}
            indexBubble={index} item={item} click={onClickPj} onClickComment={onClickComment}/>
        )
      })}
    </React.Fragment>
  )
};

const TabPanel = (props: PanelProps) => {
  const classes = useEmotionStyles(styles);
  const {value, index, children} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </div>
  )
};

export const getActeurName = (acteurList: ActeurModel[], code: string, baseName: string) => {
  const acteur = acteurList.find((acteur) => acteur.codeAbonne?.trim() === code.trim());
  return acteur?.libelle ?? baseName
};

export const SimplifiedHistorique = ({onClickPj, onClickComment, setMessagesLength}: HistoriqueProps) => {
  const classes = useEmotionStyles(styles);
  const {mission} = useMissionContext();
  const {user} = React.useContext(UserContext);
  const list = mission?.historiqueDTO?.list ?? [];
  const messages = list.filter((message) => message.typeCode !== '900501' ? message : '')
      .map((message: ItemHistoriqueModel) => {
        return message.envoyeRecu === 'envoye' ?
      {...message,
        destinataire: getActeurName(mission!.acteursDTO!.acteurs!, message.destinataireAbo!, message.destinataire)} :
      {...message,
        emetteur: getActeurName(mission!.acteursDTO!.acteurs!, message.emetteurAbo!, message.emetteur)}
      });
  const [messagesToDisplay, setMessagesToDisplay] = useState<ItemHistoriqueModel[]>(messages);
  const [value, setValue] = React.useState(0);
  const [typeMessage, setTypeMessage] = React.useState('');
  const [acteur, setActeur] = React.useState('');
  const [sortDate, setSortDate] = useState(1);
  const messagesSent = messagesToDisplay?.filter((message) => message.envoyeRecu === 'envoye' ? message : '');
  const messagesReceived = messagesToDisplay?.filter((message) => message.envoyeRecu === 'recu' ? message : '');

  useEffect(() => {
    if (setMessagesLength) {
      switch (value) {
        case 0:
          setMessagesLength(messagesToDisplay.length);
          break;
        case 1:
          setMessagesLength(messagesSent.length);
          break;
        case 2:
          setMessagesLength(messagesReceived.length);
          break;
      }
    }
  }, [value, messagesToDisplay]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value)
  };

  const handleChangeTypeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeMessage(event.target.value)
  };

  const handleChangeActeur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActeur(event.target.value)
  };

  const resetFilters = () => {
    setTypeMessage('');
    setActeur('');
    setSortDate(1);
    setMessagesToDisplay(messages);
  };

  const typesMessage: string[] = [];
  messages?.map((message) => {
    typesMessage.push(message.libelle.trim())
  });
  const listTypesMessage = Array.from(new Set([...typesMessage])).slice().sort((a, b) => {
    if (a > b) {
      return 1
    } else {
      return -1
    }
  });

  const acteurs: {codeAbo?: string, libelle: string}[] = [];
  messages?.forEach((message: ItemHistoriqueModel) => {
    if (message.emetteur !== '') {
      acteurs.push({codeAbo: message.emetteurAbo, libelle: message.emetteur.trim()});
    }
    if (message.destinataire !== '') {
      acteurs.push({codeAbo: message.destinataireAbo, libelle: message.destinataire.trim()});
    }
  });
  const acteursFiltres = Array.from(new Map(acteurs.map((acteur) => [acteur['codeAbo']?.trim(), acteur])).values());
  const listActeurs = acteursFiltres.slice().filter((acteur) => acteur.codeAbo !== user.codeAbo)
      .sort((a, b) => {
        if (a.libelle > b.libelle) {
          return 1
        } else {
          return -1
        }
      });

  const sortByDate = () => {
    setMessagesToDisplay([...messagesToDisplay].sort((a, b) => {
      const dateA = moment(a.dateReception?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
      const dateB = moment(b.dateReception?.replace(' - ', ''), 'DD/MM/YYYY HH:mm:ss').unix();
      return dateA > dateB ? sortDate : -sortDate
    }));
    setSortDate(-sortDate)
  };

  const changeFilters = () => {
    let result = messages;

    if (typeMessage!=='') {
      result = result?.filter((message) => message.libelle.trim() === typeMessage.trim() ? message : '');
      setMessagesToDisplay(result)
    }

    if (acteur!=='') {
      result = result?.filter((message) => (message.emetteurAbo?.trim() === acteur.trim() ||
        message.destinataireAbo?.trim() === acteur.trim()) ? message : '');
      setMessagesToDisplay(result)
    }

    if (typeMessage === '' && acteur === '') {
      setMessagesToDisplay(result)
    }
  };

  useEffect(() => {
    changeFilters()
  }, [typeMessage, acteur]);

  const a11yProps = (index : number) =>{
    return {
      'id': `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          indicatorColor="primary" textColor="inherit"
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{style: {backgroundColor: '#241E46', height: '3px'}}}
        >
          <Tab
            label="Tous les messages" {...a11yProps(0)} className={classes.tab}/>
          <Tab
            label="Envoyés" {...a11yProps(1)} disabled={messagesSent?.length === 0} className={classes.tab}/>
          <Tab
            label="Reçus" {...a11yProps(2)} disabled={messagesReceived?.length === 0} className={classes.tab}/>
        </Tabs>
      </AppBar>
      <div className={classes.main}>
        <form noValidate autoComplete="off">
          <div className={classes.containerPrincipal}>
            <Typography className={classes.search}>Rechercher vos messages</Typography>
            <div className={classes.filtres}>
              <div>
                <TextFieldSelect
                  className={classes.textField}
                  id={'typeMessage'}
                  name={'typeMessage'}
                  label={'Type de message'}
                  marginDense={true}
                  withEmptyItem={listTypesMessage.length > 1}
                  emptyItemLabel={'Tous'}
                  disabled={listTypesMessage.length === 1}
                  value={listTypesMessage.length !== 1 ? typeMessage : listTypesMessage[0]}
                  itemsList={listTypesMessage.map((type) => {
                    return (
                      <MenuItem key={type} value={type}>{type}</MenuItem>
                    )
                  })}
                  onChange={handleChangeTypeMessage}
                />
                <TextFieldSelect
                  className={classes.textField}
                  id={'acteur'}
                  name={'acteur'}
                  label={'Acteur'}
                  marginDense={true}
                  withEmptyItem={listActeurs.length > 1}
                  emptyItemLabel={'Tous'}
                  disabled={listActeurs?.length === 1}
                  value={listActeurs.length !== 1 ? acteur : listActeurs[0].codeAbo ?? ''}
                  itemsList={listActeurs.map((acteur) => {
                    return (
                      <MenuItem key={acteur.codeAbo} value={acteur.codeAbo}>{acteur.libelle}</MenuItem>
                    )
                  })}
                  onChange={handleChangeActeur}
                />
                <Button id={'filtreDateHisto'} className={classes.buttonDate} onClick={sortByDate}>
                  <div style={{display: 'flex', flexDirection: 'column', margin: '8px 0 0 2px',
                    opacity: '0.8'}}>
                    {sortDate === 1 ? <SortUpIcon /> : <SortDownIcon />}
                    <span>Date</span>
                  </div>
                </Button>
                <Tooltip disableInteractive title="Réinitialiser la recherche">
                  <IconButton id={'btnReinitRecherche'} onClick={() => resetFilters()}>
                    <RefreshIcon classes={{root: classes.iconRoot}} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </form>
      </div>
      <TabPanel value={value} index={0}>
        <React.Fragment>
          <NoMessage messagesList={messagesToDisplay}/>
          <Content messagesList={messagesToDisplay} onClickComment={onClickComment} onClickPj={onClickPj}/>
        </React.Fragment>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <React.Fragment>
          <NoMessage messagesList={messagesSent}/>
          <Content messagesList={messagesSent} onClickComment={onClickComment} onClickPj={onClickPj}/>
        </React.Fragment>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <React.Fragment>
          <NoMessage messagesList={messagesReceived}/>
          <Content messagesList={messagesReceived} onClickComment={onClickComment} onClickPj={onClickPj}/>
        </React.Fragment>
      </TabPanel>
    </div>
  )
};
