import React from 'react'
import {SituationAdministrative} from './SituationAdministrative';
import {Entete} from './Entete';

interface ReponseSAProps {
  formulaire: string,
  data: any,
  prefix: string
}

export const ReponseSA = (props: ReponseSAProps) => {
  const {formulaire, data, prefix} = props

  return (<div>
    <div style={{columnGap: '30px', marginTop: '30px', display: 'flex'}}>
      <Entete data={data} prefix={prefix} formulaire={formulaire}/>
      <SituationAdministrative data={data} prefix={prefix}/>
    </div>
  </div>)
};
