import React, {useContext, useState} from 'react';
import {Loader} from '../../../common/Loader';
import {ReactComponent as DownloadIcon} from '../../../../images/icones/btn-download-small.svg';
import {customTheme} from '../../../../common/GlobalTheme';
import {PhotoModel} from '../../../../model/detailsDossier/PhotoModel';
import orderBy from 'lodash/orderBy';
import {Button, Card, CardContent, IconButton, Link, Tooltip, Typography} from '@mui/material';
import Picture from './Picture';
import {AttachmentProps, downloadAllAttachments} from '../DownloadAttachments';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../store/UserContext';

interface AlbumActeursProps {
  acteur: string,
  groupeAuthor: _.Dictionary<PhotoModel[]>,
  referenceSinistre?: string,
  click: (id: string, album: PhotoModel[], index: number, photo: PhotoModel, from: string) => void,
}

/** Design */
const styles = (theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
    boxShadow: 'none',
    marginTop: '16px',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '16px',
  },
  lientitre: {
    cursor: 'pointer',
  },
  subTitle: {
    textAlign: 'left' as const,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: theme.palette.primary.main,
    opacity: '1',
  },
  date: {
    marginLeft: '8px',
    textAlign: 'left' as const,
    fontSize: '14px',
    letterSpacing: '0px',
    color: theme.palette.text.primary,
    opacity: '1',
    display: 'inline',
  },
  lineAlbum: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonPhoto: {
    padding: '4px',
    border: 'none',
  },
  loader: {
    display: 'flex',
    margin: '0px 0px 0px 15px',
  },
});

export const AlbumActeurs = ({groupeAuthor, acteur, referenceSinistre, click}: AlbumActeursProps) => {
  const classes = useEmotionStyles(styles);
  const {user} = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorDownload, setOnErrorDownload] = useState(false);
  const [isFilesInZip, setIsFilesInZip] = useState(true);
  const [isEndDownload, setIsEndDownload] = useState(false);
  const [nbFilesInZip, setNbFilesInZip] = useState(0);

  const acteurName = acteur !== 'Application FLASH' ? acteur : 'FLASH';
  const attachments = () => {
    const tab: AttachmentProps[] = [];
    groupeAuthor[acteur].forEach((photo) => {
      tab.push({
        id: photo.id,
        name: photo.name,
        date: photo.date?.substring(0, 10).replaceAll('/', ''),
      });
    });
    return tab;
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.title}>
          <Link key={'lientitre'} className={classes.lientitre} onClick={() => click(groupeAuthor[acteur][0].id,
              groupeAuthor[acteur], 0, groupeAuthor[acteur][0], 'acteur')}
          >
            <Typography id={'title-photo-album-acteur-' + acteurName.trim()} className={classes.subTitle}>
              Album photo {acteurName}
            </Typography>
          </Link>
          <Typography id={'info-photo-album-acteur-' + acteurName.trim()} className={classes.date}>
            {groupeAuthor[acteur]?.length} photo{groupeAuthor[acteur]?.length>1 ? 's' : ''} - dernière photo
            déposée le {orderBy(groupeAuthor[acteur], 'date', 'desc')[0].date?.replace('-', 'à')}
          </Typography>
        </div>
        {groupeAuthor[acteur] &&
          <div className={classes.lineAlbum}>
            <div>
              {groupeAuthor[acteur]?.slice(0, 9).map((image, index2) => {
                return (
                  <Button key= {image.id} type="button" className={classes.buttonPhoto}
                    onClick={() => click(image.id, groupeAuthor[acteur], index2, image, 'acteur')}
                  >
                    <Picture imgId={image.id}/>
                  </Button>
                )
              })}
            </div>
            {isLoading ?
              <Loader className={classes.loader} size={20}/> :
                <Tooltip disableInteractive title='Télécharger' PopperProps={{disablePortal: true}}>
                  <IconButton style={{marginLeft: '5px', width: '50px', height: '50px'}}
                    onClick={() => downloadAllAttachments({attachments: attachments(),
                      author: acteurName || '', typeDoc: '',
                      referenceSinistre: referenceSinistre ?? '', numClient: user.numClient, setIsLoading,
                      setIsEndDownload, setIsFilesInZip, setOnErrorDownload, setNbFilesInZip})}
                  >
                    <DownloadIcon style={{fill: customTheme.palette.link.main}}/>
                  </IconButton>
                </Tooltip>
            }
            {onErrorDownload &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Erreur lors du téléchargement
              </Typography>
            }
            {!isFilesInZip &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                Aucune photo valide à télécharger
              </Typography>
            }
            {isFilesInZip && isEndDownload && nbFilesInZip !== groupeAuthor[acteur]?.length &&
              <Typography style={{color: 'red', fontSize: '14px'}}>
                {nbFilesInZip}
                {nbFilesInZip === 1 ? ' image téléchargée' : ' images téléchargées'} sur {groupeAuthor[acteur]?.length}
              </Typography>
            }
          </div>
        }
      </CardContent>
    </Card>
  )
};
