import produce from 'immer'
import {Dispatch} from 'react'

import {DetailsModel} from '../model/detailsDossier/DetailsModel'
import {MissionActions} from '../action/mission.action'

type MissionActionType =
    | 'SET_MISSION'
    | 'SET_ERREUR'

export interface MissionAction {
    type: MissionActionType
    mission?: DetailsModel
    error? : string
}

export interface MissionDataState {
    mission?: DetailsModel
    error?: string
    dispatch: Dispatch<MissionAction>
    actions: MissionActions
}


export const missionReducer = (state: MissionDataState, action: MissionAction) => {
  switch (action.type) {
    case 'SET_MISSION':
      return produce(state, (draftState) => {
        draftState.mission = action.mission
        return draftState
      })
    case 'SET_ERREUR':
      return produce(state, (draftState) => {
        draftState.error = action.error
        return draftState
      })
    default:
      return {...state}
  }
}

export interface MissionReducerState {
  missionReducer: MissionDataState
}
