import {useNavigate} from 'react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React from 'react'
import {Link} from 'react-router-dom';
import {ButtonBlue} from './ButtonBlue';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../common/useEmotionStyles';

interface ButtonRetourDossierProps {
  classNameButton?: string,
  classNameLink?: string,
  isButton?: boolean,
  isLink?: boolean,
  token?: string,
}

/** Design */
const styles = (theme: Theme) => ({
  linkReturn: {
    'textDecoration': 'none',
    'color': theme.palette.link.main,
    'opacity': '1',
    'fontSize': '16px',
    'fontWeight': 500,
    'display': 'flex',
    'alignItems': 'center',
    'padding': '5px',
    'width': 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

export const RetourDossier = ({classNameButton, classNameLink, isButton, isLink, token}: ButtonRetourDossierProps) => {
  const navigate = useNavigate()
  const classes = useEmotionStyles(styles)

  return (
    <>
      {isButton &&
        <ButtonBlue
          id={'boutonRetourDossier'}
          className={classNameButton}
          libelle={'Retour au dossier'}
          onClick={() => {
            navigate('/extranet/dossiers/mission/' + token)
            sessionStorage.removeItem('tabSiv')
            sessionStorage.removeItem('tokenMission')
          }}
        />
      }
      {isLink &&
        <Link className={`${classes.linkReturn} ${classNameLink}`} to={'/extranet/dossiers/mission/' + token}
          onClick={() => sessionStorage.removeItem('tabSiv')}>
          <ArrowBackIcon/>Retour
        </Link>
      }
    </>
  )
}
