import React, {useMemo, useReducer} from 'react'
import {MissionDataState, missionReducer} from '../reducers/MissionReducer'
import {MissionActions, getMissionActions} from '../action/mission.action'

interface MissionContextProviderProps {
  children: React.ReactNode,
}

const initialState: MissionDataState = {
  dispatch: () => undefined,
  actions: {} as MissionActions,
};

const MissionContext = React.createContext(initialState);

export const MissionContextProvider = (props: MissionContextProviderProps) => {
  const [state, dispatch] = useReducer(missionReducer, initialState);
  const actions = getMissionActions(state, dispatch);
  const valueMissionContextProvider = useMemo(() => ({...state, dispatch, actions}), [dispatch, actions]);

  return (
    <MissionContext.Provider value={valueMissionContextProvider}>
      {props.children}
    </MissionContext.Provider>
  )
};

export const useMissionContext = () => React.useContext(MissionContext);
