import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {ButtonBlue} from '../../../common/buttons/ButtonBlue';
import {InfosRendezVousModel} from '../../../../model/detailsDossier/RendezVousModel';
import moment from 'moment';
import {RdvCard} from './RdvCard';
import {ButtonSeeMore} from '../../../common/buttons/ButtonSeeMore';
import {ButtonSeeLess} from '../../../common/buttons/ButtonSeeLess';
import {useNavigate} from 'react-router-dom';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../common/useEmotionStyles';
import {UserContext} from '../../../../store/UserContext';

interface RendezVousProps {
  idigoRendezVous?: InfosRendezVousModel[],
  refSinistre?: string,
}

/** Design */
const styles = (theme: Theme) => ({
  container: {
    'maxHeight': '400px',
    'overflowY': 'auto' as const,
    'paddingRight': '5px',
    /* Firefox */
    'scrollbarWidth': 'thin' as const,
    'scrollbarColor': '#c1c1c1 #f1f1f1',
    /* Chrome */
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
      borderRadius: '40px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '40px',
      border: '1px solid transparent',
      backgroundClip: 'padding-box',
    },
  },
  mois: {
    color: theme.palette.secondary.dark,
    marginBottom: '10px',
  },
  bloc: {
    display: 'flex',
    columnGap: '5px',
    marginTop: '-15px',
    marginBottom: '20px',
  },
  today: {
    flexGrow: 1,
    flexBasis: '0px',
    color: theme.palette.link.main,
    fontWeight: 500,
    fontSize: '14px',
  },
  repere: {
    flexGrow: 3,
    flexBasis: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  dot: {
    height: '9px',
    width: '9px',
    backgroundColor: theme.palette.link.main,
    borderRadius: '10px',
  },
  line: {
    borderTop: '2px dotted ' + theme.palette.link.main,
    borderBottom: 'none',
    color: theme.palette.link.main,
    width: '100%',
  },
  button: {
    marginTop: '5px',
    marginBottom: '25px',
  },
  footer: {
    textAlign: 'center' as const,
  },
});

export const ContentRendezVous = ({listRdv, refSinistre,
  marginDense = false}: {listRdv?: InfosRendezVousModel[], refSinistre?: string, marginDense?: boolean}) => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const navigate = useNavigate();
  const [rendezVousList, setRendezVousList] = useState<InfosRendezVousModel[]>();
  const [seeMore, setSeeMore] = useState(false);
  const [nbRdvDisplay, setNbRdvDisplay] = useState(2);

  const searchRdv = {
    referenceSinistre: refSinistre,
  };

  useEffect(() => {
    updateRdvList()
  }, [listRdv]);

  const updateRdvList = () => {
    const rendezVousListCopy: InfosRendezVousModel[] = JSON.parse(JSON.stringify(listRdv));
    rendezVousListCopy?.sort((a, b) => {
      if (moment(a.dateDebut) > moment(b.dateDebut)) {
        return 1;
      } else {
        return -1;
      }
    });
    rendezVousListCopy?.map((rdv) => {
      rdv.dateMoisAnnee = moment(rdv.dateDebut).format( 'MMMM YYYY');
    });
    setRendezVousList(rendezVousListCopy);
  };

  const nextRdv = rendezVousList?.filter((rdv) => moment(rdv.dateDebut) >= moment())[0];

  const handleClickSeeMore = () => {
    setSeeMore(!seeMore);
    setNbRdvDisplay(rendezVousList!.length);
  };

  const handleClickSeeLess = () => {
    setSeeMore(!seeMore);
    setNbRdvDisplay(2);
  };

  return (
    <div style={{margin: marginDense ? '0px 5px' : '0px'}}>
      <div className={classes.container}>
        {rendezVousList?.slice(0, nbRdvDisplay).map((rdv, index) => {
          const keyDiv = `${index}`
          const keyRdvCard = `${index}`
          return (
            <div key={keyDiv}>
              {(index === 0 || (index > 0 && rdv.dateMoisAnnee !== rendezVousList[index-1].dateMoisAnnee)) &&
                <Typography className={classes.mois}>{rdv.dateMoisAnnee?.toUpperCase()}</Typography>
              }
              <RdvCard key={keyRdvCard} rdv={rdv} partenaire={rdv.partenaire} nextRdv={nextRdv}/>
            </div>
          )
        })}
        {!nextRdv && rendezVousList && (rendezVousList.length <= 2 || rendezVousList.length > 2 && seeMore) &&
          <div className={classes.bloc}>
            <Typography className={classes.today}>{moment().format('DD MMMM')}</Typography>
            <div className={classes.repere}>
              <div className={classes.dot}/>
              <hr className={classes.line}/>
            </div>
          </div>
        }
      </div>
      {rendezVousList && rendezVousList.length > 2 && !seeMore &&
        <ButtonSeeMore
          id={'bouton-voir-plus-rendez-vous'}
          className={classes.button}
          iconPosition={'left'}
          libelleButton={'Voir plus de rendez-vous'}
          onClick={handleClickSeeMore}
        />
      }
      {rendezVousList && rendezVousList.length > 2 && seeMore &&
        <ButtonSeeLess
          id={'bouton-voir-moins-rendez-vous'}
          className={classes.button}
          iconPosition={'left'}
          libelleButton={'Voir moins de rendez-vous'}
          onClick={handleClickSeeLess}
        />
      }
      <div className={classes.footer}>
        <ButtonBlue
          id={'accesRdv'} libelle={'Accéder aux RDV'}
          disabled={user.isTransparent}
          onClick={() => {
            sessionStorage.setItem('searchRdv', JSON.stringify(searchRdv));
            sessionStorage.setItem('fromPath', location.pathname);
            navigate('/extranet/dossiers/prise-rdv/rechercher-rdv')
          }}/>
      </div>
    </div>
  )
};

export const RendezVous = (props: RendezVousProps) => {
  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant={'h3'}>Rendez-vous</Typography>
          <ContentRendezVous listRdv={props.idigoRendezVous} refSinistre={props.refSinistre}/>
        </CardContent>
      </Card>
    </div>
  )
};
