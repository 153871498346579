import React, {ChangeEvent, useEffect, useState} from 'react';
import {AppBar, Box, Grid, Tab, Tabs} from '@mui/material'
import {SearchComponent} from './components/SearchComponent'
import {ResultComponent} from './components/ResultComponent'
import IndicateursSuivi from './components/Indicateurs/IndicateursSuivi';
import {IndicateurModel} from './components/Indicateurs/IndicateurCard';
import {getDocumentsEnAttente} from '../../api/dossier';
import {PanelProps} from '../detailsDossier/components/Details';
import {customTheme} from '../../common/GlobalTheme';
import {UserContext} from '../../store/UserContext';
import {permittedRolesDossiers, permittedRolesIndicateurs} from '../../constantes/roles/Roles';
import useHasRole from '../../hook/useHasRole';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../common/useEmotionStyles';

const styles = (theme: Theme) => ({
  grid: {
    marginTop: '2%',
    marginLeft: '6%',
    marginRight: '6%',
  },
  appbar: {
    flexGrow: 1,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.link.main,
    boxShadow: '0px 3px 6px ' + theme.palette.boxShadow.main,
    border: '1px solid ' + customTheme.palette.secondary.main,
    borderRadius: '8px',
  },
  tabs: {
    padding: '0rem',
    fontSize: '1rem',
    borderRadius: '8px',
  },
  tab: {
    'textTransform': 'none' as const,
    'fontSize': '16px',
    'letterSpacing': '0px',
    'opacity': '1',
    'minHeight': '48px',
    '&:disabled': {
      color: theme.palette.disabled.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  tabPanel: {
    padding: '0px',
    marginTop: '30px',
  },
  message: {
    color: customTheme.palette.tertiary.main,
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    opacity: '1',
  },
  button: {
    marginTop: '32px',
  },
});

const TabPanel = (props: PanelProps) => {
  const classes = useEmotionStyles(styles);
  const {value, index, children} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{width: '100%'}}
    >
      {value === index && (
        <Box p={3} className={classes.tabPanel}>
          {children}
        </Box>
      )}
    </div>
  )
};

export const Recherche: React.FC = () => {
  const classes = useEmotionStyles(styles);
  const {user} = React.useContext(UserContext);
  const hasRoleDossiers = useHasRole(permittedRolesDossiers);
  const hasRoleIndicateurs = useHasRole(permittedRolesIndicateurs);
  const {numClient, userCompte, service} = user;
  const [documentsEnAttenteList, setDocumentsEnAttenteList] = useState<IndicateurModel[]>();
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    if (tabValue === 1) {
      getDocumentsEnAttente(numClient, userCompte, service).then((data) => {
        if (data.documentsEnAttente && data.documentsEnAttente.length > 0) {
          setDocumentsEnAttenteList(sortIndicatorsByOccurrences(data.documentsEnAttente))
        }
      }).catch(console.error)
    }
  }, [tabValue]);

  const sortIndicatorsByOccurrences = (indicateursList: IndicateurModel[]) => {
    const listCopy = [...indicateursList];
    listCopy.sort((a, b) =>
            (a.nombreOccurences > b.nombreOccurences) ? 1 : -1);
    return listCopy
  };

  const handleChangeTabValue = (event: ChangeEvent<{}>, value: number) => {
    setTabValue(value)
  };

  const a11yProps = (index: number) => {
    return {
      'id': `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <div className={classes.grid}>
      { (hasRoleDossiers || hasRoleIndicateurs) &&
        <>
          <Grid container>
            <>
              <AppBar position="static" className={classes.appbar}>
                <Tabs
                  indicatorColor="primary" textColor="inherit"
                  className={classes.tabs}
                  value={tabValue}
                  onChange={handleChangeTabValue}
                  variant="fullWidth"
                  TabIndicatorProps={{style: {display: 'none'}}}
                >
                  {
                    hasRoleDossiers && <Tab
                      className={classes.tab}
                      style={{borderRadius: '8px 0px 0px 8px'}}
                      label="Dossiers"
                      {...a11yProps(0)}/>
                  }
                  {
                    hasRoleIndicateurs && <Tab
                      className={classes.tab}
                      style={{borderRadius: '0px 8px 8px 0px'}}
                      label="Indicateurs"
                      {...a11yProps(hasRoleDossiers ? 1 : 0)}/>
                  }
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <>
                  {
                    hasRoleDossiers && <>
                      <Grid item md={12}>
                        <SearchComponent/>
                      </Grid>
                      < Grid item md={12} sm={12} xs={12}>
                        <ResultComponent/>
                      </Grid>
                    </>
                  }
                </>
              </TabPanel>
              <TabPanel value={tabValue} index={hasRoleDossiers ? 1 : 0}>
                <>
                  {
                    hasRoleIndicateurs && <Grid item md={12}>
                      <IndicateursSuivi indicateurModelList={documentsEnAttenteList}/>
                    </Grid>
                  }
                </>
              </TabPanel>
            </>
          </Grid>
        </>
      }
    </div>
  )
};
