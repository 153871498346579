import React from 'react';
import {Typography} from '@mui/material';
import {Theme} from '@emotion/react';
import useEmotionStyles from '../../../../../common/useEmotionStyles';
import {FormikValues, useFormikContext} from 'formik';
import LocaliteAutocompleteField from '../../../multiStepsForm/fields/LocaliteAutocompleteField';
import InputSelectDtField from '../../../multiStepsForm/fields/InputSelectDTField';
import {DOMAIN} from '../../../../../constantes/constantesNormeAuto';
import InputTextField from '../../../multiStepsForm/fields/InputTextField';

const styles = (theme: Theme) => ({
  cardContent: {
    backgroundColor: theme.palette.secondary.light,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    rowGap: '8px',
  },
  marginTop24: {
    marginTop: '24px',
  },
});

export const infosLieuExpertise = [
  '9',
  'A',
  'B',
  'C',
  'D',
];

const MissionnementExpert = () => {
  const classes = useEmotionStyles(styles);
  const {values}: FormikValues = useFormikContext();

  return (
    <div className={classes.cardContent}>
      <Typography variant={'h3'}>Missionnement de l’expert</Typography>
      <InputTextField
        name={'missionnement.codeAbonne'}
        label={'Code abonné de l\'expert'}
        mandatory={true}
        maxLength={12}
      />
      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <InputTextField
          name='missionnement.codeExpert'
          label='Code expert chez Assureur'
          mandatory={false}
          maxLength={14}
        />
        <InputSelectDtField
          label={'Nature de la mission'}
          name={'missionnement.natureMission'}
          mandatory={true}
          domain={DOMAIN.AR2}
          typeDT={'90040102'}
        />
      </div>
      <div className={`${classes.flex} ${classes.marginTop24}`}>
        <InputTextField
          name='missionnement.dateDepot'
          label='Date de dépôt'
          type='date'
          mandatory={false}
        />
        <InputTextField
          name='missionnement.dateMissionnement'
          label='Date de missionnement'
          type='date'
          mandatory={true}
        />
      </div>
      <div className={classes.marginTop24}>
        <InputSelectDtField
          label={'Lieu d\'expertise'}
          name={'missionnement.lieuExpertise'}
          mandatory={true}
          domain={DOMAIN.AR2}
          typeDT={'90030301'}
        />
      </div>

      {infosLieuExpertise.includes(values.missionnement.lieuExpertise) &&
        <div>
          <Typography className={classes.marginTop24} variant={'h3'}>Adresse du lieu d’expertise</Typography>
          <div className={`${classes.flex} ${classes.marginTop24}`}>
            <InputTextField
              name='missionnement.nom'
              label='Nom'
              mandatory={true}
              maxLength={32}
            />
            <InputTextField
              name='missionnement.adresse'
              label='Adresse'
              mandatory={false}
              maxLength={32}
            />
            <InputTextField
              name='missionnement.adresseComplementaire1'
              label='Adresse complémentaire 1'
              mandatory={false}
              maxLength={32}
            />
            <InputTextField
              name='missionnement.adresseComplementaire2'
              label='Adresse complémentaire 2'
              mandatory={false}
              maxLength={32}
            />
            <LocaliteAutocompleteField
              id='localite-missionnement'
              label='Code postal - Localité'
              name='missionnement.localite'
              isMandatory={true}
            />
            <InputSelectDtField
              name='missionnement.pays'
              label='Pays'
              typeDT={'91090101'}
              domain={DOMAIN.AR2}
              mandatory={false}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default MissionnementExpert;
